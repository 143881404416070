<template>
  <div class="app-container change-password">

    <a-card>
      <p class="change-password_title">个人设置</p>
      <a-row>
        <a-col :span="20">
          <a-form-model ref="form" :model="user" :rules="rules" label-width="100px">
            <a-form-model-item label="头像">
              <a-upload name="file" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :action="baseUrl+'system-file/file/upload/image'" :before-upload="beforeUpload" :headers="headers" :max-count="1" @change="logoUploadChange">
                <img v-if="user.picture" :src="baseUrl+user.picture" :width="200"  alt="LOGO" />
                <div v-else>
                  <loading-outlined v-if="logoLoading"></loading-outlined>
                  <div class="ant-upload-text">{{$t('user.apply.companyInfo.logo.btn')}}</div>
                </div>
              </a-upload>
              <!-- <div style="position: relative;top: -15px; color: #5B5B5B;font-size: 13px;">
                <span style="color: red;">* </span>
                {{ $t('user.apply.companyInfo.head.hint') }}
              </div> -->
            </a-form-model-item>
            <a-form-model-item label="用户名" prop="username">
              <a-input v-model="user.username" placeholder="请输入用户名" type="text"/>
            </a-form-model-item>
            <!-- <a-form-model-item label="手机号" prop="phoneNumber">
              <a-input v-model="user.tel" v-decorator="[
                'phone',
                {
                  rules: [{ required: true, message: '请输入手机号' }],
                },
              ]" style="width: 100%">

              </a-input>
            </a-form-model-item> -->
            <a-form-model-item label="邮箱" prop="email">
              <a-input v-model="user.mail" type='email' />
            </a-form-model-item>
            <a-form-model-item label="邮箱密码" prop="" v-if="!ishidden">
               <a-input-password v-model="user.mailPassword" placeholder="请输入邮箱密码" />
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="24">
          <a-button @click="submit">
            保存
          </a-button>
        </a-col>
      </a-row>
    </a-card>

  </div>
</template>

<script>
import { updata,getUser } from "@/api/account/personSetting";
import {getInfo} from "@/api/login"
import { throws } from "assert";
import {download} from "@/utils/util"
export default {
  name: "PersonSetting",
  components: {
    // AvatarModal
  },
  data() {
    return {
      user: {
        username: "",
        tel: "",
        mail: "",
        picture: "",
        mailPassword:""
      },
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers:{'Authorization':this.$store.getters.token},
      logoUrl: "",
      logoLoading: false,
      visible: false,
      // 表单校验
      rules: {
        username: [
          {required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        phoneNumber: [
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        email: [
          {
            type: 'email',
            message: '请输入正确邮箱地址',
          },
        ]
      },
      ishidden:true
    };
  },
  activated() {
  },
  created() {
    const isconpanyId  = localStorage.getItem('companyId')
    if(isconpanyId){
      //普通会员
      this.ishidden = true
    }else{
      this.ishidden = false
    }
    // console.log(isconpanyId,'111')
  },
  mounted() {
    this.getUser()
    // setTimeout(() => {
    //   let arr = this.$store.getters.userInfo
    //   this.user.username = arr.username
    //   this.user.mail = arr.mail
    //   console.log("arr",arr);
    // }, 0)
  },
  methods: {
    getUser() {
      getUser().then(res => {
        if(res.code == 200) {
          this.user = res.data
        }
      })
    },

    setavatar(img) {
      this.user.img = img
    },

    beforeUpload(file) {
      // console.log(file);
      const isJpgOrPng=
        file.type==="image/jpeg"||
        file.type==="image/jpg"||
        file.type==="image/png";
      if(!isJpgOrPng) {
        this.$message.error("只能上传jpg/png格式的图片");
      }
      const isLt10M=file.size/1024/1024<10;
      if(!isLt10M) {
        this.$message.error("图片不得大于10MB!");
      }
      return isJpgOrPng&&isLt10M;
    },
    // 上传公司logo
    logoUploadChange(info) {
      console.log('info',info);
      if(info.file.status==='uploading') {
        this.logoLLoading=true;
        return;
      }
      if(info.file.status==='done') {
        this.logoLLoading=false;
        if(info.file.response.code==200) {
          this.user.picture = info.file.response.data;       
          // this.formRef.form.setFieldsValue({ logoUrl: this.logoUrl });
          updata({picture: this.user.picture}).then(res => {
            if (res.code==200) {
              this.$message.success("修改成功")
            } else {
              this.$message.error(res.msg)
            }
            
          });
        }
      }
      if(info.file.status==='error') {
        this.logoLLoading=false;
        this.$message.error('上传失败，请稍后重试！');
      }

    },

    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          console.log("表单验证成功");
          updata(this.user).then(res => {
            if (res.code==200) {
              this.$message.success("修改成功")
              this.$nextTick(() => {
                  this.$refs.form.clearValidate();
              });
            } else {
              this.$message.error(res.msg)
            }
            
          });
        }
      });
    },
    editCompany() {
      console.log('修改信息')
      const companyId = localStorage.getItem('companyId');
      if(companyId){
        this.$router.push({
          path:'/dashboard/workplace',
          query:{
            tipsCompany:{
              id:companyId
            }
          }
        })
      }
      
    }
  }
};
</script>

<style lang="less" scoped>
.change-password {
  width: 60%;
  margin: 0 auto;
  margin-top: 30px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.047) 0px 0px 5px;
  &_title {
    color: #666666;
    font-weight: 700;
    font-size: 18px;
    margin-left: 20px;
    padding-left: 10px;
    border-left: 6px solid #409eff;
    margin-bottom: 60px;
  }
}
</style>

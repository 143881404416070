import request from "@/utils/request";

const Api = {
  Find: "/system-mms-user/mailSendLog/mailSendLogs",
  SendEmail: "/system-mms-user/send/mail",
  SendTemplateEmail: "/system-mms-user/send/mailTemplate",
};

//发送自定义邮件
export function sendEmail(parameter) {
  return request({
    url: Api.SendEmail,
    method: "post",
    data: parameter,
  });
}
//发送模板邮件
export function sendTempalteEmail(parameter) {
  return request({
    url: Api.SendTemplateEmail,
    method: "post",
    data: parameter,
  });
}
//分页查询已发送邮件
export function findSendEmails(parameter) {
  return request({
    url: Api.Find,
    method: "post",
    data: parameter,
  });
}

<template>
  <div class="ReportsTitle">
    <div class="main">
      <p class="tit">{{title}}</p>
      <div class="iconfont" @click="drop">
        <img :class="isShow ? '':'transroate'" :src="downimg" alt="下拉">
      </div>
    </div>
    <div :class="isShow? '':'formishidden'">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import downimg from '@/assets/icons/down.png'
export default {
  name: 'ReportsTitle',
  props: {
    title: {
      type: String,
      require: true,
    },
  },
  components: {},
  data() {
    return {
      downimg,
      isShow: true,
    }
  },
  watch: {},
  created() {},
  methods: {
    drop() {
      this.isShow = !this.isShow
      // console.log('下拉切换')
    },
  },
}
</script>

<style scoped lang="less">
p {
  margin: 0;
}
.formishidden {
  display: none;
}
.ReportsTitle {
  .main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #efefef;
    color: #333;
    font-weight: bold;
    border-radius: 1px;
    padding: 10px 10px;
    margin-bottom: 10px;
    .tit {
      font-size: 16px;
    }
    .iconfont {
      img {
        cursor: pointer;
        width: 15px;
        transition: all 0.3s;
      }
      .transroate {
        transform: rotate(-90deg);
      }
    }
  }
}
</style>

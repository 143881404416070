import request from "@/utils/request";
import {mmsRequest} from "@/utils/request";

const userApi = {
  Login: "/system-auth/login",
  Logout: "/system-auth/logout",
  MMSLogin: "/user/login",
  MMSLogout: "/logout",

  // ForgePassword: "/auth/forge-password",
  // Apply: "/companyInfo/add",
  // get my info
  UserInfo: "/system-mms-user/user/info",
  UserMenu: "/system-mms-user/user/menus",
};

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return request({
    url: userApi.Login,
    method: "post",
    data: parameter,
  });
}
/**
 * get user info
 */
export function getInfo() {
  return request({
    url: userApi.UserInfo,
    method: "post",
  });
}

/**
 * 获取用户菜单
 */
export function getCurrentUserNav(parameter) {
  return request({
    url: userApi.UserMenu,
    method: "post",
    data: parameter,
  });
}

/**
 * 退出
 */
export function logout() {
  return request({
    url: userApi.Logout,
    method: "post",
  });
}
/**
 * @param parameter
 * @returns {*}
 */
export function mmsLogin(parameter) {
  return mmsRequest({
    url: userApi.MMSLogin,
    method: "post",
    data: parameter,
  });
}
/**
 * 退出
 */
export function mmsLogout() {
  return mmsRequest({
    url: userApi.MMSLogout,
    method: "post",
  });
}
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      staticStyle: { top: "20px" },
      attrs: {
        title: "代码",
        footer: null,
        visible: _vm.visible,
        wrapClassName: "code-modal-9136076486841527",
        width: "850px",
        destroyOnClose: true,
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-tabs",
        { staticStyle: { height: "100%" }, attrs: { tabPosition: "left" } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "VUE" } },
            [
              _c("previewCode", {
                attrs: { editorJson: _vm.editorVueJson, fileFormat: "vue" },
              }),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "HTML" } },
            [
              _c("previewCode", {
                attrs: { editorJson: _vm.editorHtmlJson, fileFormat: "html" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
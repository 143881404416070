<template>
  <div class="reports">
    <a-card class="noborder top-header">
      <ReportsHeaderNew  @search="headerSearch" :formList="headerFormList"></ReportsHeaderNew>
    </a-card>
    <a-card title="信息列表" class="noborder bottom-card">
      <div slot="extra">
      </div>
      <a-table :columns="isAdmin?columns:columnsAdmin" :rowKey="record => record.id + record.dynamicFormId" :data-source="data" :pagination="pagination" @change="tableChange" :scroll="{ x: true }">
        <template slot="reportStatus" slot-scope="text">
          <div class="hzzz">
            {{handleStatus(text)}}
          </div>
        </template>
        <div slot="recallStatus" slot-scope="text" v-if="isAdmin">
          <template v-if="text == 0"><span class="tblock tag-red">未催</span></template>
          <template v-if="text == 1"><span class="tblock tag-green">已催</span></template>
        </div>
        <template slot="caozuo" slot-scope="text,record">
          <div style="text-align: center;">
            <span class="send" @click="editForm(record,'view')" v-if="record.type != 0">
              {{ $t('reports.operate.View') }}
            </span>
            <span class="send" @click="editForm(record,'edit')" v-if="isAdmin || record.reportStatus == 1">
              {{ isAdmin?$t('reports.operate.Edit'):'填报' }}
            </span>
          </div>

        </template>
      </a-table>
    </a-card>
    <!-- 声明 -->
    <a-modal width="65%" title="" :maskClosable="false" :visible="announceVisible" :closable="false">
      <div class="announce-modal-content">
        <h3>免责声明</h3>
        <div>中国电子电路行业协为更客观准确的反映电子电路行业发展态势，正确把握2024年发展趋势，现就填报2023年度中国电子电路行业企业数据统计工作。请各单位积极配合做好企业数据材料的收集、审核及报送工作，确保填报的数据真实准确。提交后不可更改数据，如若一定要更改数据，需提交更改说明书并加盖公章，更改联系<strong>CPCA</strong>信息部张国旗，<strong>021-54179011-612，19121739850，info@cpca.org.cn。</strong>
        </div>
        <div>协会对获取的企业单位数据、商业秘密等严格保密，严格管理，根据企业提供的数据，采集主要数据将用于《第二十三届（2023）中国电子电路行业主要企业榜单》和《中国电子电路行业发展年报（2024）》的编制、《第六届中国电子电路行业优秀企业评选活动》和向政府有关部门提供行业信息的依据，也是作为协会出具企业行业排名、市场占有率、申报各级制造业单项冠军、专精特新中小企业等证明材料的重要依据，凡参加本次数据填报的企业，协会可提供相关服务。填报数据要真实可靠，统计数据造假将影响企业的诚信记录，由此造成的后果企业自负。
        </div>    
      
        <div>协会以企业自律自主填报数据为准，对会员企业填报的数据的准确性、完整性、及时性不负有任何形式的担保或保证，也不对数据的使用和发布的结果承担任何法律责任和经济责任。本免责声明以及其修改权、更新权及最终解释权均属于中国电子电路行业协会。</div>

      </div>
      <template slot="footer">
        <span style="color: red;float: left;line-height: 30px;font-size: 16px;">请仔细阅读“免责声明”后，点击“确定”，进行下一步“表单填写”</span>
        <a-button key="back" @click="announceHandleCancel">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="handleAnnounce">
          确定
        </a-button>
      </template>
    </a-modal>
    <viewForm ref="viewForm" :width="'80%'" @toSubmitForm="toSubmitForm"></viewForm>
  </div>
</template>

<script>
import { getFormById } from "@/api/form/formDesign.js"
import { addDraft, findCompanyFill, update } from "@/api/quarter/filling.js"
import { toSubmitForm } from "@/api/form/allForm.js"
import viewForm from "@/components/KFormDesign/packages/components/viewForm/index.vue"
import ReportsHeaderNew from '@/components/MasCmponent/ReportsHeaderNew.vue'
import { EnterpriseInfos } from "@/api/quarter/index.js"
import { getDataList, saveDraft_, EchoData, updstatus_, rushTo ,download_ ,enterpriseInfoUpdate} from '@/api/mas/pcb.js'
import detailpng from '@/assets/icons/detail.png'
import editpng from '@/assets/icons/edit.png'
import resetpng from '@/assets/icons/reset.png'
let vm = null
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    vm.rowSelectionList =  selectedRowKeys
  },
}
export default {
  name: 'reports',
  props: {
    type: {
      type: String,
    },
    headerFormList: {
      type: Array,
    },
  },
  components: {
    ReportsHeaderNew,
    viewForm
  },
  data() {
    return {
      yearSelectList: [
        { name: '2021年', key: '2021' },
        { name: '2022年', key: '2022' },
        { name: '2023年', key: '2023' },
        { name: '2024年', key: '2024' },
      ],
      monthSelectList: [
        { name: '第一季度', key: 'Q1' },
        { name: '第二季度', key: 'Q2' },
        { name: '第三季度', key: 'Q3' },
        { name: '第四季度', key: 'Q4' },
      ],
      quarterSelectForm: {
        year: undefined,
        month: undefined
      },
      quarterSelectFormRules: {
        year: [{ required: true, message: '请选择年份', trigger: 'change' }],
        month: [{ required: true, message: '请选择季度', trigger: 'change' }],
      },
      rowSelectionList:[],
      sendType:0,
      enterpriseInfoForm:{},
      rowId:'',
      basicCompanyss:false,
      headers:{'Authorization':this.$store.getters.token},
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      fileList: [],
      uploading: false,
      row:{},
      isAdmin: this.$store.getters.nickname&&this.$store.getters.nickname.indexOf('admin')>-1,
      detailpng,
      editpng,
      resetpng,
      rowSelection,
      yearvisible: false,
      isbtnLoading: false,
      businesvisible: false,
      announceVisible:false,
      pagination: {
        total: 0,
        pageIndex: 1,
        current: 1,
        pageSize: 10, //每页中显示10条数据
        showTotal: (total) => `共 ${total} 条`,
        size: 'small',
      },
      columns: [
        {
          title: '单位名称',
          key: 'enterpriseName',
          dataIndex: 'enterpriseName',
          scopedSlots: { customRender: 'enterpriseName' },
          width: '200px',
        },
        {
          title: '填报状态',
          dataIndex: 'reportStatus',
          key: 'reportStatus',
          align: 'center',
          width: '80px',
          scopedSlots: { customRender: 'reportStatus' },
        },
        {
          title: '表单标题',
          dataIndex: 'dynamicFormTitle',
          key: 'dynamicFormTitle',
          width: '100px',
        },
        {
          title: '联系人',
          key: 'contactName',
          width: '100px',
          dataIndex: 'contactName',
          scopedSlots: { customRender: 'contactName' },
        },
        {
          title: '联系方式',
          key: 'contactPhone',
          width: '120px',
          dataIndex: 'contactPhone',
          scopedSlots: { customRender: 'contactPhone' },
        },
        {
          title: '邮箱',
          key: 'contactMail',
          width: '120px',
          dataIndex: 'contactMail',
          align: 'center',
        },
        {
          title: '单位地址',
          key: 'communicationAddress',
          dataIndex: 'communicationAddress',
          width: '200px',
        },
        // {
        //   title: '催报状态',
        //   key: 'recallStatus',
        //   dataIndex: 'recallStatus',
        //   scopedSlots: { customRender: 'recallStatus' },
        //   align: 'center',
        // },
        {
          title: '操作',
          key: 'caozuo',
          dataIndex: 'caozuo',
          width: '200px',
          fixed: 'right',
          scopedSlots: { customRender: 'caozuo' },
          align: 'center',
        },
      ],
      columnsAdmin:[
        // {
        //   dataIndex: 'enterpriseBusinessType',
        //   key: 'enterpriseBusinessType',
        //   title: '申报类型',
        //   width: '100px',
        //   scopedSlots: { customRender: 'enterpriseBusinessType' },
        // },
        // {
        //   title: '年度',
        //   dataIndex: 'timeInterval',
        //   key: 'timeInterval',
        //   width: '100px',
        // },
        // {
        //   title: '季度',
        //   dataIndex: 'quarterType',
        //   key: 'quarterType',
        //   width: '100px',
        //   align: 'center',
        //   scopedSlots: { customRender: 'quarterType' },
        // },
        {
          title: '单位名称',
          key: 'enterpriseName',
          dataIndex: 'enterpriseName',
          scopedSlots: { customRender: 'enterpriseName' },
          width: '200px',
        },
        {
          title: '填报状态',
          dataIndex: 'reportStatus',
          key: 'reportStatus',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'reportStatus' },
        },
        {
          title: '表单标题',
          dataIndex: 'dynamicFormTitle',
          key: 'dynamicFormTitle',
          width: '100px',
        },
        {
          title: '联系人',
          key: 'contactName',
          width: '100px',
          dataIndex: 'contactName',
          scopedSlots: { customRender: 'contactName' },
        },
        {
          title: '联系方式',
          key: 'contactPhone',
          dataIndex: 'contactPhone',
          scopedSlots: { customRender: 'contactPhone' },
        },
        {
          title: '邮箱',
          key: 'contactMail',
          dataIndex: 'contactMail',
          align: 'center',
        },
        {
          title: '单位地址',
          key: 'communicationAddress',
          dataIndex: 'communicationAddress',
          width: '200px',
        },
        {
          title: '操作',
          key: 'caozuo',
          dataIndex: 'caozuo',
          width: '200px',
          fixed: 'right',
          scopedSlots: { customRender: 'caozuo' },
        },
      ],
      data: [],
      annualform: {
        enterpriseId: '',
        quarterType: '',
      },
      enterpriseId: '',
      quarterType:'',
      enterpriseBusinessType: '',
      formMode: '', //view edit add
      recordId: '',
      stepcurrent: 0,
      steparr: [],
      title: '',
      headerquery: {}, //查询条件
      formTypeisdraft: false, //当前修改的是否是草稿状态
      fileLists: [],

      
    }
  },
  watch: {},
  created() {
    vm = this
    if(this.isAdmin){
      this.timeInterval = new Date().getFullYear - 1;
      
    }
    if (!this.isAdmin) {
      this.columns.splice(7,1)
    }
  },
  methods: {
    //查询
    headerSearch(parmas) {
      this.pagination.pageIndex = 1,
      this.pagination.current = 1,
      this.headerquery = parmas
      this.findFormsByType()
    },
    findFormsByType() {
      let data = {
        pageIndex: this.pagination.pageIndex,
        pageSize: this.pagination.pageSize,
        object: this.headerquery,
      }
      data.object["type"] = this.type
      EnterpriseInfos(data).then((res) => {
        if (res.code === 200) {
          this.pagination.total = res.data.total
          this.data = res.data.pageObject
        } else {
          this.data = []
          this.pagination.total = 0
          this.$message.error(res.msg)
        }
      })
    },
  
    handleStatus(val) {
      let res = ''
      const status = [
        {
          label: '未填',
          value: '1',
        },
        {
          label: '已填',
          value: '2',
        },
      ]
      const finddata = status.find((item) => item.value == val)
      if (finddata) {
        res = finddata.label
      }

      return res
    },
    // 取消声明
    announceHandleCancel() {
      this.announceVisible = false;
    },
    handleAnnounce() {
      this.announceVisible = false;
      this.viewForm("write")
    },

    tableChange(pageobj) {
      this.pagination.pageIndex = pageobj.current
      this.pagination.current = pageobj.current
      this.findFormsByType()
    },
    editForm(record, type) {
      this.row = record
      this.enterpriseBusinessType = record.enterpriseBusinessType
      this.quarterType = record.quarterType
      this.formMode = type
      this.enterpriseId = record.enterpriseId
      if (type == 'view') {
        this.viewForm("view")
      } else {
        if (this.headerquery.year==2023) {          
          this.announceVisible = true;
        }else{
          this.viewForm("write")
        }
      }
    },
    // 查询表单
    viewForm(type) {
      this.popFormType = type
      let formDate = ""
      // console.log("this.row",this.row);
      getFormById({id: this.row.dynamicFormId}).then(res => {
        if (res.code == 200) {
          let object = {
            year: res.data.year,
            type: res.data.type,
            enterpriseId: this.row.id,
            dynamicFormId: this.row.dynamicFormId
          }
          this.$refs.viewForm.viewForm(res, type, this.row, formDate)
          findCompanyFill(object).then(res1 => {
            formDate = res1.data && res1.data.dynamicFormData? res1.data.dynamicFormData : ""
            if (res1.code == 200) {
              this.$refs.viewForm.viewForm(res, type, this.row, formDate)
            } else {
              this.$message.error(res1.msg)
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    toSubmitForm(type,object) {
      object.enterpriseId = this.row.id
      if (type == "draft") {
        let draftObject = {
          enterpriseId: object.enterpriseId,
          dynamicFormId: object.dynamicFormId,
          content: object.dynamicFormData
        }
        addDraft(draftObject).then(res => {
          this.getMessage(res, "保存成功")
        })
      } else {
        if (this.row.reportStatus == '1') {
          toSubmitForm(object).then(res => {
            this.getMessage(res, "提交完成")
            if (res.code == 200) {
              this.$refs.viewForm.visible = false
              this.findFormsByType()
            }
          })
        } else {
          update(object).then(res => {
            this.getMessage(res, "修改完成")
            if (res.code == 200) {
              this.$refs.viewForm.visible = false
              this.findFormsByType()
            }
          })
        }
      }
    },
    getMessage(res, message) {
      if (res.code == 200) {
        this.$message.success(message)
      } else {
        this.$message.error(res.msg)
      }
    },
    upstatus(record) {
      //reportStatus
      let that = this
      this.$confirm({
        title: '是否驳回当前公司的填报状态?',
        onOk() {
          const { id, reportStatus } = record
          updstatus_(id, '2').then((res) => {
            that.headerSearch()
          })
        },
        onCancel() { },
      })
    },
    /**
     * @val 切换form的type值
     */
    /**
     * @data 用户提交表单数据
     * @formType 提交的是 pcb |ccl |其他类型表单
     */
    handleCancel() {
      this.yearvisible = false
      this.stepcurrent = 0
      this.enterpriseBusinessType = ''
      this.headerSearch()
    },
    takeout() {
      EchoData(this.enterpriseBusinessType).then((res) => {
        if (res.code === 200) {
          // console.log(res, '取出数据')
          let data = res.data
          try {
            let content = JSON.parse(data.content)
            this.stepcurrent = content.current
            if (!content.enterpriseInfo.id) {
              content.enterpriseInfo = JSON.parse(JSON.stringify(this.annualform.enterpriseInfo))
            }
            this.annualform = content
          } catch (error) {
            this.$message.error('暂无此申报表单的草稿数据')
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.reports {
  background: #f3f3f3;
  height: 100%;
  // padding: 24px 24px;
  display: flex;
  flex-direction: column;
  .noborder {
    border: none;
    border-radius: 10px;
  }
  .top-header {
    margin-bottom: 24px;
  }
  .bottom-card {
    flex: 1;
    .ant-card-extra {
      .add {
        margin-right: 24px;
      }
    }
  }

  .caozuo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    img {
      width: 18px;
    }
  }

  .hzzz {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
}
.announce-modal-content{
  h3{text-align: center;line-height: 25px;}
  div {text-indent: 2em;}
}
</style>
<style lang="less">
.yearModal {
  .ant-modal {
    top: 50px;
  }
  .ant-modal-body {
    max-height: calc(100vh - 200px);
    overflow: auto;
  }
}

.send {
  display: inline-block;
  border: #5199F8 1px solid;
  padding: 2px;
  border-radius: 5px;
  margin-right: 15px;
  color: #5199F8;
}
.send:hover{
  border: #5ee34a 1px solid;
  color: #5ee34a;
  cursor: pointer;
}
.send:active{
  border: #288719 1px solid;
  color: #288719;
}

.send-admin{
  font-size:14px;
  margin: 5px;
  color: #5199F8;
}

.send-admin:hover{
  color: #5ee34a;
}
.send-admin:active{
  color: #288719;
}
</style>

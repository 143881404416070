<template>
  <div>
    <div style="overflow:hidden;margin-bottom: 0; padding-bottom: 0;">
        <div v-for="(news,index) in memberNews" :key="index">

        <h4 v-if="news.title">{{ $t(news.title) }}</h4>
        <h4 v-if="news.content" v-html="news.content"></h4>
        <p class="creattime-size" v-if="news.content || news.title">{{ $t(news.createTime) }} <a-divider style="margin: 0; padding: 0;" /></p>
        
        </div>
        
    </div>
    <div style="margin-top: 0; padding-top: 0;"><a-button type="link" @click="moreNews">查看更多</a-button></div>
    
  </div>
</template>

<script>

export default {
  name: 'News',
  data() {
    return {
      memberNews:[]
      
    }
  },
  mounted() {
    
    setTimeout(() => {
      this.memberNews = this.$store.getters.messageNews;
    }, 0),

    this.search()
  },

  methods: {
    search() {
      console.log("memberNews ,creatTime", this.memberNews.title);
      console.log("memberNews--1", this.$store);
    },
    moreNews() {
    this.$router.push({ path: '/info/stationMessage' })
    }
    
  },

  
}
</script>

<style>
.creattime-size{
  font-size: 12px;
  color: #565050;

}

</style>
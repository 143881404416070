import request from "@/utils/request";

const Api = {
  Find: '/system-mms-user/station_message/find',
  Add: '/system-mms-user/station_message/sendMessage',
  AddTem: '/system-mms-user/station_message/sendTemplateMessage',
  FindStationMessage: '/system-message/reminder/reminders',
  MarkRead: '/system-mms-user/station_message/haveRead',
  EnterpriseInfoAll: '/system-business/enterpriseInfo/enterpriseInfoAll',
  EnterpriseReminder: '/system-message/reminder/enterpriseReminder',
};

//发送站内消息
export function sendInfo(parameter) {
  return request({
    url: Api.Add,
    method: "post",
    data: parameter,
  });
}

export function enterpriseInfoAll(parameter) {
  return request({
    url: Api.EnterpriseInfoAll,
    method: 'post',
    data: parameter,
  });
}

export function enterpriseReminder(parameter) {
  return request({
    url: Api.EnterpriseReminder,
    method: 'post',
    data: parameter,
  });
}
//发送站内模板消息
export function sendTempalteInfo(parameter) {
  return request({
    url: Api.AddTem,
    method: "post",
    data: parameter,
  });
}
//分页查询站内信管理
export function findSendInfos(parameter) {
  return request({
    url: Api.FindStationMessage,
    method: "post",
    data: parameter,
  });
}



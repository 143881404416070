var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("page-header-wrapper", [
    _c(
      "div",
      [
        _c(
          "a-row",
          { attrs: { gutter: 24 } },
          [
            _c(
              "a-col",
              {
                staticClass: "col-flex",
                attrs: { xl: 16, lg: 12, md: 12, sm: 24, xs: 24 },
              },
              [
                _vm.isAdmin
                  ? [
                      _c("div", { staticClass: "apply Container_info" }, [
                        _c("div", { staticClass: "title_box" }, [
                          _c("div", { staticClass: "t" }, [
                            _vm._v("每日填报监控"),
                          ]),
                        ]),
                        _c("div", {
                          ref: "chart",
                          staticStyle: {
                            width: "48%",
                            height: "86%",
                            margin: "0",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "main",
                            staticStyle: {
                              "border-left": "2px dashed #DDE0E5",
                              position: "absolute",
                              left: "50%",
                              top: "16%",
                              height: "80%",
                              width: "50%",
                              padding: "10px !important",
                            },
                          },
                          [
                            _vm.fillingDatas.length
                              ? _c("a-table", {
                                  attrs: {
                                    columns: _vm.fillingColumns,
                                    "data-source": _vm.fillingDatas,
                                    pagination: false,
                                    scroll: { y: _vm.tableHeight },
                                    "row-key": (record, index) => index,
                                  },
                                })
                              : _c("a-empty", { staticClass: "posempty" }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "pagin_box" },
                          [
                            _c("a-pagination", {
                              attrs: {
                                size: "small",
                                total: _vm.fillingTotal,
                                defaultPageSize: 5,
                                hideOnSinglePage: "",
                              },
                              on: {
                                change: (current, pageSize) =>
                                  _vm.pageChange(current, pageSize, "filling"),
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "message Container_info" }, [
                        _c("div", { staticClass: "title_box" }, [
                          _c("div", { staticClass: "t" }, [
                            _vm._v("填报情况统计 "),
                            _vm.areaTotal
                              ? _c("span", [
                                  _vm._v("( " + _vm._s(_vm.areaTotal) + " )"),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c("div", {
                          ref: "reportChart",
                          staticStyle: {
                            width: "28%",
                            height: "86%",
                            margin: "10px 0",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "20%",
                              height: "86%",
                              position: "absolute",
                              top: "40%",
                              left: "28%",
                            },
                          },
                          [
                            _c("span", [_vm._v("未上报")]),
                            _vm._v(" "),
                            _c("span", { staticStyle: { float: "right" } }, [
                              _vm._v(_vm._s(_vm.failToReportedProportion)),
                            ]),
                            _c("a-progress", {
                              attrs: {
                                type: "line",
                                strokeColor: "#9ECA7F",
                                percent: Number(
                                  _vm.failToReportedProportion.split(".")[0]
                                ),
                                "show-info": false,
                              },
                            }),
                            _c("div"),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "margin-top": "25%",
                                  display: "inline-block",
                                },
                              },
                              [_vm._v("已上报")]
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  float: "right",
                                  "margin-top": "25%",
                                },
                              },
                              [_vm._v(_vm._s(_vm.haveReportedProportion))]
                            ),
                            _c("a-progress", {
                              attrs: {
                                percent: Number(
                                  _vm.haveReportedProportion.split(".")[0]
                                ),
                                strokeColor: "#2953E3",
                                "show-info": false,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "border-left": "2px dashed #DDE0E5",
                              position: "absolute",
                              left: "50%",
                              top: "16%",
                              height: "80%",
                              width: "50%",
                              padding: "10px !important",
                            },
                          },
                          [
                            _vm.areaDatas.length
                              ? _c("a-table", {
                                  attrs: {
                                    columns: _vm.areaColumns,
                                    "data-source": _vm.areaDatas,
                                    pagination: false,
                                    scroll: { y: _vm.tableHeight },
                                    "row-key": (record, index) => index,
                                  },
                                })
                              : _c("a-empty", { staticClass: "posempty" }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "pagin_box" },
                          [
                            _c("a-pagination", {
                              attrs: {
                                size: "small",
                                total: _vm.areaTotal,
                                defaultPageSize: 5,
                                hideOnSinglePage: "",
                              },
                              on: {
                                change: (current, pageSize) =>
                                  _vm.pageChange(current, pageSize, "area"),
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : [
                      _c("div", { staticClass: "message Container_info" }, [
                        _c("div", { staticClass: "title_box" }, [
                          _c("div", { staticClass: "t" }, [
                            _vm._v("已填报数据 "),
                            _vm.filledTotal
                              ? _c("span", [
                                  _vm._v("( " + _vm._s(_vm.filledTotal) + " )"),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "main" },
                          [
                            _vm.filledData.length
                              ? _c("a-table", {
                                  attrs: {
                                    columns: _vm.filledColumns,
                                    "data-source": _vm.filledData,
                                    pagination: false,
                                    scroll: { y: _vm.tableHeight },
                                    "row-key": (record, index) => index,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "fillingType",
                                        fn: function (text, record) {
                                          return _c(
                                            "div",
                                            {
                                              staticStyle: { padding: "6px 0" },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(record.title)),
                                              ]),
                                            ]
                                          )
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4044585145
                                  ),
                                })
                              : _c("a-empty", { staticClass: "posempty" }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "pagin_box" },
                          [
                            _c("a-pagination", {
                              attrs: {
                                size: "small",
                                total: _vm.filledTotal,
                                defaultPageSize: 5,
                              },
                              on: {
                                change: (current, pageSize) =>
                                  _vm.pageChange(current, pageSize, "filled"),
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
              ],
              2
            ),
            _c(
              "a-col",
              {
                staticClass: "col-flex-pay",
                attrs: { xl: 8, lg: 12, md: 12, sm: 24, xs: 24 },
              },
              [
                _vm.isAdmin
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "pay Container_info",
                          attrs: { loading: _vm.loading },
                        },
                        [
                          _c("div", { staticClass: "title_box" }, [
                            _c("div", { staticClass: "t" }, [
                              _vm._v("催报统计"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "main" },
                            [
                              _c(
                                "a-radio-group",
                                {
                                  staticClass: "gp",
                                  attrs: {
                                    "default-value": "1",
                                    "button-style": "solid",
                                  },
                                  on: { change: _vm.changeUrgeStatus },
                                },
                                [
                                  _c(
                                    "a-radio-button",
                                    { attrs: { value: "1" } },
                                    [
                                      _vm._v(
                                        " 已催报 (" +
                                          _vm._s(this.urgeTotal) +
                                          ")"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "a-radio-button",
                                    { attrs: { value: "0" } },
                                    [
                                      _vm._v(
                                        " 待催报 (" +
                                          _vm._s(this.notUrgeTotal) +
                                          ")"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.urgeStatus == 1
                                ? _c(
                                    "div",
                                    [
                                      _vm.urgeData.length
                                        ? _c("a-table", {
                                            attrs: {
                                              columns: _vm.urgeColumns,
                                              "data-source": _vm.urgeDatas,
                                              pagination: false,
                                              scroll: { y: _vm.tableHeight },
                                              "row-key": (record, index) =>
                                                index,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "urgeStatus",
                                                  fn: function (text) {
                                                    return _c(
                                                      "div",
                                                      {},
                                                      [
                                                        text == 1
                                                          ? [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "tblock tag-red",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "待催报"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                        text == 2
                                                          ? [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "tblock tag-green",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "已催报"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                        text == 3
                                                          ? [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "tblock tag-green",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "已完成"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    )
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3242917282
                                            ),
                                          })
                                        : _c("a-empty", {
                                            staticClass: "posempty",
                                          }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _vm.notUrgeData.length
                                        ? _c("a-table", {
                                            attrs: {
                                              columns: _vm.urgeColumns,
                                              "data-source": _vm.notUrgeDatas,
                                              pagination: false,
                                              scroll: { y: _vm.tableHeight },
                                              "row-key": (record, index) =>
                                                index,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "urgeStatus",
                                                  fn: function (text) {
                                                    return _c(
                                                      "div",
                                                      {},
                                                      [
                                                        text == 1
                                                          ? [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "tblock tag-red",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "待催报"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                        text == 2
                                                          ? [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "tblock tag-green",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "已催报"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                        text == 3
                                                          ? [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "tblock tag-green",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "已完成"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    )
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3242917282
                                            ),
                                          })
                                        : _c("a-empty", {
                                            staticClass: "posempty",
                                          }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _vm.urgeStatus == 1
                            ? _c(
                                "div",
                                { staticClass: "pagin_box" },
                                [
                                  _c("a-pagination", {
                                    attrs: {
                                      size: "small",
                                      total: _vm.urgeTotal,
                                      defaultPageSize: 15,
                                      hideOnSinglePage: "",
                                    },
                                    on: {
                                      change: (current, pageSize) =>
                                        _vm.pageChange(
                                          current,
                                          pageSize,
                                          "urge"
                                        ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "pagin_box" },
                                [
                                  _c("a-pagination", {
                                    attrs: {
                                      size: "small",
                                      total: _vm.notUrgeTotal,
                                      defaultPageSize: 15,
                                      hideOnSinglePage: "",
                                    },
                                    on: {
                                      change: (current, pageSize) =>
                                        _vm.pageChange(
                                          current,
                                          pageSize,
                                          "notUrge"
                                        ),
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]
                      ),
                    ]
                  : [
                      _c(
                        "div",
                        { staticClass: "message Container_info" },
                        [
                          _c(
                            "div",
                            { staticClass: "title_box" },
                            [
                              _c("div", { staticClass: "t" }, [
                                _vm._v("填报通知"),
                              ]),
                              _c("a-icon", {
                                staticClass: "send-admin",
                                attrs: { type: "right" },
                                on: { click: _vm.jumpFilling },
                              }),
                            ],
                            1
                          ),
                          _vm.filledData1.length
                            ? _c(
                                "div",
                                { staticClass: "main" },
                                [
                                  _c("a-table", {
                                    attrs: {
                                      columns: _vm.filledColumns1,
                                      "data-source": _vm.filledData1,
                                      pagination: false,
                                      scroll: { y: _vm.tableHeight },
                                      "row-key": (record, index) => index,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "fillingType",
                                          fn: function (text, record) {
                                            return _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  cursor: "pointer",
                                                },
                                                on: { click: _vm.jumpFilling },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getFormName(text)
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      164777031
                                    ),
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "pagin_box" },
                                    [
                                      _c("a-pagination", {
                                        attrs: {
                                          size: "small",
                                          total: _vm.filledTotal1,
                                          defaultPageSize: 5,
                                        },
                                        on: {
                                          change: (current, pageSize) =>
                                            _vm.pageChange(
                                              current,
                                              pageSize,
                                              "filled1"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c("a-empty", { staticClass: "posempty" }),
                        ],
                        1
                      ),
                    ],
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default {
  "dashboard.analysis.test": "工专路 {no} 号店",
  "dashboard.analysis.introduce": "指标说明",
  "dashboard.analysis.total-sales": "总销售额",
  "dashboard.analysis.day-sales": "日均销售额￥",
  "dashboard.analysis.visits": "访问量",
  "dashboard.analysis.visits-trend": "访问量趋势",
  "dashboard.analysis.visits-ranking": "访问量排名",
  "dashboard.analysis.day-visits": "日访问量",
  "dashboard.analysis.week": "周同比",
  "dashboard.analysis.day": "日同比",
  "dashboard.analysis.payments": "支付笔数",
  "dashboard.analysis.finish-rate": "完成率",
  "dashboard.analysis.operational-effect": "运营活动效果",
  "dashboard.analysis.sales-trend": "销售趋势",
  "dashboard.analysis.sales-ranking": "门店销售额排名",
  "dashboard.analysis.all-year": "全年",
  "dashboard.analysis.all-month": "本月",
  "dashboard.analysis.all-week": "本周",
  "dashboard.analysis.all-day": "今日",
  "dashboard.analysis.search-users": "搜索用户数",
  "dashboard.analysis.per-capita-search": "人均搜索次数",
  "dashboard.analysis.online-top-search": "线上热门搜索",
  "dashboard.analysis.the-proportion-of-sales": "销售额类别占比",
  "dashboard.analysis.dropdown-option-one": "操作一",
  "dashboard.analysis.dropdown-option-two": "操作二",
  "dashboard.analysis.channel.all": "全部渠道",
  "dashboard.analysis.channel.online": "线上",
  "dashboard.analysis.channel.stores": "门店",
  "dashboard.analysis.sales": "销售额",
  "dashboard.analysis.traffic": "客流量",

  "dashboard.analysis.table.rank": "排名",
  "dashboard.analysis.table.search-keyword": "培训内容",
  "dashboard.analysis.table.users": "用户观看数",
  "dashboard.analysis.table.weekly-range": "周涨幅",
  "dashboard.analysis.member-count": "会员总数",
  "dashboard.analysis.month-range": "月涨幅",
  "dashboard.analysis.project-applies": "项目申请数",
  "dashboard.analysis.member-renewals": "会员续费数",
  "dashboard.analysis.train-top": "热门培训",
  "dashboard.analysis.technology-train": "技术培训",
  "dashboard.analysis.manage-train": "管理培训",
  "dashboard.analysis.the-proportion-of-areas": "地区分布",
  "dashboard.analysis.areas": "地区分布",
  "dashboard.analysis.area.all": "全部地区",
  "dashboard.analysis.area.northwest": "西北",
  "dashboard.analysis.area.southwest": "西南",
  "dashboard.analysis.area.southeast": "东南",
  "dashboard.analysis.area.northeast": "东北",
};

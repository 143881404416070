var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "pro-layout",
    _vm._b(
      {
        attrs: {
          menus: _vm.menus,
          collapsed: _vm.collapsed,
          mediaQuery: _vm.query,
          isMobile: _vm.isMobile,
          handleMediaQuery: _vm.handleMediaQuery,
          handleCollapse: _vm.handleCollapse,
          i18nRender: _vm.i18nRender,
        },
        scopedSlots: _vm._u([
          {
            key: "menuHeaderRender",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "menuBox" },
                  [
                    !_vm.collapsed
                      ? _c("div", { staticClass: "leftLogo" }, [
                          _c("img", {
                            staticClass: "logo",
                            attrs: { src: _vm.logopng, alt: "logo" },
                          }),
                        ])
                      : _c("LogoSvg"),
                    !_vm.collapsed
                      ? _c(
                          "h1",
                          {
                            staticStyle: {
                              "line-height": "23px",
                              "margin-top": "5px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("systemName")))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "headerContentRender",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "a-tooltip",
                      { attrs: { title: _vm.$t("page.reload") } },
                      [
                        _c("a-icon", {
                          staticStyle: {
                            "font-size": "18px",
                            cursor: "pointer",
                          },
                          attrs: { type: "reload" },
                          on: { click: _vm.reload },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-button",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          cursor: "pointer",
                          "margin-left": "15px",
                        },
                        attrs: { type: "primary" },
                        on: { click: _vm.toMms },
                      },
                      [
                        _c("a-icon", { attrs: { type: "left" } }),
                        _vm._v("跳转会员系统"),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "rightContentRender",
            fn: function () {
              return [
                _c("right-content", {
                  attrs: {
                    "top-menu": _vm.settings.layout === "topmenu",
                    "is-mobile": _vm.isMobile,
                    theme: _vm.settings.theme,
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footerRender",
            fn: function () {
              return [_c("global-footer")]
            },
            proxy: true,
          },
        ]),
      },
      "pro-layout",
      _vm.settings,
      false
    ),
    [_vm._t("default"), _c("router-view")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 6
    ? "duration.earlyMorning"
    : hour < 12
    ? "duration.morning"
    : hour < 18
    ? "duration.afternoon"
    : "duration.evening";
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}
const typeOptions = [
  { value: 1, label: "理事长单位" },
  { value: 2, label: "副理事长单位" },
  { value: 3, label: "资深副理事长单位" },
  { value: 4, label: "常务理事单位" },
  { value: 5, label: "资深常务理事单位" },
  { value: 6, label: "监事长单位" },
  { value: 7, label: "副监事长单位" },
  { value: 8, label: "理事单位" },
  { value: 9, label: "普通会员单位" },
];
export {typeOptions};
const promotionStatusOptions = [
  { value: 0, label: "待审核" },
  { value: 1, label: "通过" },
  { value: 2, label: "拒绝" },
];
export { promotionStatusOptions };

const locationOptions = [
  {label:"北京",value:"北京"},
  {label:"天津",value:"天津"},
  {label:"上海",value:"上海"},
  {label:"重庆",value:"重庆"},
  {label:"河北",value:"河北"},
  {label:"山西",value:"山西"},
  {label:"辽宁",value:"辽宁"},
  {label:"吉林",value:"吉林"},
  {label:"黑龙江",value:"黑龙江"},
  {label:"江苏",value:"江苏"},
  {label:"浙江",value:"浙江"},
  {label:"安徽",value:"安徽"},
  {label:"福建",value:"福建"},
  {label:"江西",value:"江西"},
  {label:"山东",value:"山东"},
  {label:"河南",value:"河南"},
  {label:"湖北",value:"湖北"},
  {label:"湖南",value:"湖南"},
  {label:"广东",value:"广东"},
  {label:"海南",value:"海南"},
  {label:"四川",value:"四川"},
  {label:"贵州",value:"贵州"},
  {label:"云南",value:"云南"},
  {label:"陕西",value:"陕西"},
  {label:"甘肃",value:"甘肃"},
  {label:"青海",value:"青海"},
  {label:"台湾",value:"台湾"},
  {label:"内蒙古",value:"内蒙古"},
  {label:"广西",value:"广西"},
  {label:"西藏",value:"西藏"},
  {label:"宁夏",value:"宁夏"},
  {label:"新疆",value:"新疆"},
  {label:"香港",value:"香港"},
  {label:"澳门",value:"澳门"}
];
export { locationOptions };

const classificationOptions = [
  { value: 1, label: "刚性板" },
  { value: 2, label: "挠性板" },
  { value: 3, label: "刚挠板" },
  { value: 4, label: "专用材料" },
  { value: 5, label: "覆铜板" },
  { value: 6, label: "专用化学品" },
  { value: 7, label: "专用设备" },
  { value: 8, label: "环保" },
  { value: 9, label: "其他" },

];
export { classificationOptions };

const billingStatusOptions = [
  { value: 1,label: "待开票" },
  { value: 2,label: "已开票" }
];
export { billingStatusOptions };

const paymentStatusOptions = [
  { value: 1,label: "待缴费" },
  { value: 2,label: "待审核" },
  { value: 3,label: "已完成" }
]
export { paymentStatusOptions };
function generateYearOptions(){
  let finished = false;
  let _yearOptions = [];
  let currentYear = new Date().getFullYear()-1; 
  while(!finished){
    if (currentYear >= 2017) {
      _yearOptions.push({
        value: currentYear,
        label: currentYear + "-" + (currentYear + 1),
      });
      currentYear--;
    } else {
      finished = true;
    }
  }
}

function generateAnnualOptions(){
  let finished = false;
  let _yearOptions = [];
  let currentYear = new Date().getFullYear()-1; 
  while(!finished){
    if (currentYear>=2017) {
      _yearOptions.push({ value: currentYear, label: currentYear + "年" });
      currentYear--;
    } else {
      finished = true;
    }
  }
  return _yearOptions;
}


function generateAnnualOptionsNew() {
  let finished = false;
  let _yearOptions = [];
  let currentYear = new Date().getFullYear();
  while (!finished) {
    if (currentYear >= 2017) {
      _yearOptions.push({ value: currentYear, label: currentYear + '年' });
      currentYear--;
    } else {
      finished = true;
    }
  }
  return _yearOptions;
}

function generateQuarterAnnualOptions() {
  let finished = false;
  let _yearOptions = [];
  let currentYear = new Date().getFullYear();
  while (!finished) {
    if (currentYear >= 2017) {
      _yearOptions.push({ value: currentYear, label: currentYear + "年" });
      currentYear--;
    } else {
      finished = true;
    }
  }
  return _yearOptions;
}

let annualOptions = generateAnnualOptions();
let annualOptionsNew = generateAnnualOptionsNew();
const yearOptions = generateYearOptions();
let quarterAnnualOptions = generateQuarterAnnualOptions();
export { yearOptions, annualOptions, annualOptionsNew, quarterAnnualOptions};

export function findNameByType(type){
  for(let i=0;i<typeOptions.length;i++){
    if(typeOptions[i].value==type){
      return typeOptions[i].label;
    }
  }
  return "";  
}
/**
 * 根据value获取label 常用语选择查询
 * @param {*} value 值
 * @param {*} options 选项数组
 */
export function findLabelByValue(value,options){
  for (let i = 0; i < options.length; i++) {
    if (options[i].value == value) {
      return options[i].label;
    }
  }
  return "";  
}
export function getCellStyleFunction(minWidth="100px",fontSize="12px") {
  return () => {
    return {
      style: {
        'font-size': fontSize,
        'min-width': minWidth,
      }
    };
  }
}
/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE () {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}
export function scorePassword (pass) {
  let score = 0
  if (!pass) {
    return score
  }
  // award every unique letter until 5 repetitions
  const letters = {}
  for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1
      score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass)
  }

  let variationCount = 0
  for (var check in variations) {
      variationCount += (variations[check] === true) ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}
export function download(
  res,
  filename,
  type 
) {
  let suffixarr = filename.split(".");
  if (!type&&suffixarr) {
    const suffix_ = suffixarr[suffixarr.length - 1];
    if (suffix_ === "pdf") {
      type = "application/pdf;charset=utf-8";
    } else if (suffix_ === "doc") {
      type = "application/vnd.ms-word;charset=utf-8";
    } else if (suffix_ === "docx") {
      type = "application/vnd.ms-word;charset=utf-8";
    } else if (suffix_ === "xlsx") {
      type = "application/vnd.ms-excel;charset=utf-8";
    } else if (suffix_ === "xls") {
      type = "application/vnd.ms-excel;charset=utf-8";
    }
  }
  const blob = new Blob([res], {
    //下载重要部分
    type: type, //下载zip，类型可以改变
  });
  // 构造一个blob对象来处理数据
  if ("download" in document.createElement("a")) {
    // 支持a标签download的浏览器
    const link = document.createElement("a"); // 创建a标签
    link.download = filename; // a标签添加属性
    link.style.display = "none";
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click(); // 执行下载
    URL.revokeObjectURL(link.href); // 释放url
    document.body.removeChild(link); // 释放标签
  }
}

//常用正则
export const regularObj = {
  mobile:/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
  landline:/0\d{2,3}-\d{7,8}/, //座机号码
  email:/^((([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6}\;))*(([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})))$/, //验证多个邮箱
  single_email:/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.(com|cn|net)$/,
  credit:/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/, //统一社会信用代码


}


<template>
  <div class="Container">
    <span style="margin-right:20px">主营业务类型</span>
    <a-radio-group v-model="typebusiness">
      <a-radio :value="item.value" v-for="item in business" :key="item.value"
        >{{ item.label }}
      </a-radio>
    </a-radio-group>
    <pcbForm />

  </div>
</template>

<script>
import { addPcb, getPcbform } from "@/api/mas/pcb.js";
import pcbForm from "../pcbReports/index.vue";
export default {
  name: "pcbReports",
  props: {},
  components: {

    pcbForm,
  },
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      typebusiness: 6,
      business: [
        {
          label: "PCB",
          value: 1,
        },
        {
          label: "CCL",
          value: 2,
        },
        {
          label: "专用材料",
          value: 3,
        },
        {
          label: "专用设备",
          value: 5,
        },
        {
          label: "专用化学品",
          value: 4,
        },
        {
          label: "环保洁净",
          value: 6,
        },
      ],
      form: {
        // enterpriseInfo:{
        //   type:1,    // pcb | ccl | 专用化妆品
        //   timeInterval:1,  //1年度 2季度
        //   enterpriseName:'', //企业名称
        //   organizationCode:'', //组织代码
        //   numberEmployees:'', //企业人数(年末)
        //   communicationAddress:'', //通信地址
        //   legalRepresentativeName:'',//法定代表人姓名
        //   contactName:'',//企业联系人姓名
        //   contactTelephone:'', //电话
        //   contactPhone:'', //手机
        //   contactMail:'', //邮箱
        //   enterpriseRegistrationType:1, //企业登记类型

        // },
        enterpriseRevenueInfo: {},
        pcbProductAccounted: {},
        enterpriseLayoutInfos: {},
        pcbProductionQuotas: {},

        name: "",
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      columns: [
        {
          title: "集团布局",
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "工厂名称",
          className: "column-money",
          dataIndex: "money",
        },
        {
          title: "所在地（市级",
          dataIndex: "address",
        },
        {
          title: "投资总额(万元)",
          dataIndex: "",
        },
        {
          title: "产能（万平方米/年",
          dataIndex: "",
        },
      ],
      data: [
        {
          name: "已有",
          money: "真寻工厂",
          address: "上海市普定",
        },
      ],
      rules: {
        name: [
          {
            required: true,
            message: "Please select Activity zone",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  created() {
    for (let i = 0; i < 50; i++) {
      this.$set(this.form, `name${i}`, "");
    }

    console.log(this.form, "----");
  },
  methods: {
    submitCompany() {},
  },
};
</script>

<style scoped lang="less">
.Container {
  padding: 20px;
  h2 {
    text-align: center;
    margin: 20px auto;
    font-size: 30px;
  }
  .bigtitle {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin: 20px auto;
  }

  .flextd {
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
    tr,
    td {
      border: none;
    }
    .item {
      &:first-child {
        width: 50%;
        height: 80px;
        line-height: 80px;
        font-size: 18px;
        text-align: center;
        border-right: 1px solid #000;
      }
      &:last-child {
        display: flex;
        flex-direction: column;
        width: 50%;
        td {
          padding: 0;
          text-align: center;
          border-bottom: 1px solid #000;
          height: 20px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>

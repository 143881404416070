<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :span="20">
              <a-form-item label="菜单名称">
                <a-input style="width:250px" v-model="searchForm.menuName" placeholder="" />
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-button type="primary" @click="findMenu">搜索</a-button>
              <a-button style="margin-left: 12px" @click="resetting">重置</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-card>

    <a-card style="margin-top: 24px" :bordered="false">
      <!-- 操作按钮 -->
      <div slot="extra">
        <a-button class="btn-item btn-no-bottom member-brand" icon="plus" size="small" @click="handleAdd">新增</a-button>
      </div>

      <a-table class="ant-table-striped" :columns="columns" :data-source="loadData" :row-key="record => record.id" :indentSize="indentSize" bordered :pagination="pagination" :expandIcon="expandIcon" size="small" expandRowByClick :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
        <template slot="menuTitle" slot-scope="text, record">
          <a-input v-if="record.editable" style="margin: -5px 0" :value="text" v-model="record.title" />
          <template v-else>
            {{ text }}
          </template>
        </template>
        <template v-for="(col, i) in ['name','component','path', 'icon', 'menuSeq']" :slot="col" slot-scope="text, record">
          <template v-if="record.editable">
            <a-select v-if="col=='icon'" style="width: 80px" :key="i+'icon'+record.id" v-model="record[col]">
              <!-- todo 补齐用到的icon选项  -->
              <a-select-option :value="record.icon">
                <a-icon :type="record.icon" v-if="record.icon"></a-icon>
              </a-select-option>
            </a-select>
            <a-input v-else :key="i+' '+record.id" :value="text" v-model="record[col]" :style="'width:'+col=='menuSeq'?'20px':'100%'"/>
          </template>
          <template v-else>
            <a-icon :key="i+'icon'+record.id" :type="record.icon" v-if="col=='icon'&&record.icon"></a-icon>
            <template v-else>{{ text }}</template>
          </template>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div slot="extra">
            <span v-if="record.editable">
              <a @click="updateMenu(record)">保存</a>
              <a-divider type="vertical" />
              <a @click="cancelEdit(record)">取消</a>
            </span>
            <span v-else>
              <a class="a-success" @click="update(record)" style="cursor: pointer">编辑</a>
              <a-divider type="vertical" />
              <a-popconfirm title="是否确认删除?" ok-text="确认" cancel-text="取消" @confirm="deleteRole(record)" @cancel="cancel">
                <a style="cursor: pointer" class="a-danger">删除</a>
              </a-popconfirm>
            </span>
          </div>
        </template>
      </a-table>
    </a-card>
    <a-modal v-model="append" title="添加菜单" @ok="addMenu" :width="800">
      <a-form layout="horizontal" :label-col="{ span: 6 }" :wrapper-col="{ span:16 }">
        <a-row>
          <a-col :span="12">
            <a-form-item label="菜单标题">
              <a-input placeholder="" v-model="menu.title" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="菜单编码">
              <a-input style="width: 100%" v-model="menu.name" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="组件">
              <a-input style="width: 100%" v-model="menu.component" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="菜单类型">
              <a-select style="width: 200px" v-model="menu.type" placeholder="请选择菜单类型">
                <a-select-option :value="1">目录</a-select-option>
                <a-select-option :value="2">菜单</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="父菜单">
              <a-select style="width: 200px" v-model="menu.parentId" placeholder="请选择父菜单">
                <a-select-option value="0">根目录</a-select-option>
                <a-select-option v-for="item in menuOptions" :key="item.id" :value="item.id">
                  {{ $t(item.title) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="菜单路径">
              <a-input v-model="menu.path" />
            </a-form-item>

          </a-col>
          <a-col :span="12">
            <a-form-item label="图标">
              <a-input style="width: 100%" v-model="menu.icon" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="跳转地址">
              <a-input placeholder="" v-model="menu.redirect" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="顺序">
              <a-input-number style="width: 100%" v-model="menu.menuSeq" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { addMenu,updateMenu,findMenu,delMenu } from '@/api/system/menu'
import { getCellStyleFunction  } from '@/utils/util'
const columns=[
  {
    title: '菜单标题',
    dataIndex: "title",
    scopedSlots: { customRender: 'menuTitle' },
    customHeaderCell: getCellStyleFunction(180,14),
    customCell: getCellStyleFunction(180,14)
  },
  {
    title: '菜单编码',
    dataIndex: "name",
    scopedSlots: { customRender: 'name' },
    customHeaderCell: getCellStyleFunction(120,14),
    customCell: getCellStyleFunction(120,14)
  },
  {
    title: '菜单组件',
    dataIndex: "component",
    scopedSlots: { customRender: 'component' },
    customHeaderCell: getCellStyleFunction(100,14),
    customCell: getCellStyleFunction(100,14)
  },

  {
    title: '菜单路由',
    dataIndex: "path",
    scopedSlots: { customRender: 'path' },
    customHeaderCell: getCellStyleFunction(120,14),
    customCell: getCellStyleFunction(120,14)
  },
  {
    title: '菜单图标',
    dataIndex: 'icon',
    scopedSlots: { customRender: 'icon' },
    customHeaderCell: getCellStyleFunction(160,14),
    customCell: getCellStyleFunction(160,14)
  },
  {
    title: '顺序',
    dataIndex: 'menuSeq',
    scopedSlots: { customRender: 'menuSeq' },
    customHeaderCell: getCellStyleFunction(50,14),
    customCell: getCellStyleFunction(50,14)
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' },
    customHeaderCell: getCellStyleFunction(180,14),
    customCell: getCellStyleFunction(180,14)
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    customHeaderCell: getCellStyleFunction(120,14),
    customCell: getCellStyleFunction(120,14)
  }
]



export default {
  name: 'MenuList',
  components: {},
  data() {
    this.columns=columns
    return {
      visible: false,
      confirmLoading: false,
      pagination: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10","20","50","100"],//每页中显示的数据
        showTotal: total => this.$t('page.pagination.total.prefix')+`${total}`+this.$t('page.pagination.total.suffix'),
      },
      //增加角色
      append: false,

      menu: {
        name: "",
        type: undefined,
        parentId: undefined,
        path: "",
        icon: "",
        menuSeq: "",
        isOpen: "",
        component: "",
        redirect: "",
        title: "",
        show: "",
        projectId:"mas"
      },
      // 查询参数
      searchForm: {
        menuName: '',
      },
      loadData: [],
      indentSize: 50,

      //查询父级ID
      menuOptions: [],
    }
  },



  methods: {
    handleAdd() {
      this.append=!this.append
    },

    //重置
    resetting() {
      this.findMenu={
        menuName: '',
      }
    },
    //根据查询菜单列表数据
    findMenu() {
      findMenu(this.searchForm).then(res => {
        if(res.code==200) {
          this.loadData=res.data;
        } else {
          this.$message.error(res.msg)
          return { list: [],total: 0,pageNum: 1 };
        }
      })
    },
    //展开按钮是否展示
    expandIcon(props) {
      if(props.record.children.length>0) {
        return <a-icon type="plus-square" />
      } else {
        return <span style={{ marginleft: 1 }}></span>
      }
    },

    //添加
    addMenu() {
      addMenu(this.menu).then(res => {
        if(res.code==200) {
          this.$message.success('添加成功');
          this.menu={
            name: "",
            type: undefined,
            parentId: undefined,
            path: "",
            icon: "",
            menuSeq: "",
            isOpen: "",
            component: "",
            redirect: "",
            title: "",
            show: "",
            projectId:'mas'
          },
          this.append=!this.append;
          this.findMenu();
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    //删除 del delRole
    cancel() {
      return;
    },
    deleteRole(record) {
      let object = {
        id:record.id
      }
      delMenu(object).then(res => {
        if (res.code==200) {
          this.$message.success("删除成功")
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //修改 upd updRole
    //开启编辑，显示“保存，取消”按钮
    update(record) {
      this.$set(record,"editable",true);
    },
    //取消编辑
    cancelEdit(record) {
      this.$delete(record,"editable");
    },

    //保存编辑
    updateMenu(record) {
      let reqData={
        id: record.id,
        name: record.name,
        parentId: record.parentId,
        redirect: record.redirect,
        path: record.path,
        menuSeq: record.menuSeq,
        icon: record.icon,
        component: record.component,
        title: record.title,
        show: 1,
      };
      
      updateMenu(reqData).then(res => {
        if(res.code==200) {
          this.$message.success('修改完成');
          this.$delete(record,"editable");
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    //查询父级ID
    findMenuOptions() {
      let requestData={
        pageIndex: 1,
        pageSize: 2000,
        "object": {
          name:""
        }
      }
      findMenu(requestData).then(res => {
        console.log('res  ',res)
        if(res.code==200) {

          this.menuOptions=res.data;
        } else {
          {/* this.$message.error(res.msg) */}
        }
      })
    }
  },

  mounted() {
    this.findMenu()
    this.findMenuOptions()
  },
}
</script>

<style lang="less">
</style>
<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <!-- 查询条件及保存数据 -->
        <a-form layout="inline" size="small">
          <a-row :gutter="48">
            <a-col :md="10" :sm="24">
              <a-form-item>
                <a-input style="width:250px" v-model="searchForm.object.keyword" placeholder="请输入要搜索日志" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-button type="primary" @click="search">搜索</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-card>
    <a-card style="margin-top:24px">
      <!-- 查询数据展示 -->
      <a-table class="ant-table-striped" :columns="columns" :data-source="loadData" :row-key="record => record.logId" :pagination="pagination" @change="handleTableChange" bordered size="small" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"></a-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { findLogs } from '@/api/system/logs'
import {getCellStyleFunction} from '@/utils/util'

const columns=[
  {
    title: '编号',
    dataIndex: "logId",
    customHeaderCell: getCellStyleFunction(100,14),
    customCell: getCellStyleFunction(100,14)
  },
  {
    title: '操作者',
    dataIndex: "operator",
    width: '120',
  },
  {
    title: '操作类型',
    dataIndex: "operatingType",
    width: '120',
  },

  {
    title: '操作对象',
    dataIndex: "functionalModule",
    width: '120',
  },
  {
    title: '操作时间',
    dataIndex: 'createTime',
    width: '200',
  },
]

export default {
  name: 'LogList',
  mounted() {
    this.search()
  },
  data() {
    this.columns=columns
    return {
      pagination: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10","20","50","100"],//每页中显示的数据
        showTotal: total => this.$t('page.pagination.total.prefix')+`${total}`+this.$t('page.pagination.total.suffix'),
      },
      // 查询参数
      searchForm: {
        pageIndex: 1,
        pageSize: 10,
        object: {
          keyword: '',
        }
      },
      loadData: [],
    }
  },



  methods: {
    //重置
    resetting() {
      this.searchForm={
        pageIndex: 1,
        pageSize: 10,
        object: {
          keyword: '',
        }
      }
    },
    //根据查询菜单列表数据
    search() {
      findLogs(this.searchForm).then(res => {
        if(res.code==200) {
          this.pagination.total=res.data.total;
          this.loadData=res.data.pageObject;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //分页事件
    handleTableChange(pagination) {
      this.searchForm.pageIndex=pagination.current;
      this.searchForm.pageSize=pagination.pageSize;
      this.search();
    },
  }
}
</script>
<style lang="less" scoped>
.editable-row-operations {
  a.danger {
    color: #ff4d4f;
    margin-left: 12px;
  }
}
</style>
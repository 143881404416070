<template>
  <a-modal title="发送邮件" :width="900" :visible="addVisible" :confirmLoading="loading" @ok="send" @cancel="() => { $emit('closeAdd') }" :destroyOnClose="true">
    <a-spin :spinning="loading">
      <a-form v-bind="formLayout">
        <!-- <a-form-item label="发送人邮箱">
          <a-input v-model="mail.mailName" placeholder="请输入发送人邮箱" style="width:300px"></a-input>
        </a-form-item>
        <a-form-item label="发送人邮箱密码">
           <a-input v-model="mail.mailPassWord" style="width:300px" placeholder="请输入发送人邮箱密码" ></a-input>
        </a-form-item> -->
        <a-form-item label="接收单位">
          <!-- <a-select style="width:300px" mode="multiple" v-model="mail.companyIds" placeholder="请选择接收单位" :options="companyOptions"/> -->
          <a-select style="width:300px" mode="multiple" v-model="mail.companyIds" placeholder="请选择接收单位"  :filter-option="filterOption"  @search="handleSearch" @blur="selblur" @change="selchange">
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-else :value="item.value" v-for="(item) in selectEventlist" :key="item.value">
            {{item.label}}
          </a-select-option>
          </a-select>

        </a-form-item>
        <a-form-item label="所属标签">
          <a-select style="width:300px" mode="multiple" v-model="mail.tagIds" placeholder="请选择会员标签" :options="tagOptions" />
        </a-form-item>
        <a-form-item label="邮件模板" v-if="isTemplateEmail">
          <a-select style="width:300px" v-model="mail.templateId" placeholder="请选择邮件模板" :options="mailTemplateOptions"/>
        </a-form-item>
        <a-form-item label="主题" v-if="!isTemplateEmail">
          <a-input style="width:500px" v-model="mail.title" placeholder="请输入主题"/>
        </a-form-item>
        <a-form-item label="内容" v-if="!isTemplateEmail">
          <wang-editor :value="''" @change="changeContent"></wang-editor>
        </a-form-item>
        <a-form-item label="附件">
          <a-upload name="file" class="avatar-uploader" :show-upload-list="true" :action="baseUrl+'upload/file'" :before-upload="beforeUpload" :max-count="5" @change="uploadChange" :headers="headers">
            <a-button> <a-icon type="upload" /> 点击上传 </a-button>
          </a-upload>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import WangEditor from "@/components/Editor/WangEditor"
import cloneDeep from 'lodash.clonedeep'
import debounce from 'lodash.debounce'
import filter from "lodash.filter"
export default {
  props: {
    addVisible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    isTemplateEmail:{
      type: Boolean,
      default: () => false
    },
    companyOptions: {
      type: Array,
      default: () => []
    },
    tagOptions: {
      type: Array,
      default: () => []
    },
    mailTemplateOptions: {
      type: Array,
      default: () => []
    },
  },
  components: {
    WangEditor
  },
  data() {
    // this.handleSearch = debounce(this.handleSearch,750)
    this.awaitSearch = debounce(this.awaitSearch,750)
    this.formLayout={
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    }
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers:{'Authorization':this.$store.getters.token},
      mail:{
        companyIds: [],
        tagIds: [],
        title: '',
        content: '',
        templateId: undefined,
        attachments: [],
        // mailName:'',
        // mailPassWord:''
      },
      selectEventlist:[],
      fetching:false
    }
  },
  created() {
  },
  watch:{
    companyOptions:{
      deep:true,
      handler(companyIds){
        let _deepCompanyIds = cloneDeep(companyIds)
        this.selectEventlist = _deepCompanyIds.slice(0,49)
      }
    }
  },
  methods: {
    // 上传前校验
    beforeUpload(file) {
      const isLt10M=file.size/1024/1024<200;
      if(!isLt10M) {
        this.$message.error("不得大于200MB!");
      }
      return isLt10M;
    },
    // 上传
    uploadChange(info) {
      if(info.file.status==='uploading') {
        return;
      }
      if(info.file.status==='done') {
        if(info.file.response.status==0&&info.file.response.code==200) {
          this.mail.attachments.push({fileName:info.file.name, url:info.file.response.data});
        }
      }
      if(info.file.status==='error') {
        this.$message.error('上传失败，请稍后重试！');
      }
    },
    changeContent(content){
      this.mail.content = content;
    },
    send() {
      this.$emit('addInfo',this.mail)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSearch(val) {
      console.log(val,'输入框搜索')
      this.fetching = true;
      if(val == ''){
        this.selectEventlist = this.sortArr(this.mail.companyIds,this.companyOptions)
        this.fetching = false;
      }
      this.awaitSearch(val)
    },
    awaitSearch(val) {
      this.selectEventlist = filter(this.companyOptions,item => {
        if(item.label.indexOf(val) != -1) {
          return true
        }
      }).slice(0,49)
      this.fetching = false;
    },
    selblur(e) {
      this.selectEventlist = this.sortArr(this.mail.companyIds,this.companyOptions)
    },
    selchange(e) {
      if(e && e.length <= 0) {
        this.selectEventlist = this.selectEventlist = this.companyOptions.slice(0,49)
      }
    },
    sortArr(findcompany,allcompanyIds) {
        let _deepCompanyIds = cloneDeep(allcompanyIds)
        let findarr = _deepCompanyIds.filter(item => findcompany.includes(item.value));
        findarr.push(..._deepCompanyIds.slice(0,49))
        //去重
        let obj = {};
        let peon = findarr.reduce((cur,next) => {
            obj[next.value] ? "" : obj[next.value] = true && cur.push(next);
            return cur;
        },[]) 
          return peon
      }
  }
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "properties-centent kk-checkbox" }, [
    _c(
      "div",
      { staticClass: "properties-body" },
      [
        _c("a-empty", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.selectItem.key === "",
              expression: "selectItem.key === ''",
            },
          ],
          staticClass: "hint-box",
          attrs: { description: "未选择控件" },
        }),
        _c(
          "a-form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectItem.key !== "",
                expression: "selectItem.key !== ''",
              },
            ],
          },
          [
            _vm.isDefined(_vm.selectItem.label)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "标签" } },
                  [
                    _c("Input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.selectItem.label,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectItem, "label", $$v)
                        },
                        expression: "selectItem.label",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.hideModel && _vm.isDefined(_vm.selectItem.model)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "数据字段" } },
                  [
                    _c("Input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.selectItem.model,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectItem, "model", $$v)
                        },
                        expression: "selectItem.model",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "input"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "输入框type" } },
                  [
                    _c("Input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.options.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "type", $$v)
                        },
                        expression: "options.type",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            typeof _vm.options.rangePlaceholder !== "undefined" &&
            _vm.options.range
              ? _c(
                  "a-form-item",
                  { attrs: { label: "占位内容" } },
                  [
                    _c("Input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.options.rangePlaceholder[0],
                        callback: function ($$v) {
                          _vm.$set(_vm.options.rangePlaceholder, 0, $$v)
                        },
                        expression: "options.rangePlaceholder[0]",
                      },
                    }),
                    _c("Input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.options.rangePlaceholder[1],
                        callback: function ($$v) {
                          _vm.$set(_vm.options.rangePlaceholder, 1, $$v)
                        },
                        expression: "options.rangePlaceholder[1]",
                      },
                    }),
                  ],
                  1
                )
              : _vm.isDefined(_vm.options.placeholder)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "占位内容" } },
                  [
                    _c("Input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.options.placeholder,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "placeholder", $$v)
                        },
                        expression: "options.placeholder",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "textarea"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "自适应内容高度" } },
                  [
                    _c("InputNumber", {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "最小高度" },
                      model: {
                        value: _vm.options.minRows,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "minRows", $$v)
                        },
                        expression: "options.minRows",
                      },
                    }),
                    _c("InputNumber", {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "最大高度" },
                      model: {
                        value: _vm.options.maxRows,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "maxRows", $$v)
                        },
                        expression: "options.maxRows",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.width)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "宽度" } },
                  [
                    _c("Input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.options.width,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "width", $$v)
                        },
                        expression: "options.width",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.height)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "高度" } },
                  [
                    _c("InputNumber", {
                      model: {
                        value: _vm.options.height,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "height", $$v)
                        },
                        expression: "options.height",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.step)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "步长" } },
                  [
                    _c("InputNumber", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.options.step,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "step", $$v)
                        },
                        expression: "options.step",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.min)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "最小值" } },
                  [
                    _c("InputNumber", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.options.min,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "min", $$v)
                        },
                        expression: "options.min",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.max)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "最大值" } },
                  [
                    _c("InputNumber", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.options.max,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "max", $$v)
                        },
                        expression: "options.max",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.maxLength)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "最大长度" } },
                  [
                    _c("InputNumber", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.options.maxLength,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "maxLength", $$v)
                        },
                        expression: "options.maxLength",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.minLimit) ||
            ["batch"].includes(_vm.selectItem.type)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "最小行数" } },
                  [
                    _c("InputNumber", {
                      attrs: { min: 0, placeholder: "请输入" },
                      model: {
                        value: _vm.options.minLimit,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "minLimit", $$v)
                        },
                        expression: "options.minLimit",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.tabBarGutter)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "标签间距" } },
                  [
                    _c("InputNumber", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.options.tabBarGutter,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "tabBarGutter", $$v)
                        },
                        expression: "options.tabBarGutter",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.precision)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "数值精度" } },
                  [
                    _c("InputNumber", {
                      attrs: { min: 0, max: 50, placeholder: "请输入" },
                      model: {
                        value: _vm.options.precision,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "precision", $$v)
                        },
                        expression: "options.precision",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.dictCode)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "dictCode" } },
                  [
                    _c("Input", {
                      model: {
                        value: _vm.options.dictCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "dictCode", $$v)
                        },
                        expression: "options.dictCode",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.options)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "选项配置" } },
                  [
                    _c(
                      "Radio",
                      {
                        attrs: { buttonStyle: "solid" },
                        model: {
                          value: _vm.options.dynamic,
                          callback: function ($$v) {
                            _vm.$set(_vm.options, "dynamic", $$v)
                          },
                          expression: "options.dynamic",
                        },
                      },
                      [
                        _c("RadioButton", { attrs: { value: false } }, [
                          _vm._v("静态数据"),
                        ]),
                        _c("RadioButton", { attrs: { value: true } }, [
                          _vm._v("动态数据"),
                        ]),
                      ],
                      1
                    ),
                    _c("Input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.options.dynamic,
                          expression: "options.dynamic",
                        },
                      ],
                      attrs: { placeholder: "动态数据变量名" },
                      model: {
                        value: _vm.options.dynamicKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "dynamicKey", $$v)
                        },
                        expression: "options.dynamicKey",
                      },
                    }),
                    _c("KChangeOption", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.options.dynamic,
                          expression: "!options.dynamic",
                        },
                      ],
                      model: {
                        value: _vm.options.options,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "options", $$v)
                        },
                        expression: "options.options",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            ["tabs", "selectInputList"].includes(_vm.selectItem.type)
              ? _c(
                  "a-form-item",
                  {
                    attrs: {
                      label:
                        _vm.selectItem.type === "tabs"
                          ? "页签配置"
                          : "列选项配置",
                    },
                  },
                  [
                    _c("KChangeOption", {
                      attrs: { type: "tab" },
                      model: {
                        value: _vm.selectItem.columns,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectItem, "columns", $$v)
                        },
                        expression: "selectItem.columns",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "grid"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "栅格间距" } },
                  [
                    _c("InputNumber", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.selectItem.options.gutter,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectItem.options, "gutter", $$v)
                        },
                        expression: "selectItem.options.gutter",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "grid"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "列配置项" } },
                  [
                    _c("KChangeOption", {
                      attrs: { type: "colspan" },
                      model: {
                        value: _vm.selectItem.columns,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectItem, "columns", $$v)
                        },
                        expression: "selectItem.columns",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "switch"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "默认值" } },
                  [
                    _c("ASwitch", {
                      model: {
                        value: _vm.options.defaultValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "defaultValue", $$v)
                        },
                        expression: "options.defaultValue",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            ["number", "slider"].indexOf(_vm.selectItem.type) >= 0
              ? _c(
                  "a-form-item",
                  { attrs: { label: "默认值" } },
                  [
                    _c("InputNumber", {
                      attrs: {
                        step: _vm.options.step,
                        min: _vm.options.min || -Infinity,
                        max: _vm.options.max || Infinity,
                      },
                      model: {
                        value: _vm.options.defaultValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "defaultValue", $$v)
                        },
                        expression: "options.defaultValue",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "rate"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "默认值" } },
                  [
                    _c("Rate", {
                      attrs: {
                        allowHalf: _vm.options.allowHalf,
                        count: _vm.options.max,
                      },
                      model: {
                        value: _vm.options.defaultValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "defaultValue", $$v)
                        },
                        expression: "options.defaultValue",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "select"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "" } },
                  [
                    _c("span", { staticStyle: { color: "#262626" } }, [
                      _vm._v("默认值："),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "clear",
                        on: {
                          click: () => {
                            _vm.options.defaultValue = undefined
                          },
                        },
                      },
                      [_vm._v(" 清空 ")]
                    ),
                    _c("Select", {
                      attrs: {
                        options: _vm.options.options,
                        allowClear: _vm.options.clearable,
                        mode: _vm.options.multiple ? "multiple" : "",
                      },
                      model: {
                        value: _vm.options.defaultValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "defaultValue", $$v)
                        },
                        expression: "options.defaultValue",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "radio"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "" } },
                  [
                    _c("span", { staticStyle: { color: "#262626" } }, [
                      _vm._v("默认值："),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "clear",
                        on: {
                          click: () => {
                            _vm.options.defaultValue = ""
                          },
                        },
                      },
                      [_vm._v(" 清空 ")]
                    ),
                    _c("Radio", {
                      attrs: { options: _vm.options.options },
                      model: {
                        value: _vm.options.defaultValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "defaultValue", $$v)
                        },
                        expression: "options.defaultValue",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "checkbox"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "默认值" } },
                  [
                    _c("Checkbox", {
                      attrs: { options: _vm.options.options },
                      model: {
                        value: _vm.options.defaultValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "defaultValue", $$v)
                        },
                        expression: "options.defaultValue",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "date"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "默认值" } },
                  [
                    !_vm.options.range
                      ? _c("Input", {
                          attrs: {
                            placeholder: !_vm.isDefined(_vm.options.format)
                              ? ""
                              : _vm.options.format,
                          },
                          model: {
                            value: _vm.options.defaultValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "defaultValue", $$v)
                            },
                            expression: "options.defaultValue",
                          },
                        })
                      : _vm._e(),
                    _vm.options.range
                      ? _c("Input", {
                          attrs: {
                            placeholder: !_vm.isDefined(_vm.options.format)
                              ? ""
                              : _vm.options.format,
                          },
                          model: {
                            value: _vm.options.rangeDefaultValue[0],
                            callback: function ($$v) {
                              _vm.$set(_vm.options.rangeDefaultValue, 0, $$v)
                            },
                            expression: "options.rangeDefaultValue[0]",
                          },
                        })
                      : _vm._e(),
                    _vm.options.range
                      ? _c("Input", {
                          attrs: {
                            placeholder: !_vm.isDefined(_vm.options.format)
                              ? ""
                              : _vm.options.format,
                          },
                          model: {
                            value: _vm.options.rangeDefaultValue[1],
                            callback: function ($$v) {
                              _vm.$set(_vm.options.rangeDefaultValue, 1, $$v)
                            },
                            expression: "options.rangeDefaultValue[1]",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            ![
              "number",
              "radio",
              "checkbox",
              "date",
              "rate",
              "select",
              "switch",
              "slider",
              "html",
            ].includes(_vm.selectItem.type) &&
            _vm.isDefined(_vm.options.defaultValue)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "默认值" } },
                  [
                    _c("Input", {
                      attrs: {
                        placeholder: _vm.isDefined(_vm.options.format)
                          ? "请输入"
                          : _vm.options.format,
                      },
                      model: {
                        value: _vm.options.defaultValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "defaultValue", $$v)
                        },
                        expression: "options.defaultValue",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "html"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "默认值" } },
                  [
                    _c("Textarea", {
                      attrs: { autoSize: { minRows: 4, maxRows: 8 } },
                      model: {
                        value: _vm.options.defaultValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "defaultValue", $$v)
                        },
                        expression: "options.defaultValue",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.format)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "时间格式" } },
                  [
                    _c("Input", {
                      attrs: { placeholder: "时间格式如：YYYY-MM-DD HH:mm:ss" },
                      model: {
                        value: _vm.options.format,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "format", $$v)
                        },
                        expression: "options.format",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.orientation)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "标签位置" } },
                  [
                    _c(
                      "Radio",
                      {
                        attrs: { buttonStyle: "solid" },
                        model: {
                          value: _vm.options.orientation,
                          callback: function ($$v) {
                            _vm.$set(_vm.options, "orientation", $$v)
                          },
                          expression: "options.orientation",
                        },
                      },
                      [
                        _c("RadioButton", { attrs: { value: "left" } }, [
                          _vm._v("左"),
                        ]),
                        _c("RadioButton", { attrs: { value: "" } }, [
                          _vm._v("居中"),
                        ]),
                        _c("RadioButton", { attrs: { value: "right" } }, [
                          _vm._v("右"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "tabs"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "页签位置" } },
                  [
                    _c(
                      "Radio",
                      {
                        attrs: { buttonStyle: "solid" },
                        model: {
                          value: _vm.options.tabPosition,
                          callback: function ($$v) {
                            _vm.$set(_vm.options, "tabPosition", $$v)
                          },
                          expression: "options.tabPosition",
                        },
                      },
                      [
                        _c("RadioItem", { attrs: { value: "top" } }, [
                          _vm._v("top"),
                        ]),
                        _c("RadioItem", { attrs: { value: "right" } }, [
                          _vm._v("right"),
                        ]),
                        _c("RadioItem", { attrs: { value: "bottom" } }, [
                          _vm._v("bottom"),
                        ]),
                        _c("RadioItem", { attrs: { value: "left" } }, [
                          _vm._v("left"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "tabs"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "页签类型" } },
                  [
                    _c(
                      "Radio",
                      {
                        attrs: { buttonStyle: "solid" },
                        model: {
                          value: _vm.options.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.options, "type", $$v)
                          },
                          expression: "options.type",
                        },
                      },
                      [
                        _c("RadioButton", { attrs: { value: "line" } }, [
                          _vm._v("line"),
                        ]),
                        _c("RadioButton", { attrs: { value: "card" } }, [
                          _vm._v("card"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.size)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "大小" } },
                  [
                    _c(
                      "Radio",
                      {
                        attrs: { buttonStyle: "solid" },
                        model: {
                          value: _vm.options.size,
                          callback: function ($$v) {
                            _vm.$set(_vm.options, "size", $$v)
                          },
                          expression: "options.size",
                        },
                      },
                      [
                        _c("RadioButton", { attrs: { value: "large" } }, [
                          _vm._v("large"),
                        ]),
                        _c("RadioButton", { attrs: { value: "default" } }, [
                          _vm._v("default"),
                        ]),
                        _c("RadioButton", { attrs: { value: "small" } }, [
                          _vm._v("small"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "button"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "类型" } },
                  [
                    _c(
                      "Radio",
                      {
                        attrs: { buttonStyle: "solid" },
                        model: {
                          value: _vm.options.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.options, "type", $$v)
                          },
                          expression: "options.type",
                        },
                      },
                      [
                        _c("RadioItem", { attrs: { value: "primary" } }, [
                          _vm._v("Primary"),
                        ]),
                        _c("RadioItem", { attrs: { value: "default" } }, [
                          _vm._v("Default"),
                        ]),
                        _c("RadioItem", { attrs: { value: "dashed" } }, [
                          _vm._v("Dashed"),
                        ]),
                        _c("RadioItem", { attrs: { value: "danger" } }, [
                          _vm._v("Danger"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.downloadWay)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "下载方式" } },
                  [
                    _c(
                      "Radio",
                      {
                        attrs: { buttonStyle: "solid" },
                        model: {
                          value: _vm.options.downloadWay,
                          callback: function ($$v) {
                            _vm.$set(_vm.options, "downloadWay", $$v)
                          },
                          expression: "options.downloadWay",
                        },
                      },
                      [
                        _c("RadioButton", { attrs: { value: "a" } }, [
                          _vm._v("a标签"),
                        ]),
                        _c("RadioButton", { attrs: { value: "ajax" } }, [
                          _vm._v("ajax"),
                        ]),
                        _c("RadioButton", { attrs: { value: "dynamic" } }, [
                          _vm._v("动态函数"),
                        ]),
                      ],
                      1
                    ),
                    _c("Input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.options.downloadWay === "dynamic",
                          expression: "options.downloadWay === 'dynamic'",
                        },
                      ],
                      attrs: { placeholder: "动态函数名" },
                      model: {
                        value: _vm.options.dynamicFun,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "dynamicFun", $$v)
                        },
                        expression: "options.dynamicFun",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "button"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "按钮操作" } },
                  [
                    _c(
                      "Radio",
                      {
                        attrs: { buttonStyle: "solid" },
                        model: {
                          value: _vm.options.handle,
                          callback: function ($$v) {
                            _vm.$set(_vm.options, "handle", $$v)
                          },
                          expression: "options.handle",
                        },
                      },
                      [
                        _c("RadioButton", { attrs: { value: "submit" } }, [
                          _vm._v("提交"),
                        ]),
                        _c("RadioButton", { attrs: { value: "reset" } }, [
                          _vm._v("重置"),
                        ]),
                        _c("RadioButton", { attrs: { value: "dynamic" } }, [
                          _vm._v("动态函数"),
                        ]),
                      ],
                      1
                    ),
                    _c("Input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.options.handle === "dynamic",
                          expression: "options.handle === 'dynamic'",
                        },
                      ],
                      attrs: { placeholder: "动态函数名" },
                      model: {
                        value: _vm.options.dynamicFun,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "dynamicFun", $$v)
                        },
                        expression: "options.dynamicFun",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "alert"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "辅助描述" } },
                  [
                    _c("Input", {
                      model: {
                        value: _vm.options.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "description", $$v)
                        },
                        expression: "options.description",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "alert"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "类型" } },
                  [
                    _c(
                      "Radio",
                      {
                        attrs: { buttonStyle: "solid" },
                        model: {
                          value: _vm.options.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.options, "type", $$v)
                          },
                          expression: "options.type",
                        },
                      },
                      [
                        _c("RadioItem", { attrs: { value: "success" } }, [
                          _vm._v("success"),
                        ]),
                        _c("RadioItem", { attrs: { value: "info" } }, [
                          _vm._v("info"),
                        ]),
                        _c("RadioItem", { attrs: { value: "warning" } }, [
                          _vm._v("warning"),
                        ]),
                        _c("RadioItem", { attrs: { value: "error" } }, [
                          _vm._v("error"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "alert"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "操作属性" } },
                  [
                    _c("kCheckbox", {
                      attrs: { label: "显示图标" },
                      model: {
                        value: _vm.options.showIcon,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "showIcon", $$v)
                        },
                        expression: "options.showIcon",
                      },
                    }),
                    _c("kCheckbox", {
                      attrs: { label: "无边框" },
                      model: {
                        value: _vm.options.banner,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "banner", $$v)
                        },
                        expression: "options.banner",
                      },
                    }),
                    _c("kCheckbox", {
                      attrs: { label: "可关闭" },
                      model: {
                        value: _vm.options.closable,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "closable", $$v)
                        },
                        expression: "options.closable",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "uploadImg"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "样式" } },
                  [
                    _c(
                      "Radio",
                      {
                        attrs: { buttonStyle: "solid" },
                        model: {
                          value: _vm.options.listType,
                          callback: function ($$v) {
                            _vm.$set(_vm.options, "listType", $$v)
                          },
                          expression: "options.listType",
                        },
                      },
                      [
                        _c("RadioButton", { attrs: { value: "text" } }, [
                          _vm._v("text"),
                        ]),
                        _c("RadioButton", { attrs: { value: "picture" } }, [
                          _vm._v("picture"),
                        ]),
                        _c(
                          "RadioButton",
                          { attrs: { value: "picture-card" } },
                          [_vm._v("card")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.limit)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "最大上传数量" } },
                  [
                    _c("InputNumber", {
                      attrs: { min: 1 },
                      model: {
                        value: _vm.options.limit,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "limit", $$v)
                        },
                        expression: "options.limit",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.scrollY)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "scrollY" } },
                  [
                    _c("InputNumber", {
                      attrs: { min: 0 },
                      model: {
                        value: _vm.options.scrollY,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "scrollY", $$v)
                        },
                        expression: "options.scrollY",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.action)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "上传地址" } },
                  [
                    _c("Input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.options.action,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "action", $$v)
                        },
                        expression: "options.action",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.fileName)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "文件name" } },
                  [
                    _c("Input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.options.fileName,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "fileName", $$v)
                        },
                        expression: "options.fileName",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.data)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "额外参数（JSON格式）" } },
                  [
                    _c("Textarea", {
                      attrs: { placeholder: "严格JSON格式" },
                      model: {
                        value: _vm.options.data,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "data", $$v)
                        },
                        expression: "options.data",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "text"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "文字对齐方式" } },
                  [
                    _c(
                      "Radio",
                      {
                        attrs: { buttonStyle: "solid" },
                        model: {
                          value: _vm.options.textAlign,
                          callback: function ($$v) {
                            _vm.$set(_vm.options, "textAlign", $$v)
                          },
                          expression: "options.textAlign",
                        },
                      },
                      [
                        _c("RadioButton", { attrs: { value: "left" } }, [
                          _vm._v("左"),
                        ]),
                        _c("RadioButton", { attrs: { value: "center" } }, [
                          _vm._v("居中"),
                        ]),
                        _c("RadioButton", { attrs: { value: "right" } }, [
                          _vm._v("右"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "text"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "字体属性设置" } },
                  [
                    _c("ColorPicker", {
                      model: {
                        value: _vm.options.color,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "color", $$v)
                        },
                        expression: "options.color",
                      },
                    }),
                    _c("Select", {
                      staticStyle: {
                        width: "36%",
                        "margin-left": "2%",
                        "vertical-align": "bottom",
                      },
                      attrs: { options: _vm.familyOptions },
                      model: {
                        value: _vm.options.fontFamily,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "fontFamily", $$v)
                        },
                        expression: "options.fontFamily",
                      },
                    }),
                    _c("Select", {
                      staticStyle: {
                        width: "35%",
                        "margin-left": "2%",
                        "vertical-align": "bottom",
                      },
                      attrs: { options: _vm.sizeOptions },
                      model: {
                        value: _vm.options.fontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "fontSize", $$v)
                        },
                        expression: "options.fontSize",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "text"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "操作属性" } },
                  [
                    _c("kCheckbox", {
                      attrs: { label: "显示必选标记" },
                      model: {
                        value: _vm.options.showRequiredMark,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "showRequiredMark", $$v)
                        },
                        expression: "options.showRequiredMark",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            typeof _vm.options.hidden !== "undefined" ||
            typeof _vm.options.disabled !== "undefined" ||
            typeof _vm.options.readonly !== "undefined" ||
            typeof _vm.options.clearable !== "undefined" ||
            typeof _vm.options.multiple !== "undefined" ||
            typeof _vm.options.range !== "undefined" ||
            typeof _vm.options.showTime !== "undefined" ||
            typeof _vm.options.allowHalf !== "undefined" ||
            typeof _vm.options.showInput !== "undefined" ||
            typeof _vm.options.animated !== "undefined"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "操作属性" } },
                  [
                    _vm.isDefined(_vm.options.hidden)
                      ? _c("kCheckbox", {
                          attrs: { label: "隐藏" },
                          model: {
                            value: _vm.options.hidden,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "hidden", $$v)
                            },
                            expression: "options.hidden",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.disabled)
                      ? _c("kCheckbox", {
                          attrs: { label: "禁用" },
                          model: {
                            value: _vm.options.disabled,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "disabled", $$v)
                            },
                            expression: "options.disabled",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.readonly)
                      ? _c("kCheckbox", {
                          attrs: { label: "只读" },
                          model: {
                            value: _vm.options.readonly,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "readonly", $$v)
                            },
                            expression: "options.readonly",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.clearable)
                      ? _c("kCheckbox", {
                          attrs: { label: "可清除" },
                          model: {
                            value: _vm.options.clearable,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "clearable", $$v)
                            },
                            expression: "options.clearable",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.multiple)
                      ? _c("kCheckbox", {
                          attrs: { label: "多选" },
                          model: {
                            value: _vm.options.multiple,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "multiple", $$v)
                            },
                            expression: "options.multiple",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.range)
                      ? _c("kCheckbox", {
                          attrs: { label: "范围选择" },
                          model: {
                            value: _vm.options.range,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "range", $$v)
                            },
                            expression: "options.range",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.showTime)
                      ? _c("kCheckbox", {
                          attrs: { label: "时间选择器" },
                          model: {
                            value: _vm.options.showTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "showTime", $$v)
                            },
                            expression: "options.showTime",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.allowHalf)
                      ? _c("kCheckbox", {
                          attrs: { label: "允许半选" },
                          model: {
                            value: _vm.options.allowHalf,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "allowHalf", $$v)
                            },
                            expression: "options.allowHalf",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.showInput)
                      ? _c("kCheckbox", {
                          attrs: { label: "显示输入框" },
                          model: {
                            value: _vm.options.showInput,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "showInput", $$v)
                            },
                            expression: "options.showInput",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.showLabel)
                      ? _c("kCheckbox", {
                          attrs: { label: "显示Label" },
                          model: {
                            value: _vm.options.showLabel,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "showLabel", $$v)
                            },
                            expression: "options.showLabel",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.chinesization)
                      ? _c("kCheckbox", {
                          attrs: { label: "汉化" },
                          model: {
                            value: _vm.options.chinesization,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "chinesization", $$v)
                            },
                            expression: "options.chinesization",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.hideSequence)
                      ? _c("kCheckbox", {
                          attrs: { label: "隐藏序号" },
                          model: {
                            value: _vm.options.hideSequence,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "hideSequence", $$v)
                            },
                            expression: "options.hideSequence",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.drag)
                      ? _c("kCheckbox", {
                          attrs: { label: "允许拖拽" },
                          model: {
                            value: _vm.options.drag,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "drag", $$v)
                            },
                            expression: "options.drag",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.showSearch)
                      ? _c("kCheckbox", {
                          attrs: { label: "可搜索" },
                          model: {
                            value: _vm.options.showSearch,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "showSearch", $$v)
                            },
                            expression: "options.showSearch",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.treeCheckable)
                      ? _c("kCheckbox", {
                          attrs: { label: "可勾选" },
                          model: {
                            value: _vm.options.treeCheckable,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "treeCheckable", $$v)
                            },
                            expression: "options.treeCheckable",
                          },
                        })
                      : _vm._e(),
                    _vm.isDefined(_vm.options.animated)
                      ? _c("kCheckbox", {
                          attrs: { label: "动画切换" },
                          model: {
                            value: _vm.options.animated,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "animated", $$v)
                            },
                            expression: "options.animated",
                          },
                        })
                      : _vm._e(),
                    _c("kCheckbox", {
                      attrs: {
                        title: "勾选后移除FormItem嵌套且表单无法获取该组件数据",
                        label: "移除FormItem",
                      },
                      model: {
                        value: _vm.options.noFormItem,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "noFormItem", $$v)
                        },
                        expression: "options.noFormItem",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.selectItem.rules) &&
            _vm.selectItem.rules.length > 0
              ? _c(
                  "a-form-item",
                  { attrs: { label: "校验" } },
                  [
                    _c("kCheckbox", {
                      attrs: { label: "必填" },
                      model: {
                        value: _vm.selectItem.rules[0].required,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectItem.rules[0], "required", $$v)
                        },
                        expression: "selectItem.rules[0].required",
                      },
                    }),
                    _c("Input", {
                      attrs: { placeholder: "必填校验提示信息" },
                      model: {
                        value: _vm.selectItem.rules[0].message,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectItem.rules[0], "message", $$v)
                        },
                        expression: "selectItem.rules[0].message",
                      },
                    }),
                    _c("KChangeOption", {
                      attrs: { type: "rules" },
                      model: {
                        value: _vm.selectItem.rules,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectItem, "rules", $$v)
                        },
                        expression: "selectItem.rules",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "table"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "表格样式CSS" } },
                  [
                    _c("Input", {
                      model: {
                        value: _vm.selectItem.options.customStyle,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectItem.options, "customStyle", $$v)
                        },
                        expression: "selectItem.options.customStyle",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "table"
              ? _c(
                  "a-form-item",
                  { attrs: { label: "属性" } },
                  [
                    _c("kCheckbox", {
                      attrs: { label: "显示边框" },
                      model: {
                        value: _vm.selectItem.options.bordered,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectItem.options, "bordered", $$v)
                        },
                        expression: "selectItem.options.bordered",
                      },
                    }),
                    _c("kCheckbox", {
                      attrs: { label: "鼠标经过点亮" },
                      model: {
                        value: _vm.selectItem.options.bright,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectItem.options, "bright", $$v)
                        },
                        expression: "selectItem.options.bright",
                      },
                    }),
                    _c("kCheckbox", {
                      attrs: { label: "紧凑型" },
                      model: {
                        value: _vm.selectItem.options.small,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectItem.options, "small", $$v)
                        },
                        expression: "selectItem.options.small",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectItem.type === "table"
              ? _c("a-form-item", { attrs: { label: "提示" } }, [
                  _c("p", { staticStyle: { "line-height": "26px" } }, [
                    _vm._v("请点击右键增加行列，或者合并单元格"),
                  ]),
                ])
              : _vm._e(),
            _vm.isDefined(_vm.selectItem.help)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "帮助信息" } },
                  [
                    _c("Input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.selectItem.help,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectItem, "help", $$v)
                        },
                        expression: "selectItem.help",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.addonBefore)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "前缀" } },
                  [
                    _c("Input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.options.addonBefore,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "addonBefore", $$v)
                        },
                        expression: "options.addonBefore",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isDefined(_vm.options.addonAfter)
              ? _c(
                  "a-form-item",
                  { attrs: { label: "后缀" } },
                  [
                    _c("Input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.options.addonAfter,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "addonAfter", $$v)
                        },
                        expression: "options.addonAfter",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
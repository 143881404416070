var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("page-header-wrapper", [
    _c(
      "div",
      { staticClass: "reports" },
      [
        _vm.isShow
          ? _c("a-card", [_vm._v(" 年度统计，敬请期待！ ")])
          : _c(
              "a-card",
              { staticClass: "noborder top-header" },
              [
                _c("HeaderForm", {
                  attrs: { formList: _vm.headerFormList, buttonName: "导出" },
                  on: { search: _vm.onExport },
                }),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
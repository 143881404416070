var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("global-footer", {
    staticClass: "footer custom-render",
    scopedSlots: _vm._u([
      {
        key: "links",
        fn: function () {
          return undefined
        },
        proxy: true,
      },
      {
        key: "copyright",
        fn: function () {
          return [
            _vm._v(
              " 版权所有：" +
                _vm._s(_vm.$t("copyRight")) +
                "    联系电话：19121739850    备案号："
            ),
            _c("a", { attrs: { href: "https://beian.miit.gov.cn/" } }, [
              _vm._v("沪ICP备05041564号"),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
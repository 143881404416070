<template>

  <div id="userLayout" :class="['user-layout-wrapper', isMobile && 'mobile']">
    <div class="container">
      <div class="user-layout-lang">
        <select-lang class="select-lang-trigger islogin"/>
      </div>
      <div class="user-layout-content">
        <div class="top">
          <div class="header">
            <img src="~@/assets/user_logo.png" class="logo" alt="logo">

          </div>
          <div class="desc">
            <a href="/">
              <span class="title">{{$t('systemName')}}</span>
            </a>
            <!-- {{ $t('layouts.userLayout.title') }} -->
          </div>
        </div>

        <router-view />

        <div class="footer">
          <!-- <div class="links">
            <a href="_self">帮助</a>
            <a href="_self">隐私</a>
            <a href="_self">条款</a>
          </div> -->
          <!-- <div class="copyright">
            版权所有：{{$t('copyRight')}}  联系电话：19121739850 备案号：<a href="https://beian.miit.gov.cn/">沪ICP备05041564号</a>
          </div> -->
        </div>
      </div>
      <div class="copyright">
        版权所有：{{$t('copyRight')}}  联系电话：19121739850 备案号：<a href="https://beian.miit.gov.cn/">沪ICP备05041564号</a>
      </div>
    </div>
  </div>
</template>

<script>
import { deviceMixin } from '@/store/device-mixin'
import SelectLang from '@/components/SelectLang'

export default {
  name: 'UserLayout',
  components: {
    SelectLang
  },
  mixins: [deviceMixin],
  mounted () {
    document.body.classList.add('userLayout')
  },
  data(){
    return {
      prefixCls: {
        type: String,
        default: 'ant-pro-global-header-index-action'
      },
    }
  },
  beforeDestroy () {
    document.body.classList.remove('userLayout')
  }
}
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
  height: 100%;

  &.mobile {
    .container {
      .main {
        max-width: 368px;
        width: 98%;
      }
    }
  }

  .container {
    width: 100%;
    min-height: 100%;
    background: #f0f2f5 url(~@/assets/bglogin.png) no-repeat;
    // background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
    background-size: calc(100% + 100px) 100%;
    //padding: 50px 0 84px;
    background-position: -100px;
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.1);
      width: 100%;
      height: 100%;
      content: ' ';
      z-index: -1;
    }

    .user-layout-lang {
      width: 100%;
      height: 40px;
      line-height: 44px;
      text-align: right;

      .select-lang-trigger {
        cursor: pointer;
        padding: 12px;
        margin-right: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        vertical-align: middle;
      }
    }

    .user-layout-content {
      padding: 32px 0 24px;
      position: absolute;
      top: 50%;
      right: 10%;
      transform: translate(0%, -50%);
      z-index: 2;
      background: #fff;
      border-radius: 10px;
      // box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.2);
      .top {
        text-align: center;

        .header {
          height: 100px;
          line-height: 100px;

          .badge {
            position: absolute;
            display: inline-block;
            line-height: 1;
            vertical-align: middle;
            margin-left: -12px;
            margin-top: -10px;
            opacity: 0.8;
          }

          .logo {
            height: 85px;
            vertical-align: top;
            // margin-right: 16px;
            border-style: none;
          }

          .title {
            font-size: 40px;
            color: rgba(18, 92, 31, 0.85);
            font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
            font-weight: 600;
            position: relative;
            top: 2px;
            background: #fff;
          }
        }
        .desc {
          font-size: 40px;
          color: rgba(0, 0, 0, 0.45);
          margin-top: 12px;
          margin-bottom: 40px;
        }
      }

      .main {
        min-width: 260px;
        width: 420px;
        // width: 368px;
        margin: 0 auto;
        box-shadow: none;
        padding-top: 10px;
      }

      .footer {
        // position: absolute;
        width: 100%;
        bottom: 0;
        padding: 0 16px;
        margin: 10px  0 5px 24px;
        text-align: center;

        .links {
          margin-bottom: 8px;
          font-size: 14px;
          a {
            color: rgba(0, 0, 0, 0.45);
            transition: all 0.3s;
            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }
        .copyright {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
        }
      }
    }

    a {
      text-decoration: none;
    }
  }
}

/deep/.islogin {
  .active {
    color: #fff !important;
  }
}
.member{
  margin-bottom: 0;
  text-align:left;
  padding-left: 40px;
}
.copyright{
  text-align: center;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%,0);
  color: rgb(16, 15, 15);
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "option-change-container" },
    [
      _vm.type === "option" || _vm.type === "tab"
        ? _c(
            "a-row",
            { attrs: { gutter: 8 } },
            [
              _vm._l(_vm.value, function (val, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "option-change-box" },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 9 } },
                      [
                        _c("Input", {
                          attrs: { placeholder: "名称" },
                          model: {
                            value: val.label,
                            callback: function ($$v) {
                              _vm.$set(val, "label", $$v)
                            },
                            expression: "val.label",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 9 } },
                      [
                        _c("Input", {
                          attrs: { placeholder: "值" },
                          model: {
                            value: val.value,
                            callback: function ($$v) {
                              _vm.$set(val, "value", $$v)
                            },
                            expression: "val.value",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "option-delete-box",
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(index)
                            },
                          },
                        },
                        [_c("a-icon", { attrs: { type: "delete" } })],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              }),
              _c("a-col", { attrs: { span: 24 } }, [
                _c("a", { on: { click: _vm.handleAdd } }, [_vm._v("添加")]),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.type === "rules"
        ? _c(
            "a-row",
            { attrs: { gutter: 8 } },
            [
              _vm._l(_vm.value, function (val, index) {
                return _c("span", { key: index }, [
                  index !== 0
                    ? _c(
                        "div",
                        { staticClass: "option-change-box" },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 18 } },
                            [
                              _c("Input", {
                                attrs: { placeholder: "提示信息" },
                                model: {
                                  value: val.message,
                                  callback: function ($$v) {
                                    _vm.$set(val, "message", $$v)
                                  },
                                  expression: "val.message",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 18 } },
                            [
                              _c("Input", {
                                attrs: { placeholder: "正则表达式pattern" },
                                model: {
                                  value: val.pattern,
                                  callback: function ($$v) {
                                    _vm.$set(val, "pattern", $$v)
                                  },
                                  expression: "val.pattern",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("a-col", { attrs: { span: 6 } }, [
                            _c(
                              "div",
                              {
                                staticClass: "option-delete-box",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(index)
                                  },
                                },
                              },
                              [_c("a-icon", { attrs: { type: "delete" } })],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              }),
              _c("a-col", { attrs: { span: 24 } }, [
                _c("a", { on: { click: _vm.handleAddRules } }, [
                  _vm._v("增加校验"),
                ]),
              ]),
            ],
            2
          )
        : _vm.type === "colspan"
        ? _c(
            "a-row",
            { attrs: { gutter: 8 } },
            [
              _vm._l(_vm.value, function (val, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "option-change-box" },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 18 } },
                      [
                        _c("InputNumber", {
                          staticStyle: { width: "100%" },
                          attrs: { max: 24, placeholder: "名称" },
                          model: {
                            value: val.span,
                            callback: function ($$v) {
                              _vm.$set(val, "span", $$v)
                            },
                            expression: "val.span",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "option-delete-box",
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(index)
                            },
                          },
                        },
                        [_c("a-icon", { attrs: { type: "delete" } })],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              }),
              _c("a-col", { attrs: { span: 24 } }, [
                _c("a", { on: { click: _vm.handleAddCol } }, [_vm._v("添加")]),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", {
    staticClass: "header",
    domProps: { textContent: _vm._s(_vm.title) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
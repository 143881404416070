<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <!-- 查询条件及保存数据 -->
        <a-form layout="inline" size="small">
          <a-row :gutter="48">
            <a-col :md="10" :sm="24">
              <a-form-item>
                <a-input style="width:250px" v-model="searchForm.object.content" placeholder="请输入要搜索的内容" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-button class="btn-item" type="primary" @click="search(1)">搜索</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-card>

    <a-card style="margin-top:24px">
      <!-- 操作按钮 -->
      <div slot="extra">
        <a-button class="btn-item btn-no-bottom member-brand" size="small" @click="openCreateDialog(false)">发送自定义邮件</a-button>
        <a-button class="btn-item btn-no-bottom member-success" size="small" @click="openCreateDialog(true)">发送模板邮件</a-button>
      </div>
      <!-- 查询数据展示 -->
      <a-table class="ant-table-striped" :scroll="{ x: true }" :columns="columns" :data-source="loadData" :row-key="record => record.id" :pagination="pagination" @change="changePage" bordered size="small" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
        <template slot="id" slot-scope="text,record">
          <a @click="viewRecord(record)">{{text}}</a>
        </template>
        <template slot="type" slot-scope="text,record">
          {{record.templateId?'模板':'自定义'}}
        </template>
        <template slot="status" slot-scope="text">{{findLabelByValue(text,statusOptions)}}</template>
        <template slot="templateId" slot-scope="text">{{findLabelByValue(text,mailTemplateOptions)}}</template>
        <template slot="content"  slot-scope="text">
              <div class="overflow3" :title="text">{{text}}</div>
        </template>
      </a-table>
    </a-card>

    <!-- 发送  -->
    <email-info-form :isTemplateEmail="isTemplateEmail" :addVisible="addVisible" :companyOptions="companyOptions" :tagOptions="tagOptions" :mailTemplateOptions="mailTemplateOptions" @closeAdd="closeAdd" @addInfo="addInfo"></email-info-form>

    <!-- 查看 -->
    <email-info-view :viewVisible="viewVisible" :companyOptions="companyOptions" :tagOptions="tagOptions" :mailTemplateOptions="mailTemplateOptions" @closeDetail="closeDetail" :mail="mailRecord"></email-info-view>
    <maskfixed :ltxt="'发送中...'" v-if="sendvisible"></maskfixed>
  </div>
</template>

<script>
import { sendEmail,sendTempalteEmail,findSendEmails } from '@/api/info/emailPublish'
import EmailInfoForm from './components/EmailInfoForm'
import EmailInfoView from './components/EmailInfoView'
import { getCellStyleFunction,findLabelByValue } from '@/utils/util'

import {findTags} from "@/api/tag"
import {findEmailTemplates} from "@/api/system/emailTemplate"
import {findCompanys} from "@/api/common"
import maskfixed from "@/components/Mask/index.vue"
const columns=[
  {
    title: '编号',
    dataIndex: "id",
    scopedSlots: { customRender: 'id' },
    width:160
  },
  {
    title: '发送类型',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' },
    width:100
  },
  {
    title: '标题',
    dataIndex: "title",
    customHeaderCell: getCellStyleFunction(180,14),
    customCell: getCellStyleFunction(180,14)
  },
  {
    title: '内容',
    dataIndex: "content",
    customHeaderCell: getCellStyleFunction(180,14),
    customCell: getCellStyleFunction(180,14),
    scopedSlots: { customRender: 'content' },
  },
  {
    title: '模板',
    dataIndex: "templateId",
    customHeaderCell: getCellStyleFunction(180,14),
    customCell: getCellStyleFunction(180,14),
    scopedSlots: { customRender: 'templateId' },
    width:'auto'
  },
  {
    title: '发送状态',
    dataIndex: 'status',
    customHeaderCell: getCellStyleFunction(100,14),
    customCell: getCellStyleFunction(100,14),
    scopedSlots: { customRender: 'status' },
    width:100
  },
  {
    title: '发送时间',
    dataIndex: 'createTime',
    customHeaderCell: getCellStyleFunction(180,14),
    customCell: getCellStyleFunction(180,14),
    width:150
  },
]

export default {
  name: 'EmailPublish',
  components: {
    EmailInfoForm,
    EmailInfoView,
    maskfixed
  },
  mounted() {
    this.search(1);
    let mailTemplateReqData = {
      pageSize: 1000,
      pageIndex: 1,
      object: {
        scenes: "",
      }
    };

    let tagReqData =  {
      pageSize: 1000,
      pageIndex: 1,
      object: {
        tagName: "",
      }
    };
    // 查询公司集合
    findCompanys().then(res =>{
      if(res.status==0&&res.code==200) {
        this.companyOptions = [];
        res.data.forEach(item=>{
          this.companyOptions.push({label:item.companyNameCn,value:item.id});
        });
      }
    })
    // 查询标签集合
    findTags(tagReqData).then(res =>{
      if(res.status==0&&res.code==200) {
        this.tagOptions=[];
        res.data.pageObject.forEach(item=>{
          this.tagOptions.push({label:item.name,value:item.id});
        });
      }
    })

    // 查询邮件模板集合
    findEmailTemplates(mailTemplateReqData).then(res =>{
      if(res.status==0&&res.code==200) {
        this.mailTemplateOptions=[];
        res.data.pageObject.forEach(item=>{
          this.mailTemplateOptions.push({label:item.scenes,value:item.id});
        })
      }
    })
  },
  data() {
    this.columns=columns
    return {
      mailTemplateOptions:[],
      companyOptions:[],
      tagOptions:[],
      pagination: {
        current: 1,
        total: 0,
        pageIndex: 1,
        pageSize: 10,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10","20","50","100"],//每页中显示的数据
        showTotal: total => this.$t('page.pagination.total.prefix')+`${total}`+this.$t('page.pagination.total.suffix'),
      },
      isTemplateEmail: false,
      mailRecord: {},
      // 查询参数
      searchForm: {
        pageIndex: 1,
        pageSize: 10,
        object: {
          title: '',
          content: '',
          templateId: undefined
        }
      },
      loadData: [
      ],
      addVisible: false,
      viewVisible: false,
      statusOptions:[
        {value:1,label:"发送成功"},
        {value:2,label:"发送失败"},
      ],
      sendvisible:false,
    }
  },

  methods: {
    findLabelByValue,
    //重置
    resetting() {
      this.searchForm={
        pageIndex: 1,
        pageSize: 10,
        object: {
          title: '',
          content: '',
          templateId: undefined
        }
      }
    },
    //根据查询菜单列表数据
    search(pageIndex) {
      this.searchForm.pageIndex=pageIndex? pageIndex:1;
      findSendEmails(this.searchForm).then(res => {
        if(res.status==0&&res.code==200) {
          this.pagination.total=res.data.total;
          this.pagination.current=this.searchForm.pageIndex;
          this.loadData=res.data.pageObject;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //分页事件
    changePage(pagination) {
      this.searchForm.pageIndex=pagination.current;
      this.searchForm.pageSize=pagination.pageSize;
      this.searchForm.current=pagination.current;

      this.search(this.searchForm.pageIndex);
    },
    // 取消发送
    closeAdd() {
      this.addVisible=false;
    },
    // 发送邮件
    addInfo(form) {
      this.sendvisible = true
      if((!form.companyIds || form.companyIds.length <= 0)&& (!form.tagIds || form.tagIds.length<=0)){
        this.$message.error("标签或者公司至少选择1项")
        return false
      }
      // console.log('guolaiel')
      if(this.isTemplateEmail) {
        sendTempalteEmail(form).then(res => {
           this.sendvisible = false
          if(res.status==0&&res.code==200) {
            this.$message.success(res.msg)
            this.addVisible=false
            this.search(1)
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        sendEmail(form).then(res => {
           this.sendvisible = false
          if(res.status==0&&res.code==200) {
            this.$message.success(res.msg);
            this.addVisible=false;
            this.search(1);
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    // 打开发送邮件弹窗
    openCreateDialog(isTemplateEmail) {
      this.isTemplateEmail=isTemplateEmail;
      this.addVisible=true;
    },
    // 打开查看邮件弹窗
    viewRecord(record) {
      this.mailRecord=record;
      this.viewVisible=true;
    },
    closeDetail() {
      this.viewVisible=false;
      this.mailRecord={};
    },
  }
}
</script>
<style lang="less" scoped>
.overflow3{
  width: 100%;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
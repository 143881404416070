var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { staticStyle: { height: "70vh" }, attrs: { bordered: false } },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "relative",
                top: "20vh",
                width: "50%",
                "margin-left": "25%",
              },
            },
            [
              _c("div", { staticStyle: { "font-size": "16px" } }, [
                _vm._v("年份选择（如果不选择年份，则生成季度数据）"),
              ]),
              _c(
                "a-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择年度", clearable: "" },
                  model: {
                    value: _vm.timeInterval,
                    callback: function ($$v) {
                      _vm.timeInterval = $$v
                    },
                    expression: "timeInterval",
                  },
                },
                _vm._l(_vm.annualOptions, function (item, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: item.value } },
                    [_vm._v(" " + _vm._s(item.label) + " ")]
                  )
                }),
                1
              ),
              _c(
                "a-button",
                {
                  staticStyle: {
                    width: "30%",
                    "margin-left": "35%",
                    "margin-top": "10vh",
                  },
                  attrs: { type: "primary" },
                  on: { click: _vm.sync },
                },
                [_vm._v(" 同步 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
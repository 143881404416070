<template>
  <page-header-wrapper>
    <a-card>
      <div style="margin-bottom: 15px;">
        <a-form-model ref="form" :model="form" @submit="handleSubmit" :rules="rules" layout="inline">
          <a-form-model-item label="年度" prop="year">
            <a-select v-model="form.year" :disabled="disabled" placeholder="请选择" style="width: 100px" allow-clear>
              <a-select-option :value="item.value" v-for="(item,index) in annualOptionsNew" :key="index">
                {{item.label}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="类型" prop="type">
            <a-select v-model="form.type" :disabled="disabled" placeholder="请选择" style="width: 80px" allow-clear>
              <a-select-option :value="item.value" v-for="(item,index) in quarterOptions" :key="index">
                {{item.label}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="标题" prop="title">
            <a-input v-model="form.title" :disabled="disabled" style="width: 250px" placeholder="请输入标题">
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="描述" prop="description">
            <a-input v-model="form.description" :disabled="disabled" style="width: 350px" placeholder="请输入描述">
            </a-input>
          </a-form-model-item>
          <a-form-model-item class="btn-box">
            <a-button @click="resetform" :disabled="disabled"> 重置 </a-button>
            <a-button @click="saveForm" :disabled="saveFormDisabled" type="primary" style="margin-left: 10px;"> 保存 </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div style="width: 100%;">
        <a-radio-group default-value="0" style="width: 100%;" button-style="solid" @change="radioGroupChange">
          <a-radio-button value="0" style="width: 50%;text-align: center;">
            表单设计
          </a-radio-button>
          <a-radio-button value="1" style="width: 50%;text-align: center;">
            设置联动
          </a-radio-button>
        </a-radio-group>
        <!-- <a-button @click="toLinkage" style="float: left;width: 50%;"> 设置联动 </a-button>
        <a-button @click="toFormDesign"  style="float: left;width: 50%;"> 表单设计 </a-button> -->
      </div>
      <k-form-design v-if="!logicShow" :showHead="false" ref="kfd" title="表单设计" @save="handleSave" :toolbars="toolbars" toolbarsTop/>
      <div v-if="logicShow" style="border: 1px solid #F0F0F0; border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;padding: 30px;">
        <a-button @click="logicOk(formObject)" style="float: right;margin: 10px 0;" type="primary"> 保存联动 </a-button>
        <a-button @click="addLogic()" style="float: right;margin: 10px;" type="primary"> 添加逻辑 </a-button>
        <div style="clear: both;"></div>
        <div style="max-height: 430px;overflow: auto;padding-right: 10px;">
          <div v-for="(logicItem,logicIndex) in logicList" :key="logicIndex" :style="{'border-bottom': logicIndex != (logicList.length -1 )?'3px solid #0b35de':'none'}" style="padding-bottom: 20px;">
            <div style="width: 100%;height: 30px;border-bottom: 1px solid #F0F0F0;margin: 20px 0;font-size: 16px;font-weight: 500;">
              规则{{ logicIndex+1 }}
              <a-icon type="delete" @click="deleteItem(logicItem.id,logicList)" class="send-admin" style="float: right;"/>
            </div>
            <a-radio-group default-value="and" v-model="logicItem.type" size="small" style="width: 100%;height: 30px;margin-bottom: 20px;">
              当满足以下条件时
              <div style="float: right;">
                <a-radio-button value="and">
                  并且
                </a-radio-button>
                <a-radio-button value="or">
                  或者
                </a-radio-button>
              </div>
              
            </a-radio-group>
            <div style="clear: both;"></div>
            <div style="width: 100%;border-radius: 10px;background-color: #FBFBFF;padding: 20px;">
              <div v-for="(conditionItem,conditionIndex) in logicItem.conditionList" :key="conditionIndex" style="padding-bottom: 10px;"> 
                <a-select placeholder="请选择" v-model="conditionItem.model" style="width: 30%" allow-clear  @change="getSelectOption(conditionItem,conditionItem.model)">
                  <a-select-option :value="linkageItem.model" v-for="(linkageItem,index) in linkageList" :key="index+linkageItem.model">
                    {{linkageItem.label}}
                  </a-select-option>
                </a-select>
                ==
                <a-select placeholder="请选择"  v-model="conditionItem.value" style="width: 30%" allow-clear>
                  <a-select-option :value="selectValueItem.value" v-for="(selectValueItem,index) in conditionItem.selectOption" :key="index+selectValueItem.value" :disabled="conditionItem.selectValueDisabled">
                    {{selectValueItem.label}}
                  </a-select-option>
                </a-select>
                <a-icon type="delete" @click="deleteItem(conditionItem.id,logicItem.conditionList)" class="send-admin" />
              </div>
            </div>
            <div style="clear: both;width: 100%;background-color: #fff;height: 30px;margin: 20px 0;">
              <a-button @click="addCondition(logicItem.conditionList)" type="primary" ghost> 添加条件 </a-button>
            </div>
            <div style="width: 100%;border-radius: 10px;background-color: #FBFBFF;padding: 20px;">
              <div>显示下列组件</div>
              <a-checkbox-group v-model="logicItem.showList">
                <div v-for="(item, index) in formObjectList" :key="index">
                  <a-checkbox :value="item.key" >
                    {{ item.label }}
                  </a-checkbox>
                </div>
              </a-checkbox-group>
            </div>
          </div>
        </div>
        <!-- <div style="width: 100%;height: 20px;">
          
        </div> -->
      </div>
      
    </a-card>
    
  </page-header-wrapper>
</template>

<script>
import { annualOptionsNew } from "@/utils/util"
import { add, getFormById, upd } from "@/api/form/formDesign.js"

export default {
  name: "FormDesign",
  components: {

  },
  data() {
    return {
      saveFormDisabled: false,
      annualOptionsNew,
      logicShow: false,
      toolbars: [
        // 'save', 
        // 'preview', 
        'importJson', 
        'exportJson', 
        // 'exportCode', 
        'reset', 
        'close', 
        "undo", 
        "redo"
      ],
      quarterOptions: [
        { value: '1', label: '年度' },
        { value: '2', label: '季度' },
        { value: '3', label: '月度' },
        { value: '4', label: '问卷' },
      ],
      linkageList: [],
      formObjectList: [],
      formList: {},
      logicList: [
        {
          id: new Date().getTime(),
          conditionList: [
            {
              id: new Date().getTime(),
              model: '',
              value: '',
              selectOption: [],
              selectValueDisabled: true
            },
          ],
          showList: [],
          type: 'and'
        },
      ],
      
      form: {
        year: undefined, //年度
        type: undefined, //季度
        title: '', //标题
        description: '', //描述
      },
      disabled: false,
      rules: {
        year: [
          { required: true, message: '请选择年度！', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择季度！', trigger: 'blur' }
        ],
        title: [{ required: true, message: '请输入标题！', trigger: 'blur' }],
        description: [{ required: true, message: '请输入描述！', trigger: 'blur' }]
      },
      values:{
        "list": [],
        "config": {
          "layout": "horizontal",
          "labelCol": {
              "xs": 4,
              "sm": 4,
              "md": 4,
              "lg": 4,
              "xl": 4,
              "xxl": 4
          },
          "labelWidth": 100,
          "labelLayout": "flex",
          "wrapperCol": {
              "xs": 18,
              "sm": 18,
              "md": 18,
              "lg": 18,
              "xl": 18,
              "xxl": 18
          },
          "hideRequiredMark": false,
          "customStyle": ""
        }
      },
    }
  },
  mounted() {
    let params = this.$route.query
    // console.log("params", params);
    if (params.id) {
      this.disabled = true
      this.form = {
        id: params.id,
        year: params.year, //年度
        type: params.type, //季度
        title: params.title, //标题
        description: params.description, //描述
      }
      this.getFormById(params.id)
    }
  },
  methods: {
    handleSubmit(a,b,c) {
      // console.log("111",a,b,c);
    },
    resetform() {
      this.form = {
        year: undefined, //年度
        type: undefined, //季度
        title: '', //标题
        description: '', //描述
      }
    },
    getSelectOption(conditionItem, key) {
      conditionItem.selectOption = []
      this.linkageList.forEach(item => {
        if (item.model == key) {
          conditionItem.selectOption = item.options.options
        }
      })
      // console.log("selectOption", selectOption);
      conditionItem.selectValueDisabled=false
      // return selectOption
    },
    deleteItem(key,list) {
      list.forEach((item,index) => {
        if (item.id == key) {
          list.splice(index, 1)
          return
        }
      })
    },
    // 添加逻辑
    addLogic() {
      this.logicList.push({
        id: new Date().getTime(),
        conditionList: [
          {
            id: new Date().getTime(),
            model: '',
            value: '',
            selectValueDisabled: true
          }
        ],
        showList: [],
        type: 'and'
      })
    },
    // 添加条件
    addCondition(conditionList) {
      conditionList.push({
        id: new Date().getTime(),
        model: '',
        value: '',
        selectValueDisabled: true
      })
    },
    // logicOk
    logicOk(formObject) {
      // console.log("this.logicList", this.logicList);
      // console.log("logicItem.showList", logicItem.showList);
      // 遍历所有逻辑
      this.changeFormObject(formObject)
      this.$message.success('保存成功')
      // console.log("this.formObject",this.formObject);
      this.formObject.logicList = this.logicList
    },
    changeFormObject(formObject) {
      formObject.list.forEach(item => {
        item.showCondition = {
          type: '',
          list: []
        }
        this.logicList.forEach(logicItem => {
        // 遍历逻辑内勾选项
          logicItem.showList.forEach(showListItem => {
            if (logicItem.conditionList && logicItem.conditionList.length > 0 && logicItem.showList && logicItem.showList.length > 0) {
              // 遍历表单
              item.show = true
              if (item.key == showListItem) {
                // console.log("showListItem",showListItem);
                item.showCondition.type = logicItem.type
                // 将逻辑条件放入表单对应项内
                logicItem.conditionList.forEach((conditionListItem, index) => {
                  if (conditionListItem.value && conditionListItem.model) {
                    // console.log("showListItem-model",conditionListItem);
                    item.showCondition.list.push({lable: conditionListItem.model, value: conditionListItem.value})
                    // console.log("showListItem-model-1",item);
                  }
                })
                // console.log("showListItem-item",item);
              }
            }
          })
        })
        if (item.columns && item.columns.length > 0) {
          item.columns.forEach(columnsItem => {
            this.changeFormObject(columnsItem)
          })
        }
        
      })
      
      // console.log("this.logicList",this.logicList);
    },
    radioGroupChange(value) {
      let key = value.target.value
      if (key == 0) {
        this.saveFormDisabled = false
        this.toFormDesign()
      } else {
        this.saveFormDisabled = true
        this.toLinkage()
      }
    },
    toLinkage() {
      this.logicShow = true
      this.formObject = JSON.parse(JSON.stringify(this.$refs.kfd.getValue()))
      if (!this.formObject.list) {
        return
      }
      if (this.formObject.logicList) {
        this.logicList = this.formObject.logicList
      }
      this.linkageList = []
      this.formObjectList = []
      this.getLinkageList(this.formObject)
      // console.log("this.formObjectList",this.formObjectList);
    },
    getLinkageList(formObject) {
      formObject.list.forEach(item => {
        if (item.type == 'select' || item.type == 'checkbox' || item.type == 'radio') {
          this.linkageList.push(item)
        }
        if (item.columns && item.columns.length > 0) {
          item.columns.forEach(columnsItem => {
            this.getLinkageList(columnsItem)
          })
        } else {
          this.formObjectList.push(item)
        }
      })
    },
    toFormDesign() {
      this.logicShow = false
      this.$nextTick(()=> {
        this.$refs.kfd.handleSetData(this.formObject)
      })
    },
    saveForm() {
      this.handleSave(JSON.stringify(this.$refs.kfd.getValue()))
    },
    handleSave(values) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!JSON.parse(values).list || (JSON.parse(values).list && JSON.parse(values).list.length < 1)) {
            this.$message.error('表单内容不能为空！');
            return
          }
          let newValue = JSON.parse(values)
          newValue.list.forEach(item => {
            item.show = true
            
          })
          this.form.content = JSON.stringify(newValue)
          // return
          if (this.form.id) {
            this.upd()
          } else {
            this.add()
          }
        } else {
          this.$message.error('请完善表单必填项！');
        }
      });
    },
    add() {
      add(this.form).then ( res => {
        if (res.code == 200) {
          this.$message.success("创建成功")
          this.resetform()
          this.$refs.kfd.handleSetData(this.values)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    upd() {
      upd(this.form).then ( res => {
        if (res.code == 200) {
          this.$message.success("修改成功")
          this.$router.push({ path: '/form/allForm' })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getFormById(id) {
      getFormById({id:id}).then(res => {
        // console.log("getFormById-res", res);
        if (res.code == 200) {
          this.$refs.kfd.handleSetData(JSON.parse(res.data.content))
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }

}
</script>

<style scoped lang="less">
.btn-box {
  .ant-form-item-children {
    .submitbtn {
      margin-right: 24px;
      margin-left: 24px;
    }
  }
}
.send-admin{
  font-size:14px;
  margin: 5px;
  color: #5199F8;
}

.send-admin:hover{
  color: #5ee34a;
}
.send-admin:active{
  color: #288719;
}
</style>
<template>
  <page-header-wrapper>
    <a-card>
      <div style="margin-bottom: 15px;">
        <a-form-model ref="form" :model="query.object" @submit="getFormData" layout="inline">
          <a-form-model-item label="年度" prop="year">
            <a-select v-model="query.object.year" placeholder="请选择" style="width: 150px" allow-clear>
              <a-select-option :value="item.value" v-for="(item,index) in annualOptionsNew" :key="index">
                {{item.label}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="类型" prop="type">
            <a-select v-model="query.object.type" placeholder="请选择" style="width: 150px" allow-clear>
              <a-select-option :value="item.value" v-for="(item,index) in quarterOptions" :key="index">
                {{item.label}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="标题" prop="title">
            <a-input v-model="query.object.title" placeholder="请输入标题">
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="描述" prop="description">
            <a-input v-model="query.object.description" placeholder="请输入描述">
            </a-input>
          </a-form-model-item>
          <a-form-model-item class="btn-box" :labelCol="{ span: 0 }" >
            <a-button  type="primary" @click="queryAllForm">
              查询
            </a-button>
            <a-button @click="resetform" style="margin-left: 5px;"> 重置 </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <a-table :columns="columns" :pagination="pagination" @change="pageChange" :scroll="{ x: true }" :data-source="data">
        <span slot="type" slot-scope="text, record">
            <div>{{ record.type == 1? "年度" : record.type == 2 ? '季度' : record.type == 2 ? '月度' : '问卷' }}</div>
        </span>
        <span slot="description" slot-scope="text, record">
          <a-tooltip>
            <template slot="title">
              {{ record.description }}
            </template>
            <div class="multi-ellipsis">{{ record.description }}</div>
          </a-tooltip>
        </span>
        <span slot="action" slot-scope="text, record">
          <a-icon  @click="viewForm(record, 'view')" type="read" alt="预览" class="send-admin"/>
          <a-icon  @click="viewFormStat(record)" type="bar-chart" alt="预览" class="send-admin"/>
          <!-- <a-icon  @click="viewForm(record, 'write')" type="file-done" alt="填写" class="send-admin"/> -->
          <router-link :to="{path:'/form/formDesign',query: record }">
              <a-icon type="form" alt="修改" @click="editForm(record,'edit')" class="send-admin"/>
          </router-link>
          <a-icon type="delete" alt="删除" @click="delForm(record)" class="send-admin" />
        </span>
      </a-table>
      <viewForm ref="viewForm" :popFormType="popFormType" :width="'80%'" @toSubmitForm="toSubmitForm"></viewForm>
      <formStat ref="formStat" :width="'80%'"></formStat>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { annualOptionsNew } from "@/utils/util"
import { getFormData, delForm, toSubmitForm, findComponentUsage } from "@/api/form/allForm.js"
import { getFormById } from "@/api/form/formDesign.js"
import viewForm from "@/components/KFormDesign/packages/components/viewForm/index.vue"
import formStat from "./FormStat.vue"
import { forEach } from 'store/storages/all'
import get from 'lodash.get'
const columns = [
  {
    title: '表单标题',
    dataIndex: 'title',
    key: 'title',
    align: 'center'
  },
  {
    title: '年度',
    dataIndex: 'year',
    key: 'year',
    align: 'center'
  },
  {
    title: '类型',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    scopedSlots: { customRender: 'type' }
  },
  {
    title: '表单描述',
    dataIndex: 'description',
    key: 'description',
    width: 150,
    align: 'center',
    scopedSlots: { customRender: 'description' }
  },
  // {
  //   title: '创建者',
  //   dataIndex: 'createBy',
  //   key: 'createBy',
  //   align: 'center'
  // },
  {
    title: '创建时间',
    key: 'createTime',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center'
  },  
];
export default {
  name: "AllForm",
  components: {
    viewForm,
    formStat
  },
  data() {
    return {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10, //每页中显示10条数据
        showTotal: (total) => `共 ${total} 条`,
        size: 'small',
      },
      popFormType:'view',
      viewFormContent: {},
      annualOptionsNew,
      defaultValueList: [],
      quarterOptions: [
        { value: '1', label: '年度' },
        { value: '2', label: '季度' },
        { value: '3', label: '月度' },
        { value: '4', label: '问卷' },
      ],
      form: {
        year: undefined, //年度
        type: undefined, //季度
        title: '', //标题
        description: '', //描述
      },
      data:[],
      columns,
      query: {
        pageIndex: 1,
        pageSize: 10,
        object: {
          year: undefined, //年度
          type: undefined, //季度
          title: '', //标题
          description: '', //描述
        }
      }
    }
  },
  mounted() {
    this.getFormData()
  },
  methods: {
    resetform() {
      this.form = {
        year: undefined, //年度
        type: undefined, //季度
        title: '', //标题
        description: '', //描述
      }
    },
    pageChange(pageObj) {
      this.query.pageIndex = pageObj.current
      this.query.current = pageObj.current
      this.pagination.current = pageObj.current
      this.getFormData()
    },
    queryAllForm() {
      this.query.pageIndex = 1
      this.query.current = 1,
       this.pagination.current = 1
      this.getFormData()
    },
    getFormData() {
      getFormData(this.query).then(res => {
        if (res.code == 200) {
          this.pagination.total = res.data.total
          this.data = res.data.pageObject
        } else {
          this.$message.error(res.msg)
        }
        
      })
    },
    delForm(row) {
      this.$confirm({
        title: '提示',
        content: `是否确认删除？`,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        onOk: () => {
          delForm({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success("删除成功")
              this.getFormData()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        onCancel: () => {
          this.$message.warning('取消删除')
        }
      })
    },
    viewForm(row, type) {
      this.popFormType = type
      getFormById({id: row.id}).then(res => {
        // console.log("getFormById-res",res.data);
        if (res.code == 200) {
          this.$refs.viewForm.viewForm(res, type)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    toSubmitForm(object) {
      this.$refs.viewForm.visible = false
      return
      toSubmitForm(object).then(res => {
        // console.log("res",res.data);
        this.$message.success(res.data)
        this.$refs.viewForm.visible = false
      })
    },

    viewFormStat(row) {
      findComponentUsage({id: row.id}).then(res => {
        if (res.code == 200) {
          this.$refs.formStat.viewFormStat(res)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    
  }

}
</script>

<style scoped lang="less">
.multi-ellipsis {
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 超出两行则以...形式隐藏
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.send-admin{
  font-size:14px;
  margin: 5px;
  color: #5199F8;
}

.send-admin:hover{
  color: #5ee34a;
}
.send-admin:active{
  color: #288719;
}
</style>
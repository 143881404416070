var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pcbContainer" },
    [
      _c(
        "ReportsTitle",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.current == 0 || _vm.formMode == "view",
              expression: "current == 0 || formMode == 'view'",
            },
          ],
          attrs: { title: "企业经营状况" },
        },
        [
          _c("enterpriseCompany", {
            ref: "enterprise",
            attrs: {
              enterpriseBusinessType: _vm.enterpriseBusinessType,
              childForm: _vm.annualform.enterpriseRevenueInfo,
              formMode: _vm.formMode,
              formType: "ccl",
            },
            on: { tonext: _vm.tonext },
          }),
        ],
        1
      ),
      _c(
        "ReportsTitle",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.current == 1 || _vm.formMode == "view",
              expression: "current == 1 || formMode == 'view'",
            },
          ],
          attrs: { title: "集团布局" },
        },
        [
          _c("pcbtable", {
            ref: "pcbtable",
            staticClass: "pcbtt",
            attrs: {
              enterpriseBusinessType: _vm.enterpriseBusinessType,
              childForm: _vm.annualform.enterpriseLayoutInfos,
              formMode: _vm.formMode,
            },
            on: { tonext: _vm.tonext },
          }),
        ],
        1
      ),
      _c(
        "ReportsTitle",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.current == 2 || _vm.formMode == "view",
              expression: "current == 2 || formMode == 'view'",
            },
          ],
          attrs: { title: "生产指标" },
        },
        [
          _c("ccltable", {
            ref: "production",
            attrs: {
              childForm: _vm.annualform.cclProductionQuotas,
              formMode: _vm.formMode,
            },
            on: { tonext: _vm.tonext },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
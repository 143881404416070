<template>
  <page-header-wrapper>
    <!-- <a-card>
      协会季度填报列表，敬请期待！
    </a-card> -->
    <AnnualComponent :type="type" :headerFormList="headerFormList"></AnnualComponent>
  </page-header-wrapper>
</template>

<script>
import AnnualComponent from '@/views/filling/components/Filling.vue'
import { typeBussine, findDynamicAndIds } from "./Business.js"
import { annualOptions } from "@/utils/util"
export default {
  name: "AnnualYear",
  components: {
    AnnualComponent,
  },
  data(){
    return {
      type: "1",
      headerFormList: [
        // timeInterval: new Date().getFullYear(), //年度
        {
          name: 'year',
          type: 'select',
          value: 2023,
          label: '年度',
          allowClear: false,
          placeholder: "请选择",
          option: annualOptions
        },
        // {
        //   name: 'dynamicFormTitle',
        //   type: 'input',
        //   value: '',
        //   label: '表单标题',
        //   placeholder: "请输入"
        // },
        {
          name: 'enterpriseName',
          type: 'input',
          value: '',
          label: '单位名称',
          placeholder: "请输入"
        },
        {
          name: 'dynamicFormId',
          type: 'select',
          allowClear: true,
          value: undefined,
          label: '选择表单',
          placeholder: "请选择",
          option: [],
        },
        {
          name: 'fillingType',
          type: 'select',
          allowClear: true,
          value: undefined,
          label: '填报类型',
          placeholder: "请选择",
          option: typeBussine,
        },
        {
          name: 'reportStatus',
          type: 'select',
          allowClear: true,
          value: undefined,
          label: '填报状态',
          placeholder: "请选择",
          option: [
            {
              label: '全部',
              value: '',
            },
            {
              label: '未填',
              value: '1',
            },
            {
              label: '已填',
              value: '2',
            },
          ],
        },
      ]
    }
  },
  created() {
    this.findDynamicAndId()
  },
  methods:{
    async findDynamicAndId() {
      let res = await findDynamicAndIds()
      for(let i = 0; i < this.headerFormList.length; i++){
        if(this.headerFormList[i].name === 'dynamicFormId'){
          this.headerFormList[i].option = res
        }
      }
    }
  }

}
</script>

<style scoped>
.noborder {
    border: none;
    border-radius: 10px;
  }
</style>
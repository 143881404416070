<template>
  <div class="pcbContainer">
    <!-- <ReportsTitle title="企业基本信息" v-show="current === 0 || formMode == 'view'">
      <basicCompany ref="basic" :childForm="annualform.enterpriseInfo" :formMode="formMode" @tonext="tonext"></basicCompany>
    </ReportsTitle> -->
    <ReportsTitle title="企业经营状况" v-show="current === 0 || formMode == 'view'">
      <enterpriseCompany ref="enterprise" :childForm="annualform.enterpriseRevenueInfo" :formMode="formMode" formType="1" @tonext="tonext"></enterpriseCompany>
    </ReportsTitle>
    <ReportsTitle title="PCB产品（占比）应用领域分类" v-show="current === 1 || formMode == 'view'">
      <PcbProportion ref="Proportion" :childForm="annualform.pcbProductAccounted" :formMode="formMode" @tonext="tonext"></PcbProportion>
    </ReportsTitle>
    <ReportsTitle title="集团布局" v-show="current === 2 || formMode == 'view'">
      <pcbtable class="pcbtt" ref="pcbtable" :childForm="annualform.enterpriseLayoutInfos" :formMode="formMode" @tonext="tonext" :enterpriseBusinessType="enterpriseBusinessType"></pcbtable>
    </ReportsTitle>
    <ReportsTitle title="PCB生产指标" v-show="current >= 3 || formMode == 'view'">
      <Pcbproduction ref="production" :childForm="annualform.pcbProductionQuotas" :formMode="formMode" @tonext="tonext"></Pcbproduction>
    </ReportsTitle>
    <!-- <a-radio class="pcbtt"
      >我已阅读并同意 <span style="color: #228f45">隐私条款</span>
    </a-radio> -->
  </div>
</template>

<script>
import ReportsTitle from '@/components/MasCmponent/ReportsTitle.vue'
import basicCompany from '@/components/MasCmponent/ReportsItem/PcbbasicCompany.vue'
import enterpriseCompany from '@/components/MasCmponent/ReportsItem/PcbenterpriseCompany.vue'
import pcbtable from '@/components/MasCmponent/ReportsItem/PcbTable.vue'
import Pcbproduction from '@/components/MasCmponent/ReportsItem/Pcbproduction.vue'
import PcbProportion from '@/components/MasCmponent/ReportsItem/PcbProportion.vue'
export default {
  name: 'pcbReports',
  props: {
    annualform: {
      type: Object,
    },
    formMode: {
      type: String,
    },
    current: {
      type: [Number, String],
    },
    enterpriseBusinessType: {
      type: [Number, String],
    },
  },
  components: {
    ReportsTitle,
    basicCompany,
    enterpriseCompany,
    pcbtable,
    Pcbproduction,
    PcbProportion,
  },
  data() {
    return {}
  },
  watch: {
    formMode: {
      deep: true,
      handler(mode) {
        console.log(mode, 'form表单模式-------------')
      },
    },
    annualform: {
      deep: true,
      handler(newData, oldData) {
        this.annualform.enterpriseInfo = newData.enterpriseInfo
      },
    },
  },
  created() {},
  methods: {
    getFormData() {
      // const _basicCompany = this.$refs.basic
      const _enterprise = this.$refs.enterprise
      const _Proportion = this.$refs.Proportion
      const _pcbtable = this.$refs.pcbtable
      const _production = this.$refs.production

      Promise.all([ _enterprise.submit(), _Proportion.submit()]).then((res) => {
        if (res.includes(false)) {
          this.$message.error('表单有未填写的字段请填写')
          return false
        }
        let data = {
          enterpriseInfo: this.annualform.enterpriseInfo,
          enterpriseRevenueInfo: res[0],
          pcbProductAccounted: res[1],
          enterpriseLayoutInfos: _pcbtable.data.map((item) => {
            return {
              factoryState: item.factoryState,
              factoryName: item.factoryName,
              locationMunicipalLevel: item.locationMunicipalLevel,
              totalAmountInvestment: item.totalAmountInvestment,
              capacity: item.capacity,
              id: item.id ? item.id : '',
              enterpriseId: item.enterpriseId ? item.enterpriseId : '',
              productPositioning: item.productPositioning ? item.productPositioning : '',
              outputValue: item.outputValue ? item.outputValue : '',
            }
          }),
          pcbProductionQuotas: _production.data.map(({ annualCapacity, childProductionType, designCapacity, enterpriseId, id, productionQuotaType, sales ,capacityUtilizationRate,salesAmount}) => {
            return {
              annualCapacity,
              childProductionType,
              designCapacity,
              productionQuotaType,
              capacityUtilizationRate,
              salesAmount,
              sales,
              enterpriseId: enterpriseId ? enterpriseId : '',
              id: id ? id : '',
            }
          }),
        }

        //处理多余的初始化数据
        if (data.enterpriseLayoutInfos.length > 3) {
          data.enterpriseLayoutInfos = data.enterpriseLayoutInfos.filter((item, index) => {
            return index < data.enterpriseLayoutInfos.length - 3
          })
        }

        this.$emit('childsubmit', data)
      })
    },

    //重置表单
    resetFormval() {
      // const _basicCompany = this.$refs.basic
      const _enterprise = this.$refs.enterprise
      const _Proportion = this.$refs.Proportion
      const _pcbtable = this.$refs.pcbtable
      const _production = this.$refs.production

      // _basicCompany.resetForm()
      _enterprise.resetForm()
      _Proportion.resetForm()
      _pcbtable.resetForm()
      _production.resetForm()
    },
    tonext(cur) {
      this.$emit('tonext', cur)
    },
    //存入草稿的数据
    saveDraft() {
      // const _basicCompany = this.$refs.basic
      const _basicCompany = {}
      const _enterprise = this.$refs.enterprise
      const _Proportion = this.$refs.Proportion
      const _pcbtable = this.$refs.pcbtable
      const _production = this.$refs.production

      let data = {
        enterpriseInfo: _basicCompany,
        enterpriseRevenueInfo: _enterprise.form,
        pcbProductAccounted: _Proportion.form,
        enterpriseLayoutInfos: _pcbtable.data.map((item) => {
          return {
            factoryState: item.factoryState,
            factoryName: item.factoryName,
            locationMunicipalLevel: item.locationMunicipalLevel,
            totalAmountInvestment: item.totalAmountInvestment,
            capacity: item.capacity,
            id: item.id ? item.id : '',
            enterpriseId: item.enterpriseId ? item.enterpriseId : '',
            productPositioning: item.productPositioning ? item.productPositioning : '',
            outputValue: item.outputValue ? item.outputValue : '',
          }
        }),
        pcbProductionQuotas: _production.data.map(({ annualCapacity, childProductionType, designCapacity, enterpriseId, id, productionQuotaType, sales ,capacityUtilizationRate,salesAmount}) => {
          return {
            annualCapacity,
            childProductionType,
            designCapacity,
            productionQuotaType,
            capacityUtilizationRate,
            salesAmount,
            sales,
            enterpriseId: enterpriseId ? enterpriseId : '',
            id: id ? id : '',
          }
        }),
      }
      //处理多余的初始化数据
      if (data.enterpriseLayoutInfos.length > 3) {
        data.enterpriseLayoutInfos = data.enterpriseLayoutInfos.filter((item, index) => {
          return index < data.enterpriseLayoutInfos.length - 3
        })
      }

      this.$emit('savell', '1', data)
    },
  },
}
</script>

<style scoped lang="less">
.pcbContainer {
  padding: 0 0;
  h2 {
    text-align: center;
    margin: 20px auto;
    font-size: 30px;
  }
  .bigtitle {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin: 20px auto;
  }

  .flextd {
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
    tr,
    td {
      border: none;
    }
    .item {
      &:first-child {
        width: 50%;
        height: 80px;
        line-height: 80px;
        font-size: 18px;
        text-align: center;
        border-right: 1px solid #000;
      }
      &:last-child {
        display: flex;
        flex-direction: column;
        width: 50%;
        td {
          padding: 0;
          text-align: center;
          border-bottom: 1px solid #000;
          height: 20px;
          line-height: 20px;
        }
      }
    }
  }
}
.pcbtt {
  margin: 10px auto;
}
</style>

<template>
  <div class="pcbtable">
    <a-table :columns="columns" :data-source="data" bordered :pagination="false" :rowKey="record => record.key">

      <!-- <template slot="productionQuotaType" slot-scope="text, record">
        {{ handleText(record) }}
      </template> -->
      <template slot="annualOutput" slot-scope="text, record">
        <a-input v-if="formMode != 'view'" v-model="record.annualOutput" placeholder="请输入"></a-input>
        <div v-else>{{record.annualOutput}}</div>
      </template>
      <template slot="salesAmount" slot-scope="text, record">
        <a-input v-if="formMode != 'view'" v-model="record.salesAmount" placeholder="请输入"></a-input>
        <div v-else>{{record.salesAmount}}</div>
      </template>
      <!-- <template slot="totalAmountTax" slot-scope="text, record">
        <a-input v-if="formMode != 'view'" v-model="record.totalAmountTax" placeholder="请输入"></a-input>
        <div v-else>{{record.totalAmountTax}}</div>
      </template> -->
      <template slot="capacityUtilizationRate" slot-scope="text, record">
        <a-input v-if="formMode != 'view'" v-model="record.capacityUtilizationRate" placeholder="请输入"></a-input>
        <div v-else>{{record.capacityUtilizationRate}}</div>
      </template>
    </a-table>
  </div>
</template>

<script>
import uniqueid from 'lodash.uniqueid'
export default {
  name: 'ccltable',
  props: {
    childForm: {
      type: Array,
    },
    formMode: {
      type: String,
      default() {
        return 'edit'
      },
    },
  },
  watch: {
    childForm: {
      deep: true,
      handler(arr) {
        if (arr && arr.length > 0) {
          this.data = arr.map((item) => {
            return {
              ...item,
              key: uniqueid('2'),
            }
          })
        }
      },
    },
  },
  components: {},
  data() {
    return {
      //cclProductionQuotas
      columns: [
        // {
        //   title: '指标类型',
        //   dataIndex: 'productionQuotaType',
        //   scopedSlots: { customRender: 'productionQuotaType' },
        //   width: '120px',
        // },
        {
          title: '2022年产量（万平方米）',
          className: 'annualOutput',
          dataIndex: 'annualOutput',
          scopedSlots: { customRender: 'annualOutput' },
        },
        {
          title: '产能利用率（%）',
          dataIndex: 'capacityUtilizationRate',
          scopedSlots: { customRender: 'capacityUtilizationRate' },
        },
        {
          title: '营业收入(销售额)（万元）',
          dataIndex: 'salesAmount',
          scopedSlots: { customRender: 'salesAmount' },
        },
        // {
        //   title: '纳税总额（万元）',
        //   dataIndex: 'totalAmountTax',
        //   scopedSlots: { customRender: 'totalAmountTax' },
        // },
        // {
        //   title: '出口额（万元）',
        //   dataIndex: 'exports',
        //   scopedSlots: { customRender: 'exports' },
        // },
      ],
      data: [
        {
          productionQuotaType: 1,
          annualOutput: '',
          salesAmount: '',
          totalAmountInvestment: '',
          totalAmountTax: '',
          id:'',
          capacityUtilizationRate: '',
          key: uniqueid('2'),
        },
        {
          productionQuotaType: 1,
          annualOutput: '',
          salesAmount: '',
          totalAmountInvestment: '',
          totalAmountTax: '',
          id:'',
          capacityUtilizationRate: '',
          key: uniqueid('2'),
        },
      ],
    }
  },

  created() {},
  methods: {
    handleText(record) {
      let text = ''
      let _arr = [
        {
          type: 1,
          title: '刚性覆铜板CCL',
        },
        {
          type: 2,
          title: '挠性覆铜板FCCL',
        },
        {
          type: 3,
          title: '半固化片PP',
        },
      ]
      const findtext = _arr.find((item) => item.type == record.productionQuotaType)
      if (findtext) {
        text = findtext.title
      }

      return text
    },
    removeItem(index) {
      this.data.splice(index, 1)
    },
    submit(findstep, direction) {
      let cur = findstep.current
      if (direction === 'next') {
        cur++
      } else {
        cur--
        if (cur < 0) {
          cur = 0
        }
      }
      this.$emit('tonext', cur)
    },
  },
}
</script>

<style scoped lang="less">
.pcbtable {
}
</style>

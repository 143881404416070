var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      staticStyle: { top: "20px" },
      attrs: {
        title: "JSON数据",
        visible: _vm.visible,
        cancelText: "关闭",
        destroyOnClose: true,
        wrapClassName: "code-modal-9136076486841527",
        width: "850px",
      },
      on: { ok: _vm.handleImportJson, cancel: _vm.handleCancel },
    },
    [
      _c("p", { staticClass: "hint-box" }, [_vm._v("导入格式如下:")]),
      _c(
        "div",
        { staticClass: "json-box-9136076486841527" },
        [
          _c("Codemirror", {
            ref: "myEditor",
            staticStyle: { height: "100%" },
            model: {
              value: _vm.jsonFormat,
              callback: function ($$v) {
                _vm.jsonFormat = $$v
              },
              expression: "jsonFormat",
            },
          }),
        ],
        1
      ),
      _c(
        "Upload",
        {
          attrs: {
            action: "/abc",
            beforeUpload: _vm.beforeUpload,
            showUploadList: false,
            accept: "application/json",
          },
        },
        [
          _c("Button", { attrs: { type: "primary" } }, [
            _vm._v(" 导入json文件 "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import request from '@/utils/request';

const userApi = {
  Add: '/system-business/dynamicForm/add',
  GetFormById: '/system-business/dynamicForm/dynamicFormById',
  Upd: '/system-business/dynamicForm/upd',
};

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function add(parameter) {
  return request({
    url: userApi.Add,
    method: 'post',
    data: parameter,
  });
}

export function upd(parameter) {
  return request({
    url: userApi.Upd,
    method: 'post',
    data: parameter,
  });
}
/**
 * get user info
 */
export function getFormById(parameter) {
  return request({
    url: userApi.GetFormById,
    method: 'get',
    params: parameter,
  });
}

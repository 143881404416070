var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CheckboxItem",
    {
      attrs: { val: _vm._val, checked: _vm.chackboxVal },
      on: { change: _vm.handleChange },
    },
    [_vm._v(" " + _vm._s(_vm.label) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.record.options.noFormItem
    ? _c(
        _vm.componentItem,
        _vm._b({ tag: "component" }, "component", _vm.getComponentProps, false)
      )
    : _c(
        "a-form-model-item",
        {
          attrs: {
            prop: `domains.${_vm.index}.${_vm.record.model}`,
            rules: _vm.record.rules,
          },
        },
        [
          _c(
            _vm.componentItem,
            _vm._b(
              {
                ref: "inputItem",
                tag: "component",
                on: { change: _vm.handleChange },
              },
              "component",
              _vm.getComponentProps,
              false
            )
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
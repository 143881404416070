import request from "@/utils/request";
const tagApi = {
  DelTag: "/system-mms-user/tag/del",
  AddTag: "/system-mms-user/tag/add",
  UpdTag: "/system-mms-user/tag/update",
  Tags: "/system-mms-user/tag/tags",
  Tag: "/system-mms-user/tag/tagById",
};

/**
 * 获取所有标签 func
 * @param parameter
 * @returns {*}
 */
export function findTags(parameter) {
  return request({
    url: tagApi.Tags,
    method: "post",
    data: parameter,
  });
}
/**
 * 根据id查询标签 func
 * @param parameter
 * @returns {*}
 */
export function findTagById(parameter) {
  return request({
    url: tagApi.Tag,
    method: "post",
    data: parameter,
  });
}
/**
 * 添加标签 func
 * @param parameter
 * @returns {*}
 */
export function addTag(parameter) {
  return request({
    url: tagApi.AddTag,
    method: "post",
    data: parameter,
  });
}


/**
 * 更新标签 func
 * @param parameter
 * @returns {*}
 */
export function updateTag(parameter) {
  return request({
    url: tagApi.UpdTag,
    method: "post",
    data: parameter,
  });
}
/**
 * 删除标签 func
 * @param parameter
 * @returns {*}
 */
export function deleteTag(parameter) {
  return request({
    url: tagApi.DelTag,
    method: "post",
    data: parameter,
  });

  
}

<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <!-- 查询条件及保存数据 -->
        <a-form layout="inline" size="small">
          <a-row :gutter="48">
            <a-col :md="10" :sm="24">
              <a-form-item>
                <a-input style="width:250px" v-model="searchForm.object.content" placeholder="请输入要搜索的内容" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-button class="btn-item" type="primary" @click="search(1)">搜索</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-card>
    <a-card style="margin-top:24px">
      <!-- 操作按钮 -->
      <div slot="extra">
        <a-button class="btn-item btn-no-bottom member-brand" size="small" @click="openCreateDialog">发送新短信</a-button>
      </div>
      <!-- 查询数据展示 -->
      <a-table class="ant-table-striped" :columns="columns" :data-source="loadData" :row-key="record => record.id" :pagination="pagination" @change="changePage" bordered size="small" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
        <template slot="id" slot-scope="text,record">
          <a @click="viewRecord(record)">{{text}}</a>
        </template>
        <template slot="sendStatus" slot-scope="text">{{findLabelByValue(text,statusOptions)}}</template>
        <template slot="templateId" slot-scope="text">{{findLabelByValue(text,smsTemplateOptions)}}</template>
      </a-table>
    </a-card>

    <!-- 查看 -->
    <!-- <sms-view :viewVisible="viewVisible" @closeDetail="closeDetail" :sms="sms"></sms-view> -->
    <!-- 发送  -->
    <sms-info-form :addVisible="addVisible" :companyOptions="companyOptions" :tagOptions="tagOptions" :smsTemplateOptions="smsTemplateOptions" @closeAdd="closeAdd" @addInfo="addSms"></sms-info-form>
  </div>
</template>

<script>
import { sendSms,findSmses } from '@/api/info/smsPublish'
import SmsInfoForm from './components/SmsInfoForm'
// import SmsView from './components/SmsView'

import { findTags } from "@/api/tag"
import { findSmsTemplates } from "@/api/system/smsTemplate"
import { findCompanys } from "@/api/common"

import { findLabelByValue } from '@/utils/util'

const columns=[
  {
    title: '编号',
    dataIndex: "id",
    scopedSlots: { customRender: 'id' },
    width: 120
  },
  {
    title: '短信模板',
    dataIndex: "templateId",
    scopedSlots: { customRender: 'templateId' },
    width: 120
  },

  {
    title: '发送状态',
    dataIndex: 'sendStatus',
    scopedSlots: { customRender: 'sendStatus' },
    width: 100,
  },

  {
    title: '发送时间',
    dataIndex: 'createTime',
    width: 180
  },
]

export default {
  name: 'SmsPublish',
  components: {
    SmsInfoForm,
    // SmsView
  },
  created() {
    this.search(1);
    let templateReqData={
      pageSize: 50,
      pageIndex: 1,
      object: {
        code: "",
      }
    };
    let tagReqData={
      pageSize: 1000,
      pageIndex: 1,
      object: {
        tagName: "",
      }
    };
    // 查询公司集合
    findCompanys().then(res => {
      if(res.status==0&&res.code==200) {
        this.companyOptions=[];
        res.data.forEach(item => {
          this.companyOptions.push({ label: item.companyNameCn,value: item.id });
        });
      }
    });
    // 查询标签集合
    findTags(tagReqData).then(res => {
      if(res.status==0&&res.code==200) {
        this.tagOptions=[];
        res.data.pageObject.forEach(item => {
          this.tagOptions.push({ label: item.name,value: item.id });
        });
      }
    });

    // 查询邮件模板集合
    findSmsTemplates(templateReqData).then(res => {
      this.smsTemplateOptions=[];
      if(res.status==0&&res.code==200&&res.data) {
        res.data.pageObject.forEach(item => {
          this.smsTemplateOptions.push({ label: item.templateName,value: item.templateCode,record: item });
        })
      }
    });
  },
  data() {
    this.columns=columns
    return {
      pagination: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10","20","50","100"],//每页中显示的数据
        showTotal: total => this.$t('page.pagination.total.prefix')+`${total}`+this.$t('page.pagination.total.suffix'),
      },
      // 查询参数
      searchForm: {
        pageIndex: 1,
        pageSize: 10,
        object: {
          content: '',
        }
      },
      loadData: [],
      indentSize: 50,
      addVisible: false,
      sms: {
        companyIds: [],
        tagIds: [],
        templateId: '',
      },
      smsRecord: {},
      viewVisible: false,
      smsTemplateOptions: [],
      companyOptions: [],
      tagOptions: [],
      statusOptions: [{
        label: '发送失败',
        value: 0
      },{
        label: '发送成功',
        value: 1
      }],
    }
  },



  methods: {
    findLabelByValue,
    //根据查询菜单列表数据
    search(pageIndex) {
      this.searchForm.pageIndex=pageIndex? pageIndex:1;
      findSmses(this.searchForm).then(res => {
        if(res.status==0&&res.code==200) {
          this.pagination.total=res.data.total;
          this.loadData=res.data.pageObject;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //分页事件
    changePage(pagination) {
      this.searchForm.pageIndex=pagination.current;
      this.searchForm.pageSize=pagination.pageSize;
      this.pagination.current=pagination.current;
      this.search(this.seachForm.pageIndex);
    },
    closeAdd() {
      this.addVisible=false;
    },
    addSms() {
      if((!this.sms.companyIds||this.sms.companyIds.length<=0)&&(!this.sms.tagIds||this.sms.tagIds.length<=0)) {
        this.$message.error("标签或者公司至少选择1项")
        return;
      }

      sendSms(this.sms).then(res => {
        if(res.status==0&&res.code==200) {
          this.$message.success(res.msg)
          this.addVisible=false
          this.sms={
            companyIds: [],
            tagIds: [],
            templateId: '',
          };
          this.search(1)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    openCreateDialog() {
      this.addVisible=true;
    },
    viewRecord(record) {
      this.smsRecord=record;
      this.viewVisible=true;
    },
    closeDetail() {
      this.viewVisible=false;
      this.smsRecord={};
    }


  }
}
</script>
<style lang="less" scoped>
.editable-row-operations {
  a.danger {
    color: #ff4d4f;
    margin-left: 12px;
  }
}
</style>
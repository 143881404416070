var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.record.type === "tabs"
    ? _c(
        "a-tabs",
        {
          staticClass: "grid-row",
          attrs: {
            "default-active-key": 0,
            tabBarGutter: _vm.record.options.tabBarGutter,
            type: _vm.record.options.type,
            size: _vm.record.options.size,
            tabPosition: _vm.record.options.tabPosition,
            animated: _vm.record.options.animated,
          },
          model: {
            value: _vm.activeKey,
            callback: function ($$v) {
              _vm.activeKey = $$v
            },
            expression: "activeKey",
          },
        },
        _vm._l(_vm.record.columns, function (tabItem, index) {
          return _c(
            "a-tab-pane",
            { key: index, attrs: { tab: tabItem.label, forceRender: true } },
            _vm._l(tabItem.list, function (item) {
              return _c("buildBlocks", {
                key: item.key,
                ref: "nestedComponents",
                refInFor: true,
                attrs: {
                  disabled: _vm.disabled,
                  dynamicData: _vm.dynamicData,
                  record: item,
                  formConfig: _vm.formConfig,
                  config: _vm.config,
                },
                on: {
                  handleReset: function ($event) {
                    return _vm.$emit("handleReset")
                  },
                  change: _vm.handleChange,
                },
              })
            }),
            1
          )
        }),
        1
      )
    : _vm.record.type === "grid"
    ? _c(
        "a-row",
        {
          staticClass: "grid-row",
          attrs: { gutter: _vm.record.options.gutter },
        },
        _vm._l(_vm.record.columns, function (colItem, idnex) {
          return _c(
            "a-col",
            {
              key: idnex,
              staticClass: "grid-col",
              attrs: { span: colItem.span || 0 },
            },
            _vm._l(colItem.list, function (item) {
              return _c("buildBlocks", {
                key: item.key,
                ref: "nestedComponents",
                refInFor: true,
                attrs: {
                  disabled: _vm.disabled,
                  dynamicData: _vm.dynamicData,
                  record: item,
                  formConfig: _vm.formConfig,
                  config: _vm.config,
                },
                on: {
                  handleReset: function ($event) {
                    return _vm.$emit("handleReset")
                  },
                  change: _vm.handleChange,
                },
              })
            }),
            1
          )
        }),
        1
      )
    : _vm.record.type === "card"
    ? _c(
        "a-card",
        { staticClass: "grid-row", attrs: { title: _vm.record.label } },
        _vm._l(_vm.record.list, function (item) {
          return _c("buildBlocks", {
            key: item.key,
            ref: "nestedComponents",
            refInFor: true,
            attrs: {
              disabled: _vm.disabled,
              dynamicData: _vm.dynamicData,
              record: item,
              formConfig: _vm.formConfig,
              config: _vm.config,
            },
            on: {
              handleReset: function ($event) {
                return _vm.$emit("handleReset")
              },
              change: _vm.handleChange,
            },
          })
        }),
        1
      )
    : _vm.record.type === "table" && _vm.record.show
    ? _c(
        "table",
        {
          staticClass: "kk-table-9136076486841527",
          class: {
            bright: _vm.record.options.bright,
            small: _vm.record.options.small,
            bordered: _vm.record.options.bordered,
          },
          style:
            "width:" +
            _vm.record.options.width +
            ";" +
            _vm.record.options.customStyle,
        },
        _vm._l(_vm.record.trs, function (trItem, trIndex) {
          return _c(
            "tr",
            { key: trIndex },
            _vm._l(
              trItem.tds.filter((item) => item.colspan && item.rowspan),
              function (tdItem, tdIndex) {
                return _c(
                  "td",
                  {
                    key: tdIndex,
                    staticClass: "table-td",
                    attrs: { colspan: tdItem.colspan, rowspan: tdItem.rowspan },
                  },
                  _vm._l(tdItem.list, function (item) {
                    return _c("buildBlocks", {
                      key: item.key,
                      ref: "nestedComponents",
                      refInFor: true,
                      attrs: {
                        disabled: _vm.disabled,
                        dynamicData: _vm.dynamicData,
                        record: item,
                        formConfig: _vm.formConfig,
                        config: _vm.config,
                      },
                      on: {
                        handleReset: function ($event) {
                          return _vm.$emit("handleReset")
                        },
                        change: _vm.handleChange,
                      },
                    })
                  }),
                  1
                )
              }
            ),
            0
          )
        }),
        0
      )
    : !_vm.record.options.hidden && _vm.record.show
    ? _c("KFormItem", {
        key: _vm.record.key,
        ref: "nestedComponents",
        attrs: {
          disabled: _vm.disabled,
          dynamicData: _vm.dynamicData,
          record: _vm.record,
          formConfig: _vm.formConfig,
          config: _vm.config,
        },
        on: {
          handleReset: function ($event) {
            return _vm.$emit("handleReset")
          },
          change: _vm.handleChange,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
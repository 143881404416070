var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            width: _vm.width,
            title: !_vm.disabled ? "表单填写" : "表单预览",
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("k-form-build", {
            ref: "kfb",
            attrs: {
              value: _vm.value,
              defaultValue: _vm.defaultValue,
              disabled: _vm.disabled,
            },
            on: { change: _vm.change },
          }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { on: { click: _vm.handleCancel } }, [
                _vm._v(" 取消 "),
              ]),
              !_vm.disabled
                ? _c("a-button", { on: { click: _vm.findDraft } }, [
                    _vm._v(" 取出草稿 "),
                  ])
                : _vm._e(),
              !_vm.disabled
                ? _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleOk("draft")
                        },
                      },
                    },
                    [_vm._v(" 保存草稿 ")]
                  )
                : _vm._e(),
              !_vm.disabled
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleOk("edit")
                        },
                      },
                    },
                    [_vm._v(" 提交 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
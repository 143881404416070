<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :span="18">
              <a-form-item label="角色名称">
                <a-input style="width:250px" v-model="queryParam.object.roleName" placeholder="请输入角色名称" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-button class="btn-item" type="primary" @click="queryRole">搜索</a-button>
              <a-button class="btn-item" @click="resetting">重置</a-button>
            </a-col>
          </a-row>

        </a-form>
      </div>
    </a-card>
    <a-card  style="margin-top: 24px" :bordered="false">
      <!-- 操作按钮 -->
      <div slot="extra">
        <a-popconfirm ok-text="确定" cancel-text="取消" @confirm="addRole">
          <template slot="icon"><i /></template>
          <template slot="title">
            <a-form layout="horizontal" :label-col="{ span: 6 }" :wrapper-col="{ span:16 }" style="width:300px">
              <a-form-item label="角色编码" style="margin-bottom: 0;">
                <a-input-number style="width: 100%" v-model="role.roleCode"  placeholder="请输入角色编码"/>
              </a-form-item>
              <a-form-item label="角色名称" style="margin-bottom: 0;">
                <a-input v-model="role.roleName" placeholder="请输入角色名称"/>
              </a-form-item>
              <a-form-item label="角色描述" style="margin-bottom: 0;">
                <a-input v-model="role.roleDescription"  placeholder="请输入角色描述"/>
              </a-form-item>
              </a-form>
          </template>
          <a-button class="btn-item btn-no-bottom member-brand" icon="plus" size="small" @click="handleAdd">新增</a-button>
        </a-popconfirm>
      </div>

      <a-table class="ant-table-striped" :columns="columns" :data-source="loadData" :row-key="record => record.id" :pagination="pagination" bordered @change="handleTableChange" size="small" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
        <template v-for="(col, i) in ['roleCode','roleName','roleDescription']" :slot="col" slot-scope="text, record">
          <a-input :key="i+' '+record.id" v-if="record.editable" :value="text" v-model="record[col]" />
          <template v-else>
            {{ text }}
          </template>
        </template>

        <template slot="operation" slot-scope="text, record">
          <div class="editable-row-operations">
            <span v-if="record.editable">
              <a @click="updateRole(record)">保存</a>
              <a-divider type="vertical" />
              <a @click="cancelEdit(record)">取消</a>
            </span>
            <span v-else>
              <a @click="update(record)" type="primary" style="cursor: pointer" class="a-success">编辑</a>
              <a-divider type="vertical" />
              <a-popconfirm title="是否确认删除?" ok-text="确认" cancel-text="取消" @confirm="deleteRole(record.id)" @cancel="cancel">
                <a style="cursor: pointer" class="a-danger">删除</a>
              </a-popconfirm>
              <a-divider type="vertical" />
              <a @click="handleEditRoleMenu(record.id)" icon="plus" class="a-brand">编辑角色菜单</a>
            </span>
          </div>
        </template>
      </a-table>

      <a-modal :visible="editRoleMenuVisible" title="编辑角色菜单资源" @ok="updateRoleMenu" @cancel="cancelEditRoleMenu">
        <a-tree v-model="checkedKeys" checkable :tree-data="menus" :defaultExpandAll="true" :replaceFields="replaceFields" :checkStrictly="true"></a-tree>
      </a-modal>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { addRole,deleteRole,updateRole,queryRole,findRoleMenu,addRoleMenu } from '@/api/system/role'
import { findMenu } from '@/api/system/menu'

import { getCellStyleFunction  } from '@/utils/util'

const columns=[
   {
    title: '角色编码',
    dataIndex: "roleCode",
    customHeaderCell: getCellStyleFunction(200,14),
    customCell: getCellStyleFunction(200,14)
  },

  {
    title: '角色名称',
    dataIndex: "roleName",
    customHeaderCell: getCellStyleFunction(200,14),
    customCell: getCellStyleFunction(200,14)
  },
  {
    title: '角色描述',
    dataIndex: 'roleDescription',
    scopedSlots: { customRender: 'roleDescription' },
    customHeaderCell: getCellStyleFunction(200,14),
    customCell: getCellStyleFunction(200,14)
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    customHeaderCell: getCellStyleFunction(200,14),
    customCell: getCellStyleFunction(200,14)
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    customHeaderCell: getCellStyleFunction(220,14),
    customCell: getCellStyleFunction(220,14)
  }
];

export default {
  name: 'RoleList',
  components: {  },
  data() {
    return {
      //增加角色
      addVisible: false,
      role: {
        roleName: "",
        roleCode: "",
        roleDescription: "",

      },
      columns:columns,
      // 查询参数
      loadData: [],
      queryParam: {
        pageIndex: 1,
        pageSize: 10,
        object: {
          roleName: '',
        }
      },
      pagination: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10","20","50","100"],//每页中显示的数据
        showTotal: total => this.$t('page.pagination.total.prefix')+`${total}`+this.$t('page.pagination.total.suffix')
      },
      editRoleMenuVisible: false,
      checkedKeys: [],
      editingRoleId: "",
      menus: [],
      replaceFields: {
        title: "title",
        key: 'id',
      },
      //角色选中的菜单资源
      selectedKeys: [],
      appendFindRoleMenu: false,
    }
  },
  methods: {
    handleAdd() {
      this.addVisible=!this.addVisible;
    },
    //重置
    resetting() {
      this.queryParam.object={
        roleName: '',
      }
    },
    //根据条件查询角色列表数据
    queryRole() {
      queryRole(this.queryParam).then(res => {
        if(res.code==200) {
          this.pagination.total=res.data.total;
          this.loadData=res.data.pageObject;
        } else {
          this.$message.error(res.msg);
        }
      })
    },

    //分页事件
    handleTableChange(pagination) {
      this.pagination.pageIndex=pagination.pageIndex;
      this.pagination.pageSize=pagination.pageSize;
    },

    //添加角色
    addRole() {
      addRole(this.role).then(res => {
        if(res.code==200) {
          this.$message.success('提交成功');
          this.role={
            roleName: "",
            roleCode: "",
            roleDescription: "",
          },
            this.addVisible=!this.addVisible
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    //删除角色
    deleteRole(id) {
      let requestData={
        id: id
      }
      deleteRole(requestData).then(res => {
        if(res.code==200) {
          this.$message.success('成功删除');
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    cancel() {
      return;
    },

    //修改 upd updRole
    //开启编辑，显示“保存，取消”按钮
    update(record) {
      this.$set(record,"editable",true);
    },
    //取消编辑
    cancelEdit(record) {
      this.$delete(record,"editable");

    },
    //保存编辑
    updateRole(record) {
      let updateRoles={
        id: record.id,
        roleName: record.roleName,
        roleCode: record.roleCode,
        roleDescription: record.roleDescription
      };
      updateRole(updateRoles).then(res => {
        if(res.code==200) {
          this.$message.success('修改完成');
          this.$delete(record,"editable");
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    //查询所有菜单
    findMenu() {
      findMenu().then(res => {
        if(res.code==200) {
          this.menus=this.changeMenukeyToName(res.data);
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    //编辑菜单资源点击事件
    handleEditRoleMenu(id) {
      this.editRoleMenuVisible=!this.editRoleMenuVisible;
      this.editingRoleId=id;
      let checkedKeys=[]
      let requestData={
        id: id
      }
      findRoleMenu(requestData).then(res => {
        if(res.code==200) {
          for(let i=0;i<res.data.length;i++) {
           checkedKeys.push(res.data[i].id);
          }
          this.checkedKeys = checkedKeys;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    cancelEditRoleMenu(){
      this.editRoleMenuVisible=!this.editRoleMenuVisible;
    },
    //提交角色菜单资源
    updateRoleMenu() {
      // 取出checked数据 并将其转换为后端需要的数据格式
      let checkedKeys = this.checkedKeys.checked?this.checkedKeys.checked:this.checkedKeys;
      let requestData={
        id: this.editingRoleId,
        menus: checkedKeys
      }
      addRoleMenu(requestData).then(res => {
        if(res.code==200) {
          this.$message.success("修改成功")
          this.editRoleMenuVisible=!this.editRoleMenuVisible;
        } else {
          this.$message.error(res.msg)
        }
      })

    },
    // 工具方法 将菜单key转换为name
    changeMenukeyToName(menus) {
      if(menus&&menus.length>0) {
        for(let i=0;i<menus.length;i++) {
          menus[i].title=this.$t(menus[i].title);
          this.changeMenukeyToName(menus[i].children);
        }
      }
      return menus;
    },

  },
  mounted() {
    this.queryRole()
    this.findMenu()
  }
}
</script>

<style lang="less">
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-form-model",
    {
      ref: "qu",
      attrs: {
        model: _vm.query,
        labelAlign: "right",
        id: "reports-form",
        colon: false,
      },
      on: { submit: _vm.handleSubmit },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _vm.timeIntervalLable != "季度"
        ? _c(
            "a-form-model-item",
            { attrs: { label: "年度" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择", "allow-clear": "" },
                  model: {
                    value: _vm.query.timeInterval,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "timeInterval", $$v)
                    },
                    expression: "query.timeInterval",
                  },
                },
                [
                  _vm._l(_vm.annualOptions, function (item, index) {
                    return _vm.timeIntervalLable == "年度"
                      ? _c(
                          "a-select-option",
                          { key: index, attrs: { value: item.value } },
                          [_vm._v(" " + _vm._s(item.label) + " ")]
                        )
                      : _vm._e()
                  }),
                  _vm._l(_vm.quarterAnnualOptions, function (item, index) {
                    return _vm.timeIntervalLable == "季度"
                      ? _c(
                          "a-select-option",
                          { key: index, attrs: { value: item.value } },
                          [_vm._v(" " + _vm._s(item.label) + " ")]
                        )
                      : _vm._e()
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.timeIntervalLable == "季度"
        ? _c(
            "a-form-model-item",
            { attrs: { label: "季度" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "show-search": "",
                    placeholder: "请选择",
                    "allow-clear": "",
                  },
                  model: {
                    value: _vm.query.quarterTypeOld,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "quarterTypeOld", $$v)
                    },
                    expression: "query.quarterTypeOld",
                  },
                },
                _vm._l(_vm.quarterOptions, function (item, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: item.value } },
                    [_vm._v(" " + _vm._s(item.year + item.label) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isAdmin
        ? _c(
            "a-form-model-item",
            { attrs: { label: "主营业务" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择主营业务", "allow-clear": "" },
                  model: {
                    value: _vm.query.enterpriseBusinessType,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "enterpriseBusinessType", $$v)
                    },
                    expression: "query.enterpriseBusinessType",
                  },
                },
                _vm._l(_vm.business, function (item, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: item.value } },
                    [_vm._v(" " + _vm._s(item.label) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isAdmin
        ? _c(
            "a-form-model-item",
            { attrs: { label: "注册地区" } },
            [
              _c("a-input", {
                attrs: { placeholder: "地区" },
                model: {
                  value: _vm.query.communicationAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "communicationAddress", $$v)
                  },
                  expression: "query.communicationAddress",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isAdmin
        ? _c(
            "a-form-model-item",
            { attrs: { label: "单位名称" } },
            [
              _c("a-input", {
                attrs: { placeholder: "单位名称" },
                model: {
                  value: _vm.query.enterpriseName,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "enterpriseName", $$v)
                  },
                  expression: "query.enterpriseName",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-form-model-item",
        { attrs: { label: "填报状态" } },
        [
          _c(
            "a-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "请选择填报状态", "allow-clear": "" },
              model: {
                value: _vm.query.reportStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "reportStatus", $$v)
                },
                expression: "query.reportStatus",
              },
            },
            _vm._l(_vm.status, function (item, index) {
              return _c(
                "a-select-option",
                { key: index, attrs: { value: item.value } },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "a-form-model-item",
        {
          staticClass: "btn-box",
          attrs: { labelCol: { span: 0 }, wrapperCol: { span: 24 } },
        },
        [
          _c(
            "a-button",
            {
              staticClass: "submitbtn",
              attrs: { type: "primary", "html-type": "submit" },
            },
            [_vm._v(" 查询 ")]
          ),
          _c("a-button", { on: { click: _vm.resetform } }, [_vm._v(" 重置 ")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
// eslint-disable-next-line
import * as loginService from "@/api/login";
// eslint-disable-next-line
import { BasicLayout, BlankLayout, PageView, RouteView } from "@/layouts";

// 前端路由表
const constantRouterComponents = {
  // 基础页面 layout 必须引入
  BasicLayout: BasicLayout,
  BlankLayout: BlankLayout,
  RouteView: RouteView,
  PageView: PageView,

  403: () => import(/* webpackChunkName: "error" */ '@/views/exception/403'),
  404: () => import(/* webpackChunkName: "error" */ '@/views/exception/404'),
  500: () => import(/* webpackChunkName: "error" */ '@/views/exception/500'),

  // 你需要动态引入的页面组件
  Index: () => import('@/views/Index'),

  // 信息填报
  AnnualFillingList: () => import('@/views/filling/AnnualFillingList.vue'),
  QuarterFillingList: () => import('@/views/filling/QuarterFillingList.vue'),
  MonthlyFillingList: () => import('@/views/filling/MonthlyFillingList.vue'),

  // //会员填报
  // MemberAnnualFillingList: () =>
  //   import("@/views/member-info/MemberAnnualFillingList.vue"),
  // MemberQuarterFillingList: () =>
  //   import("@/views/member-info/MemberQuarterFillingList.vue"),
  // MemberMonthlyFillingList: () =>
  //   import("@/views/member-info/MemberMonthlyFillingList.vue"),

  // 统计
  AnnualStatistic: () => import('@/views/statistic/AnnualStatistic.vue'),
  QuarterStatistic: () => import('@/views/statistic/QuarterStatistic.vue'),
  MonthlyStatistic: () => import('@/views/statistic/MonthlyStatistic.vue'),
  // 信息管理
  InfoPublish: () => import('@/views/info/InfoPublish'),
  EmailPublish: () => import('@/views/info/EmailPublish'),
  SmsPublish: () => import('@/views/info/SmsPublish'),

  // 表单管理
  FormDesign: () => import('@/views/formManage/FormDesign'),
  AllForm: () => import('@/views/formManage/AllForm.vue'),

  // 系统管理
  MenuList: () => import('@/views/system/MenuList'),
  RoleList: () => import('@/views/system/RoleList'),
  Sync: () => import('@/views/system/sync'),
  LogList: () => import('@/views/system/LogList'),
  EmailTemplate: () => import('@/views/system/EmailTemplate'),
  InfoTemplate: () => import('@/views/system/InfoTemplate'),

  // 个人中心
  PersonSetting: () => import('@/views/account/PersonSetting'),
  PasswordChange: () => import('@/views/account/PasswordChange'),

  // exception
  Exception403: () =>
    import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
  Exception404: () =>
    import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
  Exception500: () =>
    import(/* webpackChunkName: "fail" */ '@/views/exception/500'),

  // account
  BasicSetting: () => import('@/views/account/settings/BasicSetting'),
  SecuritySettings: () => import('@/views/account/settings/Security'),
  passwordChange: () => import('@/views/account/settings/Index'),

  // 公共组件
  Login: () => import('@/views/user/Login'),
};

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  path: "*",
  redirect: "/404",
  hidden: true,
};

// 根级菜单
const rootRouter = {
  key: "",
  name: "home",
  path: "",
  component: "BasicLayout",
  redirect: "/index",
  meta: {
    title: "menu.home",
  },
  children: [],
};

/**
 * 动态生成菜单
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = () => {
  return new Promise((resolve, reject) => {
    loginService
      .getCurrentUserNav({projectId:'mas'})
      .then((res) => {
        const result = res.data;
        const menuNav = [];
        const childrenNav = [];
        // 后端数据, 根级树数组,  根级 PID
        
        listToTree(result, childrenNav, 0);
        rootRouter.children = childrenNav;
        menuNav.push(rootRouter);
        const routers = generator(menuNav);
        routers.push(notFoundRouter);
        resolve(routers);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap, parent) => {
  return routerMap.map((item) => {
    const { title, show, hideChildren, hiddenHeaderContent, target, icon } =
      item.meta || {};
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.path || `${(parent && parent.path) || ""}/${item.key}`,
      // 路由名称，建议唯一
      name: item.name || item.key || "",
      // 该路由对应页面的 组件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 该路由对应页面的 组件 :方案2 (动态加载)
      component:
        constantRouterComponents[item.component || item.key] ||
        (() => import(`@/views/${item.component}`)),

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: title,
        icon: icon || undefined,
        hiddenHeaderContent: hiddenHeaderContent,
        target: target,
        permission: item.name,
      },
    };
    // 是否设置了隐藏菜单
    if (show === false) {
      currentRouter.hidden = true;
    }
    // 是否设置了隐藏子菜单
    if (hideChildren) {
      currentRouter.hideChildrenInMenu = true;
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith("http")) {
      currentRouter.path = currentRouter.path.replace("//", "/");
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect);
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter);
    }
    return currentRouter;
  });
};

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
const listToTree = (list, tree, parentId) => {
  
  list.forEach((item) => {
    // 判断是否为父级菜单
    if (item.parentId === parentId) {
      const child = {
        ...item,
        key: item.key || item.name,
        children: [],
      };
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, item.id);
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children;
      }
      // 加入到树中
      tree.push(child);
    }
  });
};

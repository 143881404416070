import request from "@/utils/request";

const Api = {
  Find: "/system-mms-user/sms_template/find",
};

//查询
export function findSmsTemplates(parameter) {
  return request({
    url: Api.Find,
    method: "post",
    data: parameter,
  });
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      staticStyle: { top: "20px" },
      attrs: {
        title: "JSON数据",
        footer: null,
        visible: _vm.visible,
        destroyOnClose: true,
        wrapClassName: "code-modal-9136076486841527",
        width: "850px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [_c("previewCode", { attrs: { editorJson: _vm.editorJson } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-form-model",
    {
      ref: "qu",
      attrs: {
        model: _vm.query,
        labelAlign: "right",
        id: "reports-form",
        colon: false,
      },
      on: { submit: _vm.handleSubmit },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _vm._l(_vm.formList, function (item, index) {
        return _c(
          "a-form-model-item",
          { key: index, attrs: { label: item.label } },
          [
            item.type == "select"
              ? _c(
                  "a-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: item.placeholder,
                      "allow-clear": item.allowClear,
                    },
                    model: {
                      value: item.value,
                      callback: function ($$v) {
                        _vm.$set(item, "value", $$v)
                      },
                      expression: "item.value",
                    },
                  },
                  _vm._l(item.option, function (optionItem, optionIndex) {
                    return _c(
                      "a-select-option",
                      { key: optionIndex, attrs: { value: optionItem.value } },
                      [_vm._v(" " + _vm._s(optionItem.label) + " ")]
                    )
                  }),
                  1
                )
              : _vm._e(),
            item.type == "input"
              ? _c("a-input", {
                  attrs: { placeholder: item.placeholder },
                  model: {
                    value: item.value,
                    callback: function ($$v) {
                      _vm.$set(item, "value", $$v)
                    },
                    expression: "item.value",
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      _c(
        "a-form-model-item",
        {
          staticClass: "btn-box",
          attrs: { labelCol: { span: 0 }, wrapperCol: { span: 24 } },
        },
        [
          _c(
            "a-button",
            {
              staticClass: "submitbtn",
              attrs: { type: "primary", "html-type": "submit" },
            },
            [_vm._v(" 查询 ")]
          ),
          _c("a-button", { on: { click: _vm.resetform } }, [_vm._v(" 重置 ")]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
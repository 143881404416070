<template>
  <page-header-wrapper>

    <div class="reports">
      <a-card v-if="isShow">
        年度统计，敬请期待！
      </a-card>
      <a-card class="noborder top-header" v-else>
        <HeaderForm @search="onExport" :formList="headerFormList" buttonName="导出"></HeaderForm>
        <!-- <div style="margin-bottom: 20px;">
          <a-button type="primary" @click="onExport">导出</a-button>
        </div> -->
        <!-- <a-table 
        :columns="columns" 
        :data-source="dataList"
        :loading="loading"
        :pagination="pagination"
        tableLayout="auto" 
        :scroll="{ x: true }"
        @change="handleTableChange">
          <template v-for="col in columnDataIndex"
            :slot="col"
            slot-scope="text, record" >
              {{ getDataList(col,record) }}
          </template>
        </a-table>
        <a-table 
        v-show="false"
        id="table"
        :columns="columns" 
        :data-source="dataList"
        :loading="loading"
        tableLayout="auto" 
        :pagination="false"
        :scroll="{ x: true }">
          <template v-for="col in columnDataIndex"
            :slot="col"
            slot-scope="text, record" >
              {{ getDataList(col,record) }}
          </template>
        </a-table> -->
      </a-card>
    </div>

    
  </page-header-wrapper>
</template>

<script>
// 引入xlsx
import * as XLSX from 'xlsx'
import HeaderForm from '@/components/MasCmponent/HeaderForm.vue'
import { getData, getMenu, exportData } from "@/api/statistic/AnnualFillingList.js";
import { download, annualOptions } from "@/utils/util";
import { typeBussine, findDynamicAndIds } from "../filling/Business"
export default {
  name: 'AnnualStatistic',
  components: {
    HeaderForm,
  },
  data() {
    return {
      // tableScroll: {y: 600, x: 300},
      // pagination: {
      //   total: 0,
      //   pageSize: 10,//每页中显示10条数据
      //   showSizeChanger: true,
      //   pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
      //   showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
      // },
      // loading: false,
      isShow: false,
      // tableShow:false,
      // dataList:[],
      // columns: [],
      // columnDataIndex: [],
      // queryParam: {
      //   page: 1,//第几页
      //   size: 10,//每页中显示数据的条数
      // },
      type: "1",
      headerFormList: [
        // timeInterval: new Date().getFullYear(), //年度
        {
          name: 'year',
          type: 'select',
          value: 2023,
          label: '年度',
          allowClear: false,
          placeholder: "请选择",
          option: annualOptions
        },
        {
          name: 'id',
          type: 'select',
          allowClear: true,
          value: undefined,
          label: '选择表单',
          placeholder: "请选择",
          option: [],
        },
      ]
    }
  },
  created() {
    this.findDynamicAndId()
    // this.getData()
    // this.getMenu()
  },
  methods: {
    // getlist() {
    //   this.columns = [
    //     {
    //       dataIndex: 'name',
    //       key: 'name',
    //       slots: { title: 'customTitle' },
    //       scopedSlots: { customRender: 'name' },
    //     },
    //     {
    //       title: 'Age',
    //       dataIndex: 'age',
    //       key: 'age',
    //     },
    //     {
    //       title: 'Address',
    //       dataIndex: 'address',
    //       key: 'address',
    //     },
    //     {
    //       title: 'Tags',
    //       key: 'tags',
    //       dataIndex: 'tags',
    //       scopedSlots: { customRender: 'tags' },
    //     },
    //     {
    //       title: 'Action',
    //       key: 'action',
    //       scopedSlots: { customRender: 'action' },
    //     },
    //   ];
    //   this.dataList = [
    //     {
    //       key: '1',
    //       name: 'John Brown',
    //       age: 32,
    //       address: 'New York No. 1 Lake Park',
    //       tags: ['nice', 'developer'],
    //     },
    //     {
    //       key: '2',
    //       name: 'Jim Green',
    //       age: 42,
    //       address: 'London No. 1 Lake Park',
    //       tags: ['loser'],
    //     },
    //     {
    //       key: '3',
    //       name: 'Joe Black',
    //       age: 32,
    //       address: 'Sidney No. 1 Lake Park',
    //       tags: ['cool', 'teacher'],
    //     },
    //   ]
    // },
    // getData() {
    //   getData().then(res => {
    //     this.dataList=res
    //   })
    // },
    // getMenu() {
    //   getMenu().then(res => {
    //     this.columns = this.getMenuList(res.children,'')
    //   })
    // },
    // handleTableChange(pagination, filters, sorter) {
    // },
    // getMenuList(data,dataIndex) {
    //   data.forEach(element => {
    //     element.scopedSlots = { customRender: '' }
    //     // 按照层级获取所需字段，用'_'间隔每一级
    //     if (dataIndex.length > 0) {
    //       element.dataIndex = JSON.parse(JSON.stringify(dataIndex +"_"+ element.index))
    //     } else {
    //       element.dataIndex = dataIndex + element.index
    //     }
    //     element.scopedSlots.customRender = element.dataIndex
    //     if (element.children != null && element.children.length > 0) {
    //       this.getMenuList(element.children,element.dataIndex)
    //     } else {
    //       this.columnDataIndex.push(element.dataIndex)
    //     }
    //   });
    //   // console.log("this.columnDataIndex",this.columnDataIndex,'data',data);
    //   return data
    // },
    // getDataList(col, record) {
    //   //根据所需数据的col值，用'_'拆分层级，以取到当前数据
    //   let array = col.split('_')
    //   //  console.log("array",array,'record',record);
    //   let data = record
    //   if (array.length <= 1) {
    //     data = record[array[0]]
    //   } else {
    //     for (let i = 0; i < array.length; i++) {
    //       data = data[array[i]]
    //     }
    //   }

    //   return data
    // },

    //onExport() {
    //this.tableShow = true
    //const wb = XLSX.utils.table_to_book(document.getElementById('table'))
    //XLSX.writeFile(wb, `年度统计报表导出.xlsx`)
    //this.tableShow = false
    //},
    async findDynamicAndId() {
      let res = await findDynamicAndIds()
      this.headerFormList[1].option = res
    },
    onExport(data) {
      if (!data.id) {
        this.$message.error('请选择表单')
        return
      }
      let object = {
        id: data.id,
        year: data.year,
        type: this.type,
      }
      exportData(object).then((res) => {
        download(res, "年度统计报表导出.xls");
      });
    },
  },
}
</script>

<style scoped lang="less">
.reports {
  background: #f3f3f3;
  height: 100%;
  // padding: 24px 24px;
  display: flex;
  flex-direction: column;
  .noborder {
    border: none;
    border-radius: 10px;
  }
  .top-header {
    margin-bottom: 24px;
  }
  .bottom-card {
    flex: 1;
    .ant-card-extra {
      .add {
        margin-right: 24px;
      }
    }
  }
}
</style>

import request from '@/utils/request'

const IndexApi = {
  FindFilling: '/system-business/systemHomePagee/managementSide',
  FindArea: '',
  FindUrge: '',
  FindFilled: '',
  FindUserFillingData: '/system-business/systemHomePagee/userSide',
};

export function queryFillingData(parameter) {
  return request({
    url: IndexApi.FindFilling,
    method: 'get',
    params: parameter,
  });
}
export function queryAreaData(parameter) {
  return request({
    url: IndexApi.FindArea,
    method: "post",
    data: parameter,
  });
}
export function queryUrgeData(parameter) {
  return request({
    url: IndexApi.FindUrge,
    method: "post",
    data: parameter,
  });
}
export function queryFilledData(parameter) {
  return request({
    url: IndexApi.FindFilled,
    method: "post",
    data: parameter,
  });
}

export function queryUserFilledData() {
  return request({
    url: IndexApi.FindUserFillingData,
    method: "get"
  });
}
import request from "@/utils/request";
const applyApi = {
  Associations: "/system-mms-user/association/associations",
  Updata: "/system-mms-user/user/upd",
  getUser: "/system-mms-user/user/info",
};

/**
 * 获取所有协会 func
 * @param parameter
 * @returns {*}
 */
export function updatePersonSetting(parameter) {
  return request({
    url: applyApi.Associations,
    method: "post",
    data: parameter,
  });
}
/**
 * apply func
 * parameter: {
 * }
 * @param parameter
 * @returns {*}
 */
export function apply(parameter) {
  return request({
    url: applyApi.Apply,
    method: "post",
    data: parameter,
  });
}

export function updata(parameter) {
  return request({
    url: applyApi.Updata,
    method: "post",
    data: parameter,
  });
}

export function getUser(parameter) {
  return request({
    url: applyApi.getUser,
    method: "post",
    data: parameter,
  });
}

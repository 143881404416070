var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "account-settings-info-view" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16, type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { order: _vm.isMobile ? 2 : 1, md: 24, lg: 16 } },
            [
              _c(
                "a-form",
                { attrs: { layout: "vertical" } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t("account.settings.basic.nickname"),
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            "account.settings.basic.nickname-message"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t("account.settings.basic.profile"),
                      },
                    },
                    [
                      _c("a-textarea", {
                        attrs: {
                          rows: "4",
                          placeholder: _vm.$t(
                            "account.settings.basic.profile-message"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t("account.settings.basic.email"),
                        required: false,
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "example@ant.design" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c("a-button", { attrs: { type: "primary" } }, [
                        _vm._v(_vm._s(_vm.$t("account.settings.basic.update"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              style: { minHeight: "180px" },
              attrs: { order: 1, md: 24, lg: 8 },
            },
            [
              _c(
                "div",
                {
                  staticClass: "ant-upload-preview",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.modal.edit(1)
                    },
                  },
                },
                [
                  _c("a-icon", {
                    staticClass: "upload-icon",
                    attrs: { type: "cloud-upload-o" },
                  }),
                  _c(
                    "div",
                    { staticClass: "mask" },
                    [_c("a-icon", { attrs: { type: "plus" } })],
                    1
                  ),
                  _c("img", { attrs: { src: _vm.option.img } }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("avatar-modal", { ref: "modal", on: { ok: _vm.setavatar } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
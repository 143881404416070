import request from "@/utils/request";

//添加提交pcb表单
export function getMenu(data) {
  return request({
    url: `/system-business/statement/getMenu`,
    method: 'post',
    data,
  });
}
export function getData(data) {
  return request({
    url: `/system-business/statement/data`,
    method: 'post',
    data,
  });
}

export function exportData(data) {
  return request({
    url: `/system-business/dynamicForm/exportData`,
    method: 'post',
    responseType: 'blob',
    data,
  });
}

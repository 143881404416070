<template>
  <page-header-wrapper>
    <div>
      <a-row :gutter="24">
        <a-col :xl="16" :lg="12" :md="12" :sm="24" :xs="24" class="col-flex">
          <template v-if="isAdmin">
            <div class="apply Container_info">
              <div class="title_box">
                <div class="t">每日填报监控</div>
              </div>
              <div style="width:48%;height:86%;margin: 0;" ref="chart"></div>
              <div class="main" style="border-left: 2px dashed #DDE0E5;position: absolute; left: 50%;top: 16%;height: 80%;width: 50%;padding: 10px !important;">
                <a-table :columns="fillingColumns" :data-source="fillingDatas" :pagination="false" :scroll="{ y: tableHeight }" v-if="fillingDatas.length" :row-key="(record,index)=> index"></a-table>
                <a-empty class="posempty" v-else/>
              </div>
              <div class="pagin_box">
                <a-pagination size="small" :total="fillingTotal" :defaultPageSize="5" hideOnSinglePage @change="(current, pageSize) =>pageChange(current, pageSize, 'filling')" />
              </div>
            </div>
            
            <div class="message Container_info">
              <div class="title_box">
                <div class="t">填报情况统计 <span v-if="areaTotal">( {{ areaTotal }} )</span> </div>
              </div>
              <div style="width:28%;height:86%;margin: 10px 0;" ref="reportChart"></div>
              <div style="width:20%;height:86%;position: absolute; top: 40%;left: 28%;">
                <span>未上报</span> <span style="float: right;">{{ failToReportedProportion }}</span>
                <a-progress type="line" strokeColor="#9ECA7F" :percent="Number(failToReportedProportion.split('.')[0])" :show-info="false" />
                <div></div>
                <span style="margin-top: 25%;display: inline-block;">已上报</span><span style="float: right;margin-top: 25%;">{{haveReportedProportion}}</span>
                <a-progress :percent="Number(haveReportedProportion.split('.')[0])" strokeColor="#2953E3" :show-info="false" />
              </div>
              <div style="border-left: 2px dashed #DDE0E5;position: absolute; left: 50%;top: 16%;height: 80%;width: 50%;padding: 10px !important;" >
                <a-table :columns="areaColumns" :data-source="areaDatas" :pagination="false" :scroll="{ y: tableHeight }" v-if="areaDatas.length" :row-key="(record,index)=> index"></a-table>
                <a-empty class="posempty" v-else/>
              </div>
              <div class="pagin_box">
                <a-pagination size="small" :total="areaTotal" :defaultPageSize="5" hideOnSinglePage @change="(current, pageSize) =>pageChange(current, pageSize, 'area')" />
              </div>
            </div>
          </template>

          <template v-else>
            <div class="message Container_info">
              <div class="title_box">
                <div class="t">已填报数据 <span v-if="filledTotal">( {{ filledTotal }} )</span></div>
              </div>
              <div class="main">
                <a-table :columns="filledColumns" :data-source="filledData" :pagination="false" :scroll="{ y: tableHeight }" v-if="filledData.length" :row-key="(record,index)=> index">
                  <div slot="fillingType" slot-scope="text,record" style="padding: 6px 0;">
                    <!--报告标题对应-->
                    <span>{{record.title}}</span>
                  </div>
                </a-table>
                <a-empty class="posempty" v-else/>
              </div>
              <div class="pagin_box">
                <a-pagination size="small" :total="filledTotal" :defaultPageSize="5"  @change="(current, pageSize) =>pageChange(current, pageSize, 'filled')" />
              </div>
            </div>
          </template>
        </a-col>
        <a-col :xl="8" :lg="12" :md="12" :sm="24" :xs="24" class="col-flex-pay">
          <template v-if="isAdmin">
            <div class="pay Container_info" :loading="loading">
              <div class="title_box">
                <div class="t">催报统计</div>
              </div>
              <div class="main">
                <a-radio-group default-value="1" button-style="solid" class="gp" @change="changeUrgeStatus">
                  <a-radio-button value="1"> 已催报 ({{ this.urgeTotal }})</a-radio-button>
                  <a-radio-button value="0"> 待催报 ({{ this.notUrgeTotal }})</a-radio-button>
                </a-radio-group>
                <div v-if="urgeStatus == 1">
                  <a-table :columns="urgeColumns" :data-source="urgeDatas" :pagination="false" :scroll="{ y: tableHeight }" v-if="urgeData.length" :row-key="(record,index)=> index">
                    <div slot="urgeStatus" slot-scope="text">
                      <template v-if="text == 1"><span class="tblock tag-red">待催报</span></template>
                      <template v-if="text == 2"><span class="tblock tag-green">已催报</span></template>
                      <template v-if="text == 3"><span class="tblock tag-green">已完成</span></template>
                    </div>
                  </a-table>
                  <a-empty class="posempty" v-else/>
                </div>
                <div v-else>
                  <a-table :columns="urgeColumns" :data-source="notUrgeDatas" :pagination="false" :scroll="{ y: tableHeight }" v-if="notUrgeData.length" :row-key="(record,index)=> index">
                    <div slot="urgeStatus" slot-scope="text">
                      <template v-if="text == 1"><span class="tblock tag-red">待催报</span></template>
                      <template v-if="text == 2"><span class="tblock tag-green">已催报</span></template>
                      <template v-if="text == 3"><span class="tblock tag-green">已完成</span></template>
                    </div>
                  </a-table>
                  <a-empty class="posempty" v-else/>
                </div>
                
              </div>
              <div v-if="urgeStatus == 1" class="pagin_box">
                <a-pagination size="small" :total="urgeTotal" :defaultPageSize="15" hideOnSinglePage @change="(current, pageSize) =>pageChange(current, pageSize, 'urge')" />
              </div>
              <div v-else class="pagin_box">
                <a-pagination size="small" :total="notUrgeTotal" :defaultPageSize="15" hideOnSinglePage @change="(current, pageSize) =>pageChange(current, pageSize, 'notUrge')" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="message Container_info">
              <div class="title_box">
                <div class="t">填报通知</div>
                <a-icon type="right" @click="jumpFilling" class="send-admin"/>
              </div>
              <!-- <div class="main" v-if="meetings.length">
                <div class="notices-item" :key="index" v-for="(item, index) in meetings">
                  <div class="txt " style="cursor:pointer;" @click="viewMeetingDetail(item.id)">
                    {{ item.title}}
                    <div class="times">{{ item.createTime }}</div>
                  </div>
                </div>
              </div> -->
              <div class="main" v-if="filledData1.length">
                <a-table :columns="filledColumns1" :data-source="filledData1" :pagination="false" :scroll="{ y: tableHeight }" :row-key="(record,index)=> index">
                  <div slot="fillingType" slot-scope="text,record" @click="jumpFilling" style="cursor: pointer;">
                    <span>{{getFormName(text)}}</span>
                   </div>
                </a-table>
                <div class="pagin_box">
                  <a-pagination size="small" :total="filledTotal1" :defaultPageSize="5"  @change="(current, pageSize) =>pageChange(current, pageSize, 'filled1')" />
                </div>
              </div>
              
              <a-empty v-else class="posempty"/>
            </div>

            
          </template>
        </a-col>
      </a-row>
    </div>

  </page-header-wrapper>
</template>

<script>
import { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import { queryFillingData,queryUserFilledData} from '@/api/workspace'
import { getPcbform } from '@/api/mas/pcb.js'
import { findSendInfos } from '@/api/info/infoPublish'
import router from '@/router'
import { getFormData } from "@/api/form/allForm.js"

const fillingColumns =[
  {
    dataIndex: 'time',
    key: 'time',
    title: '日期',
  },
  {
    title: '当天填报',
    dataIndex: 'dailyCount',
    key: 'dailyCount',
    width: '60px',
    align: 'center',
  },
  {
    title: '累计填报',
    dataIndex: 'addUpCount',
    key: 'addUpCount',
    width: '60px',
    align: 'center',
  },
  {
    title: '填报占比',
    dataIndex: 'fillInTheProportion',
    key: 'fillInTheProportion',
    width: '100px',
    align: 'center',
    scopedSlots: { customRender: 'fillInTheProportion' },
  },
];
const filledColumns = [
  {
    dataIndex: 'year',
    key: 'year',
    title: '数据年份',
    width: '150px',
    align: 'center',
  },
  {
    title: '表单标题',
    dataIndex: 'title',
    key: 'title',
    width: '180px',
    align: 'center',
    scopedSlots: { customRender: 'title' },
  },
  {
    title: '填写时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: '180px',
    align: 'center',
    scopedSlots: { customRender: 'createTime' },
  },
]

const filledColumns1 = [
  {
    title: '表单标题',
    dataIndex: 'fillingType',
    key: 'fillingType',
    align: 'center',
    scopedSlots: { customRender: 'fillingType' },
  },
  {
    title: '催报时间',
    dataIndex: 'promptTime',
    key: 'promptTime',
    align: 'center',
  },
]

const areaColumns = [
  {
    dataIndex: 'area',
    key: 'area',
    title: '地区',
  },
  {
    title: '目标数',
    dataIndex: 'targetNumber',
    key: 'targetNumber',
    width: '90px',
    align: 'center',
  },
  {
    title: '上报数',
    dataIndex: 'haveReportedNumber',
    key: 'haveReportedNumber',
    width: '90px',
    align: 'center',
  },
  {
    title: '上报率',
    dataIndex: 'proportion',
    key: 'proportion',
    width: '90px',
    align: 'center',
    scopedSlots: { customRender: 'proportion' },
  },
]
const urgeColumns = [
  {
    title: '组织机构代码',
    dataIndex: 'organizationCode',
    key: 'organizationCode',
    width: '170px',
    align: 'center',
   },
  {
    title: '企业名称',
    dataIndex: 'enterpriseName',
    key: 'enterpriseName',
    align: 'center',
  },
  // {
  //   title: '状态',
  //   dataIndex: 'status',
  //   key: 'status',
  //   width: '150px',
  //   align: 'center',
  //   scopedSlots: { customRender: 'status' },
  // },
]
export default {
  name: 'Index',
  components: {
    PageHeaderWrapper,
  },
  data() {
    return {
      haveReportedProportion: '',
      failToReportedProportion:'',
      isAdmin:this.$store.getters.nickname&&this.$store.getters.nickname.indexOf('admin')>-1,
      tableHeight: 500,
      loading: false,
      areaTotal:0,
      areaData:[],
      fillingTotal:0,
      fillingData:[],
      filledTotal:0,
      filledData: [],
      filledData1:[],
      urgeTotal:0,
      urgeData: [],
      urgeDatas: [],
      notUrgeTotal: 0,
      notUrgeData: [],
      notUrgeDatas: [],
      urgeStatus:1,
      fillingColumns,
      filledColumns,
      filledColumns1,
      areaColumns,
      urgeColumns,
      fillingDatas: [],
      areaDatas: [],
      allForms:[]

    }
  },
  computed: {    
  },
  created() {
    if(this.isAdmin){
      this.queryFillingData(1);
    } else {
      this.queryFilledData(1);
      this.queryFilledData1(1);
    }
  },
  mounted() {
    this.getFormData()

  },
  methods: {
    jumpFilling() {
      router.push('/filling/annual')
    },
    getFormName(formId){
      let formFilterItems = this.allForms.filter(item=>item.id==formId);
      if(formFilterItems&&formFilterItems[0]){ 
        return formFilterItems[0].title;
      }
    },
    getFormData() {
      let object = {
        pageIndex: 1,
        pageSize: 1000
      }
      getFormData(object).then(res => {
        if (res.code == 200) {
          this.allForms = res.data.pageObject
        }
      })
    },
    //echarts
    initCharts(data) {
      let myChart = this.$echarts.init(this.$refs.chart);
      let optionDate = []
      let option = []
      for (let index = 0; index < 6; index++) {
        option.push(data[index].dailyCount)
        optionDate.push(data[index].time.split('-')[1]+'-'+data[index].time.split('-')[2])
      }
      myChart.setOption({
        xAxis: {
          data: optionDate
        },
        yAxis: {},
        series: [
          {
            type: 'bar',
            data: option
          }
        ]
      })
    },
    reportInitCharts(haveReportedProportion,failToReportedProportion) {
      let myChart = this.$echarts.init(this.$refs.reportChart);
      myChart.setOption({
        title: {
          text: '',
          left: 'center',
          top: 'center'
        },
        series: [
          {
            type: 'pie',
            label: {
              show: false
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: Number(haveReportedProportion.split(".")[0]),
                name: ''
              },
              {
                value: Number(failToReportedProportion.split(".")[0]),
                name: ''
              }
            ],
            radius: ['40%', '70%']
          }
        ]
      })
    },
    pageChange(page, pageSize, type) {
      if (type === 'filling') {
        this.fillingDatas = this.fillingData.slice((page - 1) * 5, page * 5)
      }
      if (type === 'filled') {
        this.queryFilledData(page)

      }
      if (type === 'filled1') {
        this.queryFilledData1(page)

      }
      if (type === 'area') {
        this.areaDatas = this.areaData.slice((page - 1) * 5, page * 5)
        // this.queryAreaData(page)
      }
      if (type === 'urge') {
        // this.queryUrgeData(page,pageSize)
        this.urgeDatas = this.urgeData.slice((page - 1) * 15, page * 15)
      }
      if (type === 'notUrge') {
        // this.queryUrgeData(page,pageSize)
        this.notUrgeDatas = this.notUrgeData.slice((page - 1) * 15, page * 15)
      }
    },
    // 查询每日监控数据
    queryFillingData(pageIndex = 1,pageSize=5) {
      let reqData = {
        pageIndex: pageIndex,
        pageSize: pageSize,
        dynamicFormId: '1697169233456867'
      }
      queryFillingData(reqData).then((res) => {
        if (res.code == 200) {
          this.initCharts(res.data.fillInDailyMonitoringList);
          this.fillingData = res.data.fillInDailyMonitoringList
          this.fillingTotal = this.fillingData.length
          this.pageChange(1,5,'filling')
          this.areaData = res.data.fillInTheStatistics.fillInTheStatisticsList
          this.areaTotal = this.areaData.length
          this.pageChange(1,5,'area')
          this.haveReportedProportion = res.data.fillInTheStatistics.haveReportedProportion
          this.failToReportedProportion = res.data.fillInTheStatistics.failToReportedProportion
          this.reportInitCharts(this.haveReportedProportion, this.failToReportedProportion)
          // console.log('this.fillingData',this.fillingData);
          this.urgeData = res.data.haveUrgedToReport
          this.urgeTotal = res.data.haveUrgedToReport.length
          this.pageChange(1, 15, 'urge')
          this.notUrgeData = res.data.urgedReport
          this.notUrgeTotal = res.data.urgedReport.length
          this.pageChange(1,15,'notUrge')
          
          this.loading = false
          
        }
      })
    },
    // 查询会员单位已填报数据
    queryFilledData() {
      queryUserFilledData().then((res) => {
        if (res.code == 200) {
          this.filledData = res.data.dynamicForms
          this.loading = false
          this.filledTotal = res.data.total
        }
      })
    },

    queryFilledData1(pageIndex) {
      let object={
        pageIndex: pageIndex,
        pageSize: 5,
        object: {
          status: '',
          title: '',
          content: ''
        }
      }
      if(!this.isAdmin){
        object.membershipNumber=localStorage.getItem('userName')
      }
      findSendInfos(object).then(res => {
        console.log("res",res);
        if (res.code == 200) {
          this.filledTotal1 = res.data.total;
          this.loading = false
          this.filledData1 = res.data.pageObject;
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 切换状态
    changeUrgeStatus(urgeStatus) {
      this.urgeStatus = urgeStatus.target.value;
      // console.log('this.urgeStatus',this.urgeStatus);
      // this.queryUrgeData(1,10);
    }
  },
}
</script>

<style lang="less" scoped>
@import '~ant-design-vue/es/style/themes/default.less';

.text-overflow() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

// mixins for clearfix
// ------------------------
.clearfix() {
  zoom: 1;
  &::before,
  &::after {
    display: table;
    content: ' ';
  }
  &::after {
    clear: both;
    height: 0;
    font-size: 0;
    visibility: hidden;
  }
}


.page-header-content {
  display: flex;

  .avatar {
    flex: 0 1 72px;

    & > span {
      display: block;
      width: 72px;
      height: 72px;
      border-radius: 72px;
    }
  }

  .content {
    position: relative;
    top: 4px;
    flex: 1 1 auto;
    margin-left: 24px;
    color: @text-color-secondary;
    line-height: 22px;

    .content-title {
      margin-bottom: 12px;
      color: @heading-color;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.extra-content {
  .clearfix();
  float: right;
  white-space: nowrap;

  .stat-item {
    position: relative;
    display: inline-block;
    padding: 0 32px;

    > p:first-child {
      margin-bottom: 4px;
      color: @text-color-secondary;
      font-size: @font-size-base;
      line-height: 22px;
    }

    > p {
      margin: 0;
      color: @heading-color;
      font-size: 30px;
      line-height: 38px;

      > span {
        color: @text-color-secondary;
        font-size: 20px;
      }
    }

    &::after {
      position: absolute;
      top: 8px;
      right: 0;
      width: 1px;
      height: 40px;
      background-color: @border-color-split;
      content: '';
    }

    &:last-child {
      padding-right: 0;

      &::after {
        display: none;
      }
    }
  }
}

.project-list {
  .card-title {
    font-size: 0;

    a {
      color: rgba(0, 0, 0, 0.85);
      margin-left: 12px;
      line-height: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;

      &:hover {
        color: #1890ff;
      }
    }
  }

  .card-description {
    color: rgba(0, 0, 0, 0.45);
    height: 44px;
    line-height: 22px;
    overflow: hidden;
  }

  .project-item {
    display: flex;
    margin-top: 8px;
    overflow: hidden;
    font-size: 12px;
    height: 20px;
    line-height: 20px;

    a {
      color: rgba(0, 0, 0, 0.45);
      display: inline-block;
      flex: 1 1 0;

      &:hover {
        color: #1890ff;
      }
    }

    .datetime {
      color: rgba(0, 0, 0, 0.25);
      flex: 0 0 auto;
      float: right;
    }
  }

  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    height: 44px;
    line-height: 22px;
    overflow: hidden;
  }
}

.item-group {
  padding: 20px 0 8px 24px;
  font-size: 0;

  a {
    color: #409eff;
    display: inline-block;
    font-size: 14px;
    margin-bottom: 13px;
    margin: 0 12px 12px 12px;
    // width: 25%;
  }
}

.members {
  a {
    display: block;
    margin: 12px 0;
    line-height: 24px;
    height: 24px;

    .member {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 24px;
      max-width: 100px;
      vertical-align: top;
      margin-left: 12px;
      transition: all 0.3s;
      display: inline-block;
    }

    &:hover {
      span {
        color: #1890ff;
      }
    }
  }
}

.Container_info {
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  position: relative;
  .title_box {
    background: #356de9;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    i {
      cursor: pointer;
    }
  }
  /deep/.posempty,
  .ant-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .main {
    height: 340px;
    padding: 20px 30px 0px 100px;
    overflow: auto;
    .dotcontainer {
      position: relative;
      .dian {
        width: 12px;
        height: 12px;
        background: #356de9;
        border-radius: 50%;
      }
      .date {
        color: #333333;
        position: absolute;
        left: -85px;
        top: 50%;
        white-space: nowrap;
        transform: translate(0, -50%);
        font-size: 15px;
      }
    }
    .ant-timeline-item-content .contenterre {
      display: flex;
      .t {
        flex: 1;
      }
      .time {
        white-space: nowrap;
        width: 60px;
        text-align: right;
      }
    }
  }
  .pagin_box {
    padding: 10px 0;
    text-align: right;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.pay {
  // height: calc(100vh - 200px);
  height: 100%;
  .main {
    height: auto;
    padding: 20px;
    .tblock {
      display: inline-block;
      padding: 5px;
      border-radius: 4px;
    }
    .gp {
      margin-bottom: 24px;
    }
  }
}
.message {
  .main {
    height: auto;
    padding: 20px;
    .notices-item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 30px;
      .laba {
        width: 20px;
        margin-right: 10px;
      }
      .txt {
        flex: 1;
      }
      .times {
        width: 150px;
        text-align: right;
      }
    }
  }
}

.col-flex {
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.col-flex-pay {
  height: 95vh;
}

.apply,
.message {
  height: 50%;
  .main {
    height: auto;
  }
}
.apply {
  margin-bottom: 12px;
}
.message {
  margin-top: 12px;
}
@media screen and (max-height: 770px) {
  .message {
    .main {
      height: 200px;
    }
  }
  .apply {
    .main {
      height: 200px;
    }
  }
}

/deep/ .ant-table-thead > tr > th {
  padding: 6px;
}
/deep/ .ant-table-tbody > tr > td {
  padding: 5px;
}

.send-admin{
  font-size:18px;
  margin: 5px;
  color: white;
}

.send-admin:hover{
  color: #5ee34a;
}
.send-admin:active{
  color: #288719;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container change-password" },
    [
      _c(
        "a-card",
        [
          _c("p", { staticClass: "change-password_title" }, [
            _vm._v("个人设置"),
          ]),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "a-form-model",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.user,
                        rules: _vm.rules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "头像" } },
                        [
                          _c(
                            "a-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                name: "file",
                                "list-type": "picture-card",
                                "show-upload-list": false,
                                action:
                                  _vm.baseUrl + "system-file/file/upload/image",
                                "before-upload": _vm.beforeUpload,
                                headers: _vm.headers,
                                "max-count": 1,
                              },
                              on: { change: _vm.logoUploadChange },
                            },
                            [
                              _vm.user.picture
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.baseUrl + _vm.user.picture,
                                      width: 200,
                                      alt: "LOGO",
                                    },
                                  })
                                : _c(
                                    "div",
                                    [
                                      _vm.logoLoading
                                        ? _c("loading-outlined")
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "ant-upload-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "user.apply.companyInfo.logo.btn"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "用户名", prop: "username" } },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: "请输入用户名",
                              type: "text",
                            },
                            model: {
                              value: _vm.user.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "username", $$v)
                              },
                              expression: "user.username",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "邮箱", prop: "email" } },
                        [
                          _c("a-input", {
                            attrs: { type: "email" },
                            model: {
                              value: _vm.user.mail,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "mail", $$v)
                              },
                              expression: "user.mail",
                            },
                          }),
                        ],
                        1
                      ),
                      !_vm.ishidden
                        ? _c(
                            "a-form-model-item",
                            { attrs: { label: "邮箱密码", prop: "" } },
                            [
                              _c("a-input-password", {
                                attrs: { placeholder: "请输入邮箱密码" },
                                model: {
                                  value: _vm.user.mailPassword,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.user, "mailPassword", $$v)
                                  },
                                  expression: "user.mailPassword",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-button", { on: { click: _vm.submit } }, [
                    _vm._v(" 保存 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
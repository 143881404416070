<template>
  <global-footer class="footer custom-render">
    <template v-slot:links>
      <!-- <a href="https://www.github.com/vueComponent/pro-layout" target="_blank">Pro Layout</a>
      <a href="https://www.github.com/vueComponent/ant-design-vue-pro" target="_blank">Github</a>
      <a href="https://www.github.com/sendya/" target="_blank">@Sendya</a> -->
    </template>
    <template v-slot:copyright>
      版权所有：{{$t('copyRight')}}  &nbsp;&nbsp; 联系电话：19121739850 &nbsp;&nbsp; 备案号：<a href="https://beian.miit.gov.cn/">沪ICP备05041564号</a>
      <!-- <a href="http://www.cpca.org.cn/" target="_blank">&copy; {{$t('copyRight')}}</a> -->
    </template>
  </global-footer>
</template>

<script>
import { GlobalFooter } from '@ant-design-vue/pro-layout'

export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  }
}
</script>

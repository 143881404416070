<template>
  <div class="basicCompany">
    <a-form-model :model="form" :rules="rules" ref="gdp" :colon="false">
      <a-form-model-item label="汽车电子" prop="automotiveElectronics">
        <a-input v-model="form.automotiveElectronics" placeholder="请输入汽车电子 " addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <a-form-model-item label="通讯类产品" prop="communicationProducts">
        <a-input v-model="form.communicationProducts" placeholder="请输入通讯类产品" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <a-form-model-item label="手机" prop="mobilePhone">
        <a-input v-model="form.mobilePhone" placeholder="请输入手机" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <a-form-model-item label="电脑及周边产品" prop="computerAndSurrounding">
        <a-input v-model="form.computerAndSurrounding" placeholder="请输入电脑及周边产品" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <a-form-model-item label="数据中心" prop="dataCenter">
        <a-input v-model="form.dataCenter" placeholder="请输入数据中心" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <a-form-model-item label="消费电子" prop="consumerElectronics">
        <a-input v-model="form.consumerElectronics" placeholder="请输入消费电子" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <a-form-model-item label="工控" prop="industrialControl">
        <a-input v-model="form.industrialControl" placeholder="请输入工控" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <!-- <a-form-model-item label="半导体封装" prop="semiconductorPackaging">
        <a-input v-model="form.semiconductorPackaging" placeholder="请输入半导体封装" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item> -->

      <a-form-model-item label="医疗" prop="medicalInstruments">
        <a-input v-model="form.medicalInstruments" placeholder="请输入医疗" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <a-form-model-item label="军事/航空航天" prop="militarySpace">
        <a-input v-model="form.militarySpace" placeholder="请输入军事/航空航天" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <a-form-model-item label="其他" prop="other">
        <a-input v-model="form.other" placeholder="请输入其他" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

    </a-form-model>

    <ReportsTitle title="应用领域详细说明">
      <div>
        <a-table :columns="columns" size="small" :pagination="false" :data-source="tableData">
        </a-table>
      </div>
    </ReportsTitle>

  </div>
</template>

<script>
import ReportsTitle from '@/components/MasCmponent/ReportsTitle.vue'
export default {
  name: 'PcbProportion',
  props: {
    childForm: {
      type: Object,
    },
    formMode: {
      type: String,
      default() {
        return 'edit'
      },
    },
  },
  components: { ReportsTitle },
  watch: {
    deep: true,
    childForm: {
      handler(obj) {
        this.form = { ...obj }
      },
    },
  },
  data() {
    return {
      columns: [{
        title: 'PCB应用领域',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
      },
      {
        title: '主要应用领域参考',
        dataIndex: 'introduce',
        key: 'introduce',
        width: '200',
        align: 'center',
      }],
      tableData: [
        { name: '汽车电子', introduce: '汽车电子相关应用设备' },
        { name: '通信（不含手机）', introduce: '通信基站等无线网络设备；OTN传输设备、微波传输等传输网设备；OLT、ONU、光纤到户等固网宽带等设备' },
        { name: '手机', introduce: '手机' },
        { name: '电脑及周边', introduce: '个人电脑、笔记本及周边' },
        { name: '数据中心', introduce: '路由器、交换机、服务器等' },
        { name: '消费电子', introduce: '平板电脑、可穿戴设备、游戏机和智能家居设备等' },
        { name: '工控', introduce: '工控电子设备' },
        { name: '医疗', introduce: '医疗电子设备' },
        { name: '军事/航空航天', introduce: '军事、航空、航天相关应用设备' }
      ],
      form: {
        //pcbProductAccounted
        automotiveElectronics: '',
        communicationProducts: '',
        computerPeripheralProducts: '',
        consumerProducts: '',
        industrial: '',
        semiconductorPackaging: '',
        medicalInstruments: '',
        militarySpace: '',
        other: '',
        mobilePhone: '',
        computerAndSurrounding: '',
        dataCenter: '',
        consumerElectronics: '',
        industrialControl: '',
      },
      rules: {
        automotiveElectronics: [{ required: true, message: '请输入汽车电子（%）', trigger: 'blur' }],
        communicationProducts: [{ required: true, message: '请输入通讯类产品(不含手机)（%）', trigger: 'blur' }],
        computerPeripheralProducts: [{ required: true, message: '请输入电脑及周边产品(%)', trigger: 'blur' }],
        consumerProducts: [{ required: true, message: '请输入请输入消费类产品(%)"', trigger: 'blur' }],
        industrial: [{ required: true, message: '请输入请输入工业(%)', trigger: 'blur' }],
        semiconductorPackaging: [{ required: true, message: '请输入请输入半导体封装(%)', trigger: 'blur' }],
        medicalInstruments: [{ required: true, message: '请输入请输入医疗仪器(%)', trigger: 'blur' }],
        militarySpace: [{ required: true, message: '请输入军事航天(%)', trigger: 'blur' }],
        other: [{ required: true, message: '请输入其他(%)', trigger: 'blur' }],
        mobilePhone: [{ required: true, message: '请输入手机(%)', trigger: 'blur' }],
        computerAndSurrounding: [{ required: true, message: '请输入电脑及周边产品(%)', trigger: 'blur' }],
        dataCenter: [{ required: true, message: '请输入数据中心(%)', trigger: 'blur' }],
        consumerElectronics: [{ required: true, message: '请输入消费电子(%)', trigger: 'blur' }],
        industrialControl: [{ required: true, message: '请输入工控(%)', trigger: 'blur' }],
      },
    }
  },
  created() { },
  methods: {
    submit(findstep, direction) {
      return new Promise((resolve, reject) => {
        this.$refs.gdp.validate((valid) => {
          if (valid) {
            resolve(this.form)
            // this.$emit('tonext',3)
            let cur = findstep.current
            if (direction === 'next') {
              cur++
            } else {
              cur--
              if (cur < 0) {
                cur = 0
              }
            }
            this.$emit('tonext', cur)
          } else {
            resolve(false)
          }
        })
      })
    },
    resetForm() {
      this.form = {
        automotiveElectronics: '',
        communicationProducts: '',
        computerPeripheralProducts: '',
        consumerProducts: '',
        industrial: '',
        semiconductorPackaging: '',
        medicalInstruments: '',
        militarySpace: '',
        other: '',
        mobilePhone: '',
        computerAndSurrounding: '',
        dataCenter: '',
        consumerElectronics: '',
        industrialControl: '',
      }
    },
  },
}
</script>

<style scoped lang="less">
.basicCompany {
  .ant-form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // justify-content: space-between;
    .small_title {
      width: 100%;
      padding: 20px;
      position: relative;
      font-size: 15px;
      &::before {
        content: " ";
        height: 15px;
        width: 2px;
        background: #0b8235;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .ant-form-item {
      width: 30%;
      display: flex;
      flex-wrap: wrap;
      /deep/ .ant-form-item-label {
        width: 120px;
      }
      /deep/.ant-form-item-control-wrapper {
        flex: 1;
      }
      // margin-right: 20px;
    }
    .hz {
      width: 100%;
    }
  }
}
</style>

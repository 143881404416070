<template>
  <a-drawer title="发送邮件详情" width="800" placement="right" :closable="true" :visible="viewVisible" :maskClosable="true" @close="() => { $emit('closeDetail') }">
    <a-descriptions title="" :column="1">
      <a-descriptions-item label="接收单位">
        <template v-if="mail.companyIds">
          <a-tag v-for="companyId in JSON.parse(mail.companyIds)" :key="companyId">{{findLabelByValue(companyId,companyOptions)}}</a-tag>        
        </template>
      </a-descriptions-item>
      <a-descriptions-item label="对应标签">
        <template v-if="mail.tagIds">
          <a-tag v-for="tagId in JSON.parse(mail.tagIds)" :key="tagId">{{findLabelByValue(tagId,tagOptions)}}</a-tag>        
        </template>
      </a-descriptions-item>
      <a-descriptions-item label="邮件模板" v-if="mail.templateId">{{findLabelByValue(mail.templateId, mailTemplateOptions)}}</a-descriptions-item>
      <template v-if="!mail.templateId">      
        <a-descriptions-item label="主题">{{mail.title}}</a-descriptions-item>
        <a-descriptions-item label="内容">
          <div v-html="mail.content"></div>
        </a-descriptions-item>
      </template>
      <a-descriptions-item label="附件">
        <template v-if="mail.attachments">
          <a v-for="attachment in JSON.parse(mail.attachments)" :key="attachment.url" :href="baseUrl+attachment.url">{{attachment.fileName}}</a>        
        </template>
      </a-descriptions-item>
    </a-descriptions>
  </a-drawer>
</template>

<script>

import { findLabelByValue } from '@/utils/util'

export default {
  props: {
    viewVisible: {
      type: Boolean,
      required: true
    },
    companyOptions: {
      type: Array,
      default: () => []
    },
    tagOptions: {
      type: Array,
      default: () => []
    },
    mailTemplateOptions: {
      type: Array,
      default: () => []
    },
    mail: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL
    }
  },
  created() {
  },
  methods: {
    findLabelByValue
  }
}
</script>

import axios from "axios";
import store from "@/store";
import storage from "store";
import notification from "ant-design-vue/es/notification";
import { VueAxios } from "./axios";
import { ACCESS_TOKEN } from "@/store/mutation-types";

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 600000, // 请求超时时间
});

export const mmsRequest = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_MMS_URL,
  timeout: 600000, // 请求超时时间
});

mmsRequest.interceptors.request.use((config) => {
  config.headers["projectId"] = "mms";
  return config;
}, errorHandler);


// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response;
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN);
    if (error.response.status === 403 || error.response.data.code === 403) {
      notification.error({
        message: "Forbidden",
        description: data.msg,
      });
    }
    if (error.response.status === 404||error.response.data.code === 404) {
      console.log("error",error.response.code);
    }
    if (
      error.response.status === 401 ||
      error.response.data.code === 401 ||
      error.response.code === 401
    ) {
      // notification.error({
      //   message: "未授权",
      //   description: "授权检查未通过",
      // });
      // if (token) {
        store.dispatch("Logout").then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      // }
    }
  }
  return Promise.reject(error);
};

// request interceptor
request.interceptors.request.use((config) => {
  const token = storage.get(ACCESS_TOKEN);
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers["Authorization"] = token;
  }
  config.headers["projectId"] = 'mas';
  // config.headers["Content-Type"] = "multipart/form-data";
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  if (response.headers["authorization"]) {
    storage.set(ACCESS_TOKEN,response.headers["authorization"]);
  }
  return response.data;
}, errorHandler);

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request);
    Vue.use(VueAxios, mmsRequest);
  },
};

export default request;

export { installer as VueAxios, request as axios };

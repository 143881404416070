import request from "@/utils/request";

//添加提交pcb表单
export function addPcb(data) {
  return request({
    url: `/system-business/pcbInfo/add`,
    method: "post",
    data,
  });
}

//查询pcb某个年度申报详情数据
export function PcbDetail(enterpriseId) {
  return request({
    url: `/system-business/pcbInfo/pcbInfoByEnterpriseId`,
    method: "post",
    data: {
      enterpriseId,
    },
  });
}

//修改某个pcb表单详情数据
export function pcbUpdateForm(data) {
  return request({
    url: `/system-business/pcbInfo/upd`,
    method: "post",
    data,
  });
}

//查询pcb表单数据
export function getPcbform(data) {
  return request({
    url: `/system-business/enterpriseInfo/enterpriseInfos`,
    method: "post",
    data,
  });
}

export function getDataList(data) {
  return request({
    url: `/system-business/enterpriseQuarterInfo/enterpriseQuarterInfos`,
    method: "post",
    data,
  });
}

//提交ccl表单
export function addCcl(data) {
  return request({
    url: `/system-business/cclInfo/add`,
    method: "post",
    data,
  });
}

//更新CCl表单
export function cclUpdateForm(data) {
  return request({
    url: `/system-business/cclInfo/upd`,
    method: "post",
    data,
  });
}

//查询ccl某个id
export function getCclDetail(enterpriseId) {
  return request({
    url: `/system-business/cclInfo/cclInfoByEnterpriseId`,
    method: "post",
    data: {
      enterpriseId,
    },
  });
}

//添加专用化妆品企业数据统计申报表
export function addData(data) {
  return request({
    url: `/system-business/dataStatisticsInfo/add`,
    method: "post",
    data,
  });
}

//更新专用化妆品报表数据
export function UpdDateDataForm(data) {
  return request({
    url: `/system-business/dataStatisticsInfo/upd`,
    method: "post",
    data,
  });
}

//查询专用化妆品详情报表数据
export function getDataDetail(enterpriseId) {
  return request({
    url: `/system-business/dataStatisticsInfo/dataStatisticsInfoByEnterpriseId`,
    method: "post",
    data: {
      enterpriseId,
    },
  });
}

//pcb删除接口
export const pcbdel = (enterpriseId) => {
  return request({
    url: `/system-business/pcbInfo/del`,
    method: "post",
    data: {
      enterpriseId,
    },
  });
};
//ccl删除接口
export const ccldel = (enterpriseId) => {
  return request({
    url: `/system-business/cclInfo/del`,
    method: "post",
    data: {
      enterpriseId,
    },
  });
};
//data删除接口
export const datadel = (enterpriseId) => {
  return request({
    url: `/system-business/dataStatisticsInfo/del`,
    method: "post",
    data: {
      enterpriseId,
    },
  });
};

//新增申报表单的时候加入草稿
export const saveDraft_ = (data) => {
  return request({
    url: `/system-business/draftBox/add`,
    method: "post",
    data,
  });
};

//新增状态下回显草稿数据
export const EchoData = (type) => {
  return request({
    url: `/system-business/draftBox/draftBox`,
    method: "post",
    data: {
      type,
    },
  });
};

//修改填报状态
export const updstatus_ = (id, reportStatus) => {
  return request({
    url: `/system-business/enterpriseInfo/updateReportStatus`,
    method: "post",
    data: {
      id,
      reportStatus,
    },
  });
};

//催报
export const rushTo = (reportStatus) => {
  return request({
    url: `/system-message/send/rushTo`,
    method: "post",
    data: reportStatus,
  });
};

//下载
export const download_ = (reportStatus) => {
  return request({
    url: `/system-file/file/file/download`,
    method: "post",
    data: reportStatus,
    responseType: "blob",
  });
};


//下载
export const enterpriseInfoUpdate = (reportStatus) => {
  return request({
    url: `/system-business/enterpriseInfo/update`,
    method: "post",
    data: reportStatus,
  });
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "drag-move-box",
      class: { active: _vm.record.key === _vm.selectItem.key },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.$emit("handleSelectItem", _vm.record)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "form-item-box" },
        [
          _c("kFormItem", {
            attrs: { formConfig: _vm.config, record: _vm.record },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "copy",
          class:
            _vm.record.key === _vm.selectItem.key ? "active" : "unactivated",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("handleCopy")
            },
          },
        },
        [_c("a-icon", { attrs: { type: "copy" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "delete",
          class:
            _vm.record.key === _vm.selectItem.key ? "active" : "unactivated",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("handleDelete")
            },
          },
        },
        [_c("a-icon", { attrs: { type: "delete" } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import request from "@/utils/request";

const logsApi = {
  Find: "/log/find",
  Tips: "/system-mms-user/companyInfo/remindCompaniesUpdateInformation",
};

//查询
export function findLogs(parameter) {
  return request({
    url: logsApi.Find,
    method: "post",
    data: parameter,
  });
}

//查找提醒企业修改信息

export function companyTips() {
  return request({
    url: logsApi.Tips,
    method: "get",
  });
}

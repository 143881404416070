var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pcbtable" },
    [
      _c("a-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.data,
          bordered: "",
          pagination: false,
          rowKey: (record) => record.key,
        },
        scopedSlots: _vm._u([
          {
            key: "annualOutput",
            fn: function (text, record) {
              return [
                _vm.formMode != "view"
                  ? _c("a-input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: record.annualOutput,
                        callback: function ($$v) {
                          _vm.$set(record, "annualOutput", $$v)
                        },
                        expression: "record.annualOutput",
                      },
                    })
                  : _c("div", [_vm._v(_vm._s(record.annualOutput))]),
              ]
            },
          },
          {
            key: "salesAmount",
            fn: function (text, record) {
              return [
                _vm.formMode != "view"
                  ? _c("a-input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: record.salesAmount,
                        callback: function ($$v) {
                          _vm.$set(record, "salesAmount", $$v)
                        },
                        expression: "record.salesAmount",
                      },
                    })
                  : _c("div", [_vm._v(_vm._s(record.salesAmount))]),
              ]
            },
          },
          {
            key: "capacityUtilizationRate",
            fn: function (text, record) {
              return [
                _vm.formMode != "view"
                  ? _c("a-input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: record.capacityUtilizationRate,
                        callback: function ($$v) {
                          _vm.$set(record, "capacityUtilizationRate", $$v)
                        },
                        expression: "record.capacityUtilizationRate",
                      },
                    })
                  : _c("div", [_vm._v(_vm._s(record.capacityUtilizationRate))]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["user-layout-wrapper", _vm.isMobile && "mobile"],
      attrs: { id: "userLayout" },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "user-layout-lang" },
          [_c("select-lang", { staticClass: "select-lang-trigger islogin" })],
          1
        ),
        _c(
          "div",
          { staticClass: "user-layout-content" },
          [
            _c("div", { staticClass: "top" }, [
              _vm._m(0),
              _c("div", { staticClass: "desc" }, [
                _c("a", { attrs: { href: "/" } }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("systemName"))),
                  ]),
                ]),
              ]),
            ]),
            _c("router-view"),
            _c("div", { staticClass: "footer" }),
          ],
          1
        ),
        _c("div", { staticClass: "copyright" }, [
          _vm._v(
            " 版权所有：" +
              _vm._s(_vm.$t("copyRight")) +
              " 联系电话：19121739850 备案号："
          ),
          _c("a", { attrs: { href: "https://beian.miit.gov.cn/" } }, [
            _vm._v("沪ICP备05041564号"),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/user_logo.png"), alt: "logo" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
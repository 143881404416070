// eslint-disable-next-line
import { UserLayout } from '@/layouts'
export const asyncRouterMap = [
  // {
  //   path: "*",
  //   redirect: "/404",
  //   hidden: true,
  // },
];

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: "/user",
    component: UserLayout,
    redirect: "/user/login",
    hidden: true,
    children: [
      {
        path: "login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "login" */ "@/views/user/Login"),
      },
    ],
  },
  {
    path:'/pcbreports',
    name:'pcbreports',
    component:() => import("@/views/reports/ReportsForm/index")
  },
  {
    path:'/cclreports',
    name:'cclreports',
    component:() => import("@/views/reports/cclReports/index")
  },
  {
    path:'/specialreports',
    name:'specialreports',
    component:() => import("@/views/reports/specialReports/index")
  },
  {
    path:'/reports',
    name:'reports',
    component:() => import("@/views/reports/index")
  },
  {
    path: "/404",
    component: () =>
      import(/* webpackChunkName: "fail" */ "@/views/exception/404"),
  },
  {
    path: "/recover",
    name: "recover",
    component: undefined,
  },

];

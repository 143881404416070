export default {
  systemName: "统计报表系统",
  copyRight: "中国电子电路行业协会",
  submit: "提交",
  save: "保存",
  "submit.ok": "提交成功",
  "save.ok": "保存成功",
  delete: "删除",
  "delete.popConfirm": "确定要删除吗？",
  cancel: "取消",
  edit: "编辑",
  add: "添加",
  unauthorized: "未授权",
  unauthorizedDes: "授权检查未通过",
  "message.welcome": "欢迎",
  "message.comeback": "欢迎回来",
  "duration.earlyMorning": "凌晨好",
  "duration.morning": "上午好",
  "duration.forenoon": "上午好",
  "duration.noon": "中午好",
  "duration.afternoon": "下午好",
  "duration.evening": "晚上好",
  "page.reload": "刷新页面",
  "page.reload.success": "刷新成功",
  "page.pagination.total.prefix": "共 ",
  "page.pagination.total.suffix": " 条",
  "btn.previous": "上一步",
  "btn.next": "下一步",
  "btn.draft":"存入草稿"
};

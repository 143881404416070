<template>
  <div class="basicCompany">
    <a-form-model :model="form" :rules="rules" ref="enterprise" :colon="false">
      <div class="small_title">经济指标</div>
      <a-form-model-item label="营业收入" prop="operatingIncome">
        <a-input v-model="form.operatingIncome" addon-after="万元" type="number" placeholder="请输入营业收入" :disabled="formMode == 'view' ? true:false" />

      </a-form-model-item>

      <!-- <a-form-model-item label="毛利率" prop="grossProfitMargin">
        <a-input addon-after="%" v-model="form.grossProfitMargin" placeholder="请输入" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item> -->

      <a-form-model-item label="净利润" prop="netProfit">
        <a-input addon-after="万元" v-model="form.netProfit" type="number" placeholder="请输入净利润" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <a-form-model-item label="营业总成本" prop="totalOperatingCost">
        <a-input v-model="form.totalOperatingCost" addon-after="万元" type="number" placeholder="请输入营业总成本" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <a-form-model-item label="纳税总额" prop="totalTaxPaid">
        <a-input v-model="form.totalTaxPaid" addon-after="万元" type="number" placeholder="请输入纳税总额" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <div class="small_title">总成本比例</div>
      <a-form-model-item label="人工费用" prop="artificial">
        <a-input addon-after="%" v-model="form.artificial" type="number" placeholder="请输入" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <a-form-model-item label="原材料" prop="rawMaterials">
        <a-input addon-after="%" v-model="form.rawMaterials" type="number" placeholder="请输入" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <!-- <a-form-model-item label="水电与设备折旧" prop="depreciationLoss">
        <a-input v-model="form.depreciationLoss" addon-after="%" placeholder="请输入水电与设备折旧" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item> -->

      <a-form-model-item label="制造费用" prop="manufacturingExpense">
        <a-input v-model="form.manufacturingExpense" type="number" addon-after="%" placeholder="请输入" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <a-form-model-item label="其它" prop="other">
        <a-input v-model="form.other" addon-after="%" type="number" placeholder="请输入" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <div class="small_title">出口</div>
      <a-form-model-item label="出口额" prop="exports">
        <a-input v-model="form.exports" addon-after="万元" type="number" placeholder="请输入出口额" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <a-form-model-item label="主要出口国家" prop="exportingCountry">
        <a-input v-model="form.exportingCountry" addon-after="" placeholder="请输入主要出口国家" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <template v-if="formType == '1'">
        <div class="small_title">企业库存指标</div>
        <a-form-model-item label="2022年末" prop="beginYearInventory">
          <a-input v-model="form.beginYearInventory" addon-after="万元" type="number" placeholder="请输入" :disabled="formMode == 'view' ? true:false" />
        </a-form-model-item>
        <a-form-model-item label="2022年初" prop="endYearInventory">
          <a-input v-model="form.endYearInventory" addon-after="万元" type="number" placeholder="请输入" :disabled="formMode == 'view' ? true:false" />
        </a-form-model-item>
        <div class="small_title">应收账款余额</div>
        <a-form-model-item label="2022年末" prop="beginYearAccount">
          <a-input v-model="form.beginYearAccount" addon-after="万元" type="number" placeholder="请输入" :disabled="formMode == 'view' ? true:false" />
        </a-form-model-item>
        <a-form-model-item label="2022年初" prop="endYearAccount">
          <a-input v-model="form.endYearAccount" addon-after="万元" type="number" placeholder="请输入" :disabled="formMode == 'view' ? true:false" />
        </a-form-model-item>
      </template>

      <div class="small_title">企业研发能力</div>
      <a-form-model-item label="研发投入" prop="developmentInvestment">
        <a-input v-model="form.developmentInvestment" addon-after="万元" type="number" placeholder="请输入研发投入" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <a-form-model-item label="工程技术人员" prop="technicalPersonnelTotal">
        <a-input v-model="form.technicalPersonnelTotal" placeholder="请输入" type="number" addon-after="人" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <div class="small_title">获取（发明专利）数</div>
      <a-form-model-item label="2022年" prop="patentInvention">
        <a-input v-model="form.patentInvention" placeholder="请输入" type="number" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <a-form-model-item label="2022年末累计" prop="cumulativeInvention">
        <a-input v-model="form.cumulativeInvention" placeholder="请输入" type="number" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <div class="small_title">获取（其他专利）数</div>
      <a-form-model-item label="2022年" prop="otherPatentInvention">
        <a-input v-model="form.otherPatentInvention" placeholder="请输入" type="number" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <a-form-model-item label="2022年末累计" prop="otherCumulativeInvention">
        <a-input v-model="form.otherCumulativeInvention" placeholder="请输入" type="number" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <div class="small_title" v-if="enterpriseBusinessType != 2">企业专精特新产品(只填一种)</div>
      <a-form-model-item label="细分产品名称" prop="segmentedProductName" v-if="enterpriseBusinessType != 2">
        <a-input v-model="form.segmentedProductName" addon-after="" placeholder="请输入" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <a-form-model-item label="产值" prop="outputValue">
        <a-input v-model="form.outputValue" placeholder="请输入" type="number" addon-after="万元" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <a-form-model-item label="产量" prop="speciesCapacity">
        <a-input v-model="form.speciesCapacity" addon-after="万平方米" placeholder="请输入" type="number" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <a-form-model-item label="主要应用领域" prop="applicationField">
        <a-input v-model="form.applicationField" placeholder="请输入" addon-after="" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>

      <!-- <div class="small_title">前五客户占总营收比例</div>
      <a-form-model-item label="客户A" prop="proportionRevenueA">
        <a-input v-model="form.proportionRevenueA" placeholder="请输入" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <a-form-model-item label="客户B" prop="proportionRevenueB">
        <a-input v-model="form.proportionRevenueB" placeholder="请输入" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <a-form-model-item label="客户C" prop="proportionRevenueC">
        <a-input v-model="form.proportionRevenueC" placeholder="请输入" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <a-form-model-item label="客户D" prop="proportionRevenueD">
        <a-input v-model="form.proportionRevenueD" placeholder="请输入" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <a-form-model-item label="客户E" prop="proportionRevenueE">
        <a-input v-model="form.proportionRevenueE" placeholder="请输入" addon-after="%" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <div class="small_title">占企业总营收最大的PCB种类</div>
      <a-form-model-item label="种类" prop="species">
        <a-input v-model="form.species" placeholder="请输入PCB种类" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <a-form-model-item label="营业收入" prop="speciesSales">
        <a-input v-model="form.speciesSales" placeholder="请输入" addon-after="万元" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <a-form-model-item label="产量" prop="speciesCapacity">
        <a-input v-model="form.speciesCapacity" placeholder="请输入" addon-after="万平方米" :disabled="formMode == 'view' ? true:false" />
      </a-form-model-item>
      <a-form-model-item label="主要应用领域" prop="applicationField">
        <a-input v-if="formMode != 'view'" v-model="form.applicationField" placeholder="请输入" />
        <div v-else class="formistext">{{form.species}}</div>
      </a-form-model-item> -->
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: 'enterpriseCompany',
  props: {
    childForm: {
      type: Object,
    },
    formMode: {
      type: String,
      default() {
        return 'edit'
      },
    },
    formType: {
      type: String, //pcb ccl data
      default() {
        return '1'
      },
    },
    enterpriseBusinessType: {
      type: [Number, String],
    },
  },
  components: {},
  watch: {
    deep: true,
    childForm: {
      handler(obj) {
        this.form = { ...obj }
        // console.log(obj, '接收到')
      },
    },
  },
  data() {
    return {
      form: {
        //enterpriseRevenueInfo
        /**
         * 主要应用领域
         */
        applicationField: '',
        /**
         * 人工（%）
         */
        artificial: '',
        /**
         * 2022年末（万元）
         */
        beginYearAccount: '',
        /**
         * 2022年末（万元）
         */
        beginYearInventory: '',
        /**
         * 水电与设备折旧（%）
         */
        depreciationLoss: '',
        /**
         * 研发投入（万元）
         */
        developmentInvestment: '',
        /**
         * 2022年初（万元
         */
        endYearAccount: '',
        /**
         * 2022年初（万元
         */
        endYearInventory: '',
        /**
         * 出口额（%）
         */
        exports: '',
        /**
         * 毛利率（%）
         */
        grossProfitMargin: '',
        /**
         * 净利润（%）
         */
        netProfit: '',
        /**
         * 非PCB销售比例（%）
         */
        notPcbSalesRatio: '',
        /**
         * 营业收入（销售额） （万元） (含非PCB销售收入，注明%)
         */
        operatingIncome: '',
        /**
         * 其他发明专利
         */
        otherPatentInvention: '',
        /**
         * 其他专利未累计
         */
        otherCumulativeInvention: '',
        /**
         * 发明专利
         */
        patentInvention: '',
        /**
         * 专利未累计
         */
        cumulativeInvention: '',
        /**
         * 利润率（%）
         */
        profitMargin: '',
        /**
         * 客户A(%)
         */
        proportionRevenueA: '',
        /**
         * 客户B(%)
         */
        proportionRevenueB: '',
        /**
         * 客户C(%)
         */
        proportionRevenueC: '',
        /**
         * 客户D(%)
         */
        proportionRevenueD: '',
        /**
         * 客户E(%)
         */
        proportionRevenueE: '',
        /**
         * 原材料（%）
         */
        rawMaterials: '',
        /**
         * 种类
         */
        species: '',
        /**
         * 产量（万平方米
         */
        speciesCapacity: '',
        /**
         * 营业收入（销售额） （万元）
         */
        speciesSales: '',
        /**
         * 工程技术人员（人）
         */
        technicalPersonnelTotal: '',
        /**
         * 纳税总额 （万元）
         */
        totalAmountTax: '',
        totalOperatingCost: '',
        totalTaxPaid: '',
        manufacturingExpense: '',
        other: '',
        exportingCountry: '',
        segmentedProductName: '',
        outputValue: '',
      },
      rules: {
        applicationField: [{ required: true, message: '请输入主要应用领域', trigger: 'blur' }],
        artificial: [{ required: true, message: '请输入人工费用', trigger: 'blur' }],
        beginYearAccount: [{ required: true, message: '请输入 2022年末（万元）', trigger: 'blur' }],
        beginYearInventory: [{ required: true, message: '请输入2022年末（万元）', trigger: 'blur' }],
        depreciationLoss: [{ required: true, message: '请输入水电与设备折旧（%）', trigger: 'blur' }],
        developmentInvestment: [{ required: true, message: '请输入研发投入（万元）', trigger: 'blur' }],
        endYearAccount: [{ required: true, message: '请输入2022年初（万元）', trigger: 'blur' }],
        endYearInventory: [{ required: true, message: '请输入2022年初（万元）', trigger: 'blur' }],
        exports: [{ required: true, message: '请输入出口额（%）', trigger: 'blur' }],
        grossProfitMargin: [{ required: true, message: '请输入毛利率（%）', trigger: 'blur' }],
        netProfit: [{ required: true, message: '请输入净利润（%）', trigger: 'blur' }],
        notPcbSalesRatio: [{ required: true, message: '请输入非PCB销售比例（%）', trigger: 'blur' }],
        operatingIncome: [{ required: true, message: '请输入营业收入（销售额） （万元）', trigger: 'blur' }],
        otherPatentInvention: [{ required: true, message: '请输入其他发明专利', trigger: 'blur' }],
        otherCumulativeInvention: [{ required: true, message: '请输入其他专利未累计', trigger: 'blur' }],
        patentInvention: [{ required: true, message: '请输入发明专利', trigger: 'blur' }],
        cumulativeInvention: [{ required: true, message: '请输入专利未累计', trigger: 'blur' }],
        profitMargin: [{ required: true, message: '请输入利润率（%）', trigger: 'blur' }],
        proportionRevenueA: [{ required: true, message: '请输入客户A(%)', trigger: 'blur' }],
        proportionRevenueB: [{ required: true, message: '请输入客户B(%)', trigger: 'blur' }],
        proportionRevenueC: [{ required: true, message: '请输入客户C(%)', trigger: 'blur' }],
        proportionRevenueD: [{ required: true, message: '请输入客户D(%)', trigger: 'blur' }],
        proportionRevenueE: [{ required: true, message: '请输入客户E(%)', trigger: 'blur' }],
        rawMaterials: [{ required: true, message: '请输入原材料（%）', trigger: 'blur' }],
        species: [{ required: true, message: '请输入种类', trigger: 'blur' }],
        speciesCapacity: [{ required: true, message: '请输入产量（万平方米)', trigger: 'blur' }],
        speciesSales: [{ required: true, message: '请输入营业收入（销售额） （万元）', trigger: 'blur' }],
        technicalPersonnelTotal: [{ required: true, message: '请输入工程技术人员（人）', trigger: 'blur' }],
        totalAmountTax: [{ required: true, message: '请输入纳税总额 （万元）', trigger: 'blur' }],
        totalOperatingCost: [{ required: true, message: '请输入营业总成本 （万元）', trigger: 'blur' }],
        totalTaxPaid: [{ required: true, message: '请输入纳税总额 （万元）', trigger: 'blur' }],
        manufacturingExpense: [{ required: true, message: '请输入制造费用（%）', trigger: 'blur' }],
        other: [{ required: true, message: '请输入其它（%）', trigger: 'blur' }],
        exportingCountry: [{ required: true, message: '请输入主要出口国家', trigger: 'blur' }],
        segmentedProductName: [{ required: true, message: '请输入细分产品名称', trigger: 'blur' }],
        outputValue: [{ required: true, message: '请输入产值 （万元）', trigger: 'blur' }],
      },
    }
  },
  created() { },
  methods: {
    submit(findstep, direction) {
      return new Promise((resolve, reject) => {
        this.$refs.enterprise.validate((valid) => {
          if (valid) {
            resolve(this.form)
            // this.$emit('tonext',2)
            let cur = findstep.current
            if (direction === 'next') {
              cur++
            } else {
              cur--
              if (cur < 0) {
                cur = 0
              }
            }
            this.$emit('tonext', cur)
          } else {
            resolve(false)
          }
        })
      })
    },
    resetForm() {
      this.form = {
        /**
         * 主要应用领域
         */
        applicationField: '医疗',
        /**
         * 人工（%）
         */
        artificial: '',
        /**
         * 2022年末（万元）
         */
        beginYearAccount: '',
        /**
         * 2022年末（万元）
         */
        beginYearInventory: '',
        /**
         * 水电与设备折旧（%）
         */
        depreciationLoss: '',
        /**
         * 研发投入（万元）
         */
        developmentInvestment: '',
        /**
         * 2022年初（万元
         */
        endYearAccount: '',
        /**
         * 2022年初（万元
         */
        endYearInventory: '',
        /**
         * 出口额（%）
         */
        exports: '',
        /**
         * 毛利率（%）
         */
        grossProfitMargin: '',
        /**
         * 净利润（%）
         */
        netProfit: '',
        /**
         * 非PCB销售比例（%）
         */
        notPcbSalesRatio: '',
        /**
         * 营业收入（销售额） （万元） (含非PCB销售收入，注明%)
         */
        operatingIncome: '',
        /**
         * 其他发明专利
         */
        otherPatentInvention: '',
        /**
         * 其他专利未累计
         */
        otherCumulativeInvention: '',
        /**
         * 发明专利
         */
        patentInvention: '',
        /**
         * 专利未累计
         */
        cumulativeInvention: '',
        /**
         * 利润率（%）
         */
        profitMargin: '',
        /**
         * 客户A(%)
         */
        proportionRevenueA: '',
        /**
         * 客户B(%)
         */
        proportionRevenueB: '',
        /**
         * 客户C(%)
         */
        proportionRevenueC: '',
        /**
         * 客户D(%)
         */
        proportionRevenueD: '',
        /**
         * 客户E(%)
         */
        proportionRevenueE: '',
        /**
         * 原材料（%）
         */
        rawMaterials: '',
        /**
         * 种类
         */
        species: '',
        /**
         * 产量（万平方米
         */
        speciesCapacity: '',
        /**
         * 营业收入（销售额） （万元）
         */
        speciesSales: '',
        /**
         * 工程技术人员（人）
         */
        technicalPersonnelTotal: '',
        /**
         * 纳税总额 （万元）
         */
        totalAmountTax: '',
        totalOperatingCost: '',
        totalTaxPaid: '',
        manufacturingExpense: '',
        other: '',
        exportingCountry: '',
        segmentedProductName: '',
        outputValue: '',
      }
    },
  },
}
</script>

<style scoped lang="less">
.basicCompany {
  .ant-form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .small_title {
      width: 100%;
      padding: 20px;
      position: relative;
      font-size: 15px;
      &::before {
        content: " ";
        height: 15px;
        width: 2px;
        background: #0b8235;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .ant-form-item {
      width: 30%;
      display: flex;
      flex-wrap: wrap;
      /deep/ .ant-form-item-label {
        width: 120px;
      }
      /deep/.ant-form-item-control-wrapper {
        flex: 1;
      }
      // margin-right: 20px;
    }
    .hz {
      width: 100%;
    }
  }
}
.formistext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

import request from '@/utils/request';

const userApi = {
  GetFormData: '/system-business/dynamicForm/dynamicForms',
  DelForm: '/system-business/dynamicForm/del',
  // ForgePassword: "/auth/forge-password",
  // Apply: "/companyInfo/add",
  // get my info
  UserInfo: '/system-mms-user/user/info',
  UserMenu: '/system-mms-user/user/menus',
  SubmitForm: '/system-business/enterpriseReport/submit',
  FindComponentUsage: '/system-business/dynamicForm/findComponentUsage',
};

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function getFormData(parameter) {
  return request({
    url: userApi.GetFormData,
    method: 'post',
    data: parameter,
  });
}

export function findComponentUsage(parameter) {
  return request({
    url: userApi.FindComponentUsage,
    method: 'post',
    data: parameter,
  });
}
/**
 * get user info
 */
export function delForm(parameter) {
  return request({
    url: userApi.DelForm,
    method: 'delete',
    params: parameter,
  });
}

export function toSubmitForm(parameter) {
  return request({
    url: userApi.SubmitForm,
    method: 'post',
    data: parameter,
  });
}

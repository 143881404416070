<template>
  <div class="main">
    <a-form id="formLogin" class="user-layout-login" ref="formLogin" :form="form" @submit="handleSubmit">
      <a-alert v-if="isLoginError" type="error" showIcon style="margin-bottom: 24px;" :message="$t('user.login.message-invalid-credentials')" />
      <a-form-item>
        <a-input size="large" type="text" :placeholder="$t('user.login.username.placeholder')" v-decorator="['username',
                {rules: [{ required: true, message: $t('user.userName.required') }]}]">
          <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
        </a-input>
      </a-form-item>

      <a-form-item style="padding-bottom: 0;">
        <a-input-password size="large" @keyup.native="onkeyup" @keydown.native="onkeydown" @blur="bigChar=false" :placeholder="$t('user.login.password.placeholder')" v-decorator="['password',{rules: [{ required: true, message: $t('user.password.required') }], validateTrigger: 'blur'}]">
          <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
        </a-input-password>
        <span v-show="bigChar" style="font-size: 12px;color:#5C8D66;margin:0 0 0 10px;padding: 0;">{{ $t('user.login.prompt.CapsLock') }}</span>
      </a-form-item>
      <a-form-item>
        <a-checkbox v-decorator="['rememberMe', { valuePropName: 'checked' }]">{{ $t('user.login.remember-me') }}</a-checkbox>
        <!-- <a href="http://login.cpca.org.cn/user/login?redirect=recover" class="forge-password isa" style="float: right;">{{ $t('user.login.forgot-password') }}</a> -->
      </a-form-item>

      <a-form-item style="margin-top:24px">
        <a-button size="large" type="primary" htmlType="submit" class="login-button" :loading="state.loginBtn" :disabled="state.loginBtn">{{ $t('user.login.login') }}
        </a-button>
      </a-form-item>
    </a-form>
    <!-- <div class="user-login-other">
      <router-link class="register" :to="{ name: 'apply' }">{{ $t('user.login.signup') }}</router-link>
    </div> -->
    <div class="user-login-tips" style="margin-top:20px; font-size: 13px;">
      {{ $t('user.login.prompt') }}
      </br>
      {{ $t('user.login.prompt.begin') }}
      <a href="https://login.cpca.org.cn" style="text-decoration:underline;" class="isa">CPCA {{ $t('user.login.prompt.among') }}</a> {{ $t('user.login.prompt.end') }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
function getQueryString(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
      return decodeURIComponent(r[2]);
  };
  return null;
}
export default {
  data() {
    return {
      bigChar: false,
      shifKey: undefined,
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      }
    }
  },
  created() {
    let mmsToken = getQueryString("mmsToken")
    let masToken = getQueryString("masToken")
    if(masToken){
      this.CrossLogin({"masToken":masToken,"mmsToken":mmsToken}).then((res)=>this.loginSuccess(""))
    }
  },
  methods: {
    ...mapActions(['Login','Logout','CrossLogin']),
     handleSubmit(e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        Login
      }=this

      state.loginBtn=true

      const validateFieldsKey=['username','password'];

      validateFields(validateFieldsKey,{ force: true },(err,values) => {
        if(!err) {
          const loginParams={ ...values }
          delete loginParams.username;
          loginParams['userName']=values.username;
          loginParams.password=values.password;
          Login(loginParams)
            .then((res) => this.loginSuccess(res))
            .catch(err => this.requestFailed(err))
            .finally(() => {
              state.loginBtn=false
            });
        } else {
          setTimeout(() => {
            state.loginBtn=false;
          },600);
        }
      })
    },
    // 登录成功
    loginSuccess(res) {
      // 跳转到首页【注意不同角色进入不同页面】
      this.$router.push({ path: '/index' })
      // 延迟 0.5秒显示欢迎信息
      setTimeout(() => {
        this.$notification.success({
          message: this.$t('message.welcome'),
          description: this.$t(timeFix())+','+this.$t('message.comeback')
        })
      },500)
      this.isLoginError=false
    },
    // 登录失败
    requestFailed(err) {
      this.isLoginError=true;
      // this.$notification['error']({
      //   message: '错误',
      //   description: err.msg||'请求出现错误，请稍后再试',
      //   duration: 4
      // })
    },
    //判断键盘的大写状态是否已打开
    onkeyup (event) {
      const _that = this
      // 判断是否按键为caps Lock
      if (event.keyCode === 20) {
        _that.bigChar = !_that.bigChar
        return
      }
      // 按键不是caps Lock，判断每次最后输入的字母的大小写
      let e = event || window.event  
      let keyvalue = e.keyCode ? e.keyCode : e.which
      let shifKey = this.shifKey
      if (typeof (_that.userPassword) === 'undefined') return
      let userPassword = _that.userPassword
      let strlen = userPassword.length

      if (strlen) {
        let uniCode = userPassword.charCodeAt(strlen - 1)
        // 65到90字母键
        if (keyvalue >= 65 && keyvalue <= 90) {
          // 是否同时按住shift键
          if (((uniCode >= 65 && uniCode <= 90) && !shifKey) || ((uniCode >= 97 && uniCode <= 122) && shifKey)) {
            _that.bigChar = true
          } else {
            _that.bigChar = false
          }
        }
      }
    },
    onkeydown (event) {
      let e = event || window.event
      let keyvalue = e.keyCode ? e.keyCode : e.which
      let shifKey = e.shiftKey ? e.shiftKey : ((keyvalue === 16))
      this.shifKey = shifKey
    }
  }
}
</script>

<style lang="less" scoped>
.main{
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-radius: 10px;
  background: #fff;
}
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    margin-top: 24px;
    line-height: 22px;
    .register {
      float: right;
    }
  }

  .user-login-tips {
    margin-top: 24px !important;
    line-height: 22px;
  }
}
.isa:hover{
	color: rgb(31, 248, 248);
}
</style>

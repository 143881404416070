var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { width: _vm.record.options.width } },
    [
      !_vm.record.options.drag
        ? _c(
            "Upload",
            {
              attrs: {
                disabled: _vm.record.options.disabled || _vm.parentDisabled,
                name: _vm.config.uploadFileName || _vm.record.options.fileName,
                headers:
                  _vm.config.uploadFileHeaders || _vm.record.options.headers,
                data: _vm.config.uploadFileData || _vm.optionsData,
                action: _vm.config.uploadFile || _vm.record.options.action,
                multiple: _vm.record.options.multiple,
                fileList: _vm.fileList,
                remove: _vm.remove,
                beforeUpload: _vm.beforeUpload,
              },
              on: { preview: _vm.handlePreview, change: _vm.handleChange },
            },
            [
              _vm.fileList.length < _vm.record.options.limit
                ? _c(
                    "Button",
                    {
                      attrs: {
                        disabled:
                          _vm.record.options.disabled || _vm.parentDisabled,
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "upload" } }),
                      _vm._v(
                        " " + _vm._s(_vm.record.options.placeholder) + " "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "UploadDragger",
            {
              class: {
                "hide-upload-drag": !(
                  _vm.fileList.length < _vm.record.options.limit
                ),
              },
              attrs: {
                disabled: _vm.record.options.disabled || _vm.parentDisabled,
                name: _vm.config.uploadFileName || _vm.record.options.fileName,
                headers:
                  _vm.config.uploadFileHeaders || _vm.record.options.headers,
                data: _vm.config.uploadFileData || _vm.optionsData,
                action: _vm.config.uploadFile || _vm.record.options.action,
                multiple: _vm.record.options.multiple,
                fileList: _vm.fileList,
                remove: _vm.remove,
                beforeUpload: _vm.beforeUpload,
              },
              on: { preview: _vm.handlePreview, change: _vm.handleChange },
            },
            [
              _c(
                "p",
                { staticClass: "ant-upload-drag-icon" },
                [_c("a-icon", { attrs: { type: "cloud-upload" } })],
                1
              ),
              _c("p", { staticClass: "ant-upload-text" }, [
                _vm._v("单击或拖动文件到此区域"),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import request from '@/utils/request';

const quarterApi = {
  AddDraft: '/system-business/draftBox/add',
  FindDraft: '/system-business/draftBox/find',
  FindCompanyFill: '/system-business/enterpriseReport/find',
  Update: '/system-business/enterpriseReport/upd',
  FindDynamicAndId: '/system-business/dynamicForm/findDynamicAndId',
};

export function addDraft(parameter) {
  return request({
    url: quarterApi.AddDraft,
    method: 'post',
    data: parameter,
  });
}

export function findDraft(parameter) {
  return request({
    url: quarterApi.FindDraft,
    method: 'post',
    data: parameter,
  });
}

export function findCompanyFill(parameter) {
  return request({
    url: quarterApi.FindCompanyFill,
    method: 'post',
    data: parameter,
  });
}

export function update(parameter) {
  return request({
    url: quarterApi.Update,
    method: 'post',
    data: parameter,
  });
}

export function findDynamicAndId() {
  return request({
    url: quarterApi.FindDynamicAndId,
    method: 'get',
  });
}

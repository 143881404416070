var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Container" },
    [
      _c("span", { staticStyle: { "margin-right": "20px" } }, [
        _vm._v("主营业务类型"),
      ]),
      _c(
        "a-radio-group",
        {
          model: {
            value: _vm.typebusiness,
            callback: function ($$v) {
              _vm.typebusiness = $$v
            },
            expression: "typebusiness",
          },
        },
        _vm._l(_vm.business, function (item) {
          return _c(
            "a-radio",
            { key: item.value, attrs: { value: item.value } },
            [_vm._v(_vm._s(item.label) + " ")]
          )
        }),
        1
      ),
      _c("pcbForm"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
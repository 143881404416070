import storage from "store";
import { mmsLogin, login, getInfo, mmsLogout, logout } from "@/api/login";
import { ACCESS_TOKEN, MMS_ACCESS_TOKEN } from "@/store/mutation-types";
import { welcome } from "@/utils/util";
import {companyTips} from "@/api/system/logs"
const user = {
  state: {
    token: "",
    mmsToken: "",
    name: "",
    welcome: "",
    avatar: "",
    roles: [],
    info: {},
    baseImgurl: process.env.VUE_APP_API_BASE_URL,
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_MMSTOKEN: (state, mmsToken) => {
      state.mmsToken = mmsToken;
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name;
      state.welcome = welcome;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            if (response.code == 200) {
              const result = response.data;
              storage.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000);
              commit("SET_TOKEN", result.token);
              userInfo["username"] = userInfo["userName"];
              mmsLogin(userInfo).then((mmsResponse) => {
                if (
                  mmsResponse.data &&
                  mmsResponse.data.status == 0 &&
                  mmsResponse.data.code == 200
                ) {
                  const mmsResult = mmsResponse.data;
                  storage.set(
                    MMS_ACCESS_TOKEN,
                    mmsResult.data.jwt,
                    7 * 24 * 60 * 60 * 1000
                  );
                  commit("SET_MMSTOKEN", mmsResult.data.jwt);
                  resolve();
                } else {
                  reject(mmsResponse);
                }
              });
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    CrossLogin({ commit }, tokenInfo) {
      return new Promise((resolve, reject) => {
        console.log("tokenInfo", tokenInfo);
        storage.set(
          MMS_ACCESS_TOKEN,
          tokenInfo.mmsToken,
          7 * 24 * 60 * 60 * 1000
        );
        commit("SET_MMSTOKEN", tokenInfo.mmsToken);
        storage.set(ACCESS_TOKEN, tokenInfo.masToken, 7 * 24 * 60 * 60 * 1000);
        commit("SET_TOKEN", tokenInfo.masToken);
        resolve();
      });
    },
    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((response) => {
            if (response.code == 401) {
              reject(response);
            }
            const result = response.data;
            if (
              result &&
              result.sysRoleVOS[0] &&
              result.sysRoleVOS[0].menus.length > 0
            ) {
              const role = result.sysRoleVOS[0];
              role.permissions = result.sysRoleVOS[0].menus;
              role.permissions.map((per) => {
                if (
                  per.actionEntitySet != null &&
                  per.actionEntitySet.length > 0
                ) {
                  const action = per.actionEntitySet.map((action) => {
                    return action.action;
                  });
                  per.actionList = action;
                }
              });
              role.permissionList = role.permissions.map((permission) => {
                return permission.id;
              });
              commit("SET_ROLES", role);
              commit("SET_INFO", result);
            } else {
              reject(new Error("getInfo: roles must be a non-null array !"));
            }

            commit("SET_NAME", { name: result.name, welcome: welcome() });
            commit("SET_AVATAR", result.avatar);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token)
          .then(() => {
            commit("SET_TOKEN", "");
            commit("SET_ROLES", []);
            storage.remove(ACCESS_TOKEN);
            mmsLogout(state.mmsToken)
              .then(() => {
                commit("SET_MMSTOKEN", "");
                storage.remove(MMS_ACCESS_TOKEN);
                resolve();
              })
              .catch((mmsErr) => {
                reject(mmsErr);
                console.log("logout fail:", mmsErr);
              });
          })
          .catch((err) => {
            console.log("logout fail:", err);
            reject(err);
          });
      });
    },

    //查询信息
    openCompanyTips({ commit }) {
      return new Promise((resolve, reject) => {
        companyTips()
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default user;

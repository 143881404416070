<template>
  <div class="pcbContainer">
    <!-- <ReportsTitle title="企业基本信息" v-show="current == 0 || formMode == 'view'">
      <basicCompany ref="basic" :childForm="annualform.enterpriseInfo" :formMode="formMode" :formType="enterpriseBusinessType" @tonext="tonext"></basicCompany>
    </ReportsTitle> -->
    <ReportsTitle title="企业经营状况" v-show="current == 0 || formMode == 'view'">
      <enterpriseCompany ref="enterprise" :childForm="annualform.enterpriseRevenueInfo" :formMode="formMode" :formType="enterpriseBusinessType" @tonext="tonext"></enterpriseCompany>
    </ReportsTitle>
    <ReportsTitle title="集团布局" v-show="current == 1 || formMode == 'view'">
      <pcbtable class="pcbtt" ref="pcbtable" :childForm="annualform.enterpriseLayoutInfos" :formMode="formMode" @tonext="tonext"></pcbtable>
    </ReportsTitle>
  </div>
</template>

<script>
import ReportsTitle from '@/components/MasCmponent/ReportsTitle.vue'
import basicCompany from '@/components/MasCmponent/ReportsItem/PcbbasicCompany.vue'
import enterpriseCompany from '@/components/MasCmponent/ReportsItem/PcbenterpriseCompany.vue'
import pcbtable from '@/components/MasCmponent/ReportsItem/PcbTable.vue'
export default {
  name: 'pcbReports',
  props: {
    annualform: {
      type: Object,
    },
    formMode: {
      type: String,
    },
    current: {
      type: [Number, String],
    },
    enterpriseBusinessType: {
      type: [Number, String],
    },
  },
  components: {
    ReportsTitle,
    basicCompany,
    enterpriseCompany,
    pcbtable,
  },
  data() {
    return {}
  },
  watch: {
    formMode: {
      deep: true,
      handler(mode) {
        console.log(mode, 'form表单模式-------------')
      },
    },
    annualform: {
      deep: true,
      handler(newData, oldData) {
        this.annualform.enterpriseInfo = newData.enterpriseInfo
      },
    },
  },
  created() {},
  methods: {
    getFormData() {
      // const _basicCompany = this.$refs.basic
      const _enterprise = this.$refs.enterprise
      const _pcbtable = this.$refs.pcbtable //集团布局
      console.log("this.annualform.enterpriseInfo",this.annualform.enterpriseInfo);
      Promise.all([ _enterprise.submit()]).then((res) => {
        if (res.includes(false)) {
          this.$message.error('表单有未填写的字段请填写')
          return false
        }
        let data = {
          enterpriseInfo: this.annualform.enterpriseInfo,
          enterpriseRevenueInfo: res[0],
          enterpriseLayoutInfos: _pcbtable.data.map((item) => {
            return {
              productPositioning: item.productPositioning,
              factoryState: item.factoryState,
              factoryName: item.factoryName,
              locationMunicipalLevel: item.locationMunicipalLevel,
              totalAmountInvestment: item.totalAmountInvestment,
              capacity: item.capacity,
              id: item.id ? item.id : '',
              enterpriseId: item.enterpriseId ? item.enterpriseId : '',
              outputValue: item.outputValue ? item.outputValue : '',
            }
          }),
        }
        // data.enterpriseInfo.type = this.enterpriseBusinessType
        this.$emit('childsubmit', data, this.enterpriseBusinessType)
      })

    },
    tonext(cur) {
      this.$emit('tonext', cur)
    },
    saveDraft() {
      // const _basicCompany = this.$refs.basic
      const _basicCompany = {}
      const _enterprise = this.$refs.enterprise
      const _pcbtable = this.$refs.pcbtable //集团布局
      let data = {
        enterpriseInfo: _basicCompany,
        enterpriseRevenueInfo: _enterprise.form,
        enterpriseLayoutInfos: _pcbtable.data.map((item) => {
          return {
            factoryState: item.factoryState,
            factoryName: item.factoryName,
            locationMunicipalLevel: item.locationMunicipalLevel,
            totalAmountInvestment: item.totalAmountInvestment,
            capacity: item.capacity,
            id: item.id ? item.id : '',
            enterpriseId: item.enterpriseId ? item.enterpriseId : '',
          }
        }),
      }
      data.enterpriseInfo.type = this.enterpriseBusinessType
      this.$emit('savell', this.enterpriseBusinessType, data)
    },
  },
}
</script>

<style scoped lang="less">
.pcbContainer {
  padding: 0 0;
  h2 {
    text-align: center;
    margin: 20px auto;
    font-size: 30px;
  }
  .bigtitle {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin: 20px auto;
  }

  .flextd {
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
    tr,
    td {
      border: none;
    }
    .item {
      &:first-child {
        width: 50%;
        height: 80px;
        line-height: 80px;
        font-size: 18px;
        text-align: center;
        border-right: 1px solid #000;
      }
      &:last-child {
        display: flex;
        flex-direction: column;
        width: 50%;
        td {
          padding: 0;
          text-align: center;
          border-bottom: 1px solid #000;
          height: 20px;
          line-height: 20px;
        }
      }
    }
  }
}
.pcbtt {
  margin: 10px auto;
}
</style>

<template>
  <page-header-wrapper>
    <FillingComponent :type="type" :headerFormList="headerFormList"></FillingComponent>
  </page-header-wrapper>
</template>

<script>
import FillingComponent from '@/views/filling/components/Filling.vue'
import { annualOptionsNew } from "@/utils/util"
import { typeBussine, findDynamicAndIds } from "./Business.js"
export default {
  name: "MonthlyFillingList",
  components: {
    FillingComponent,
  },
  data(){
    return {
      type: "3",
      headerFormList: [
        {
          name: 'year',
          type: 'select',
          value: 2024,
          label: '年度',
          allowClear: false,
          placeholder: "请选择",
          option: annualOptionsNew
        },
        {
          name: 'dynamicFormTitle',
          type: 'input',
          value: '',
          label: '表单标题',
          placeholder: "请输入"
        },
        {
          name: 'enterpriseName',
          type: 'input',
          value: '',
          label: '单位名称',
          placeholder: "请输入"
        },
        {
          name: 'fillingType',
          type: 'select',
          allowClear: true,
          value: undefined,
          label: '填报类型',
          placeholder: "请选择",
          option: typeBussine,
        },
        {
          name: 'dynamicFormId',
          type: 'select',
          allowClear: true,
          value: undefined,
          label: '选择表单',
          placeholder: "请选择",
          option: [],
        },
        {
          name: 'reportStatus',
          type: 'select',
          allowClear: true,
          value: undefined,
          label: '填报状态',
          placeholder: "请选择",
          option: [
            {
              label: '全部',
              value: '',
            },
            {
              label: '未填',
              value: '1',
            },
            {
              label: '已填',
              value: '2',
            },
          ],
        },
      ]
    }
  },
  created() {
    this.findDynamicAndId()
  },
  methods:{
    async findDynamicAndId() {
      let res = await findDynamicAndIds()
      this.headerFormList[4].option = res
    }
  }

}
</script>

<style scoped>
.noborder {
    border: none;
    border-radius: 10px;
  }
</style>
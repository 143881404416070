var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "basicCompany" },
    [
      _c(
        "a-modal",
        {
          attrs: {
            width: "75%",
            title: "请填写企业基本信息",
            maskClosable: false,
            visible: _vm.basicCompanyVisible,
          },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "basicCompany",
              attrs: {
                layout: "inline",
                model: _vm.form,
                rules: _vm.rules,
                colon: false,
                labelAlign: "right",
                labelCol: { style: "width: 130px" },
              },
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: "企业名称", prop: "enterpriseName" },
                        },
                        [
                          _vm.formMode != "view"
                            ? _c("a-input", {
                                staticClass: "formisedit",
                                attrs: { placeholder: "请输入企业名称" },
                                model: {
                                  value: _vm.form.enterpriseName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "enterpriseName", $$v)
                                  },
                                  expression: "form.enterpriseName",
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "formistext",
                                  attrs: { title: _vm.form.enterpriseName },
                                },
                                [_vm._v(_vm._s(_vm.form.enterpriseName))]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "组织机构代码",
                            prop: "organizationCode ",
                          },
                        },
                        [
                          _vm.formMode != "view"
                            ? _c("a-input", {
                                staticClass: "formisedit",
                                attrs: { placeholder: "请输入企业人数" },
                                model: {
                                  value: _vm.form.organizationCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "organizationCode", $$v)
                                  },
                                  expression: "form.organizationCode",
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "formistext",
                                  attrs: { title: _vm.form.organizationCode },
                                },
                                [_vm._v(_vm._s(_vm.form.organizationCode))]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "企业人数(年末)",
                            prop: "numberEmployees",
                          },
                        },
                        [
                          _vm.formMode != "view"
                            ? _c("a-input", {
                                staticClass: "formisedit",
                                attrs: { placeholder: "请输入企业人数" },
                                model: {
                                  value: _vm.form.numberEmployees,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "numberEmployees", $$v)
                                  },
                                  expression: "form.numberEmployees",
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "formistext",
                                  attrs: { title: _vm.form.numberEmployees },
                                },
                                [_vm._v(_vm._s(_vm.form.numberEmployees))]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "通信地址",
                            prop: "communicationAddress",
                          },
                        },
                        [
                          _vm.formMode != "view"
                            ? _c("a-textarea", {
                                staticClass: "formisedit",
                                attrs: { placeholder: "请输入通信地址" },
                                model: {
                                  value: _vm.form.communicationAddress,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "communicationAddress",
                                      $$v
                                    )
                                  },
                                  expression: "form.communicationAddress",
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "formistext",
                                  attrs: {
                                    title: _vm.form.communicationAddress,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.form.communicationAddress))]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "企业联系人姓名",
                            prop: "contactName",
                          },
                        },
                        [
                          _vm.formMode != "view"
                            ? _c("a-input", {
                                staticClass: "formisedit",
                                attrs: { placeholder: "请输入企业联系人姓名" },
                                model: {
                                  value: _vm.form.contactName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contactName", $$v)
                                  },
                                  expression: "form.contactName",
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "formistext",
                                  attrs: { title: _vm.form.contactName },
                                },
                                [_vm._v(_vm._s(_vm.form.contactName))]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "联系人电话",
                            prop: "contactTelephone",
                          },
                        },
                        [
                          _vm.formMode != "view"
                            ? _c("a-input", {
                                staticClass: "formisedit",
                                attrs: { placeholder: "请输入电话" },
                                model: {
                                  value: _vm.form.contactTelephone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contactTelephone", $$v)
                                  },
                                  expression: "form.contactTelephone",
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "formistext",
                                  attrs: { title: _vm.form.contactTelephone },
                                },
                                [_vm._v(_vm._s(_vm.form.contactTelephone))]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: "联系人手机", prop: "contactPhone" },
                        },
                        [
                          _vm.formMode != "view"
                            ? _c("a-input", {
                                staticClass: "formisedit",
                                attrs: { placeholder: "请输入手机" },
                                model: {
                                  value: _vm.form.contactPhone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contactPhone", $$v)
                                  },
                                  expression: "form.contactPhone",
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "formistext",
                                  attrs: { title: _vm.form.contactPhone },
                                },
                                [_vm._v(_vm._s(_vm.form.contactPhone))]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "联系人邮箱", prop: "contactMail" } },
                        [
                          _vm.formMode != "view"
                            ? _c("a-input", {
                                staticClass: "formisedit",
                                attrs: { placeholder: "请输入邮箱" },
                                model: {
                                  value: _vm.form.contactMail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contactMail", $$v)
                                  },
                                  expression: "form.contactMail",
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "formistext",
                                  attrs: { title: _vm.form.contactMail },
                                },
                                [_vm._v(_vm._s(_vm.form.contactMail))]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.formType === "2"
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "企业网址",
                                prop: "corporateWebSite",
                              },
                            },
                            [
                              _vm.formMode != "view"
                                ? _c("a-input", {
                                    staticClass: "formisedit",
                                    attrs: { placeholder: "请输入企业网址" },
                                    model: {
                                      value: _vm.form.corporateWebSite,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "corporateWebSite",
                                          $$v
                                        )
                                      },
                                      expression: "form.corporateWebSite",
                                    },
                                  })
                                : _c(
                                    "div",
                                    {
                                      staticClass: "formistext",
                                      attrs: {
                                        title: _vm.form.corporateWebSite,
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.form.corporateWebSite))]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.formType === "2"
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "企业传真",
                                prop: "enterpriseFax",
                              },
                            },
                            [
                              _vm.formMode != "view"
                                ? _c("a-input", {
                                    staticClass: "formisedit",
                                    attrs: { placeholder: "请输入企业传真" },
                                    model: {
                                      value: _vm.form.enterpriseFax,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "enterpriseFax", $$v)
                                      },
                                      expression: "form.enterpriseFax",
                                    },
                                  })
                                : _c(
                                    "div",
                                    {
                                      staticClass: "formistext",
                                      attrs: { title: _vm.form.enterpriseFax },
                                    },
                                    [_vm._v(_vm._s(_vm.form.enterpriseFax))]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "企业登记类型",
                            prop: "enterpriseRegistrationType",
                          },
                        },
                        [
                          _vm.formMode != "view"
                            ? _c(
                                "a-select",
                                {
                                  staticClass: "formisedit",
                                  attrs: { placeholder: "请选择企业登记类型" },
                                  model: {
                                    value: _vm.form.enterpriseRegistrationType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "enterpriseRegistrationType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.enterpriseRegistrationType",
                                  },
                                },
                                _vm._l(_vm.seloption, function (item) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: item.value,
                                      attrs: { value: item.value },
                                    },
                                    [_vm._v(" " + _vm._s(item.label) + " ")]
                                  )
                                }),
                                1
                              )
                            : _c("div", { staticClass: "formistext" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.handlertxt(
                                      _vm.form.enterpriseRegistrationType
                                    )
                                  )
                                ),
                              ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
                _vm._v(" 取消 "),
              ]),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary" },
                  on: { click: _vm.submit },
                },
                [_vm._v(" 保存 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "15px" } },
            [
              _c(
                "a-form-model",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    layout: "inline",
                  },
                  on: { submit: _vm.handleSubmit },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "年度", prop: "year" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100px" },
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            "allow-clear": "",
                          },
                          model: {
                            value: _vm.form.year,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "year", $$v)
                            },
                            expression: "form.year",
                          },
                        },
                        _vm._l(_vm.annualOptionsNew, function (item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "类型", prop: "type" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "80px" },
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            "allow-clear": "",
                          },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.quarterOptions, function (item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "标题", prop: "title" } },
                    [
                      _c("a-input", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          disabled: _vm.disabled,
                          placeholder: "请输入标题",
                        },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "描述", prop: "description" } },
                    [
                      _c("a-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          disabled: _vm.disabled,
                          placeholder: "请输入描述",
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { staticClass: "btn-box" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { disabled: _vm.disabled },
                          on: { click: _vm.resetform },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            disabled: _vm.saveFormDisabled,
                            type: "primary",
                          },
                          on: { click: _vm.saveForm },
                        },
                        [_vm._v(" 保存 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "a-radio-group",
                {
                  staticStyle: { width: "100%" },
                  attrs: { "default-value": "0", "button-style": "solid" },
                  on: { change: _vm.radioGroupChange },
                },
                [
                  _c(
                    "a-radio-button",
                    {
                      staticStyle: { width: "50%", "text-align": "center" },
                      attrs: { value: "0" },
                    },
                    [_vm._v(" 表单设计 ")]
                  ),
                  _c(
                    "a-radio-button",
                    {
                      staticStyle: { width: "50%", "text-align": "center" },
                      attrs: { value: "1" },
                    },
                    [_vm._v(" 设置联动 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.logicShow
            ? _c("k-form-design", {
                ref: "kfd",
                attrs: {
                  showHead: false,
                  title: "表单设计",
                  toolbars: _vm.toolbars,
                  toolbarsTop: "",
                },
                on: { save: _vm.handleSave },
              })
            : _vm._e(),
          _vm.logicShow
            ? _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #F0F0F0",
                    "border-bottom-left-radius": "10px",
                    "border-bottom-right-radius": "10px",
                    padding: "30px",
                  },
                },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { float: "right", margin: "10px 0" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.logicOk(_vm.formObject)
                        },
                      },
                    },
                    [_vm._v(" 保存联动 ")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { float: "right", margin: "10px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addLogic()
                        },
                      },
                    },
                    [_vm._v(" 添加逻辑 ")]
                  ),
                  _c("div", { staticStyle: { clear: "both" } }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "max-height": "430px",
                        overflow: "auto",
                        "padding-right": "10px",
                      },
                    },
                    _vm._l(_vm.logicList, function (logicItem, logicIndex) {
                      return _c(
                        "div",
                        {
                          key: logicIndex,
                          staticStyle: { "padding-bottom": "20px" },
                          style: {
                            "border-bottom":
                              logicIndex != _vm.logicList.length - 1
                                ? "3px solid #0b35de"
                                : "none",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                height: "30px",
                                "border-bottom": "1px solid #F0F0F0",
                                margin: "20px 0",
                                "font-size": "16px",
                                "font-weight": "500",
                              },
                            },
                            [
                              _vm._v(" 规则" + _vm._s(logicIndex + 1) + " "),
                              _c("a-icon", {
                                staticClass: "send-admin",
                                staticStyle: { float: "right" },
                                attrs: { type: "delete" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(
                                      logicItem.id,
                                      _vm.logicList
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-radio-group",
                            {
                              staticStyle: {
                                width: "100%",
                                height: "30px",
                                "margin-bottom": "20px",
                              },
                              attrs: { "default-value": "and", size: "small" },
                              model: {
                                value: logicItem.type,
                                callback: function ($$v) {
                                  _vm.$set(logicItem, "type", $$v)
                                },
                                expression: "logicItem.type",
                              },
                            },
                            [
                              _vm._v(" 当满足以下条件时 "),
                              _c(
                                "div",
                                { staticStyle: { float: "right" } },
                                [
                                  _c(
                                    "a-radio-button",
                                    { attrs: { value: "and" } },
                                    [_vm._v(" 并且 ")]
                                  ),
                                  _c(
                                    "a-radio-button",
                                    { attrs: { value: "or" } },
                                    [_vm._v(" 或者 ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("div", { staticStyle: { clear: "both" } }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                "border-radius": "10px",
                                "background-color": "#FBFBFF",
                                padding: "20px",
                              },
                            },
                            _vm._l(
                              logicItem.conditionList,
                              function (conditionItem, conditionIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: conditionIndex,
                                    staticStyle: { "padding-bottom": "10px" },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "30%" },
                                        attrs: {
                                          placeholder: "请选择",
                                          "allow-clear": "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.getSelectOption(
                                              conditionItem,
                                              conditionItem.model
                                            )
                                          },
                                        },
                                        model: {
                                          value: conditionItem.model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              conditionItem,
                                              "model",
                                              $$v
                                            )
                                          },
                                          expression: "conditionItem.model",
                                        },
                                      },
                                      _vm._l(
                                        _vm.linkageList,
                                        function (linkageItem, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: index + linkageItem.model,
                                              attrs: {
                                                value: linkageItem.model,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(linkageItem.label) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _vm._v(" == "),
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "30%" },
                                        attrs: {
                                          placeholder: "请选择",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: conditionItem.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              conditionItem,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "conditionItem.value",
                                        },
                                      },
                                      _vm._l(
                                        conditionItem.selectOption,
                                        function (selectValueItem, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key:
                                                index + selectValueItem.value,
                                              attrs: {
                                                value: selectValueItem.value,
                                                disabled:
                                                  conditionItem.selectValueDisabled,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    selectValueItem.label
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _c("a-icon", {
                                      staticClass: "send-admin",
                                      attrs: { type: "delete" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(
                                            conditionItem.id,
                                            logicItem.conditionList
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                clear: "both",
                                width: "100%",
                                "background-color": "#fff",
                                height: "30px",
                                margin: "20px 0",
                              },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary", ghost: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addCondition(
                                        logicItem.conditionList
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 添加条件 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                "border-radius": "10px",
                                "background-color": "#FBFBFF",
                                padding: "20px",
                              },
                            },
                            [
                              _c("div", [_vm._v("显示下列组件")]),
                              _c(
                                "a-checkbox-group",
                                {
                                  model: {
                                    value: logicItem.showList,
                                    callback: function ($$v) {
                                      _vm.$set(logicItem, "showList", $$v)
                                    },
                                    expression: "logicItem.showList",
                                  },
                                },
                                _vm._l(
                                  _vm.formObjectList,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      { key: index },
                                      [
                                        _c(
                                          "a-checkbox",
                                          { attrs: { value: item.key } },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.label) + " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <a-modal :width="width" :title="!disabled?'表单填写':'表单预览'" v-model="visible">
      <k-form-build ref="kfb" :value="value" :defaultValue="defaultValue" :disabled="disabled" @change="change"/>
      <template slot="footer">
        <!-- <span style="color: red;float: left;line-height: 30px;font-size: 16px;">备注：必填项必填，如无填“0”</span> -->
        <a-button @click="handleCancel">
          取消
        </a-button>
        <a-button @click="findDraft" v-if="!disabled">
          取出草稿
        </a-button>
        <a-button @click="handleOk('draft')"  v-if="!disabled">
          保存草稿
        </a-button>
        <a-button type="primary" @click="handleOk('edit')"  v-if="!disabled">
          提交
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { findDraft } from "@/api/quarter/filling.js"
export default {
  name: 'ViewForm',
  props:{
    width:{
      type: String,
      default: '60%'
    },
    popFormType:{
      type: String,
      default: 'write'
    },
  },
  data() {
    return {
      id: '',
      visible: false,
      disabled: false,
      dynamicFormId: '1697169233456867',
      type: '',
      year:'',
      value: {},
      defaultValue: {},
      viewFormContent: {},
      defaultValueList: [],
      rowData: {}
    };
  },
  watch:{
    
  },
  mounted() {
    
  },

  methods: {
    handleOk(type) {
      if (type == "draft") {
        this.getDataNoCheck(type)
        return
      }
      this.$confirm({
        title: '提示',
       content: h =><span style="padding:30px">请确认所填内容真实完整，提交后不可更改数据，如需更改请联系 CPCA 信息部！</span>,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning',
        onOk: () => {
          this.$refs.kfb.getData().then(res => {
            this.submitForm(type, JSON.stringify(res))
          })
          .catch(err => {
            // console.log(err, "校验失败");
          });
        },
        onCancel: () => {
          this.$message.warning('取消确认')
        }
      })
        
    },
    getDataNoCheck(type) {
      this.$refs.kfb.getDataNoCheck().then(res => {
        this.submitForm(type, JSON.stringify(res))
      })
      .catch(err => {
        // console.log(err, "获取失败");
      });
    },
    handleCancel() {
      this.visible = false
    },
    findDraft() {
      // console.log("this.rowData",this.rowData);
      let draftObject = {
        enterpriseId: this.rowData.id?this.rowData.id:'',
        dynamicFormId: this.rowData.dynamicFormId?this.rowData.dynamicFormId:''
      }
      console.log("draftObject",draftObject);
      findDraft(draftObject).then(res => {
        if (res.code == 200) {
          this.change(null, null, "checkbox", JSON.parse(res.data.content))
          this.$nextTick(() => {
            this.$refs.kfb.setData(JSON.parse(res.data.content))
          })
          // this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    viewForm(res, type, companyInfo, formDate) {
      this.rowData = companyInfo ? JSON.parse(JSON.stringify(companyInfo)) : {}
      // console.log('formDate',formDate?JSON.parse(formDate):'');
      this.disabled = type == "view"
      this.visible = true
      this.$nextTick(() => {
        this.viewFormContent = JSON.parse(res.data.content)
        this.handleReset()
        this.isShow(JSON.parse(res.data.content))
        if (formDate) {
          this.change(null, null, "checkbox", JSON.parse(formDate))
        }
        // this.change(null, null, "checkbox", JSON.parse(res.data.content))
        this.viewFormContent = JSON.parse(JSON.stringify(this.value))
        this.dynamicFormId = res.data.id
        this.year = res.data.year
        this.type = res.data.type
        if (formDate && formDate != undefined && formDate !== null) {
          this.$refs.kfb.setData(JSON.parse(formDate))
        }
        
      })
    },
    isShow(list) {
      this.defaultValueList = []
      // 获取所有组件默认值
      this.getDefaultValueList(list)
      let defaultValueList = JSON.parse(JSON.stringify(this.defaultValueList))
      let defaultValueObject = {}
      // 默认值组成对象
      defaultValueList.forEach(item => {
        defaultValueObject[item.key] = item.value
      })
      // 有默认值项调用联动方法
      if (this.defaultValueList && this.defaultValueList.length > 0) {
        // console.log("111-1");
        this.defaultValueList.forEach(item => {
          this.showChange(item.value, item.key, item.type, defaultValueObject)
        })
      } else {
        // console.log("222-1");
        let viewFormContent = JSON.parse(JSON.stringify(this.viewFormContent))
        this.notDefaultValue(viewFormContent)
      }
      // console.log("this.value-2",this.value);
      this.tableTypeChangeShow(this.value)
    },
    notDefaultValue(viewFormContent) {
      viewFormContent.list.forEach(item => {
        if (item.showCondition && item.showCondition.list && item.showCondition.list.length > 0) {
          item.show = false
        } else {
          item.show = true
        }
        if (item.columns && item.columns.length > 0) {
          item.columns.forEach(columnsItem => {
            this.notDefaultValue(columnsItem)
          })
        }
      })
      this.value = viewFormContent
    },
    getDefaultValueList(list) {
      list.list.forEach(item => {
        if (item.options && item.options.defaultValue) {
          // console.log("item.options.defaultValue",item.model,"-",item.options.defaultValue);
          this.defaultValueList.push({'value': item.options.defaultValue, 'key': item.model, 'type': item.type})
        }
        if (item.columns && item.columns.length > 0) {
          item.columns.forEach(columnsItem => {
            this.getDefaultValueList(columnsItem)
          })
        }
      })
    },
    // 表格布局增加show属性
    tableTypeChangeShow(list) {
      // console.log("list",list);
      list.list.forEach(item => {
        if (item.type == 'table') {
          if (item.trs && item.trs.length > 0) {
            item.trs.forEach(trItem => {
              if (trItem.tds && trItem.tds.length > 0 ) {
                trItem.tds.forEach(tdItem => {
                  if (tdItem.list && tdItem.list.length > 0) {
                    tdItem.list.forEach(listItem => {
                      listItem['show'] = true
                      if (listItem.type == 'table') {
                        this.tableTypeChangeShow(listItem)
                      }
                      if (listItem.type == 'grid') {
                        // console.log("grid-listItem",listItem);
                        this.gridTypeChangeShow(listItem.columns)
                      }
                    })
                  }
                })
              }
            })
          }
        }
      })
      
    },
    // 栅格布局增加show属性
    gridTypeChangeShow(list) {
      list.forEach(item => {
        if (item.list && item.list.length > 0) {
          item.list.forEach(listItem => {
          listItem['show'] = true
          if (listItem.type == 'table') {
            this.tableTypeChangeShow(listItem)
          }
          if (listItem.type == 'grid') {
            this.gridTypeChangeShow(listItem.columns)
          }
        })
        }
      })
      
      
    },
    showChange(value, key, type, valueObject, formItem) {
      // console.log("nldkajsn",type,valueObject);
      // 获取表单数据结构
      let viewFormContent = formItem ? formItem : this.viewFormContent
      // console.log("viewFormContent",viewFormContent);
      // 符合条件类型的组件进入
      if (type == 'select' || type == 'checkbox' || type == 'radio') {
        // 遍历表单数据list
        viewFormContent.list.forEach(item => {
          let sum = 0
          // 如果当前组件包含被条件显隐项
          if (item.showCondition && item.showCondition.list && item.showCondition.list.length > 0) {
            let conditionSum = 0
            // 判断符合条件list的条数
            item.showCondition.list.forEach(conditionItem => {
              // 多选判断数组是否包含、单选判断是否相等
              if ((typeof valueObject[conditionItem.lable]) == "object") {
                if (valueObject[conditionItem.lable].some(ele => ele == conditionItem.value)) {
                  conditionSum ++
                }
              } else {
                if (valueObject[conditionItem.lable] == conditionItem.value) {
                  conditionSum ++
                }
              }
            })
            // 如果符合全部的条件list
            if (conditionSum == item.showCondition.list.length) {
              sum = 1
            }
            // 在并的情况下
            if (item.showCondition.type == 'and') {
              // console.log("111=23",conditionSum,item.showCondition.list.length);
              // 全部符合，true；否则为false
              if (sum == 1) {
                // console.log("111=23--1");
                item['show'] = true
              } else {
                // console.log("item", item);
                if ((typeof valueObject[item.model]) == "object") { 
                  valueObject[item.model] = []
                } else {
                  valueObject[item.model] = ''
                }
                item['show'] = false
              }
            } else {
              // 为或的情况：有符合条件就为true；没有则为false
              if (conditionSum > 0) {
                item['show'] = true
              } else {
                if ((typeof valueObject[item.model]) == "object") { 
                  valueObject[item.model] = []
                } else {
                  valueObject[item.model] = ''
                }
                item['show'] = false
              }
            }
          } else {
            item['show'] = true
          }
          // 如果有下级继续遍历下级组件项
          if (item.columns && item.columns.length > 0) {
            // console.log("111-2--type,valueObject",type,item);
            item.columns.forEach(columnsItem => {
              this.showChange("","",type,valueObject,columnsItem)
            })
          }
        })
      }
      // console.log("viewFormContent-1",viewFormContent);
      // this.tableTypeChangeShow(viewFormContent)
      this.value = JSON.parse(JSON.stringify(viewFormContent))
      // console.log("this.value-1",this.value);
      this.defaultValue = JSON.parse(JSON.stringify(valueObject))
    },
    handleReset() {
      // 重置表单
      // console.log("this.$refs.kfb",this.$refs.kfb);
      this.$refs.kfb.reset();
    },
    // 表单提交
    submitForm(type,data) {
      let object = {
        "year": this.year,
        "type": this.type,
        "enterpriseId": '',
        "dynamicFormId": this.dynamicFormId,
        "dynamicFormData": data
      }
      // return
      this.$emit("toSubmitForm", type, object);
    },
    change(value, key, type, formData) {
      if (value == null || key == null) {
        
      } else {
        formData[key] = value
      }
      this.showChange(value, key, type, formData);
    }
  },
};
</script>

<style lang="less" scoped>

</style>
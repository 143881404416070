var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pcbContainer" },
    [
      _c(
        "ReportsTitle",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.current === 0 || _vm.formMode == "view",
              expression: "current === 0 || formMode == 'view'",
            },
          ],
          attrs: { title: "企业经营状况" },
        },
        [
          _c("enterpriseCompany", {
            ref: "enterprise",
            attrs: {
              childForm: _vm.annualform.enterpriseRevenueInfo,
              formMode: _vm.formMode,
              formType: "1",
            },
            on: { tonext: _vm.tonext },
          }),
        ],
        1
      ),
      _c(
        "ReportsTitle",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.current === 1 || _vm.formMode == "view",
              expression: "current === 1 || formMode == 'view'",
            },
          ],
          attrs: { title: "PCB产品（占比）应用领域分类" },
        },
        [
          _c("PcbProportion", {
            ref: "Proportion",
            attrs: {
              childForm: _vm.annualform.pcbProductAccounted,
              formMode: _vm.formMode,
            },
            on: { tonext: _vm.tonext },
          }),
        ],
        1
      ),
      _c(
        "ReportsTitle",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.current === 2 || _vm.formMode == "view",
              expression: "current === 2 || formMode == 'view'",
            },
          ],
          attrs: { title: "集团布局" },
        },
        [
          _c("pcbtable", {
            ref: "pcbtable",
            staticClass: "pcbtt",
            attrs: {
              childForm: _vm.annualform.enterpriseLayoutInfos,
              formMode: _vm.formMode,
              enterpriseBusinessType: _vm.enterpriseBusinessType,
            },
            on: { tonext: _vm.tonext },
          }),
        ],
        1
      ),
      _c(
        "ReportsTitle",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.current >= 3 || _vm.formMode == "view",
              expression: "current >= 3 || formMode == 'view'",
            },
          ],
          attrs: { title: "PCB生产指标" },
        },
        [
          _c("Pcbproduction", {
            ref: "production",
            attrs: {
              childForm: _vm.annualform.pcbProductionQuotas,
              formMode: _vm.formMode,
            },
            on: { tonext: _vm.tonext },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
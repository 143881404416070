var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("TimePicker", {
    style: `width:${_vm.record.options.width}`,
    attrs: {
      disabled: _vm.record.options.disabled || _vm.parentDisabled,
      allowClear: _vm.record.options.clearable,
      placeholder: _vm.record.options.placeholder,
      format: _vm.record.options.format,
      value: _vm.time,
    },
    on: { change: _vm.handleSelectChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
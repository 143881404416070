var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("quillEditor", {
    ref: "vueQuillEditor",
    staticClass: "ql-editor-class",
    class: { chinesization: _vm.record.options.chinesization },
    style: { height: `${_vm.record.options.height}px` },
    attrs: {
      value: _vm.value,
      options: _vm.editorOption,
      disabled: _vm.record.options.disabled || _vm.parentDisabled,
    },
    on: {
      blur: function ($event) {
        return _vm.onEditorBlur($event)
      },
      focus: function ($event) {
        return _vm.onEditorFocus($event)
      },
      change: function ($event) {
        return _vm.onEditorChange($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
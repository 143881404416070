var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reports" },
    [
      _c(
        "a-card",
        { staticClass: "noborder top-header" },
        [
          _c("ReportsHeaderNew", {
            attrs: { formList: _vm.headerFormList },
            on: { search: _vm.headerSearch },
          }),
        ],
        1
      ),
      _c(
        "a-card",
        { staticClass: "noborder bottom-card", attrs: { title: "信息列表" } },
        [
          _c("div", { attrs: { slot: "extra" }, slot: "extra" }),
          _c("a-table", {
            attrs: {
              columns: _vm.isAdmin ? _vm.columns : _vm.columnsAdmin,
              rowKey: (record) => record.id + record.dynamicFormId,
              "data-source": _vm.data,
              pagination: _vm.pagination,
              scroll: { x: true },
            },
            on: { change: _vm.tableChange },
            scopedSlots: _vm._u(
              [
                {
                  key: "reportStatus",
                  fn: function (text) {
                    return [
                      _c("div", { staticClass: "hzzz" }, [
                        _vm._v(" " + _vm._s(_vm.handleStatus(text)) + " "),
                      ]),
                    ]
                  },
                },
                {
                  key: "recallStatus",
                  fn: function (text) {
                    return _vm.isAdmin
                      ? _c(
                          "div",
                          {},
                          [
                            text == 0
                              ? [
                                  _c(
                                    "span",
                                    { staticClass: "tblock tag-red" },
                                    [_vm._v("未催")]
                                  ),
                                ]
                              : _vm._e(),
                            text == 1
                              ? [
                                  _c(
                                    "span",
                                    { staticClass: "tblock tag-green" },
                                    [_vm._v("已催")]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e()
                  },
                },
                {
                  key: "caozuo",
                  fn: function (text, record) {
                    return [
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        record.type != 0
                          ? _c(
                              "span",
                              {
                                staticClass: "send",
                                on: {
                                  click: function ($event) {
                                    return _vm.editForm(record, "view")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("reports.operate.View")) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.isAdmin || record.reportStatus == 1
                          ? _c(
                              "span",
                              {
                                staticClass: "send",
                                on: {
                                  click: function ($event) {
                                    return _vm.editForm(record, "edit")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isAdmin
                                        ? _vm.$t("reports.operate.Edit")
                                        : "填报"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: "65%",
            title: "",
            maskClosable: false,
            visible: _vm.announceVisible,
            closable: false,
          },
        },
        [
          _c("div", { staticClass: "announce-modal-content" }, [
            _c("h3", [_vm._v("免责声明")]),
            _c("div", [
              _vm._v(
                "中国电子电路行业协为更客观准确的反映电子电路行业发展态势，正确把握2024年发展趋势，现就填报2023年度中国电子电路行业企业数据统计工作。请各单位积极配合做好企业数据材料的收集、审核及报送工作，确保填报的数据真实准确。提交后不可更改数据，如若一定要更改数据，需提交更改说明书并加盖公章，更改联系"
              ),
              _c("strong", [_vm._v("CPCA")]),
              _vm._v("信息部张国旗，"),
              _c("strong", [
                _vm._v("021-54179011-612，19121739850，info@cpca.org.cn。"),
              ]),
            ]),
            _c("div", [
              _vm._v(
                "协会对获取的企业单位数据、商业秘密等严格保密，严格管理，根据企业提供的数据，采集主要数据将用于《第二十三届（2023）中国电子电路行业主要企业榜单》和《中国电子电路行业发展年报（2024）》的编制、《第六届中国电子电路行业优秀企业评选活动》和向政府有关部门提供行业信息的依据，也是作为协会出具企业行业排名、市场占有率、申报各级制造业单项冠军、专精特新中小企业等证明材料的重要依据，凡参加本次数据填报的企业，协会可提供相关服务。填报数据要真实可靠，统计数据造假将影响企业的诚信记录，由此造成的后果企业自负。 "
              ),
            ]),
            _c("div", [
              _vm._v(
                "协会以企业自律自主填报数据为准，对会员企业填报的数据的准确性、完整性、及时性不负有任何形式的担保或保证，也不对数据的使用和发布的结果承担任何法律责任和经济责任。本免责声明以及其修改权、更新权及最终解释权均属于中国电子电路行业协会。"
              ),
            ]),
          ]),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    color: "red",
                    float: "left",
                    "line-height": "30px",
                    "font-size": "16px",
                  },
                },
                [
                  _vm._v(
                    "请仔细阅读“免责声明”后，点击“确定”，进行下一步“表单填写”"
                  ),
                ]
              ),
              _c(
                "a-button",
                { key: "back", on: { click: _vm.announceHandleCancel } },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleAnnounce },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("viewForm", {
        ref: "viewForm",
        attrs: { width: "80%" },
        on: { toSubmitForm: _vm.toSubmitForm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default {
  systemName: "Statistical System",
  copyRight: "China Printed Circuit Association All Rights Reserved",
  submit: "Submit",
  save: "Save",
  "submit.ok": "Submit successfully",
  "save.ok": "Saved successfully",
  delete: "Delete",
  "delete.popConfirm": "Are you sure you want to delete this item?",
  cancel: "Cancel",
  edit: "Edit",
  add: "Add",
  unauthorized: "Unauthorized",
  unauthorizedDes: "Authorization verification failed",
  "message.welcome": "Welcome",
  "message.comeback": "welcome back",
  "duration.earlyMorning": "Good morning song",
  "duration.morning": "Good morning",
  "duration.forenoon": "Good forenoon",
  "duration.noon": "Good noon",
  "duration.afternoon": "Good afternoon",
  "duration.evening": "Good evening",
  "page.reload": "Page reload",
  "page.reload.success": "Reload success",
  "page.pagination.total.prefix": "Total ",
  "page.pagination.total.suffix": " items",
  "btn.previous": "Previous",
  "btn.next": "Next",
  "btn.draft": "Save as draft",
};

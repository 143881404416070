var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: "请选择的申报类型表单",
        visible: _vm.visible,
        width: "500px",
        wrapClassName: "yearModal",
      },
      on: { cancel: _vm.handleCancel, ok: _vm.submitOk },
    },
    [
      _c(
        "div",
        {
          staticClass: "MasFrontendChoicebusiness",
          staticStyle: { width: "350px" },
        },
        [
          _c(
            "a-radio-group",
            {
              staticStyle: { "margin-left": "150px" },
              attrs: {
                disabled:
                  _vm.business.enterpriseBusinessType != "0" &&
                  _vm.business.enterpriseBusinessType != ""
                    ? true
                    : false,
              },
              on: { change: _vm.onChange },
              model: {
                value: _vm.radio,
                callback: function ($$v) {
                  _vm.radio = $$v
                },
                expression: "radio",
              },
            },
            _vm._l(_vm.plainOptions, function (item, index) {
              return _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c(
                    "a-radio",
                    { key: item.value + index, attrs: { value: item.value } },
                    [
                      _c(
                        "span",
                        { staticClass: "radio-font", style: item.style },
                        [_vm._v(_vm._s(item.label))]
                      ),
                    ]
                  ),
                  _c("br"),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
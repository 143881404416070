<template>
  <div>
    <a-modal :width="width" title="表单统计" v-model="visible" :body-style="{'background': '#F8F9FD'}">
      <div style="padding: 20px; height: 60vh; overflow: auto;">
        <a-card :title="item.name" v-for="(item, index) in list" :key="index" :bordered="false" style="width: 100%;border-radius: 20px;margin-top: 20px;">
          <a-table :columns="columns" :data-source="item.tableList" :pagination="false" bordered  style="width: 100%;border-radius: 20px;">
            
          </a-table>
        </a-card>
      </div>
      <!-- <k-form-build ref="kfb" :value="value" :defaultValue="defaultValue" :disabled="disabled" @change="change"/> -->
      <template slot="footer">
        <a-button @click="handleCancel">
          取消
        </a-button>
        
        <!-- <a-button type="primary">
          确定
        </a-button> -->
      </template>
    </a-modal>
  </div>
</template>

<script>
import { findDraft } from "@/api/quarter/filling.js"
export default {
  name: 'ViewForm',
  props:{
    width:{
      type: String,
      default: '60%'
    },
    popFormType:{
      type: String,
      default: 'write'
    },
  },
  data() {
    return {
      id: '',
      visible: false,
      disabled: false,
      list: [
        {
          title: "单选1",
          tableList: [
            {
              name: '选项一',
              selectNum: 100
            },
            {
              name: '选项一',
              selectNum: 100
            },
            {
              name: '选项一',
              selectNum: 100
            }
          ]
        },
        {
          title: "单选1",
          tableList: [
            {
              name: '选项一',
              selectNum: 100
            },
            {
              name: '选项一',
              selectNum: 100
            },
            {
              name: '选项一',
              selectNum: 100
            }
          ]
        }
      ],
      columns: [
        {
          title: '选项',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '计数',
          dataIndex: 'selectNum',
          key: 'selectNum',
        },
      ]
    };
  },
  watch:{
    
  },
  mounted() {
    
  },

  methods: {
    handleCancel() {
      this.visible = false
    },
    viewFormStat(res, type, companyInfo, formDate) {
      if (res.code == 200) {
        this.list = res.data
        this.visible = true
      } else {
        this.$message.success(res.msg)
      }
    },
    
  },
};
</script>

<style lang="less" scoped>

</style>
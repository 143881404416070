var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-continer" },
    [
      _vm.formMode != "view"
        ? _c(
            "a-steps",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: { current: _vm.current, size: "small" },
            },
            _vm._l(_vm.step, function (item, index) {
              return _c("a-step", { key: index, attrs: { title: item.name } })
            }),
            1
          )
        : _vm._e(),
      _vm.enterpriseBusinessType == 1
        ? _c("pcbReports", {
            ref: "pcbreports",
            attrs: {
              enterpriseBusinessType: _vm.enterpriseBusinessType,
              current: _vm.current,
              annualform: _vm.annualform,
              formMode: _vm.formMode,
            },
            on: {
              childsubmit: _vm.childsubmit,
              savell: _vm.savell,
              tonext: _vm.tonext,
            },
          })
        : _vm._e(),
      _vm.enterpriseBusinessType == 2
        ? _c("cclReports", {
            ref: "cclreports",
            attrs: {
              enterpriseBusinessType: _vm.enterpriseBusinessType,
              current: _vm.current,
              annualform: _vm.annualform,
              formMode: _vm.formMode,
            },
            on: {
              childsubmit: _vm.childsubmit,
              savell: _vm.savell,
              tonext: _vm.tonext,
            },
          })
        : _vm._e(),
      _vm.enterpriseBusinessType == 3 ||
      _vm.enterpriseBusinessType == 4 ||
      _vm.enterpriseBusinessType == 5 ||
      _vm.enterpriseBusinessType == 6
        ? _c("dataReports", {
            ref: "datareports",
            attrs: {
              enterpriseBusinessType: _vm.enterpriseBusinessType,
              current: _vm.current,
              annualform: _vm.annualform,
              formMode: _vm.formMode,
            },
            on: {
              childsubmit: _vm.childsubmit,
              savell: _vm.savell,
              tonext: _vm.tonext,
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          staticClass: "step",
        },
        [
          _c(
            "a-button",
            { staticClass: "tag-orange", on: { click: _vm.save } },
            [_vm._v(" 存入草稿 ")]
          ),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.takeout } },
            [_vm._v(" 取出数据 ")]
          ),
          _vm.current != 0
            ? _c("a-button", { on: { click: _vm.prvestep } }, [
                _vm._v(" 上一步 "),
              ])
            : _vm._e(),
          _vm.step && _vm.current != _vm.step.length - 1
            ? _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.nextstep } },
                [_vm._v(" 下一步 ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
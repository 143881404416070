export default {
  "dashboard.analysis.test": "Gongzhuan No.{no} shop",
  "dashboard.analysis.introduce": "Introduce",
  "dashboard.analysis.total-sales": "Total Sales",
  "dashboard.analysis.day-sales": "Daily Sales",
  "dashboard.analysis.visits": "Visits",
  "dashboard.analysis.visits-trend": "Visits Trend",
  "dashboard.analysis.visits-ranking": "Visits Ranking",
  "dashboard.analysis.day-visits": "Daily Visits",
  "dashboard.analysis.week": "WoW Change",
  "dashboard.analysis.day": "DoD Change",
  "dashboard.analysis.payments": "Payments",
  "dashboard.analysis.finish-rate": "Finish Rate",
  "dashboard.analysis.operational-effect": "Operational Effect",
  "dashboard.analysis.sales-trend": "Stores Sales Trend",
  "dashboard.analysis.sales-ranking": "Sales Ranking",
  "dashboard.analysis.all-year": "All Year",
  "dashboard.analysis.all-month": "All Month",
  "dashboard.analysis.all-week": "All Week",
  "dashboard.analysis.all-day": "All day",
  "dashboard.analysis.search-users": "Search Users",
  "dashboard.analysis.per-capita-search": "Per Capita Search",
  "dashboard.analysis.online-top-search": "Online Top Search",
  "dashboard.analysis.the-proportion-of-sales": "The Proportion Of Sales",
  "dashboard.analysis.dropdown-option-one": "Operation one",
  "dashboard.analysis.dropdown-option-two": "Operation two",
  "dashboard.analysis.channel.all": "ALL",
  "dashboard.analysis.channel.online": "Online",
  "dashboard.analysis.channel.stores": "Stores",
  "dashboard.analysis.sales": "Sales",
  "dashboard.analysis.traffic": "Traffic",
  "dashboard.analysis.table.rank": "Rank",
  "dashboard.analysis.table.search-keyword": "Keyword",
  "dashboard.analysis.table.users": "Users",
  "dashboard.analysis.table.weekly-range": "Weekly Range",
  "dashboard.analysis.table.users": "Users",
  "dashboard.analysis.table.weekly-range": "Weekly Range",
  "dashboard.analysis.member-count": "Member Count",
  "dashboard.analysis.month-range": "Month Range",
  "dashboard.analysis.project-applies": "Project Applies",
  "dashboard.analysis.member-renewals": "Member Renewals",
  "dashboard.analysis.train-top": "Train Top",
  "dashboard.analysis.technology-train": "Technology Train",
  "dashboard.analysis.manage-train": "Manage Train",
  "dashboard.analysis.the-proportion-of-areas": "The Proportion Of Areas",
  "dashboard.analysis.areas": "Areas",
  "dashboard.analysis.area.all": "ALL",
  "dashboard.analysis.area.northwest": "Northwest",
  "dashboard.analysis.area.southwest": "Southwest",
  "dashboard.analysis.area.southeast": "Southeast",
  "dashboard.analysis.area.northeast": "Northeast",
};

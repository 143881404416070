var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "basicCompany" },
    [
      _c(
        "a-form-model",
        {
          ref: "gdp",
          attrs: { model: _vm.form, rules: _vm.rules, colon: false },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "汽车电子", prop: "automotiveElectronics" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入汽车电子 ",
                  "addon-after": "%",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.automotiveElectronics,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "automotiveElectronics", $$v)
                  },
                  expression: "form.automotiveElectronics",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "通讯类产品", prop: "communicationProducts" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入通讯类产品",
                  "addon-after": "%",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.communicationProducts,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "communicationProducts", $$v)
                  },
                  expression: "form.communicationProducts",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "手机", prop: "mobilePhone" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入手机",
                  "addon-after": "%",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.mobilePhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mobilePhone", $$v)
                  },
                  expression: "form.mobilePhone",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "电脑及周边产品",
                prop: "computerAndSurrounding",
              },
            },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入电脑及周边产品",
                  "addon-after": "%",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.computerAndSurrounding,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "computerAndSurrounding", $$v)
                  },
                  expression: "form.computerAndSurrounding",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "数据中心", prop: "dataCenter" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入数据中心",
                  "addon-after": "%",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.dataCenter,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "dataCenter", $$v)
                  },
                  expression: "form.dataCenter",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "消费电子", prop: "consumerElectronics" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入消费电子",
                  "addon-after": "%",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.consumerElectronics,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "consumerElectronics", $$v)
                  },
                  expression: "form.consumerElectronics",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "工控", prop: "industrialControl" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入工控",
                  "addon-after": "%",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.industrialControl,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "industrialControl", $$v)
                  },
                  expression: "form.industrialControl",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "医疗", prop: "medicalInstruments" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入医疗",
                  "addon-after": "%",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.medicalInstruments,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "medicalInstruments", $$v)
                  },
                  expression: "form.medicalInstruments",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "军事/航空航天", prop: "militarySpace" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入军事/航空航天",
                  "addon-after": "%",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.militarySpace,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "militarySpace", $$v)
                  },
                  expression: "form.militarySpace",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "其他", prop: "other" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入其他",
                  "addon-after": "%",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.other,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "other", $$v)
                  },
                  expression: "form.other",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("ReportsTitle", { attrs: { title: "应用领域详细说明" } }, [
        _c(
          "div",
          [
            _c("a-table", {
              attrs: {
                columns: _vm.columns,
                size: "small",
                pagination: false,
                "data-source": _vm.tableData,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.record.label !== ""
    ? _c(
        "Divider",
        { attrs: { orientation: _vm.record.options.orientation || "center" } },
        [_vm._v(_vm._s(_vm.record.label))]
      )
    : _vm.record.label === ""
    ? _c("Divider")
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slider-box", style: `width:${_vm.record.options.width}` },
    [
      _c(
        "div",
        { staticClass: "slider" },
        [
          _c(
            "Slider",
            _vm._b(
              {
                model: {
                  value: _vm.sliderValue,
                  callback: function ($$v) {
                    _vm.sliderValue = $$v
                  },
                  expression: "sliderValue",
                },
              },
              "Slider",
              this.$attrs,
              false
            )
          ),
        ],
        1
      ),
      _vm.record.options.showInput
        ? _c(
            "div",
            { staticClass: "number" },
            [
              _c(
                "InputNumber",
                _vm._b(
                  {
                    staticStyle: { width: "100%" },
                    model: {
                      value: _vm.sliderValue,
                      callback: function ($$v) {
                        _vm.sliderValue = $$v
                      },
                      expression: "sliderValue",
                    },
                  },
                  "InputNumber",
                  this.$attrs,
                  false
                )
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
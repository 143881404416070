var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "page-header-wrapper",
    [
      _c("a-card", { attrs: { bordered: false } }, [
        _c(
          "div",
          { staticClass: "table-page-search-wrapper" },
          [
            _c(
              "a-form",
              { attrs: { layout: "inline" } },
              [
                _c(
                  "a-row",
                  { attrs: { gutter: 48 } },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "a-form-item",
                          { attrs: { label: "角色名称" } },
                          [
                            _c("a-input", {
                              staticStyle: { width: "250px" },
                              attrs: { placeholder: "请输入角色名称" },
                              model: {
                                value: _vm.queryParam.object.roleName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryParam.object,
                                    "roleName",
                                    $$v
                                  )
                                },
                                expression: "queryParam.object.roleName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "btn-item",
                            attrs: { type: "primary" },
                            on: { click: _vm.queryRole },
                          },
                          [_vm._v("搜索")]
                        ),
                        _c(
                          "a-button",
                          {
                            staticClass: "btn-item",
                            on: { click: _vm.resetting },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "a-card",
        { staticStyle: { "margin-top": "24px" }, attrs: { bordered: false } },
        [
          _c(
            "div",
            { attrs: { slot: "extra" }, slot: "extra" },
            [
              _c(
                "a-popconfirm",
                {
                  attrs: { "ok-text": "确定", "cancel-text": "取消" },
                  on: { confirm: _vm.addRole },
                },
                [
                  _c("template", { slot: "icon" }, [_c("i")]),
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _c(
                        "a-form",
                        {
                          staticStyle: { width: "300px" },
                          attrs: {
                            layout: "horizontal",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 16 },
                          },
                        },
                        [
                          _c(
                            "a-form-item",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { label: "角色编码" },
                            },
                            [
                              _c("a-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请输入角色编码" },
                                model: {
                                  value: _vm.role.roleCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.role, "roleCode", $$v)
                                  },
                                  expression: "role.roleCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { label: "角色名称" },
                            },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "请输入角色名称" },
                                model: {
                                  value: _vm.role.roleName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.role, "roleName", $$v)
                                  },
                                  expression: "role.roleName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-item",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { label: "角色描述" },
                            },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "请输入角色描述" },
                                model: {
                                  value: _vm.role.roleDescription,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.role, "roleDescription", $$v)
                                  },
                                  expression: "role.roleDescription",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "btn-item btn-no-bottom member-brand",
                      attrs: { icon: "plus", size: "small" },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c("a-table", {
            staticClass: "ant-table-striped",
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.loadData,
              "row-key": (record) => record.id,
              pagination: _vm.pagination,
              bordered: "",
              size: "small",
              "row-class-name": (_record, index) =>
                index % 2 === 1 ? "table-striped" : null,
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u(
              [
                _vm._l(
                  ["roleCode", "roleName", "roleDescription"],
                  function (col, i) {
                    return {
                      key: col,
                      fn: function (text, record) {
                        return [
                          record.editable
                            ? _c("a-input", {
                                key: i + " " + record.id,
                                attrs: { value: text },
                                model: {
                                  value: record[col],
                                  callback: function ($$v) {
                                    _vm.$set(record, col, $$v)
                                  },
                                  expression: "record[col]",
                                },
                              })
                            : [_vm._v(" " + _vm._s(text) + " ")],
                        ]
                      },
                    }
                  }
                ),
                {
                  key: "operation",
                  fn: function (text, record) {
                    return [
                      _c("div", { staticClass: "editable-row-operations" }, [
                        record.editable
                          ? _c(
                              "span",
                              [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateRole(record)
                                      },
                                    },
                                  },
                                  [_vm._v("保存")]
                                ),
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancelEdit(record)
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "a-success",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.update(record)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _c(
                                  "a-popconfirm",
                                  {
                                    attrs: {
                                      title: "是否确认删除?",
                                      "ok-text": "确认",
                                      "cancel-text": "取消",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.deleteRole(record.id)
                                      },
                                      cancel: _vm.cancel,
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "a-danger",
                                        staticStyle: { cursor: "pointer" },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                ),
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _c(
                                  "a",
                                  {
                                    staticClass: "a-brand",
                                    attrs: { icon: "plus" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEditRoleMenu(record.id)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑角色菜单")]
                                ),
                              ],
                              1
                            ),
                      ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c(
            "a-modal",
            {
              attrs: {
                visible: _vm.editRoleMenuVisible,
                title: "编辑角色菜单资源",
              },
              on: { ok: _vm.updateRoleMenu, cancel: _vm.cancelEditRoleMenu },
            },
            [
              _c("a-tree", {
                attrs: {
                  checkable: "",
                  "tree-data": _vm.menus,
                  defaultExpandAll: true,
                  replaceFields: _vm.replaceFields,
                  checkStrictly: true,
                },
                model: {
                  value: _vm.checkedKeys,
                  callback: function ($$v) {
                    _vm.checkedKeys = $$v
                  },
                  expression: "checkedKeys",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
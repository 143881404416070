<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="24">
            <a-col :span="22">
              <a-form-item label="模板场景">
                <a-input style="width:250px" v-model="searchForm.scenes" placeholder="请输入模板场景"/>
              </a-form-item>
            </a-col>
            <a-col :span="2">
              <a-button type="primary" @click="findInfoTemplates(1)">搜索</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-card>

    <a-card style="margin-top: 24px" :bordered="false">
      <!-- 操作按钮 -->
      <div slot="extra">
        <a-button class="btn-item btn-no-bottom member-brand" icon="plus" size="small" @click="openAddModal">新增</a-button>
      </div>

      <a-table class="ant-table-striped" :columns="columns" :data-source="loadData" :row-key="record => record.id" :indentSize="indentSize" bordered :pagination="pagination" size="small" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
        <template slot="content" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div slot="extra">
            <a class="a-success" @click="openEditModal(record)" style="cursor: pointer">编辑</a>
            <a-divider type="vertical" />
            <a-popconfirm  title="是否确认删除?" ok-text="确认" cancel-text="取消" @confirm="deleteInfoTemplate(record.id)" @cancel="cancelDelete">
              <a style="cursor: pointer" class="a-danger" >删除</a>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </a-card>

    <!-- 添加/修改模板 -->
    <a-modal v-model="addVisible" title="添加站内信息模板" @ok="addTemplate" :width="900">
      <a-form layout="horizontal" :label-col="{ span: 2 }" :wrapper-col="{ span:20 }">
        <a-row>
          <a-col :span="24">
            <a-form-item label="模板场景">
              <a-input style="width: 100%" v-model="infoTemplate.name" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="模板内容">              
              <wang-editor :value="infoTemplate.content" @change="changeInfoTemplateContent"></wang-editor>
            </a-form-item>
          </a-col>
          </a-row>
      </a-form>
    </a-modal>
    <a-modal v-model="editVisible" title="修改站内信息模板" @ok="editTemplate" :width="900">
      <a-form layout="horizontal" :label-col="{ span: 2 }" :wrapper-col="{ span:20 }">
        <a-row>
          <a-col :span="24">
            <a-form-item label="模板场景">
              <a-input style="width: 100%" v-model="infoEdit.name" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="模板内容">        
              <wang-editor :value="content" @change="changeInfoEditContent"></wang-editor>
            </a-form-item>
          </a-col>
          </a-row>
      </a-form>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
// import { addInfoTemplate, updateInfoTemplate, findInfoTemplates, deleteInfoTemplate } from '@/api/system/infoTemplate'
import { addInfoTemplate,updateInfoTemplate,findInfoTemplates,deleteInfoTemplate } from '@/api/system/infoTemplate'
import { getCellStyleFunction  } from '@/utils/util'
import WangEditor from "@/components/Editor/WangEditor"
const columns=[
  {
    title: '模板编号',
    dataIndex: "id",
    customHeaderCell: getCellStyleFunction(120,14),
    customCell: getCellStyleFunction(120,14)
  },

  {
    title: '模板场景',
    dataIndex: "name",
    scopedSlots: { customRender: 'name' },
    customHeaderCell: getCellStyleFunction(120,14),
    customCell: getCellStyleFunction(120,14)
  },
  {
    title: '模板内容',
    dataIndex: "content",
    scopedSlots: { customRender: 'content' },
    customHeaderCell: getCellStyleFunction(100,14),
    customCell: getCellStyleFunction(100,14)
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' },
    customHeaderCell: getCellStyleFunction(180,14),
    customCell: getCellStyleFunction(180,14)
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    customHeaderCell: getCellStyleFunction(120,14),
    customCell: getCellStyleFunction(120,14)
  }
]

export default {
  name: 'InfoTemplate',
  components: {
    WangEditor
  },
  created() {
    this.findInfoTemplates(1)
  },
  data() {
    this.columns=columns
    return {
      pagination: {
        current:1,
        total: 0,
        pageIndex: 1,
        pageSize: 10,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10","20","50","100"],//每页中显示的数据
        showTotal: total => this.$t('page.pagination.total.prefix')+`${total}`+this.$t('page.pagination.total.suffix'),
      },
      //增加邮件模板显示
      addVisible: false,
      editVisible: false,

      infoTemplate: {
        name: "",
        content: "",
      },
      infoEdit: {
        name: "",
        content: "",
      },
      // 查询参数
      searchForm: {
        pageSize: 10,
        pageIndex: 1,
        object:{
          scenes: '',
        }
      },
      loadData: [],
      indentSize: 50,
      content:''
    }
  },
  mounted(){
  },
  methods: {
    // 打开添加邮件模板弹窗
    openAddModal() {
      this.addVisible = !this.addVisible
    },

    //重置
    resetting() {
      this.searchForm={
        pageSize: 10,
        pageIndex: 1,
        object:{
          scenes: '',
        }
      }
      this.findInfoTemplates(1)
    },
    //根据条件查询列表数据
    findInfoTemplates(pageIndex) {
      this.searchForm.pageIndex=pageIndex? pageIndex:1;
      findInfoTemplates(this.searchForm).then(res => {
        if(res.status==0&&res.code==200) {
          this.loadData=res.data.pageObject;
          this.pagination.current=this.searchForm.pageIndex;
          this.pagination.total=res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
        // 页码或条数更改操作
    changePage(pagination) {
      this.searchForm.pageIndex=pagination.current;
      this.searchForm.pageSize=pagination.pageSize;
      this.pagination.current=pagination.current;
      this.findInfoTemplates(this.searchForm.pageIndex);
    },

    //添加模板内容
    addTemplate() {
      addInfoTemplate(this.infoTemplate).then(res => {
        if(res.status==0&&res.code==200) {
          this.$message.success(res.msg)
          this.addVisible = !this.addVisible
          this.infoTemplate = {
            scenes: "",
            content: "",
          };
          this.findInfoTemplates(1)
        } else {
          this.$message.error(res.msg)
        }
      })
      
    },
    //修改模板内容 ${companyNameCn},2022年08月01日下午两点钟有线上研讨会!

    editTemplate() {
      updateInfoTemplate(this.infoEdit).then(res => {
          if(res.status==0&&res.code==200) {
            this.$message.success(res.msg)
            
            this.editVisible = false;
            this.infoTemplate = {
              scenes: "",
              content: "",
            };
            this.findInfoTemplates(1)
          } else {
            this.$message.error(res.msg)
          }
        })
    },

    // 取消删除
    cancelDelete() {
      return;
    },
    // 删除模板
    deleteInfoTemplate(id) {
      let reqData = {
        id: id
      }
      deleteInfoTemplate(reqData).then(res => {
        if(res.status==0&&res.code==200) {
          this.$message.success(res.msg)
          this.findInfoTemplates(1)
          
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    //开启编辑弹窗
    openEditModal(record) {     
      this.infoEdit = JSON.parse(JSON.stringify(record));
      this.content = this.infoEdit.content;
      this.editVisible = true;
    },
    // 修改content值
    changeInfoTemplateContent(content){
      this.infoTemplate.content = content;
    },
    // 修改content值
    changeInfoEditContent(content){
      this.infoEdit.content = content;
    }
  },


}
</script>

<style lang="less">
.t-ellipsis {
  width: 200px;
	overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
}
</style>
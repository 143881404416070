<template>
  <a-form-model ref="qu" :model="query" @submit="handleSubmit" @submit.native.prevent labelAlign="right" id="reports-form" :colon="false">
    <a-form-model-item label="年度"  v-if="timeIntervalLable != '季度'">
      <a-select v-model="query.timeInterval" placeholder="请选择" style="width: 100%" allow-clear>
        <a-select-option :value="item.value" v-for="(item,index) in annualOptions" :key="index" v-if="timeIntervalLable == '年度'">
          {{item.label}}
        </a-select-option>

        <a-select-option :value="item.value" v-for="(item,index) in quarterAnnualOptions" :key="index" v-if="timeIntervalLable == '季度'">
          {{item.label}}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="季度" v-if="timeIntervalLable == '季度'">
      <a-select show-search v-model="query.quarterTypeOld" placeholder="请选择" style="width: 100%" allow-clear>
        <a-select-option :value="item.value" v-for="(item,index) in quarterOptions" :key="index">
          {{item.year+item.label}}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="主营业务" v-if="isAdmin">
      <!-- <a-input v-model="query.enterpriseBusinessType" placeholder="主营业务">
      </a-input> -->
      <a-select v-model="query.enterpriseBusinessType" placeholder="请选择主营业务" style="width: 100%" allow-clear>
        <a-select-option :value="item.value" v-for="(item,index) in business" :key="index" >
          {{item.label}}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="注册地区" v-if="isAdmin">
      <a-input v-model="query.communicationAddress" placeholder="地区">
      </a-input>
    </a-form-model-item>
    <a-form-model-item label="单位名称" v-if="isAdmin">
      <a-input v-model="query.enterpriseName" placeholder="单位名称">
      </a-input>
    </a-form-model-item>
    <a-form-model-item label="填报状态">
      <a-select v-model="query.reportStatus" placeholder="请选择填报状态" style="width: 100%"  allow-clear>
        <a-select-option :value="item.value" v-for="(item,index) in status" :key="index">
          {{item.label}}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item class="btn-box" :labelCol="{ span: 0 }" :wrapperCol="{ span: 24 }">
      <a-button class="submitbtn" type="primary" html-type="submit">
        查询
      </a-button>
      <a-button @click="resetform"> 重置 </a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import {quarterAnnualOptions,annualOptions} from "@/utils/util"
export default {
  name: 'ReportsHeader',
  props: {
    timeIntervalLable: {
      default: '年度'
    }
  },
  components: {},
  data() {
    return {
      isAdmin:this.$store.getters.nickname&&this.$store.getters.nickname.indexOf('admin')>-1,
      labelCol: { span: 2 },
      wrapperCol: { span: 16 },
      annualOptions,
      quarterAnnualOptions,
      quarterOptions: [
        { value: '2023+1', year:'2023', label: 'Q1' },
        { value: '2023+2', year:'2023', label: 'Q2' },
        { value: '2023+3', year:'2023', label: 'Q3' },
        { value: '2023+4', year: '2023', label: 'Q4' },
        { value: '2022+1', year:'2022', label: 'Q1' },
        { value: '2022+2', year:'2022', label: 'Q2' },
        { value: '2022+3', year:'2022', label: 'Q3' },
        { value: '2022+4', year: '2022', label: 'Q4' },
        { value: '2021+1', year:'2021', label: 'Q1' },
        { value: '2021+2', year:'2021', label: 'Q2' },
        { value: '2021+3', year:'2021', label: 'Q3' },
        { value: '2021+4', year: '2021', label: 'Q4' },
        { value: '2020+1', year:'2020', label: 'Q1' },
        { value: '2020+2', year:'2020', label: 'Q2' },
        { value: '2020+3', year:'2020', label: 'Q3' },
        { value: '2020+4', year: '2020', label: 'Q4' },
        { value: '2019+1', year:'2019', label: 'Q1' },
        { value: '2019+2', year:'2019', label: 'Q2' },
        { value: '2019+3', year:'2019', label: 'Q3' },
        { value: '2019+4', year: '2019', label: 'Q4' },
        { value: '2018+1', year:'2018', label: 'Q1' },
        { value: '2018+2', year:'2018', label: 'Q2' },
        { value: '2018+3', year:'2018', label: 'Q3' },
        { value: '2018+4', year: '2018', label: 'Q4' },
        { value: '2017+1', year:'2017', label: 'Q1' },
        { value: '2017+2', year:'2017', label: 'Q2' },
        { value: '2017+3', year:'2017', label: 'Q3' },
        { value: '2017+4', year: '2017', label: 'Q4' },
        
      ],
      query: {
        // timeInterval: new Date().getFullYear(), //年度
        quarterTypeOld: undefined,
        quarterType:undefined,
        timeInterval: undefined,
        enterpriseBusinessType: undefined, //主营业务
        communicationAddress: '', //注册地区
        enterpriseName: '', //单位名称
        reportStatus: undefined, //填报状态 布尔值
        membershipNumber:(this.$store.getters.nickname&&this.$store.getters.nickname.indexOf('admin')>-1)?'':this.$store.getters.nickname
      },
      status: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '草稿',
          value: '1',
        },
        {
          label: '重填',
          value: '2',
        },
        {
          label: '已上报',
          value: '3',
        },
      ],
      business: [
        { label: '印制线路板', value: '1' },
        { label: '覆铜板', value: '2' },
        { label: '专用设备', value: '3' },
        { label: '专用材料', value: '4' },
        { label: '专用化学品', value: '5' },
        { label: '清洁洁净', value: '6' },
      ],
    }
  },
  watch: {},
  created() {
    // if (this.isAdmin) {
    //   if (this.timeIntervalLable == '年度') {
    //     this.query.timeInterval = new Date().getFullYear() - 1;
    //   }

    // } else {
    //   if (this.timeIntervalLable == '年度') {
    //     this.query.timeInterval = ''
    //   }
    // }
    this.handleSubmit();
  },
  mounted(){
    // if (this.timeIntervalLable == '季度') {
    //     this.query.timeInterval = this.getQuarterByMonth();
    //   }
  },
  methods: {
    //获取当前季度
    getQuarterByMonth(){
      var today = new Date(); //获取当前时间
      var month = today.getMonth() + 1;//getMonth返回0-11
      //下面是几个if
      if(month >=1 && month <=3){
        return '1';
      }else if(month >=4 && month <=6){
          return '2';
      }else if(month >=7 && month <=9){
          return '3';
      }else{
          return '4';
      }
    },
    handleSubmit() {
      if (this.query.quarterTypeOld && this.query.quarterTypeOld.indexOf("+")) {
        this.query.timeInterval = this.query.quarterTypeOld.split('+')[0]
        this.query.quarterType = this.query.quarterTypeOld.split('+')[1]
      }
      
      this.$emit('search', this.query)
    },
    resetform() {
      this.query = {
        quarterType:undefined,
        timeInterval: undefined, //年度
        enterpriseBusinessType: undefined, //主营业务
        communicationAddress: '', //注册地区
        enterpriseName: '', //单位名称
        reportStatus: undefined, //填报状态 布尔值
      }
    },
  },
}
</script>

<style scoped lang="less">
#reports-form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .ant-form-item {
    width: 280px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    /deep/.ant-form-item-label {
      width: 80px;
    }
    /deep/.ant-form-item-control-wrapper {
      flex: 1;
    }
  }
  .btn-box {
    .ant-form-item-children {
      .submitbtn {
        margin-right: 24px;
        margin-left: 24px;
      }
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: "发送邮件",
        width: 900,
        visible: _vm.addVisible,
        confirmLoading: _vm.loading,
        destroyOnClose: true,
      },
      on: {
        ok: _vm.send,
        cancel: () => {
          _vm.$emit("closeAdd")
        },
      },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-form",
            _vm._b({}, "a-form", _vm.formLayout, false),
            [
              _c(
                "a-form-item",
                { attrs: { label: "接收单位" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        mode: "multiple",
                        placeholder: "请选择接收单位",
                        "filter-option": _vm.filterOption,
                      },
                      on: {
                        search: _vm.handleSearch,
                        blur: _vm.selblur,
                        change: _vm.selchange,
                      },
                      model: {
                        value: _vm.mail.companyIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.mail, "companyIds", $$v)
                        },
                        expression: "mail.companyIds",
                      },
                    },
                    [
                      _vm.fetching
                        ? _c("a-spin", {
                            attrs: { slot: "notFoundContent", size: "small" },
                            slot: "notFoundContent",
                          })
                        : _vm._l(_vm.selectEventlist, function (item) {
                            return _c(
                              "a-select-option",
                              { key: item.value, attrs: { value: item.value } },
                              [_vm._v(" " + _vm._s(item.label) + " ")]
                            )
                          }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "所属标签" } },
                [
                  _c("a-select", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      mode: "multiple",
                      placeholder: "请选择会员标签",
                      options: _vm.tagOptions,
                    },
                    model: {
                      value: _vm.mail.tagIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.mail, "tagIds", $$v)
                      },
                      expression: "mail.tagIds",
                    },
                  }),
                ],
                1
              ),
              _vm.isTemplateEmail
                ? _c(
                    "a-form-item",
                    { attrs: { label: "邮件模板" } },
                    [
                      _c("a-select", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          placeholder: "请选择邮件模板",
                          options: _vm.mailTemplateOptions,
                        },
                        model: {
                          value: _vm.mail.templateId,
                          callback: function ($$v) {
                            _vm.$set(_vm.mail, "templateId", $$v)
                          },
                          expression: "mail.templateId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isTemplateEmail
                ? _c(
                    "a-form-item",
                    { attrs: { label: "主题" } },
                    [
                      _c("a-input", {
                        staticStyle: { width: "500px" },
                        attrs: { placeholder: "请输入主题" },
                        model: {
                          value: _vm.mail.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.mail, "title", $$v)
                          },
                          expression: "mail.title",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isTemplateEmail
                ? _c(
                    "a-form-item",
                    { attrs: { label: "内容" } },
                    [
                      _c("wang-editor", {
                        attrs: { value: "" },
                        on: { change: _vm.changeContent },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                { attrs: { label: "附件" } },
                [
                  _c(
                    "a-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        name: "file",
                        "show-upload-list": true,
                        action: _vm.baseUrl + "upload/file",
                        "before-upload": _vm.beforeUpload,
                        "max-count": 5,
                        headers: _vm.headers,
                      },
                      on: { change: _vm.uploadChange },
                    },
                    [
                      _c(
                        "a-button",
                        [
                          _c("a-icon", { attrs: { type: "upload" } }),
                          _vm._v(" 点击上传 "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
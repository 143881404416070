import request from "@/utils/request";
const applyApi = {
  Associations: "/system-mms-user/user/upd_password",
};

/**
 * 获取所有协会 func
 * @param parameter
 * @returns {*}
 */
export function updatePersonPwd(parameter) {
  return request({
    url: applyApi.Associations,
    method: "post",
    data: parameter,
  });
}


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "layout-width": [
          "grid",
          "table",
          "selectInputList",
          "tabs",
          "card",
          "editor",
          "batch",
          "divider",
          "html",
        ].includes(_vm.record.type),
      },
    },
    [
      _vm.record.type === "batch"
        ? [
            _c(
              "div",
              {
                staticClass: "batch-box",
                class: { active: _vm.record.key === _vm.selectItem.key },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleSelectItem(_vm.record)
                  },
                },
              },
              [
                _c(
                  "a-form-item",
                  {
                    style:
                      _vm.config.layout === "horizontal" &&
                      _vm.config.labelLayout === "flex" &&
                      _vm.record.options.showLabel
                        ? { display: "flex" }
                        : {},
                    attrs: {
                      label: !_vm.record.options.showLabel
                        ? ""
                        : _vm.record.label,
                      "label-col":
                        _vm.config.layout === "horizontal" &&
                        _vm.record.options.showLabel
                          ? _vm.config.labelLayout === "flex"
                            ? { style: `width:${_vm.config.labelWidth}px` }
                            : _vm.config.labelCol
                          : {},
                      "wrapper-col":
                        _vm.config.layout === "horizontal" &&
                        _vm.record.options.showLabel
                          ? _vm.config.labelLayout === "flex"
                            ? { style: "width:auto;flex:1" }
                            : _vm.config.wrapperCol
                          : {},
                    },
                  },
                  [
                    _c(
                      "draggable",
                      _vm._b(
                        {
                          staticClass: "draggable-box",
                          attrs: { tag: "div" },
                          on: {
                            start: function ($event) {
                              return _vm.$emit(
                                "dragStart",
                                $event,
                                _vm.record.list
                              )
                            },
                            add: function ($event) {
                              return _vm.$emit(
                                "handleColAdd",
                                $event,
                                _vm.record.list
                              )
                            },
                          },
                          model: {
                            value: _vm.record.list,
                            callback: function ($$v) {
                              _vm.$set(_vm.record, "list", $$v)
                            },
                            expression: "record.list",
                          },
                        },
                        "draggable",
                        {
                          group: _vm.insertAllowed ? "form-draggable" : "",
                          ghostClass: "moving",
                          animation: 180,
                          handle: ".drag-move",
                        },
                        false
                      ),
                      [
                        _c(
                          "transition-group",
                          {
                            staticClass: "list-main",
                            attrs: { tag: "div", name: "list" },
                          },
                          _vm._l(_vm.record.list, function (item) {
                            return _c("formNode", {
                              key: item.key,
                              staticClass: "drag-move",
                              attrs: {
                                selectItem: _vm.selectItem,
                                record: item,
                                hideModel: _vm.hideModel,
                                config: _vm.config,
                              },
                              on: {
                                "update:selectItem": function ($event) {
                                  _vm.selectItem = $event
                                },
                                "update:select-item": function ($event) {
                                  _vm.selectItem = $event
                                },
                                handleSelectItem: _vm.handleSelectItem,
                                handleColAdd: _vm.handleColAdd,
                                handleCopy: function ($event) {
                                  return _vm.$emit("handleCopy")
                                },
                                handleShowRightMenu: _vm.handleShowRightMenu,
                                handleDelete: function ($event) {
                                  return _vm.$emit("handleDelete")
                                },
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "copy",
                    class:
                      _vm.record.key === _vm.selectItem.key
                        ? "active"
                        : "unactivated",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("handleCopy")
                      },
                    },
                  },
                  [_c("a-icon", { attrs: { type: "copy" } })],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "delete",
                    class:
                      _vm.record.key === _vm.selectItem.key
                        ? "active"
                        : "unactivated",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("handleDelete")
                      },
                    },
                  },
                  [_c("a-icon", { attrs: { type: "delete" } })],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.record.type === "selectInputList"
        ? [
            _c(
              "div",
              {
                staticClass: "select-input-list-box",
                class: { active: _vm.record.key === _vm.selectItem.key },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleSelectItem(_vm.record)
                  },
                },
              },
              [
                _c(
                  "a-form-item",
                  {
                    style:
                      _vm.config.layout === "horizontal" &&
                      _vm.config.labelLayout === "flex" &&
                      _vm.record.options.showLabel
                        ? { display: "flex" }
                        : {},
                    attrs: {
                      label: !_vm.record.options.showLabel
                        ? ""
                        : _vm.record.label,
                      "label-col":
                        _vm.config.layout === "horizontal" &&
                        _vm.record.options.showLabel
                          ? _vm.config.labelLayout === "flex"
                            ? { style: `width:${_vm.config.labelWidth}px` }
                            : _vm.config.labelCol
                          : {},
                      "wrapper-col":
                        _vm.config.layout === "horizontal" &&
                        _vm.record.options.showLabel
                          ? _vm.config.labelLayout === "flex"
                            ? { style: "width:auto;flex:1" }
                            : _vm.config.wrapperCol
                          : {},
                    },
                  },
                  _vm._l(_vm.record.columns, function (column, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "column-box" },
                      [
                        _c(
                          "div",
                          { staticClass: "check-box" },
                          [
                            _vm.record.options.multiple
                              ? _c(
                                  "CheckboxItem",
                                  { attrs: { disabled: "" } },
                                  [_vm._v(" " + _vm._s(column.label) + " ")]
                                )
                              : _c(
                                  "RadioItem",
                                  {
                                    attrs: {
                                      disabled: "",
                                      name: "radio",
                                      value: column.value,
                                    },
                                  },
                                  [_vm._v(_vm._s(column.label))]
                                ),
                          ],
                          1
                        ),
                        _c(
                          "draggable",
                          _vm._b(
                            {
                              staticClass: "draggable-box",
                              attrs: { tag: "div" },
                              on: {
                                start: function ($event) {
                                  return _vm.$emit(
                                    "dragStart",
                                    $event,
                                    column.list
                                  )
                                },
                                add: function ($event) {
                                  return _vm.$emit(
                                    "handleColAdd",
                                    $event,
                                    column.list
                                  )
                                },
                              },
                              model: {
                                value: column.list,
                                callback: function ($$v) {
                                  _vm.$set(column, "list", $$v)
                                },
                                expression: "column.list",
                              },
                            },
                            "draggable",
                            {
                              group: _vm.insertAllowed ? "form-draggable" : "",
                              ghostClass: "moving",
                              animation: 180,
                              handle: ".drag-move",
                            },
                            false
                          ),
                          [
                            _c(
                              "transition-group",
                              {
                                staticClass: "list-main",
                                attrs: { tag: "div", name: "list" },
                              },
                              _vm._l(column.list, function (item) {
                                return _c("formNode", {
                                  key: item.key,
                                  staticClass: "drag-move",
                                  attrs: {
                                    selectItem: _vm.selectItem,
                                    record: item,
                                    hideModel: _vm.hideModel,
                                    config: _vm.config,
                                  },
                                  on: {
                                    "update:selectItem": function ($event) {
                                      _vm.selectItem = $event
                                    },
                                    "update:select-item": function ($event) {
                                      _vm.selectItem = $event
                                    },
                                    handleSelectItem: _vm.handleSelectItem,
                                    handleColAdd: _vm.handleColAdd,
                                    handleCopy: function ($event) {
                                      return _vm.$emit("handleCopy")
                                    },
                                    handleShowRightMenu:
                                      _vm.handleShowRightMenu,
                                    handleDelete: function ($event) {
                                      return _vm.$emit("handleDelete")
                                    },
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  {
                    staticClass: "copy",
                    class:
                      _vm.record.key === _vm.selectItem.key
                        ? "active"
                        : "unactivated",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("handleCopy")
                      },
                    },
                  },
                  [_c("a-icon", { attrs: { type: "copy" } })],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "delete",
                    class:
                      _vm.record.key === _vm.selectItem.key
                        ? "active"
                        : "unactivated",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("handleDelete")
                      },
                    },
                  },
                  [_c("a-icon", { attrs: { type: "delete" } })],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.record.type === "tabs"
        ? [
            _c(
              "div",
              {
                staticClass: "grid-box",
                class: { active: _vm.record.key === _vm.selectItem.key },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleSelectItem(_vm.record)
                  },
                },
              },
              [
                _c(
                  "a-tabs",
                  {
                    staticClass: "grid-row",
                    attrs: {
                      "default-active-key": 0,
                      tabBarGutter: _vm.record.options.tabBarGutter || null,
                      type: _vm.record.options.type,
                      size: _vm.record.options.size,
                      tabPosition: _vm.record.options.tabPosition,
                      animated: _vm.record.options.animated,
                    },
                  },
                  _vm._l(_vm.record.columns, function (tabItem, index) {
                    return _c(
                      "a-tab-pane",
                      { key: index, attrs: { tab: tabItem.label } },
                      [
                        _c(
                          "div",
                          { staticClass: "grid-col" },
                          [
                            _c(
                              "draggable",
                              _vm._b(
                                {
                                  staticClass: "draggable-box",
                                  attrs: { tag: "div" },
                                  on: {
                                    start: function ($event) {
                                      return _vm.$emit(
                                        "dragStart",
                                        $event,
                                        tabItem.list
                                      )
                                    },
                                    add: function ($event) {
                                      return _vm.$emit(
                                        "handleColAdd",
                                        $event,
                                        tabItem.list
                                      )
                                    },
                                  },
                                  model: {
                                    value: tabItem.list,
                                    callback: function ($$v) {
                                      _vm.$set(tabItem, "list", $$v)
                                    },
                                    expression: "tabItem.list",
                                  },
                                },
                                "draggable",
                                {
                                  group: "form-draggable",
                                  ghostClass: "moving",
                                  animation: 180,
                                  handle: ".drag-move",
                                },
                                false
                              ),
                              [
                                _c(
                                  "transition-group",
                                  {
                                    staticClass: "list-main",
                                    attrs: { tag: "div", name: "list" },
                                  },
                                  _vm._l(tabItem.list, function (item) {
                                    return _c("layoutItem", {
                                      key: item.key,
                                      staticClass: "drag-move",
                                      attrs: {
                                        selectItem: _vm.selectItem,
                                        startType: _vm.startType,
                                        insertAllowedType:
                                          _vm.insertAllowedType,
                                        record: item,
                                        hideModel: _vm.hideModel,
                                        config: _vm.config,
                                      },
                                      on: {
                                        "update:selectItem": function ($event) {
                                          _vm.selectItem = $event
                                        },
                                        "update:select-item": function (
                                          $event
                                        ) {
                                          _vm.selectItem = $event
                                        },
                                        handleSelectItem: _vm.handleSelectItem,
                                        handleColAdd: _vm.handleColAdd,
                                        handleCopy: function ($event) {
                                          return _vm.$emit("handleCopy")
                                        },
                                        handleShowRightMenu:
                                          _vm.handleShowRightMenu,
                                        handleDelete: function ($event) {
                                          return _vm.$emit("handleDelete")
                                        },
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "copy",
                    class:
                      _vm.record.key === _vm.selectItem.key
                        ? "active"
                        : "unactivated",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("handleCopy")
                      },
                    },
                  },
                  [_c("a-icon", { attrs: { type: "copy" } })],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "delete",
                    class:
                      _vm.record.key === _vm.selectItem.key
                        ? "active"
                        : "unactivated",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("handleDelete")
                      },
                    },
                  },
                  [_c("a-icon", { attrs: { type: "delete" } })],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.record.type === "grid"
        ? [
            _c(
              "div",
              {
                staticClass: "grid-box",
                class: { active: _vm.record.key === _vm.selectItem.key },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleSelectItem(_vm.record)
                  },
                },
              },
              [
                _c(
                  "a-row",
                  {
                    staticClass: "grid-row",
                    attrs: { gutter: _vm.record.options.gutter },
                  },
                  _vm._l(_vm.record.columns, function (colItem, idnex) {
                    return _c(
                      "a-col",
                      {
                        key: idnex,
                        staticClass: "grid-col",
                        attrs: { span: colItem.span || 0 },
                      },
                      [
                        _c(
                          "draggable",
                          _vm._b(
                            {
                              staticClass: "draggable-box",
                              attrs: { tag: "div" },
                              on: {
                                start: function ($event) {
                                  return _vm.$emit(
                                    "dragStart",
                                    $event,
                                    colItem.list
                                  )
                                },
                                add: function ($event) {
                                  return _vm.$emit(
                                    "handleColAdd",
                                    $event,
                                    colItem.list
                                  )
                                },
                              },
                              model: {
                                value: colItem.list,
                                callback: function ($$v) {
                                  _vm.$set(colItem, "list", $$v)
                                },
                                expression: "colItem.list",
                              },
                            },
                            "draggable",
                            {
                              group: "form-draggable",
                              ghostClass: "moving",
                              animation: 180,
                              handle: ".drag-move",
                            },
                            false
                          ),
                          [
                            _c(
                              "transition-group",
                              {
                                staticClass: "list-main",
                                attrs: { tag: "div", name: "list" },
                              },
                              _vm._l(colItem.list, function (item) {
                                return _c("layoutItem", {
                                  key: item.key,
                                  staticClass: "drag-move",
                                  attrs: {
                                    selectItem: _vm.selectItem,
                                    startType: _vm.startType,
                                    insertAllowedType: _vm.insertAllowedType,
                                    record: item,
                                    hideModel: _vm.hideModel,
                                    config: _vm.config,
                                  },
                                  on: {
                                    "update:selectItem": function ($event) {
                                      _vm.selectItem = $event
                                    },
                                    "update:select-item": function ($event) {
                                      _vm.selectItem = $event
                                    },
                                    handleSelectItem: _vm.handleSelectItem,
                                    handleColAdd: _vm.handleColAdd,
                                    handleCopy: function ($event) {
                                      return _vm.$emit("handleCopy")
                                    },
                                    handleShowRightMenu:
                                      _vm.handleShowRightMenu,
                                    handleDelete: function ($event) {
                                      return _vm.$emit("handleDelete")
                                    },
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "copy",
                    class:
                      _vm.record.key === _vm.selectItem.key
                        ? "active"
                        : "unactivated",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("handleCopy")
                      },
                    },
                  },
                  [_c("a-icon", { attrs: { type: "copy" } })],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "delete",
                    class:
                      _vm.record.key === _vm.selectItem.key
                        ? "active"
                        : "unactivated",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("handleDelete")
                      },
                    },
                  },
                  [_c("a-icon", { attrs: { type: "delete" } })],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.record.type === "card"
        ? [
            _c(
              "div",
              {
                staticClass: "grid-box",
                class: { active: _vm.record.key === _vm.selectItem.key },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleSelectItem(_vm.record)
                  },
                },
              },
              [
                _c(
                  "a-card",
                  {
                    staticClass: "grid-row",
                    attrs: { title: _vm.record.label },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "grid-col" },
                      [
                        _c(
                          "draggable",
                          _vm._b(
                            {
                              staticClass: "draggable-box",
                              attrs: { tag: "div" },
                              on: {
                                start: function ($event) {
                                  return _vm.$emit(
                                    "dragStart",
                                    $event,
                                    _vm.record.list
                                  )
                                },
                                add: function ($event) {
                                  return _vm.$emit(
                                    "handleColAdd",
                                    $event,
                                    _vm.record.list
                                  )
                                },
                              },
                              model: {
                                value: _vm.record.list,
                                callback: function ($$v) {
                                  _vm.$set(_vm.record, "list", $$v)
                                },
                                expression: "record.list",
                              },
                            },
                            "draggable",
                            {
                              group: "form-draggable",
                              ghostClass: "moving",
                              animation: 180,
                              handle: ".drag-move",
                            },
                            false
                          ),
                          [
                            _c(
                              "transition-group",
                              {
                                staticClass: "list-main",
                                attrs: { tag: "div", name: "list" },
                              },
                              _vm._l(_vm.record.list, function (item) {
                                return _c("layoutItem", {
                                  key: item.key,
                                  staticClass: "drag-move",
                                  attrs: {
                                    selectItem: _vm.selectItem,
                                    startType: _vm.startType,
                                    insertAllowedType: _vm.insertAllowedType,
                                    record: item,
                                    hideModel: _vm.hideModel,
                                    config: _vm.config,
                                  },
                                  on: {
                                    "update:selectItem": function ($event) {
                                      _vm.selectItem = $event
                                    },
                                    "update:select-item": function ($event) {
                                      _vm.selectItem = $event
                                    },
                                    handleSelectItem: _vm.handleSelectItem,
                                    handleColAdd: _vm.handleColAdd,
                                    handleCopy: function ($event) {
                                      return _vm.$emit("handleCopy")
                                    },
                                    handleShowRightMenu:
                                      _vm.handleShowRightMenu,
                                    handleDelete: function ($event) {
                                      return _vm.$emit("handleDelete")
                                    },
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "copy",
                    class:
                      _vm.record.key === _vm.selectItem.key
                        ? "active"
                        : "unactivated",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("handleCopy")
                      },
                    },
                  },
                  [_c("a-icon", { attrs: { type: "copy" } })],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "delete",
                    class:
                      _vm.record.key === _vm.selectItem.key
                        ? "active"
                        : "unactivated",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("handleDelete")
                      },
                    },
                  },
                  [_c("a-icon", { attrs: { type: "delete" } })],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm.record.type === "table"
        ? [
            _c(
              "div",
              {
                staticClass: "table-box",
                class: { active: _vm.record.key === _vm.selectItem.key },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleSelectItem(_vm.record)
                  },
                },
              },
              [
                _c(
                  "table",
                  {
                    staticClass: "table-layout kk-table-9136076486841527",
                    class: {
                      bright: _vm.record.options.bright,
                      small: _vm.record.options.small,
                      bordered: _vm.record.options.bordered,
                    },
                    style:
                      "width:" +
                      _vm.record.options.width +
                      ";" +
                      _vm.record.options.customStyle,
                  },
                  _vm._l(_vm.record.trs, function (trItem, trIndex) {
                    return _c(
                      "tr",
                      { key: trIndex },
                      _vm._l(trItem.tds, function (tdItem, tdIndex) {
                        return _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: tdItem.colspan && tdItem.rowspan,
                                expression: "tdItem.colspan && tdItem.rowspan",
                              },
                            ],
                            key: tdIndex,
                            staticClass: "table-td",
                            attrs: {
                              colspan: tdItem.colspan,
                              rowspan: tdItem.rowspan,
                            },
                            on: {
                              contextmenu: function ($event) {
                                $event.preventDefault()
                                return _vm.$emit(
                                  "handleShowRightMenu",
                                  $event,
                                  _vm.record,
                                  trIndex,
                                  tdIndex
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "draggable",
                              _vm._b(
                                {
                                  staticClass: "draggable-box",
                                  attrs: { tag: "div" },
                                  on: {
                                    start: function ($event) {
                                      return _vm.$emit(
                                        "dragStart",
                                        $event,
                                        tdItem.list
                                      )
                                    },
                                    add: function ($event) {
                                      return _vm.$emit(
                                        "handleColAdd",
                                        $event,
                                        tdItem.list
                                      )
                                    },
                                  },
                                  model: {
                                    value: tdItem.list,
                                    callback: function ($$v) {
                                      _vm.$set(tdItem, "list", $$v)
                                    },
                                    expression: "tdItem.list",
                                  },
                                },
                                "draggable",
                                {
                                  group: "form-draggable",
                                  ghostClass: "moving",
                                  animation: 180,
                                  handle: ".drag-move",
                                },
                                false
                              ),
                              [
                                _c(
                                  "transition-group",
                                  {
                                    staticClass: "list-main",
                                    style: {
                                      "min-height":
                                        tdItem.rowspan * 80 - 19 + "px",
                                    },
                                    attrs: { tag: "div", name: "list" },
                                  },
                                  _vm._l(tdItem.list, function (item) {
                                    return _c("layoutItem", {
                                      key: item.key,
                                      staticClass: "drag-move",
                                      attrs: {
                                        selectItem: _vm.selectItem,
                                        startType: _vm.startType,
                                        insertAllowedType:
                                          _vm.insertAllowedType,
                                        record: item,
                                        hideModel: _vm.hideModel,
                                        config: _vm.config,
                                      },
                                      on: {
                                        "update:selectItem": function ($event) {
                                          _vm.selectItem = $event
                                        },
                                        "update:select-item": function (
                                          $event
                                        ) {
                                          _vm.selectItem = $event
                                        },
                                        handleSelectItem: _vm.handleSelectItem,
                                        handleColAdd: _vm.handleColAdd,
                                        handleCopy: function ($event) {
                                          return _vm.$emit("handleCopy")
                                        },
                                        handleShowRightMenu:
                                          _vm.handleShowRightMenu,
                                        handleDelete: function ($event) {
                                          return _vm.$emit("handleDelete")
                                        },
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  {
                    staticClass: "copy",
                    class:
                      _vm.record.key === _vm.selectItem.key
                        ? "active"
                        : "unactivated",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("handleCopy")
                      },
                    },
                  },
                  [_c("a-icon", { attrs: { type: "copy" } })],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "delete",
                    class:
                      _vm.record.key === _vm.selectItem.key
                        ? "active"
                        : "unactivated",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("handleDelete")
                      },
                    },
                  },
                  [_c("a-icon", { attrs: { type: "delete" } })],
                  1
                ),
              ]
            ),
          ]
        : [
            _c("formNode", {
              key: _vm.record.key,
              attrs: {
                selectItem: _vm.selectItem,
                record: _vm.record,
                config: _vm.config,
                hideModel: _vm.hideModel,
              },
              on: {
                "update:selectItem": function ($event) {
                  _vm.selectItem = $event
                },
                "update:select-item": function ($event) {
                  _vm.selectItem = $event
                },
                handleSelectItem: _vm.handleSelectItem,
                handleCopy: function ($event) {
                  return _vm.$emit("handleCopy")
                },
                handleDelete: function ($event) {
                  return _vm.$emit("handleDelete")
                },
                handleShowRightMenu: function ($event) {
                  return _vm.$emit("handleShowRightMenu")
                },
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
// with polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/";
import i18n from "./locales";
import { VueAxios } from "./utils/request";
import ProLayout, { PageHeaderWrapper } from "@ant-design-vue/pro-layout";
import "ant-design-vue/dist/antd.css";
import themePluginConfig from "../config/themePluginConfig";


// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock'

import bootstrap from "./core/bootstrap";
import "./core/lazy_use"; // use lazy load components
import "./permission"; // permission control
import "./utils/filter"; // global filter
import "./global.less"; // global style
import * as echarts from "echarts";
// 注：useComponents 需放最上面，优先注册懒加载组件
import KFormDesign from './components/KFormDesign/packages/index.js';
import './components/KFormDesign/styles/form-design.less';
import warterMark from './utils/waterMark';
// router.afterEach((item) => {
//   if (item.name !== 'login') {
//     warterMark.set("水印")
//   }
// })
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
// useAntd(Vue);
Vue.use(KFormDesign);
// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios);
// use pro-layout components
Vue.component("pro-layout", ProLayout);
Vue.component("page-container", PageHeaderWrapper);
Vue.component("page-header-wrapper", PageHeaderWrapper);
import { FormModel,Pagination,Empty } from "ant-design-vue";
Vue.use(FormModel);
Vue.use(Pagination);
Vue.use(Empty);
window.umi_plugin_ant_themeVar = themePluginConfig.theme;

new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex, Logo message
  created: bootstrap,
  render: (h) => h(App),
}).$mount("#app");

import router from "./router";
import store from "./store";
import storage from "store";
import NProgress from "nprogress"; // progress bar
import "@/components/NProgress/nprogress.less"; // progress bar custom style
import notification from "ant-design-vue/es/notification";
import { setDocumentTitle, domTitle } from "@/utils/domUtil";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { i18nRender } from "@/locales";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const allowList = ["login", "apply", "applyResult", "meetingDetail","pcbreports","cclreports","reports"]; // no redirect allowList
const loginRoutePath = "/user/login";
const defaultRoutePath = "/index";

router.beforeEach((to, from, next) => {
  NProgress.start(); // start progress bar
  to.meta &&
    typeof to.meta.title !== "undefined" &&
    setDocumentTitle(`${i18nRender(to.meta.title)} - ${domTitle}`);
  /* has token */
  if (storage.get(ACCESS_TOKEN)) {
    if (to.path === loginRoutePath) {
      next({ path: defaultRoutePath });
      NProgress.done();
    } else {
      // check login user.roles is null
      if (store.getters.roles.length === 0) {
        // request login userInfo

        store
          .dispatch("GetInfo")
          .then((res) => {
            if(res.code==401){
              store.dispatch("Logout").then(() => {
                next({
                  path: loginRoutePath,
                  query: { redirect: to.fullPath },
                });
              });
            }
            const roles = res.data && res.data.sysRoleVOS[0];
            roles.permissions = res.data.sysRoleVOS[0].menus;
            if (res.data.companyId) { 
              localStorage.setItem("companyId", res.data.companyId);
              localStorage.setItem("userName", res.data.username);
            }
            // generate dynamic router
            store.dispatch("GenerateRoutes", { roles }).then(() => {
              // 根据roles权限生成可访问的路由表
              // 动态添加可访问路由表
              // VueRouter@3.5.0+ New API
              store.getters.addRouters.forEach((r) => {
                router.addRoute(r);
              });
              // 请求带有 redirect 重定向时，登录自动重定向到该地址
              let redirect = decodeURIComponent(
                from.query.redirect || to.path
              );
              // store.dispatch('openCompanyTips').then(res => {
              //   console.log("res查询提示企业信息", res)
              //   let resData = res.data;
              //   if(resData.whetherRemind){
              //     redirect = '/dashboard/workplace'
              //   }
              //   if (to.path === redirect) {
              //     // set the replace: true so the navigation will not leave a history record
              //     next({ ...to, replace: true,query:{tipsCompany:resData.whetherRemind?resData:''} });
              //   } else {
              //     // 跳转到目的路由
              //     next({ path: redirect,query:{tipsCompany:resData.whetherRemind?resData:''} });
              //   }
              // })
              if (to.path === redirect) {
                // set the replace: true so the navigation will not leave a history record
                next({ ...to, replace: true });
              } else {
                // 跳转到目的路由
                next({ path: redirect });
              }
            });
          })
          .catch((err) => {
            if (
              err.status == 401 ||
              err.code == 401 ||
              (err.data && err.data.code == 401)
            ) {
              notification.error({
                message: "错误",
                description: "登录超时，请重新登录！",
              });
            } else {
              notification.error({
                message: "错误",
                description: "请求用户信息失败，请重试",
              });
            }
            // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
            store.dispatch("Logout").then(() => {
              next({ path: loginRoutePath, query: { redirect: to.fullPath } });
            });
          });
      } else {
        next();
      }
    }
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next();
    } else {
      next({ path: loginRoutePath, query: { redirect: to.fullPath } });
      NProgress.done(); // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
});

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});

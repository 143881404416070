var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-drawer",
    {
      attrs: {
        title: "发送邮件详情",
        width: "800",
        placement: "right",
        closable: true,
        visible: _vm.viewVisible,
        maskClosable: true,
      },
      on: {
        close: () => {
          _vm.$emit("closeDetail")
        },
      },
    },
    [
      _c(
        "a-descriptions",
        { attrs: { title: "", column: 1 } },
        [
          _c(
            "a-descriptions-item",
            { attrs: { label: "接收单位" } },
            [
              _vm.mail.companyIds
                ? _vm._l(JSON.parse(_vm.mail.companyIds), function (companyId) {
                    return _c("a-tag", { key: companyId }, [
                      _vm._v(
                        _vm._s(
                          _vm.findLabelByValue(companyId, _vm.companyOptions)
                        )
                      ),
                    ])
                  })
                : _vm._e(),
            ],
            2
          ),
          _c(
            "a-descriptions-item",
            { attrs: { label: "对应标签" } },
            [
              _vm.mail.tagIds
                ? _vm._l(JSON.parse(_vm.mail.tagIds), function (tagId) {
                    return _c("a-tag", { key: tagId }, [
                      _vm._v(
                        _vm._s(_vm.findLabelByValue(tagId, _vm.tagOptions))
                      ),
                    ])
                  })
                : _vm._e(),
            ],
            2
          ),
          _vm.mail.templateId
            ? _c("a-descriptions-item", { attrs: { label: "邮件模板" } }, [
                _vm._v(
                  _vm._s(
                    _vm.findLabelByValue(
                      _vm.mail.templateId,
                      _vm.mailTemplateOptions
                    )
                  )
                ),
              ])
            : _vm._e(),
          !_vm.mail.templateId
            ? [
                _c("a-descriptions-item", { attrs: { label: "主题" } }, [
                  _vm._v(_vm._s(_vm.mail.title)),
                ]),
                _c("a-descriptions-item", { attrs: { label: "内容" } }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.mail.content) },
                  }),
                ]),
              ]
            : _vm._e(),
          _c(
            "a-descriptions-item",
            { attrs: { label: "附件" } },
            [
              _vm.mail.attachments
                ? _vm._l(
                    JSON.parse(_vm.mail.attachments),
                    function (attachment) {
                      return _c(
                        "a",
                        {
                          key: attachment.url,
                          attrs: { href: _vm.baseUrl + attachment.url },
                        },
                        [_vm._v(_vm._s(attachment.fileName))]
                      )
                    }
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
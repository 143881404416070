<template>
  <div class="form-continer">
    <a-steps :current="current" size="small" style="margin-bottom:20px;" v-if="formMode != 'view'">
      <a-step :title="item.name" v-for="(item,index) in step" :key="index" />
    </a-steps>
    <pcbReports v-if="enterpriseBusinessType == 1" :enterpriseBusinessType="enterpriseBusinessType"  :current="current" @childsubmit="childsubmit" @savell="savell" @tonext="tonext" ref="pcbreports" :annualform="annualform" :formMode="formMode" />
    <cclReports v-if="enterpriseBusinessType == 2" :enterpriseBusinessType="enterpriseBusinessType"  :current="current" ref="cclreports" @childsubmit="childsubmit" @savell="savell" @tonext="tonext" :annualform="annualform" :formMode="formMode" />
    <dataReports v-if="enterpriseBusinessType == 3||enterpriseBusinessType == 4||enterpriseBusinessType == 5||enterpriseBusinessType == 6" :enterpriseBusinessType="enterpriseBusinessType"  :current="current" ref="datareports" @childsubmit="childsubmit" @savell="savell" @tonext="tonext" :annualform="annualform" :formMode="formMode"></dataReports>
    <div class="step" v-show="false">
      <a-button class="tag-orange" @click="save"> 存入草稿 </a-button>
      <a-button type="primary" @click="takeout"> 取出数据 </a-button>
      <a-button @click="prvestep" v-if="current != 0"> 上一步 </a-button>
      <a-button @click="nextstep" type="primary" v-if="step && current != step.length -1 "> 下一步 </a-button>
    </div>
  </div>
</template>

<script>
import pcbReports from '../pcbReports/index.vue'
import cclReports from '../cclReports/index.vue'
import dataReports from '../specialReports/index.vue'
import { APIStatus, handleStep } from '../Business.js'
export default {
  name: 'MasFrontendContainer',
  components: {
    pcbReports,
    cclReports,
    dataReports,
  },
  props: {
    annualform: {
      type: Object,
    },
    formMode: {
      type: String,
    },
    current: {
      type: [Number, String],
    },
    enterpriseBusinessType: {
      type: [Number, String],
    },
  },
  data() {
    return {
      step: [],
      formarr:[
        {
          type:1,
          ref:'pcbreports'
        },
        {
          type:2,
          ref:'cclreports'
        },
        {
          type:3,
          ref:'datareports'
        },
        {
          type:4,
          ref:'datareports'
        },
        {
          type:5,
          ref:'datareports'
        },
        {
          type:6,
          ref:'datareports'
        },
      ]
    }
  },
  watch: {
    enterpriseBusinessType: {
      immediate: true,
      handler(val) {
        this.step = handleStep(val)
      },
    },
  },
  mounted() {},

  methods: {
    childsubmit(data,type) {
      this.$emit('childsubmit', data,type)
    },
    save() {
      const findForm = this.formarr.find(item => item.type == this.enterpriseBusinessType)
      console.log(findForm,'findForm')
      if(findForm){
        this.$refs[findForm.ref].saveDraft()
      }
    },
    savell(type,data) {
      this.$emit('savedraft',type,data);
    },
    takeout() {
      this.$emit('outdraft')
    },

    prvestep() {
      this.verificationpassed(this.enterpriseBusinessType,this.current,'prve')
    },
    nextstep() {
      console.log(this.formMode,'formMode')
      this.verificationpassed(this.enterpriseBusinessType,this.current,'next')
    },

    tonext(current) {
      this.$emit('tonext',current)
    },

    //判断用户点击是哪个申报表格的下一步校验在通过
    verificationpassed(pcbType,current,direction = 'next') {
      const {pcbreports,cclreports,datareports} = this.$refs
      const verification = [
        {
          type:'1',
          current:0,
          ref:'enterprise',
          component:pcbreports
        },
        {
          type:'1',
          current:1,
          ref:'Proportion',
          component:pcbreports
        },
        {
          type:'1',
          current:2,
          ref:'pcbtable',
          component:pcbreports
        },
        {
          type:'1',
          current:3,
          ref:'production',
          component:pcbreports
        },
        {
          type:'2',
          current:0,
          ref:'enterprise',
          component:cclreports,
        },
        {
          type:'2',
          current:1,
          ref:'pcbtable',
          component:cclreports,
        },
        {
          type:'2',
          current:2,
          ref:'production',
          component:cclreports,
        },
        {
          type:'3',
          current:0,
          ref:'enterprise',
          component:datareports,
        },
        {
          type:'3',
          current:1,
          ref:'pcbtable',
          component:datareports,
        },
        {
          type:'4',
          current:0,
          ref:'enterprise',
          component:datareports,
        },
        {
          type:'4',
          current:1,
          ref:'pcbtable',
          component:datareports,
        },
        {
          type:'5',
          current:0,
          ref:'enterprise',
          component:datareports,
        },
        {
          type:'5',
          current:1,
          ref:'pcbtable',
          component:datareports,
        },
        {
          type:'6',
          current:0,
          ref:'enterprise',
          component:datareports,
        },
        {
          type:'6',
          current:1,
          ref:'pcbtable',
          component:datareports,
        },
      ]
      const findstep = verification.find(item => {
        return item.type == pcbType && item.current == current
      })

      console.log(pcbType,'pcbType')
      console.log(current, 'current')
      console.log("findstep",findstep);
      if(findstep) {
        // console.log(findstep,'findstep')
        findstep.component.$refs[findstep.ref].submit(findstep,direction)
      }
    }


  },
}
</script>

<style lang="less" scoped>
.step{
  text-align: center;
  .ant-btn{
    margin-right: 20px;
  }
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "a-form",
        {
          ref: "formLogin",
          staticClass: "user-layout-login",
          attrs: { id: "formLogin", form: _vm.form },
          on: { submit: _vm.handleSubmit },
        },
        [
          _vm.isLoginError
            ? _c("a-alert", {
                staticStyle: { "margin-bottom": "24px" },
                attrs: {
                  type: "error",
                  showIcon: "",
                  message: _vm.$t("user.login.message-invalid-credentials"),
                },
              })
            : _vm._e(),
          _c(
            "a-form-item",
            [
              _c(
                "a-input",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "username",
                        {
                          rules: [
                            {
                              required: true,
                              message: _vm.$t("user.userName.required"),
                            },
                          ],
                        },
                      ],
                      expression:
                        "['username',\n              {rules: [{ required: true, message: $t('user.userName.required') }]}]",
                    },
                  ],
                  attrs: {
                    size: "large",
                    type: "text",
                    placeholder: _vm.$t("user.login.username.placeholder"),
                  },
                },
                [
                  _c("a-icon", {
                    style: { color: "rgba(0,0,0,.25)" },
                    attrs: { slot: "prefix", type: "user" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            { staticStyle: { "padding-bottom": "0" } },
            [
              _c(
                "a-input-password",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "password",
                        {
                          rules: [
                            {
                              required: true,
                              message: _vm.$t("user.password.required"),
                            },
                          ],
                          validateTrigger: "blur",
                        },
                      ],
                      expression:
                        "['password',{rules: [{ required: true, message: $t('user.password.required') }], validateTrigger: 'blur'}]",
                    },
                  ],
                  attrs: {
                    size: "large",
                    placeholder: _vm.$t("user.login.password.placeholder"),
                  },
                  on: {
                    blur: function ($event) {
                      _vm.bigChar = false
                    },
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      return _vm.onkeyup.apply(null, arguments)
                    },
                    keydown: function ($event) {
                      return _vm.onkeydown.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("a-icon", {
                    style: { color: "rgba(0,0,0,.25)" },
                    attrs: { slot: "prefix", type: "lock" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.bigChar,
                      expression: "bigChar",
                    },
                  ],
                  staticStyle: {
                    "font-size": "12px",
                    color: "#5C8D66",
                    margin: "0 0 0 10px",
                    padding: "0",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("user.login.prompt.CapsLock")))]
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            [
              _c(
                "a-checkbox",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: ["rememberMe", { valuePropName: "checked" }],
                      expression:
                        "['rememberMe', { valuePropName: 'checked' }]",
                    },
                  ],
                },
                [_vm._v(_vm._s(_vm.$t("user.login.remember-me")))]
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            { staticStyle: { "margin-top": "24px" } },
            [
              _c(
                "a-button",
                {
                  staticClass: "login-button",
                  attrs: {
                    size: "large",
                    type: "primary",
                    htmlType: "submit",
                    loading: _vm.state.loginBtn,
                    disabled: _vm.state.loginBtn,
                  },
                },
                [_vm._v(_vm._s(_vm.$t("user.login.login")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "user-login-tips",
          staticStyle: { "margin-top": "20px", "font-size": "13px" },
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("user.login.prompt")) + " "),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.$t("user.login.prompt.begin")) + " "),
          _c(
            "a",
            {
              staticClass: "isa",
              staticStyle: { "text-decoration": "underline" },
              attrs: { href: "https://login.cpca.org.cn" },
            },
            [_vm._v("CPCA " + _vm._s(_vm.$t("user.login.prompt.among")))]
          ),
          _vm._v(" " + _vm._s(_vm.$t("user.login.prompt.end")) + " "),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import antd from 'ant-design-vue/es/locale-provider/zh_CN'
import momentCN from 'moment/locale/zh-cn'
import global from './zh-CN/global'


import menu from './zh-CN/menu'
import user from './zh-CN/user'

import dashboard from './zh-CN/dashboard'
import account from './zh-CN/account'
import reports from "./zh-CN/reports/reports";
import quarter from "./zh-CN/quarter";

const components = {
  antLocale: antd,
  momentName: 'zh-cn',
  momentLocale: momentCN
}

export default {
  message: "-",
  "layouts.usermenu.dialog.title": "提示",
  "layouts.usermenu.dialog.content": "您确定要退出吗？",
  ...components,
  ...global,
  ...menu,
  ...user,
  ...dashboard,
  ...account,
  ...reports,
  ...quarter,
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reports" },
    [
      _c(
        "a-card",
        { staticClass: "noborder top-header" },
        [
          _c("ReportsHeader", {
            attrs: { timeIntervalLable: "年度" },
            on: { search: _vm.headerSearch },
          }),
        ],
        1
      ),
      _c(
        "a-card",
        { staticClass: "noborder bottom-card", attrs: { title: "信息列表" } },
        [
          _c(
            "div",
            { attrs: { slot: "extra" }, slot: "extra" },
            [
              _vm.isAdmin
                ? _c(
                    "a-radio-group",
                    {
                      attrs: { "default-value": 0 },
                      model: {
                        value: _vm.sendType,
                        callback: function ($$v) {
                          _vm.sendType = $$v
                        },
                        expression: "sendType",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v(" 邮箱 "),
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v(" 短信 "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin
                ? _c(
                    "a-button",
                    {
                      staticClass: "add",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.allSend("", "allSend")
                        },
                      },
                    },
                    [_vm._v("全部催报")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              rowKey: (record) => record.id,
              "row-selection": _vm.rowSelection,
              "data-source": _vm.data,
              pagination: _vm.pagination,
              scroll: { x: true },
            },
            on: { change: _vm.tableChange },
            scopedSlots: _vm._u(
              [
                {
                  key: "type",
                  fn: function (text) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "hzzz",
                          attrs: { title: _vm.handleText(text) },
                        },
                        [_vm._v(" " + _vm._s(_vm.handleText(text)))]
                      ),
                    ]
                  },
                },
                {
                  key: "reportStatus",
                  fn: function (text) {
                    return [
                      _c("div", { staticClass: "hzzz" }, [
                        _vm._v(" " + _vm._s(_vm.handleStatus(text)) + " "),
                      ]),
                    ]
                  },
                },
                {
                  key: "enterpriseBusinessType",
                  fn: function (text) {
                    return [
                      text == 0 ? [_c("span", [_vm._v("未选")])] : _vm._e(),
                      text == 1
                        ? [_c("span", [_vm._v("印制线路板")])]
                        : _vm._e(),
                      text == 2 ? [_c("span", [_vm._v("覆铜板")])] : _vm._e(),
                      text == 3 ? [_c("span", [_vm._v("专用材料")])] : _vm._e(),
                      text == 4
                        ? [_c("span", [_vm._v("专用化学品")])]
                        : _vm._e(),
                      text == 5
                        ? [_c("span", [_vm._v("专用设备与仪器")])]
                        : _vm._e(),
                      text == 6 ? [_c("span", [_vm._v("环保洁净")])] : _vm._e(),
                    ]
                  },
                },
                {
                  key: "alertStatusYear",
                  fn: function (text) {
                    return _vm.isAdmin
                      ? _c(
                          "div",
                          {},
                          [
                            text == 0
                              ? [
                                  _c(
                                    "span",
                                    { staticClass: "tblock tag-red" },
                                    [_vm._v("未催")]
                                  ),
                                ]
                              : _vm._e(),
                            text == 1
                              ? [
                                  _c(
                                    "span",
                                    { staticClass: "tblock tag-green" },
                                    [_vm._v("已催")]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e()
                  },
                },
                {
                  key: "caozuo",
                  fn: function (text, record) {
                    return [
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        _vm.isAdmin
                          ? _c(
                              "div",
                              [
                                _vm.isAdmin
                                  ? _c("a-icon", {
                                      staticClass: "send-admin",
                                      attrs: { type: "mail" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.send(record, "send", "0")
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.isAdmin
                                  ? _c("a-icon", {
                                      staticClass: "send-admin",
                                      attrs: { type: "mobile" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.send(record, "send", "1")
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                record.type != 0
                                  ? _c("a-icon", {
                                      staticClass: "send-admin",
                                      attrs: {
                                        type: "file-search",
                                        alt: "查看详情",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getFormDetail(record)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _c("a-icon", {
                                  staticClass: "send-admin",
                                  attrs: { type: "form", alt: "修改" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editForm(record, "edit")
                                    },
                                  },
                                }),
                                _vm.isAdmin
                                  ? _c("a-icon", {
                                      staticClass: "send-admin",
                                      attrs: { type: "rollback", alt: "撤销" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.upstatus(record)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.isAdmin
                                  ? _c("a-icon", {
                                      staticClass: "send-admin",
                                      attrs: { type: "delete" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(record)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("div", [
                              record.type != 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "send",
                                      on: {
                                        click: function ($event) {
                                          return _vm.getFormDetail(record)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("reports.operate.View")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticClass: "send",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editForm(record, "edit")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("reports.operate.Edit")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                      ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: "65%",
            title: "上传文件",
            maskClosable: false,
            visible: _vm.uploadVisible,
          },
          on: { cancel: _vm.uploadHandleCancel },
        },
        [
          _c(
            "a-upload",
            {
              attrs: {
                "file-list": _vm.fileList,
                remove: _vm.handleRemove,
                action: _vm.baseUrl + "system-file/file/upload/file",
                headers: _vm.headers,
                "before-upload": _vm.beforeUpload,
              },
              on: { change: _vm.logoUploadChange },
            },
            [
              _c(
                "a-button",
                [
                  _c("a-icon", { attrs: { type: "upload" } }),
                  _vm._v(" 选择文件 "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                { key: "back", on: { click: _vm.uploadHandleCancel } },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleUpload },
                },
                [_vm._v(" 上传 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: "30%",
            title: "年度选择",
            maskClosable: false,
            visible: _vm.quarterSelectVisible,
          },
          on: {
            ok: _vm.quarterSelectHandleOk,
            cancel: _vm.quarterSelectHandleCancel,
          },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "quarterSelectRuleForm",
              attrs: {
                model: _vm.quarterSelectForm,
                rules: _vm.quarterSelectFormRules,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("quarter.year"), prop: "year" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择年度" },
                      model: {
                        value: _vm.quarterSelectForm.year,
                        callback: function ($$v) {
                          _vm.$set(_vm.quarterSelectForm, "year", $$v)
                        },
                        expression: "quarterSelectForm.year",
                      },
                    },
                    _vm._l(_vm.yearSelectList, function (item) {
                      return _c(
                        "a-select-option",
                        { key: item.key, attrs: { value: item.key } },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.basicCompanyss
        ? _c("basicCompany", {
            ref: "basicCompanys",
            attrs: {
              row: _vm.row,
              childForm: _vm.annualform.enterpriseInfo,
              formMode: _vm.formMode,
            },
            on: {
              search: _vm.headerSearch,
              basicCompanysClose: _vm.basicCompanysClose,
              editForm: _vm.editForm,
              tonext: _vm.tonext,
            },
          })
        : _vm._e(),
      _c("choicebusiness", {
        ref: "choice",
        attrs: { business: _vm.row, formMode: _vm.formMode },
        on: { switchForm: _vm.switchForm },
      }),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.yearvisible,
            width: "85%",
            wrapClassName: "yearModal",
          },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c("FormContainer", {
            ref: "FormContainer",
            attrs: {
              current: _vm.stepcurrent,
              enterpriseBusinessType: _vm.enterpriseBusinessType,
              formMode: _vm.formMode,
              annualform: _vm.annualform,
            },
            on: {
              childsubmit: _vm.childsubmit,
              tonext: _vm.tonext,
              savedraft: _vm.savedraft,
            },
          }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                { key: "back1", on: { click: _vm.handleCancel } },
                [_vm._v(" 取消 ")]
              ),
              _vm.stepcurrent != 0
                ? _c("a-button", { key: "prve", on: { click: _vm.prvestep } }, [
                    _vm._v(" 上一步 "),
                  ])
                : _vm._e(),
              _vm.stepcurrent != _vm.steparr.length - 1 &&
              _vm.formMode != "view"
                ? _c(
                    "a-button",
                    {
                      key: "next",
                      attrs: { type: "primary" },
                      on: { click: _vm.nextstep },
                    },
                    [_vm._v(" 下一步 ")]
                  )
                : _vm._e(),
              _vm.formMode !== "view"
                ? [
                    _c(
                      "a-button",
                      {
                        key: "back2",
                        staticClass: "tag-orange",
                        on: { click: _vm.savedata },
                      },
                      [_vm._v(" 存入草稿 ")]
                    ),
                    _c(
                      "a-button",
                      {
                        key: "back21",
                        attrs: { type: "primary" },
                        on: { click: _vm.takeout },
                      },
                      [_vm._v(" 取出草稿数据 ")]
                    ),
                    _vm.stepcurrent == _vm.steparr.length - 1
                      ? _c(
                          "a-button",
                          {
                            key: "back3",
                            attrs: {
                              type: "primary",
                              loading: _vm.isbtnLoading,
                            },
                            on: { click: _vm.handleok },
                          },
                          [_vm._v(" 保存 ")]
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div :class="wrpCls">
    <select-lang :class="prefixCls" />
    <!-- <a-Popover :content="content" title="站内消息通知" visible-arrow="false"	>
      <a-Button type="link">
        <a>
          <a-badge dot :overflow-count="10">
            <span style="display:inline-block;width:20px;margin-top:3px">
            <a-icon type="bell" theme="twoTone" :style="{ fontSize: '16px' }" />

            </span>
          </a-badge>
        </a>
      </a-Button>
    </a-Popover> -->
    
    <avatar-dropdown :menu="showMenu" :current-user="currentUser" :class="prefixCls" />


  </div>
</template>

<script>
import AvatarDropdown from './AvatarDropdown'
import News from '@/components/GlobalHeader/News.vue'
import SelectLang from '@/components/SelectLang'

export default {
  name: 'RightContent',
  components: {
    AvatarDropdown,
    SelectLang,
    News
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action'
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    topMenu: {
      type: Boolean,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showMenu: true,
      currentUser: {},
      content : (
        <News></News>
      ),
      
    }
  },
  computed: {
    wrpCls () {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${(this.isMobile || !this.topMenu) ? 'light' : this.theme}`]: true
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.currentUser = this.$store.getters.userInfo;
    }, 0)



  }
}
</script>

import request from "@/utils/request";

const menuApi = {
  Add: "/system-mms-user/menu/add",
  Update: "/system-mms-user/menu/update",
  Find: "/system-mms-user/menu/find",
  Del: "/system-mms-user/menu/delete",
};

//添加
export function addMenu(parameter) {
  return request({
    url: menuApi.Add,
    method: "post",
    data: parameter,
  });
}
//修改
export function updateMenu(parameter) {
  return request({
    url: menuApi.Update,
    method: "post",
    data: parameter,
  });
}
//删除
export function delMenu(parameter) {
  return request({
    url: menuApi.Del,
    method: "post",
    data: parameter,
  });
}
//查询
export function findMenu(parameter) {
  return request({
    url: menuApi.Find,
    method: "post",
    data: parameter
  });
}

<template>
  <a-modal title="发布短信通知" :width="900" :visible="addVisible" :confirmLoading="loading" @ok="() => { $emit('addInfo',sms) }" @cancel="() => { $emit('closeAdd') }">
    <a-spin :spinning="loading">
      <a-form :form="sms" v-bind="formLayout">
        <a-form-item label="接收单位">
          <!-- <a-select style="width:300px" mode="multiple" v-model="sms.companyIds" placeholder="请选择接收单位" :options="companyOptions"/> -->
          <a-select style="width:300px" mode="multiple" v-model="sms.companyIds" placeholder="请选择接收单位"  :filter-option="filterOption"  @search="handleSearch" @blur="selblur" @change="selchange">
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-else :value="item.value" v-for="(item) in selectEventlist" :key="item.value">
            {{item.label}}
          </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="所属标签">
          <a-select style="width:300px" mode="multiple" v-model="sms.tagIds" placeholder="请选择会员标签" :options="tagOptions" />
        </a-form-item>
        <a-form-item label="短信模板">
          <a-select style="width:300px" v-model="sms.code" placeholder="请选择接短信模板" :options="smsTemplateOptions"/>
        </a-form-item>
        <!-- <a-form-item label="短信内容">
          {{smsTemplateOptions.filter(item=>item.value==sms.code)[0].record.templateContent}}
        </a-form-item> -->

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import cloneDeep from 'lodash.clonedeep'
import debounce from 'lodash.debounce'
import filter from "lodash.filter"
export default {
  props: {
    addVisible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    companyOptions: {
      type: Array,
      default: () => []
    },
    tagOptions: {
      type: Array,
      default: () => []
    },
    smsTemplateOptions: {
      type: Array,
      default: () => []
    },
  },
  components: {
    
  },
  data() {
    this.awaitSearch = debounce(this.awaitSearch,750)
    this.formLayout={
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    }
    return {
      sms:{
        companyIds:[],
        tagIds:[],
        code:undefined
      },
      selectEventlist:[],
      fetching:false
    }
  },
  watch:{
    companyOptions:{
      deep:true,
      handler(companyIds){
        let _deepCompanyIds = cloneDeep(companyIds)
        this.selectEventlist = _deepCompanyIds.slice(0,49)
      }
    }
  },
  created() {

  },
  methods:{
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSearch(val) {
      console.log(val,'----')
      this.fetching = true;
      if(val == '') {
        this.selectEventlist = this.sortArr(this.sms.companyIds,this.companyOptions)
        this.fetching = false;
      } 
      this.awaitSearch(val)
    },
    awaitSearch(val) {
      this.selectEventlist = filter(this.companyOptions,item => {
        if(item.label.indexOf(val) != -1) {
          return true
        }
      }).slice(0,49)
      this.fetching = false;
    },
    selblur(e) {
      this.selectEventlist = this.sortArr(this.sms.companyIds,this.companyOptions)
    },
    selchange(e) {
      if(e && e.length <= 0) {
        this.selectEventlist = this.selectEventlist = this.companyOptions.slice(0,49)
      }
    },
    sortArr(findcompany,allcompanyIds) {
        let _deepCompanyIds = cloneDeep(allcompanyIds)
        let findarr = _deepCompanyIds.filter(item => findcompany.includes(item.value));
        // console.log(findarr,'找到了已选中的')
        findarr.push(..._deepCompanyIds.slice(0,49))
        //去重
        let obj = {};
        let peon = findarr.reduce((cur,next) => {
            obj[next.value] ? "" : obj[next.value] = true && cur.push(next);
            return cur;
        },[]) 
          return peon
      }
  }
}
</script>

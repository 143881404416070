import request from "@/utils/request";

const mailTemplateApi = {
  Add: "/system-mms-user/station_letter_template/add",
  Update: "/system-mms-user/station_letter_template/upd",
  Find: "/system-mms-user/station_letter_template/find",
  Delete: "/system-mms-user/station_letter_template/del",
};

//添加
export function addInfoTemplate(parameter) {
  return request({
    url: mailTemplateApi.Add,
    method: "post",
    data: parameter,
  });
}
//修改
export function updateInfoTemplate(parameter) {
  return request({
    url: mailTemplateApi.Update,
    method: "post",
    data: parameter,
  });
}
//查询
export function findInfoTemplates(parameter) {
  return request({
    url: mailTemplateApi.Find,
    method: "post",
    data: parameter,
  });
}
//删除
export function deleteInfoTemplate(parameter) {
  return request({
    url: mailTemplateApi.Delete,
    method: "post",
    data: parameter,
  });
}

import { findDynamicAndId } from '@/api/quarter/filling.js';
//提报表的类型
export const typeBussine = [
  { label: 'PCB', value: '1', style: { backgroundColor: 'red' } },
  { label: '专用材料', value: '2', style: { backgroundColor: 'orange' } },
  { label: '专用化学品', value: '3', style: { backgroundColor: '#677A82' } },
  { label: '专用设备与仪器', value: '4', style: { backgroundColor: 'green' } },
  { label: '环保洁净', value: '5', style: { backgroundColor: 'blue' } },
  { label: 'CCL', value: '6', style: { backgroundColor: 'purple' } },
];

export const findDynamicAndIds = async () => {
  let res = await findDynamicAndId();
  let list = []
  res.data.forEach((item) => {
    list.push({ label: item.title, value: item.id });
  });
  return list;
};

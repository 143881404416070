<template>
  <div class="reports">
    <a-card class="noborder top-header">
      <ReportsHeader @search="headerSearch" :timeIntervalLable="'年度'"></ReportsHeader>
    </a-card>
    <a-card title="信息列表" class="noborder bottom-card">
      <div slot="extra">
        <a-radio-group v-model="sendType" :default-value="0" v-if="isAdmin">
          <a-radio :value="0">
            邮箱
          </a-radio>
          <a-radio :value="1">
            短信
          </a-radio>
        </a-radio-group>
        <a-button class="add" type="primary" @click="allSend('','allSend')" v-if="isAdmin">全部催报</a-button>
        <!-- <a-button class="add" type="primary" @click="addReports" v-if="isAdmin">新增</a-button>
        <a-button type="primary" v-if="isAdmin">导出</a-button> -->
      </div>
      <!-- :row-selection="rowSelection" -->
      <a-table :columns="columns" :rowKey="record => record.id" :row-selection="rowSelection" :data-source="data" :pagination="pagination" @change="tableChange" :scroll="{ x: true }">
        <template slot="type" slot-scope="text">
          <div class="hzzz" :title="handleText(text)"> {{
          handleText(text)
        }}</div>
        </template>
        <template slot="reportStatus" slot-scope="text">
          <div class="hzzz">
            {{handleStatus(text)}}
          </div>
        </template>
        <template slot="enterpriseBusinessType" slot-scope="text">
          <template v-if="text == 0"><span>未选</span></template>
          <template v-if="text == 1"><span>印制线路板</span></template>
          <template v-if="text == 2"><span>覆铜板</span></template>
          <template v-if="text == 3"><span>专用材料</span></template>
          <template v-if="text == 4"><span>专用化学品</span></template>
          <template v-if="text == 5"><span>专用设备与仪器</span></template>
          <template v-if="text == 6"><span>环保洁净</span></template>
        </template>
        <div slot="alertStatusYear" slot-scope="text" v-if="isAdmin">
          <template v-if="text == 0"><span class="tblock tag-red">未催</span></template>
          <template v-if="text == 1"><span class="tblock tag-green">已催</span></template>
        </div>
        <template slot="caozuo" slot-scope="text,record">
          <div style="text-align: center;">
            <div v-if="isAdmin">
              <a-icon type="mail" v-if="isAdmin" @click="send(record,'send','0')" class="send-admin"/>
              <a-icon type="mobile" v-if="isAdmin" @click="send(record,'send','1')" class="send-admin"/>
              <a-icon type="file-search" alt="查看详情" @click="getFormDetail(record)" class="send-admin" v-if="record.type != 0"/>
              <a-icon type="form" alt="修改" @click="editForm(record,'edit')" class="send-admin"/>
              <a-icon type="rollback" @click="upstatus(record)" class="send-admin" alt="撤销" v-if="isAdmin"/>
              <a-icon type="delete" @click="deleteItem(record)" class="send-admin" v-if="isAdmin" />
            </div>
            <div v-else>
              <span class="send" @click="getFormDetail(record)" v-if="record.type != 0">
                {{ $t('reports.operate.View') }}
              </span>
              <span class="send" @click="editForm(record,'edit')">
                {{ $t('reports.operate.Edit') }}
              </span>
            </div>
            
            
            <!-- <span @click="send(record,'send','0')" class="send" v-if="isAdmin">
              {{ $t("reports.operate.MailUrgentReporting") }}
            </span>
            <br>
            <span @click="send(record,'send','1')" class="send" v-if="isAdmin">
              {{ $t('reports.operate.MessageUrgentReporting') }}
            </span>
            <br>
            <span class="send" @click="upstatus(record)" v-if="isAdmin">
              {{ $t('reports.operate.Cancel') }}
            </span>
            <span class="send" @click="deleteItem(record)" v-if="isAdmin">
              {{ $t('reports.operate.Delete') }}
            </span>
            <br>
            <span class="send" @click="getFormDetail(record)" v-if="record.type != 0">
              {{ $t('reports.operate.View') }}
            </span>
            <span class="send" @click="editForm(record,'edit')">
              {{ $t('reports.operate.Edit') }}
            </span> -->
            
            
            <!-- <a-icon type="" v-if="isAdmin" @click="send(record,'send','0')" class="send">邮件催报</a-icon> -->
            <!-- <span v-if="isAdmin" @click="send(record,'send','1')" class="send">短信催报</span> -->
            
            <!-- <a-icon type="download" style="font-size:22px;color:#1890ff;" v-if="!isAdmin" @click="downloads(record)"/>
            <a-icon type="upload" style="font-size:22px;color:#1890ff;" v-if="!isAdmin && record.reportStatus != 3" @click="uploadOpen(record)"/> -->
            
          </div>

        </template>
      </a-table>
    </a-card>
    <a-modal   width="65%" title="上传文件" :maskClosable="false" :visible="uploadVisible" @cancel="uploadHandleCancel">
      <a-upload :file-list="fileList" :remove="handleRemove" :action="baseUrl+'system-file/file/upload/file'" :headers="headers" :before-upload="beforeUpload" @change="logoUploadChange">
        <a-button> <a-icon type="upload" /> 选择文件 </a-button>
      </a-upload>
      <template slot="footer">
        <a-button key="back" @click="uploadHandleCancel">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="handleUpload">
          上传
        </a-button>
      </template>
    </a-modal>
    <a-modal width="30%" title="年度选择" :maskClosable="false" :visible="quarterSelectVisible"  @ok="quarterSelectHandleOk" @cancel="quarterSelectHandleCancel">
      <a-form-model :model="quarterSelectForm" ref="quarterSelectRuleForm" :rules="quarterSelectFormRules">
        <a-form-model-item :label="$t('quarter.year')" prop="year">
          <a-select v-model="quarterSelectForm.year" placeholder="请选择年度">
            <a-select-option :value="item.key" v-for="item in yearSelectList" :key="item.key">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item :label="$t('quarter.month')" prop="month">
          <a-select v-model="quarterSelectForm.month" placeholder="请选择季度">
            <a-select-option :value="item.key" v-for="item in monthSelectList" :key="item.key">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item> -->
      </a-form-model>
    </a-modal>
    <basicCompany v-if="basicCompanyss" @search="headerSearch" ref="basicCompanys" @basicCompanysClose="basicCompanysClose" @editForm="editForm" :row="row" :childForm="annualform.enterpriseInfo" :formMode="formMode" @tonext="tonext"></basicCompany>
    <choicebusiness ref="choice" :business="row" :formMode="formMode" @switchForm="switchForm"></choicebusiness>
    <a-modal :title="title" :visible="yearvisible" @cancel="handleCancel" width="85%" wrapClassName="yearModal">
      <FormContainer ref="FormContainer" :current="stepcurrent" :enterpriseBusinessType="enterpriseBusinessType" @childsubmit="childsubmit" :formMode="formMode" :annualform="annualform" @tonext="tonext" @savedraft="savedraft">
      </FormContainer>
      <!-- <a-steps :current="stepcurrent" size="small" style="margin-bottom:20px;">
        <a-step :title="item.name" v-for="(item,index) in steparr" :key="index" />
      </a-steps> -->
      <!-- <pcbReports v-if="enterpriseBusinessType == 1" :current="stepcurrent" @childsubmit="childsubmit" ref="pcbreports" :annualform="annualform" :formMode="formMode" />
      <cclReports v-if="enterpriseBusinessType == 2" :current="stepcurrent" ref="cclreports" @childsubmit="childsubmit" :annualform="annualform" :formMode="formMode" />
      <dataReports v-if="enterpriseBusinessType == 3" :current="stepcurrent" ref="datareports" @childsubmit="childsubmit" :annualform="annualform" :formMode="formMode"></dataReports> -->
      <template slot="footer">
        <a-button key="back1" @click="handleCancel"> 取消 </a-button>
        <a-button key="prve" @click="prvestep" v-if="stepcurrent != 0"> 上一步 </a-button>
        <a-button key="next" @click="nextstep" type="primary" v-if="stepcurrent != steparr.length -1 && formMode != 'view'"> 下一步 </a-button>
        <template v-if="formMode !== 'view'">
          <!-- v-if="formMode == 'add'" -->
          <a-button class="tag-orange" key="back2" @click="savedata"> 存入草稿 </a-button>
          <a-button type="primary" key="back21" @click="takeout"> 取出草稿数据 </a-button>
          <a-button key="back3" @click="handleok" type="primary" :loading="isbtnLoading" v-if="stepcurrent == steparr.length -1 "> 保存 </a-button>
        </template>
      </template>
    </a-modal>
  </div>
</template>

<script>
import basicCompany from '@/components/MasCmponent/ReportsItem/PcbbasicCompany.vue'
import ReportsHeader from '@/components/MasCmponent/ReportsHeader.vue'
import pcbReports from './pcbReports/index.vue'
import cclReports from './cclReports/index.vue'
import dataReports from './specialReports/index.vue'
import choicebusiness from './choicebusiness.vue'
import { getPcbform, saveDraft_, EchoData, updstatus_, rushTo ,download_ ,enterpriseInfoUpdate} from '@/api/mas/pcb.js'
import detailpng from '@/assets/icons/detail.png'
import editpng from '@/assets/icons/edit.png'
import resetpng from '@/assets/icons/reset.png'
import { AssignmentForm, APIStatus, handleStep, typeBussine } from './Business.js'
import cloneDeep from 'lodash.clonedeep'
import FormContainer from './ReportsForm/container.vue'
import {download} from "@/utils/util"
import { throws } from 'assert'
let vm = null
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    vm.rowSelectionList =  selectedRowKeys
  },
}
export default {
  name: 'reports',
  props: {},
  components: {
    ReportsHeader,
    pcbReports,
    cclReports,
    dataReports,
    choicebusiness,
    FormContainer,
    basicCompany
  },
  data() {
    return {
      yearSelectList: [
        { name: '2021年', key: '2021' },
        { name: '2022年', key: '2022' },
        { name: '2023年', key: '2023' },
        { name: '2024年', key: '2024' },
      ],
      monthSelectList: [
        { name: '第一季度', key: 'Q1' },
        { name: '第二季度', key: 'Q2' },
        { name: '第三季度', key: 'Q3' },
        { name: '第四季度', key: 'Q4' },
      ],
      quarterSelectForm: {
        year: undefined,
      },
      quarterSelectFormRules: {
        year: [{ required: true, message: '请选择年份', trigger: 'change' }],
        month: [{ required: true, message: '请选择季度', trigger: 'change' }],
      },
      quarterSelectVisible:false,
      rowSelectionList:[],
      sendType:0,
      enterpriseInfoForm:{},
      rowId:'',
      basicCompanyss:false,
      headers:{'Authorization':this.$store.getters.token},
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      uploadVisible:false,
      fileList: [],
      uploading: false,
      row:{},
      isAdmin: this.$store.getters.nickname&&this.$store.getters.nickname.indexOf('admin')>-1,
      detailpng,
      editpng,
      resetpng,
      rowSelection,
      yearvisible: false,
      isbtnLoading: false,
      businesvisible: false,
      pagination: {
        total: 0,
        pageIndex: 1,
        pageSize: 10, //每页中显示10条数据
        showTotal: (total) => `共 ${total} 条`,
        size: 'small',
      },
      columns: [
        {
          dataIndex: 'enterpriseBusinessType',
          key: 'enterpriseBusinessType',
          title: '申报类型',
          width: '100px',
          scopedSlots: { customRender: 'enterpriseBusinessType' },
        },
        {
          title: '年度',
          dataIndex: 'timeInterval',
          key: 'timeInterval',
          width: '100px',
        },
        {
          title: '填报状态',
          dataIndex: 'reportStatus',
          key: 'reportStatus',
          width: '100px',
          scopedSlots: { customRender: 'reportStatus' },
        },
        // {
        //   title: '注册地址',
        //   dataIndex: 'communicationAddress',
        //   key: 'communicationAddress',
        //   width: '200px',
        // },
        // {
        //   title: '组织机构代码',
        //   key: 'organizationCode',
        //   dataIndex: 'organizationCode',
        //   scopedSlots: { customRender: 'organizationCode' },
        //   width: '180px',
        // },
        {
          title: '单位名称',
          key: 'enterpriseName',
          dataIndex: 'enterpriseName',
          scopedSlots: { customRender: 'enterpriseName' },
          width: '200px',
        },
        {
          title: '联系人 ',
          key: 'contactName',
          width: '100px',
          dataIndex: 'contactName',
          scopedSlots: { customRender: 'contactName' },
        },
        {
          title: '联系方式 ',
          key: 'contactPhone',
          dataIndex: 'contactPhone',
          scopedSlots: { customRender: 'contactPhone' },
        },
        {
          title: '邮箱 ',
          key: 'contactMail',
          dataIndex: 'contactMail',
          align: 'center',
        },
        {
          title: '催报状态 ',
          key: 'alertStatusYear',
          dataIndex: 'alertStatusYear',
          scopedSlots: { customRender: 'alertStatusYear' },
          align: 'center',
        },
        {
          title: '操作 ',
          key: 'caozuo',
          dataIndex: 'caozuo',
          width: '200px',
          fixed: 'right',
          scopedSlots: { customRender: 'caozuo' },
        },
      ],
      data: [],
      annualform: {},
      enterpriseBusinessType: '',
      formMode: '', //view edit add
      recordId: '',
      stepcurrent: 0,
      steparr: [],
      title: '',
      headerquery: {}, //查询条件
      formTypeisdraft: false, //当前修改的是否是草稿状态
      fileLists:[]
    }
  },
  watch: {},
  created() {
    vm = this
    if(this.isAdmin){
      this.timeInterval = new Date().getFullYear - 1;
      
    }
    if (!this.isAdmin) {
      this.columns.splice(7,1)
    }
  },
  methods: {
    //上传部分
    uploadOpen(row) {
      this.rowId = row.id
      this.fileList = [];
      this.uploadVisible = true
    },
    uploadHandleCancel() {
      this.uploadVisible = false
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = [];
    },
    beforeUpload(file) {
      this.fileList = [file];
      // return false;
    },

    logoUploadChange(info) {
      if(info.file.status==='uploading') {
        this.logoLLoading=true;
        return;
      }
      if(info.file.status==='done') {
        if(info.file.response.code==200) {
          this.fileLists = info.file.response.data;       
          // this.formRef.form.setFieldsValue({ logoUrl: this.logoUrl });
        }
      }
      if(info.file.status==='error') {
        this.logoLLoading=false;
        this.$message.error('上传失败，请稍后重试！');
      }

    },
    handleUpload() {
      enterpriseInfoUpdate({
        id:this.rowId,
        uploadFile:this.fileLists
      }).then(res => {
        if (res.code == 200) {
          this.$message.success('上传成功')
          this.uploadHandleCancel()
          this.headerSearch(this.headerquery)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //下载
    downloads(record) {
      download_({ fileName: record.uploadFile }).then((res) => {
        let suffixarr = record.uploadFile.split(".");
        let fileName = record.enterpriseName+record.timeInterval+'年度.'+suffixarr[suffixarr.length-1];
        download(res,fileName,'');
      })
    },
    allSend() {
      this.quarterSelectForm.year = undefined
      this.quarterSelectVisible = true
    },
    //季度确认弹窗
    quarterSelectHandleOk() {
      this.$refs.quarterSelectRuleForm.validate(valid => {
        if (valid) {  
          this.send({}, 'allSend')
          this.quarterSelectHandleCancel()
        }
      })
    },
    quarterSelectHandleCancel() {
      this.quarterSelectVisible = false;
    },
    //催报
    send(data, type, reportType) {
      let reqData = { }
      if (type == 'send') {
        reqData = {
          type:'1',
          enterpriseInfoForm: {},
          warningMode: reportType,
          membershipNumber: data.membershipNumber,
          timeInterval: data.timeInterval,
        }
        // reqData.enterpriseInfoForm.warningMode=reportType
      } else if (type == 'allSend') {
        reqData = {
          type:'1',
          enterpriseInfoForm: {},
          warningMode: this.sendType,
          membershipNumber: "",
          timeInterval: this.quarterSelectForm.year,
        }
      }

      this.$confirm({
        title: '提示',
        content: `是否确认催报？`,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        onOk: () => {
          rushTo(reqData).then((res) => {
            if (res.code == 200 && res.data == '发送成功') {
              this.$message.success('催报成功')
              this.headerSearch(this.headerquery)
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        onCancel: () => {
          this.$message.warning('取消催报')
        }
      })
    },
    headerSearch(parmas) {
      this.headerquery = parmas
      this.yearList()
      console.log(parmas, 'pppopo')
    },
    handleok() {
      const { FormContainer } = this.$refs
      if (this.enterpriseBusinessType == 1) {
        FormContainer.$refs.pcbreports.getFormData()
      } else if (this.enterpriseBusinessType == 2) {
        FormContainer.$refs.cclreports.getFormData()
      } else {
        FormContainer.$refs.datareports.getFormData()
      }
    },
    getFormDetail(record) {
      this.enterpriseBusinessType = record.enterpriseBusinessType
      // console.log(record, '查看详情')
      this.formMode = 'view'
      const formType = APIStatus.find((item) => item.matchNumber == record.enterpriseBusinessType)
      if (formType) {
        AssignmentForm(
          this,
          record.id,
          'detail',
          formType.type,
          (res) => {
            this.yearvisible = true
            this.$nextTick(() => {
              this.annualform = res.data
              this.steparr = handleStep(res.data.enterpriseInfo.enterpriseBusinessType)
              const desc = typeBussine.find((item) => item.value == res.data.enterpriseInfo.enterpriseBusinessType)
              if (desc) {
                this.title = `${desc.label.toUpperCase()}年度申报`
              }
            })
            console.log('成功的')
          },
          () => {
            console.log('失败le')
          }
        )
      } else {
        // this.addReports()
      }
    },
    editForm(record, type, enterpriseInfoForm) {
      if (enterpriseInfoForm) {
        this.enterpriseInfoForm = enterpriseInfoForm
      }
      this.row = record
      this.enterpriseBusinessType = record.enterpriseBusinessType
      this.recordId = record.id
      this.formMode = 'edit'
      this.addReports('draft', type)
      // const formType = APIStatus.find((item) => item.matchNumber == record.enterpriseBusinessType)
      // if (formType) {
      //   this.formTypeisdraft = false
      //   AssignmentForm(
      //     this,
      //     record.id,
      //     'detail',
      //     formType.type,
      //     (res) => {
      //       this.yearvisible = true
      //       this.$nextTick(() => {
      //         this.annualform = res.data
      //         this.steparr = handleStep(res.data.enterpriseInfo.enterpriseBusinessType)
      //         const desc = typeBussine.find((item) => item.value == res.data.enterpriseInfo.enterpriseBusinessType)
      //         if (desc) {
      //           this.title = `${desc.label.toUpperCase()}年度申报`
      //         }
      //       })

      //       console.log('成功的')
      //     },
      //     () => {
      //       console.log('失败le')
      //     }
      //   )
      // } else {
      //   this.addReports('draft',type)
      // }
    },
    deleteItem(record) {
      let that = this
      const formType = APIStatus.find((item) => item.matchNumber == record.enterpriseBusinessType)
      if (formType) {
        this.$confirm({
          title: '是否删除?',
          onOk() {
            console.log(record, '删除')
            AssignmentForm(
              that,
              record.id,
              'del',
              formType.type,
              (res) => {
                that.$message.success('删除成功')
                that.yearList()

                console.log('成功的')
              },
              () => {
                console.log('error')
              }
            )
          },
          onCancel() { },
        })
      }
    },
    upstatus(record) {
      //reportStatus
      console.log(record, 'xiugai')
      let that = this
      this.$confirm({
        title: '是否驳回当前公司的填报状态?',
        onOk() {
          const { id, reportStatus } = record
          updstatus_(id, '2').then((res) => {
            console.log(res)
            that.yearList()
          })
        },
        onCancel() { },
      })
    },
    /**
     * @val 切换form的type值
     */
    switchForm(val, title) {
      this.annualform = {}
      this.enterpriseBusinessType = val
      this.steparr = handleStep(val)
      this.title = `${title}年度申报`
      if (this.formTypeisdraft) {
        const formType = APIStatus.find((item) => item.matchNumber == this.enterpriseBusinessType)
        AssignmentForm(
          this,
          this.recordId,
          'detail',
          formType.type,
          (res) => {
            this.yearvisible = true
            this.$nextTick(() => {
              this.annualform = res.data
            })
          },
          () => {
          }
        )
      } else {
        this.yearvisible = true
      }
    },
    /**
     * @data 用户提交表单数据
     * @formType 提交的是 pcb |ccl |其他类型表单
     */
    childsubmit(data, formType = '1') {
      console.log(data, formType, '提交表单的数据---------')
      let _result = this.Packaging(data)
      this.isbtnLoading = true
      console.log("_result",_result);
      const status = this.formMode
      AssignmentForm(
        this,
        _result,
        status,
        formType,
        (res) => {
          this.isbtnLoading = false
          this.handleCancel()
          this.yearList()
        },
        () => {
          this.isbtnLoading = false
          // console.log('接口调用错误')
        }
      )
    },
    handleCancel() {
      this.yearvisible = false
      this.stepcurrent = 0
      this.enterpriseBusinessType = ''
      this.headerSearch(this.headerquery)
    },
    prvestep() {
      if (this.stepcurrent === 0) return
      this.$refs.FormContainer.prvestep()
      // this.stepcurrent--
    },
    nextstep() {
      // if (this.stepcurrent >= this.steparr.length - 1) return
      // this.stepcurrent++
      this.$refs.FormContainer.nextstep()
    },
    savedata() {
      this.$refs.FormContainer.save()
    },
    takeout() {
      EchoData(this.enterpriseBusinessType).then((res) => {
        if (res.code === 200) {
          console.log(res, '取出数据')
          let data = res.data
          try {
            let content = JSON.parse(data.content)
            this.stepcurrent = content.current
            if (!content.enterpriseInfo.id) {
              content.enterpriseInfo = JSON.parse(JSON.stringify(this.annualform.enterpriseInfo))
            }
            this.annualform = content
          } catch (error) {
            this.$message.error('暂无此申报表单的草稿数据')
          }
        }
      })
    },
    //指定到某一步
    tonext(current) {
      this.stepcurrent = current
    },
    addReports(status = null,type) {
      if (status === 'draft') {
        this.formMode = 'edit'
        this.formTypeisdraft = true //当前是草稿状态的修改
      } else {
        this.formMode = 'add'
      }
      if (type) {
        this.basicCompanyss = true
        this.$nextTick(() => {
          this.$refs.basicCompanys.basicCompanyVisible = true
        })
      } else {
        this.$refs.choice.visible = true
      }
      // this.$refs.choice.visible = true
      
      // this.yearvisible = false
      // this.$nextTick(() => {
      //   if (this.enterpriseBusinessType == 1) this.$refs.pcbreports.resetFormval()
      // })
    },

    basicCompanysClose() {
      this.$refs.basicCompanys.basicCompanyVisible = false
      this.basicCompanyss = false
    },
    tableChange(pageobj) {
      this.pagination.pageIndex = pageobj.current
      this.yearList()
    },
    yearList(obj = null) {
      let data = {
        pageIndex: this.pagination.pageIndex,
        pageSize: this.pagination.pageSize,
        object: this.headerquery,
      }
      getPcbform(data).then((res) => {
        if (res.code === 200) {
          this.pagination.total = res.data.total
          this.data = res.data.pageObject
        }
      })
    },
    //转换文字方法
    handleText(val) {
      const txt = [
        {
          text: '印制线路板',
          type: 1,
        },
        {
          text: '覆铜板',
          type: 2,
        },
        {
          text: '专用材料', //Material
          type: 3,
        },
        {
          text: '专用化学品', //Chemicals
          type: 4,
        },
        {
          text: '专用设备与仪器',//Equipment/Instrument
          type: 5,
        },
        {
          text: '环保洁净',//Environment
          type: 6,
        },
      ]
      let text = ''
      const findtext = txt.find((item) => item.type == val)
      if (findtext) {
        text = findtext.text
      }
      return text
    },
    handleStatus(val) {
      let res = ''
      const status = [
        {
          label: '草稿',
          value: '1',
        },
        {
          label: '重填',
          value: '2',
        },
        {
          label: '已上报',
          value: '3',
        },
      ]
      const finddata = status.find((item) => item.value == val)
      if (finddata) {
        res = finddata.label
      }

      return res
    },
    //数据包装每次提交的时候都带上主营业务类型
    Packaging(data) {
      let _obj = cloneDeep(data)
      _obj.enterpriseInfo = {}
      _obj.enterpriseInfo = this.annualform.enterpriseInfo
      // _obj.enterpriseInfo.enterpriseBusinessType = this.enterpriseBusinessType
      return _obj
    },
    //调用存入草稿的数据
    savedraft(type, data) {
      console.log(type, data, '春初')
      let parmas = {
        ...data,
        current: this.stepcurrent,
      }
      parmas.enterpriseInfo = {}
      parmas.enterpriseInfo = this.annualform.enterpriseInfo
      saveDraft_({ type, content: JSON.stringify(parmas) }).then((res) => {
        if (res.code === 200) {
          this.$message.success('添加草稿成功')
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.reports {
  background: #f3f3f3;
  height: 100%;
  // padding: 24px 24px;
  display: flex;
  flex-direction: column;
  .noborder {
    border: none;
    border-radius: 10px;
  }
  .top-header {
    margin-bottom: 24px;
  }
  .bottom-card {
    flex: 1;
    .ant-card-extra {
      .add {
        margin-right: 24px;
      }
    }
  }

  .caozuo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    img {
      width: 18px;
    }
  }

  .hzzz {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
}
</style>
<style lang="less">
.yearModal {
  .ant-modal {
    top: 50px;
  }
  .ant-modal-body {
    max-height: calc(100vh - 200px);
    overflow: auto;
  }
}

.send {
  display: inline-block;
  border: #5199F8 1px dashed;
  padding: 2px;
  border-radius: 5px;
  margin-right: 15px;
  color: #5199F8;
}
.send:hover{
  border: #5ee34a 1px dashed;
  color: #5ee34a;
}
.send:active{
  border: #288719 1px dashed;
  color: #288719;
}

.send-admin{
  font-size:14px;
  margin: 5px;
  color: #5199F8;
}

.send-admin:hover{
  color: #5ee34a;
}
.send-admin:active{
  color: #288719;
}
</style>

<template>
  <a-form-model ref="qu" :model="query" @submit="handleSubmit" @submit.native.prevent labelAlign="right" id="reports-form" :colon="false">
    <a-form-model-item v-for="(item, index) in formList" :label="item.label" :key="index">
      <a-select v-model="item.value" :placeholder="item.placeholder" style="width: 100%" :allow-clear = "item.allowClear"  v-if="item.type == 'select'">
        <a-select-option :value="optionItem.value" v-for="(optionItem,optionIndex) in item.option" :key="optionIndex">
          {{optionItem.label}}
        </a-select-option>
      </a-select>
      <a-input v-model="item.value" :placeholder="item.placeholder" v-if="item.type == 'input'"></a-input>
    </a-form-model-item>
     <a-form-model-item class="btn-box" :labelCol="{ span: 0 }" :wrapperCol="{ span: 24 }">
      <a-button class="submitbtn" type="primary" html-type="submit">
        {{ buttonName }}
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import {quarterAnnualOptions,annualOptions} from "@/utils/util"
export default {
  name: 'HeaderForm',
  props: {
    formList: {
      default: {}
    },
    buttonName: {
      default: '查询'
    }
  },
  components: {},
  data() {
    return {
      query: {},
      isAdmin:this.$store.getters.nickname&&this.$store.getters.nickname.indexOf('admin')>-1,
      labelCol: { span: 2 },
      wrapperCol: { span: 16 },
    }
  },
  watch: {},
  created() {
    // this.handleSubmit();
  },
  mounted(){
    
  },
  methods: {
    getQueryObject() {
      let query = {}
      this.formList.forEach(item => {
        query[item.name] = item.value
      });
      return query
    },
    handleSubmit() {
      this.$emit('search', this.getQueryObject())
    },
  },
}
</script>

<style scoped lang="less">
#reports-form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .ant-form-item {
    width: 280px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    /deep/.ant-form-item-label {
      width: 80px;
    }
    /deep/.ant-form-item-control-wrapper {
      flex: 1;
    }
  }
  .btn-box {
    .ant-form-item-children {
      .submitbtn {
        margin-right: 24px;
        margin-left: 24px;
      }
    }
  }
}
</style>

import request from "@/utils/request";

const quarterApi = {
  AddPcb: "/system-business/pcbQuarterStatistics/add",
  AddNoPcb: "/system-business/nonPcbQuarterStatistics/add",
  QueryPcb: "/system-business/pcbQuarterStatistics/findPcbQuarterStatisticsById",
  QueryNoPcb: "/system-business/nonPcbQuarterStatistics/findNonPcbQuarterStatisticsById",
  UpdatePcb: "/system-business/pcbQuarterStatistics/update",
  UpdateNoPcb: "/system-business/nonPcbQuarterStatistics/update",
  EnterpriseInfos: "/system-business/enterpriseInfo/enterpriseInfos"
};

export function EnterpriseInfos(parameter) {
  return request({
    url: quarterApi.EnterpriseInfos,
    method: 'post',
    data: parameter,
  });
}

export function addPcb(parameter) {
  return request({
    url: quarterApi.AddPcb,
    method: "post",
    data: parameter,
  });
}

export function updatePcb(parameter) {
  return request({
    url: quarterApi.UpdatePcb,
    method: "post",
    data: parameter,
  });
}

export function addNoPcb(parameter) {
  return request({
    url: quarterApi.AddNoPcb,
    method: "post",
    data: parameter,
  });
}

export function updateNoPcb(parameter) {
  return request({
    url: quarterApi.UpdateNoPcb,
    method: "post",
    data: parameter,
  });
}

export function findPcbQuarterStatisticsById(parameter) {
  return request({
    url: quarterApi.QueryPcb,
    method: "post",
    data: parameter,
  });
}

export function findNonPcbQuarterStatisticsById(parameter) {
  return request({
    url: quarterApi.QueryNoPcb,
    method: "post",
    data: parameter,
  });
}

<template>
  <div class="basicCompany">
    <a-modal  width="75%" title="请填写企业基本信息" :maskClosable="false" :visible="basicCompanyVisible" @cancel="handleCancel">
      <a-form-model  layout="inline" :model="form" :rules="rules" ref="basicCompany" :colon="false" labelAlign="right" :labelCol="{ style: 'width: 130px' }">
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="企业名称" prop="enterpriseName">
              <a-input class="formisedit" v-if="formMode != 'view'" v-model="form.enterpriseName" placeholder="请输入企业名称" />
              <div v-else class="formistext" :title="form.enterpriseName">{{form.enterpriseName}}</div>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="组织机构代码" prop="organizationCode ">
              <a-input class="formisedit" v-if="formMode != 'view'" v-model="form.organizationCode" placeholder="请输入企业人数" />
              <div v-else class="formistext" :title="form.organizationCode">{{form.organizationCode}}</div>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="企业人数(年末)" prop="numberEmployees">
              <a-input class="formisedit" v-if="formMode != 'view'" v-model="form.numberEmployees" placeholder="请输入企业人数" />
              <div v-else class="formistext" :title="form.numberEmployees">{{form.numberEmployees}}</div>

            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-model-item label="注册地址(省)" prop="registeredProvince" v-if="formType === '2'">
              <a-input class="formisedit" v-if="formMode != 'view'" v-model="form.registeredProvince" placeholder="请输入(省)" />
              <div v-else class="formistext" :title="form.registeredProvince">{{form.registeredProvince}}</div>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="注册地址(市)" prop="registerCity" v-if="formType === '2'">
              <a-input class="formisedit" v-if="formMode != 'view'" v-model="form.registerCity" placeholder="请输入(市)" />
              <div v-else class="formistext">{{form.registerCity}}</div>
            </a-form-model-item>
          </a-col> -->
          <a-col :span="12">
            <a-form-model-item label="通信地址" prop="communicationAddress">
              <a-textarea class="formisedit" v-if="formMode != 'view'" v-model="form.communicationAddress" placeholder="请输入通信地址" />
              <div v-else class="formistext" :title="form.communicationAddress">{{form.communicationAddress}}</div>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-model-item label="法定代表人姓名" prop="legalRepresentativeName">
              <a-input class="formisedit" v-if="formMode != 'view'" v-model="form.legalRepresentativeName" placeholder="请输入企业法定代表人姓名" />
              <div v-else class="formistext" :title="form.legalRepresentativeName">{{form.legalRepresentativeName}}</div>
            </a-form-model-item>
          </a-col> -->
          <a-col :span="12">
            <a-form-model-item label="企业联系人姓名" prop="contactName">
              <a-input class="formisedit" v-if="formMode != 'view'" v-model="form.contactName" placeholder="请输入企业联系人姓名" />
              <div v-else class="formistext" :title="form.contactName">{{form.contactName}}</div>

            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="联系人电话" prop="contactTelephone">
              <a-input class="formisedit" v-if="formMode != 'view'" v-model="form.contactTelephone" placeholder="请输入电话" />
              <div v-else class="formistext" :title="form.contactTelephone">{{form.contactTelephone}}</div>

            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="联系人手机" prop="contactPhone">
              <a-input class="formisedit" v-if="formMode != 'view'" v-model="form.contactPhone" placeholder="请输入手机" />
              <div v-else class="formistext" :title="form.contactPhone">{{form.contactPhone}}</div>

            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="联系人邮箱" prop="contactMail">
              <a-input class="formisedit" v-if="formMode != 'view'" v-model="form.contactMail" placeholder="请输入邮箱" />
              <div v-else class="formistext" :title="form.contactMail">{{form.contactMail}}</div>

            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="企业网址" prop="corporateWebSite" v-if="formType === '2'">
              <a-input class="formisedit" v-if="formMode != 'view'" v-model="form.corporateWebSite" placeholder="请输入企业网址" />
              <div v-else class="formistext" :title="form.corporateWebSite">{{form.corporateWebSite}}</div>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="企业传真" prop="enterpriseFax" v-if="formType === '2'">
              <a-input class="formisedit" v-if="formMode != 'view'" v-model="form.enterpriseFax" placeholder="请输入企业传真" />
              <div v-else class="formistext" :title="form.enterpriseFax">{{form.enterpriseFax}}</div>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="企业登记类型" prop="enterpriseRegistrationType">
              <a-select class="formisedit" v-if="formMode != 'view'" v-model="form.enterpriseRegistrationType" placeholder="请选择企业登记类型">
                <a-select-option v-for="item in seloption" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
              <div v-else class="formistext">{{ handlertxt(form.enterpriseRegistrationType)}}</div>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-model-item label="企业主营产品" prop="enterpriseProduct" v-if="formType === 'data'">
              <a-input v-if="formMode != 'view'" v-model="form.enterpriseProduct" placeholder="请输入企业主营产品" />
              <div v-else class="formistext" :title="form.enterpriseProduct">{{form.enterpriseProduct}}</div>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item class="hz" label="企业主营业务类型" prop="enterpriseBusinessType" v-if="formType === 'data'">
              <a-radio-group :options="plainOptions" v-model="form.enterpriseBusinessType" :disabled="formMode != 'view'? false:true" />
            </a-form-model-item>
          </a-col> -->
        </a-row>
      </a-form-model>
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="submit">
          保存
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { regularObj } from '@/utils/util.js'
import { enterpriseInfoUpdate } from '@/api/mas/pcb.js'
const plainOptions = [
  { label: '专用设备', value: '3' },
  { label: '专用材料', value: '4' },
  { label: '专用化学品', value: '5' },
  { label: '清洁洁净', value: '6' },
]
export default {
  name: 'basicCompany',
  props: {
    formType: {
      type: String, //pcb ccl data
      default() {
        return '1'
      },
    },
    childForm: {
      type: Object,
    },
    row: {
      type: Object,
    },
    formMode: {
      type: String,
      default() {
        return 'edit'
      },
    },
    isQuarter: {
      type: Boolean,
      default() {
        return false
      },
    }
  },
  components: {},
  watch: {
    deep: true,
    childForm: {
      handler(obj) {
        this.form = { ...obj }
        // console.log(obj, '接收到')
      },
    },
  },
  data() {
    let validateMobile = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号码'))
      } else if (!regularObj.mobile.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }
    let validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入邮箱'))
      } else if (!regularObj.single_email.test(value)) {
        callback(new Error('请输入正确的邮箱号码'))
      } else {
        callback()
      }
    }
    let validateInt = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入企业年末人数'))
      } else if (!(/^(0|\+?[1-9][0-9]*)$/).test(value)) {
        callback(new Error('请输入非负整数'))
      } else {
        callback()
      }
    }
    let validateTel = (rule, value, callback) => {
      if (regularObj.landline.test(value)) {
        callback(new Error('请输入正确的电话号码'))
      } else {
        callback()
      }
    }
    return {
      basicCompanyVisible:false,
      form: {
        type: '1', // 1 pcb | 2ccl | 3专用化妆品
        timeInterval: new Date().getFullYear()-1, //1年度 2季度
        enterpriseName: '', //企业名称
        organizationCode: '', //组织代码
        numberEmployees: '', //企业人数(年末)
        communicationAddress: '', //通信地址
        legalRepresentativeName: '', //法定代表人姓名
        contactName: '', //企业联系人姓名
        contactTelephone: '', //电话
        contactPhone: '', //手机
        contactMail: '', //邮箱
        enterpriseRegistrationType: undefined, //企业登记类型
        reportStatus:null, //上报状态

        //data
        enterpriseBusinessType: '', //企业主营业务类型
        enterpriseProduct: '', //企业主营产品

        // ccl
        registeredProvince: '',
        registerCity: '',
        corporateWebSite: '',
        enterpriseFax: '',
      },
      rules: {
        enterpriseName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        organizationCode: [{ required: true, message: '请输入组织机构代码', trigger: 'blur' }],
        numberEmployees: [{ required: true, message: '请输入企业年末人数', trigger: 'blur' }, { validator: validateInt }],
        communicationAddress: [{ required: true, message: '请输入通信地址', trigger: 'blur' }],
        legalRepresentativeName: [{ required: true, message: '请输入企业法定代表人', trigger: 'blur' }],
        contactName: [{ required: true, message: '请输入企业联系人', trigger: 'blur' }],
        contactTelephone: [{ required: false, message: '请输入联系人电话', trigger: 'blur' }, { validator: validateTel }],
        contactPhone: [{ required: true, message: '请输入联系人手机', trigger: 'blur' }, { validator: validateMobile }],
        contactMail: [{ required: true, message: '请输入联系人邮箱', trigger: 'blur' }, { validator: validateEmail }],
        enterpriseRegistrationType: [{ required: true, message: '请选择企业登记类型', trigger: 'blur' }],
        enterpriseProduct: [{ required: true, message: '请输入企业主营产品', trigger: 'blur' }],
      },
      seloption: [
        {
          label: '内资企业(含港资)',
          value: '1',
        },
        {
          label: '外资企业',
          value: '2',
        },
        // {
        //   label: '港资',
        //   value: '3',
        // },
        {
          label: '台资',
          value: '4',
        },
      ],
      plainOptions,
    }
  },
  created() {
    
    
  },
  mounted() {
    if (this.row) {
      this.form = this.row
    }
  },
  methods: {
    handleCancel() {
      this.$emit('basicCompanysClose')
    },
    submit() {
      this.$refs.basicCompany.validate((valid) => {
        if (valid) {
          if (this.isQuarter) {
            this.form.id = this.row.enterpriseId
          }
          this.form.id = this.row.id
          enterpriseInfoUpdate(this.form).then((res) => {
            if (res.code === 200) {
              this.handleCancel()
              // this.$emit('editForm', this.row,'', this.form)
              // this.$emit('search')
              this.$emit("toSubmit")
            }
          })
        }
      })
    },
    handlertxt(val) {
      let text = ''
      const findobj = this.seloption.find((item) => item.value == val)
      if (findobj) {
        text = findobj.label
      }

      return text
    },
    resetForm() {
      this.form = {
        type: '1', // 1 pcb | 2ccl | 3专用化妆品
        timeInterval: new Date().getFullYear()-1, //1年度 2季度
        enterpriseName: '', //企业名称
        organizationCode: '', //组织代码
        numberEmployees: '', //企业人数(年末)
        communicationAddress: '', //通信地址
        legalRepresentativeName: '', //法定代表人姓名
        contactName: '', //企业联系人姓名
        contactTelephone: '', //电话
        contactPhone: '', //手机
        contactMail: '', //邮箱
        enterpriseRegistrationType: undefined, //企业登记类型
        enterpriseBusinessType: '', //企业主营业务类型
        // ccl
        registeredProvince: '',
        registerCity: '',
        corporateWebSite: '',
        enterpriseFax: '',
        reportStatus:null, //上报状态
      }
    },
    formtype() {
      this.form.type = this.formType
      this.form.enterpriseBusinessType = this.formType
    },
  },
}
</script>

<style scoped lang="less">
.basicCompany {
  .ant-form {
    display: flex;
    // align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .ant-form-item {
      width: 30%;
      display: flex;
      flex-wrap: wrap;
      justify-content: baseline;
      /deep/ .ant-form-item-label {
        width: 120px;
      }
      /deep/.ant-form-item-control-wrapper {
        flex: 1;
        overflow: hidden;
      }
      // margin-right: 20px;
    }
    .hz {
      width: 100%;
    }
  }
}
.formistext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 200px
}
.formisedit{
  min-width: 250px;
  width: 100%
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "page-header-wrapper",
    [
      _c("AnnualComponent", {
        attrs: { type: _vm.type, headerFormList: _vm.headerFormList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
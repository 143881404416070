<template>
  <div class="pcbContainer">
    <!-- <ReportsTitle title="企业基本信息" v-show="current == 0 || formMode == 'view'">
      <basicCompany ref="basic" :childForm="annualform.enterpriseInfo" :formMode="formMode" formType="ccl" @tonext="tonext"></basicCompany>
    </ReportsTitle> -->
    <ReportsTitle title="企业经营状况" v-show="current == 0 || formMode == 'view'">
      <enterpriseCompany ref="enterprise" :enterpriseBusinessType="enterpriseBusinessType" :childForm="annualform.enterpriseRevenueInfo" :formMode="formMode" formType="ccl" @tonext="tonext"></enterpriseCompany>
    </ReportsTitle>
    <ReportsTitle title="集团布局" v-show="current == 1 || formMode == 'view'">
      <pcbtable class="pcbtt" :enterpriseBusinessType="enterpriseBusinessType" ref="pcbtable" :childForm="annualform.enterpriseLayoutInfos" :formMode="formMode" @tonext="tonext"></pcbtable>
    </ReportsTitle>
    <ReportsTitle title="生产指标" v-show="current == 2 || formMode == 'view'">
      <ccltable ref="production" :childForm="annualform.cclProductionQuotas" :formMode="formMode" @tonext="tonext"></ccltable>
    </ReportsTitle>
    <!-- <a-radio class="pcbtt"
      >我已阅读并同意 <span style="color: #228f45">隐私条款</span>
    </a-radio> -->
  </div>
</template>

<script>
import ReportsTitle from '@/components/MasCmponent/ReportsTitle.vue'
import basicCompany from '@/components/MasCmponent/ReportsItem/PcbbasicCompany.vue'
import enterpriseCompany from '@/components/MasCmponent/ReportsItem/PcbenterpriseCompany.vue'
import pcbtable from '@/components/MasCmponent/ReportsItem/PcbTable.vue'
import PcbProportion from '@/components/MasCmponent/ReportsItem/PcbProportion.vue'
import ccltable from '@/components/MasCmponent/ReportsItem/CclTable.vue'
export default {
  name: 'pcbReports',
  props: {
    annualform: {
      type: Object,
    },
    formMode: {
      type: String,
    },
    current: {
      type: [Number, String],
    },
    enterpriseBusinessType: {
      type: [Number, String],
    },
  },
  components: {
    ReportsTitle,
    basicCompany,
    enterpriseCompany,
    pcbtable,
    PcbProportion,
    ccltable,
  },
  data() {
    return {}
  },
  watch: {
    formMode: {
      deep: true,
      handler(mode) {
        console.log(mode, 'form表单模式-------------')
      },
    },
    annualform: {
      deep: true,
      handler(newData, oldData) {
        this.annualform.enterpriseInfo = newData.enterpriseInfo
      },
    },
  },
  created() {
    // console.log('created','2232323233')
  },
  methods: {
    submitCompany() {},

    getFormData() {
      // const _basicCompany = this.$refs.basic
      const _enterprise = this.$refs.enterprise
      const _pcbtable = this.$refs.pcbtable //集团布局
      const _production = this.$refs.production //生产指标
      console.log("_production.data",_production.data);
      Promise.all([ _enterprise.submit()]).then((res) => {
        if (res.includes(false)) {
          this.$message.error('表单有未填写的字段请填写')
          return false
        }
        let data = {
          enterpriseInfo: this.annualform.enterpriseInfo,
          enterpriseRevenueInfo: res[0],
          enterpriseLayoutInfos: _pcbtable.data.map((item) => {
            
            return {
              productPositioning: item.productPositioning,
              factoryState: item.factoryState,
              factoryName: item.factoryName,
              locationMunicipalLevel: item.locationMunicipalLevel,
              totalAmountInvestment: item.totalAmountInvestment,
              capacity: item.capacity,
              id: item.id ? item.id : '',
              enterpriseId: item.enterpriseId ? item.enterpriseId : '',
              outputValue: item.outputValue ? item.outputValue : '',
            }
          }),
          cclProductionQuotas: _production.data.map(({ annualOutput, enterpriseId, exports, productionQuotaType, sales,capacityUtilizationRate,salesAmount, totalAmountTax, id }) => {
            return {
              
              capacityUtilizationRate,
              salesAmount,
              annualOutput,
              exports,
              productionQuotaType,
              sales,
              totalAmountTax,
              id: id ? id : '',
              enterpriseId: enterpriseId ? enterpriseId : '',
            }
          }),
        }
        this.$emit('childsubmit', data, this.enterpriseBusinessType)
      })

    },
    tonext(cur) {
      this.$emit('tonext', cur)
    },
    saveDraft() {
      // const _basicCompany = this.$refs.basic
      const _basicCompany = {}
      const _enterprise = this.$refs.enterprise
      const _pcbtable = this.$refs.pcbtable //集团布局
      const _production = this.$refs.production //生产指标

      let data = {
        enterpriseInfo: _basicCompany,
        enterpriseRevenueInfo: _enterprise.form,
        enterpriseLayoutInfos: _pcbtable.data.map((item) => {
          return {
            factoryState: item.factoryState,
            factoryName: item.factoryName,
            locationMunicipalLevel: item.locationMunicipalLevel,
            totalAmountInvestment: item.totalAmountInvestment,
            capacity: item.capacity,
            id: item.id ? item.id : '',
            enterpriseId: item.enterpriseId ? item.enterpriseId : '',
          }
        }),
        cclProductionQuotas: _production.data.map(({ annualOutput, enterpriseId, exports, productionQuotaType, sales, totalAmountTax, id }) => {
          return {
            annualOutput,
            exports,
            productionQuotaType,
            sales,
            totalAmountTax,
            id: id ? id : '',
            enterpriseId: enterpriseId ? enterpriseId : '',
          }
        }),
      }

      this.$emit('savell', '2', data)
    },
  },
}
</script>

<style scoped lang="less">
.pcbContainer {
  padding: 0 0;
  h2 {
    text-align: center;
    margin: 20px auto;
    font-size: 30px;
  }
  .bigtitle {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin: 20px auto;
  }

  .flextd {
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
    tr,
    td {
      border: none;
    }
    .item {
      &:first-child {
        width: 50%;
        height: 80px;
        line-height: 80px;
        font-size: 18px;
        text-align: center;
        border-right: 1px solid #000;
      }
      &:last-child {
        display: flex;
        flex-direction: column;
        width: 50%;
        td {
          padding: 0;
          text-align: center;
          border-bottom: 1px solid #000;
          height: 20px;
          line-height: 20px;
        }
      }
    }
  }
}
.pcbtt {
  margin: 10px auto;
}
</style>

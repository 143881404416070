import request from "@/utils/request";

const commonApi = {
  Down: "/system-mms-user/file/download",
  Companys: "/system-mms-user/companyInfo/simpleCompanySearch",
};

export function downloadFile(parameter) {
  return request({
    url: commonApi.Down,
    method: "post",
    data: parameter,
    responseType:"blob"
  });
}
export function findCompanys() {
  return request({
    url: commonApi.Companys,
    method: "get",
  });
}

<template>
  <a-modal title="请选择的申报类型表单" :visible="visible"  width="500px" @cancel="handleCancel"  wrapClassName="yearModal" @ok="submitOk">
    <div class="MasFrontendChoicebusiness" style="width: 350px;">
      <!-- <span class="title">请选择的申报类型表单</span> -->
      <a-radio-group style="margin-left: 150px;"  v-model="radio" @change="onChange"  :disabled="business.enterpriseBusinessType != '0'&&business.enterpriseBusinessType != ''?true:false">
        <div v-for="(item,index) in plainOptions" style="margin-top: 10px;">
          <a-radio :value="item.value"  :key="item.value+index">
          <span :style="item.style" class="radio-font">{{ item.label }}</span>
        </a-radio><br>
        </div>
        
      </a-radio-group>
    </div>
  </a-modal>
</template>

<script>
import { typeBussine } from "./Business.js"
import { enterpriseInfoUpdate} from '@/api/mas/pcb.js'
export default {
  name: 'MasFrontendChoicebusiness',
  props: {
    business: {
      type: Object,
    },
    formMode: {
      type: String,
    },
  },
  data() {
    return {
      plainOptions:typeBussine,
      visible:false,
      radio:''
    }
  },
  watch: {
    business: {
      deep: true,
      handler(newData,oldData) {
        this.radio = newData.enterpriseBusinessType
      },
    },
  },
  mounted() {
  },

  methods: {
    onChange(e) {
      // console.log(e.target.value)
      
    },
    updType(text) {
      enterpriseInfoUpdate({
        id:this.business.id,
        enterpriseBusinessType:this.radio
      }).then(res => {
        if (res.code == 200) {
          this.$emit('switchForm', this.radio, text.label)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleCancel() {
      this.visible = false
    },
    submitOk() {
      if(this.radio === '') {
        this.$message.error('请先选择申报表单类型') 
        return false
      } 
      const text = this.plainOptions.find(item => item.value == this.radio)
      if (this.business.enterpriseBusinessType == 0 || this.business.enterpriseBusinessType == '') {
        this.updType(text)
      } else {
        this.$emit('switchForm', this.radio, text.label)
      }
      this.$emit('search')
      this.handleCancel()

    }
  },
}
</script>

<style lang="less" scoped>
.title {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}

.plain-color{

}

.radio-font {
  display: inline-block;
  font-size: 16px;
  color: white;
  border-radius: 5px;
  padding: 5px;
}
</style>
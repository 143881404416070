var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            width: _vm.width,
            title: "表单统计",
            "body-style": { background: "#F8F9FD" },
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                padding: "20px",
                height: "60vh",
                overflow: "auto",
              },
            },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "a-card",
                {
                  key: index,
                  staticStyle: {
                    width: "100%",
                    "border-radius": "20px",
                    "margin-top": "20px",
                  },
                  attrs: { title: item.name, bordered: false },
                },
                [
                  _c("a-table", {
                    staticStyle: { width: "100%", "border-radius": "20px" },
                    attrs: {
                      columns: _vm.columns,
                      "data-source": item.tableList,
                      pagination: false,
                      bordered: "",
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { on: { click: _vm.handleCancel } }, [
                _vm._v(" 取消 "),
              ]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
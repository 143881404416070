var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pcbtable" },
    [
      _c("a-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.data,
          bordered: "",
          pagination: false,
          rowKey: (record) => record.key,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "factoryState",
              fn: function (text, record) {
                return [_vm._v(" " + _vm._s(_vm.handleText(record)) + " ")]
              },
            },
            {
              key: "factoryName",
              fn: function (text, record) {
                return [
                  _vm.formMode != "view"
                    ? _c("a-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: record.factoryName,
                          callback: function ($$v) {
                            _vm.$set(record, "factoryName", $$v)
                          },
                          expression: "record.factoryName",
                        },
                      })
                    : _c("div", [_vm._v(_vm._s(record.factoryName))]),
                ]
              },
            },
            {
              key: "locationMunicipalLevel",
              fn: function (text, record) {
                return [
                  _vm.formMode != "view"
                    ? _c("a-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: record.locationMunicipalLevel,
                          callback: function ($$v) {
                            _vm.$set(record, "locationMunicipalLevel", $$v)
                          },
                          expression: "record.locationMunicipalLevel",
                        },
                      })
                    : _c("div", [
                        _vm._v(_vm._s(record.locationMunicipalLevel)),
                      ]),
                ]
              },
            },
            {
              key: "capacity",
              fn: function (text, record) {
                return [
                  _vm.formMode != "view"
                    ? _c("a-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: record.capacity,
                          callback: function ($$v) {
                            _vm.$set(record, "capacity", $$v)
                          },
                          expression: "record.capacity",
                        },
                      })
                    : _c("div", [_vm._v(_vm._s(record.capacity))]),
                ]
              },
            },
            {
              key: "productPositioning",
              fn: function (text, record) {
                return [
                  _vm.formMode != "view"
                    ? _c("a-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: record.productPositioning,
                          callback: function ($$v) {
                            _vm.$set(record, "productPositioning", $$v)
                          },
                          expression: "record.productPositioning",
                        },
                      })
                    : _c("div", [_vm._v(_vm._s(record.productPositioning))]),
                ]
              },
            },
            {
              key: "outputValue",
              fn: function (text, record) {
                return [
                  _vm.formMode != "view"
                    ? _c("a-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: record.outputValue,
                          callback: function ($$v) {
                            _vm.$set(record, "outputValue", $$v)
                          },
                          expression: "record.outputValue",
                        },
                      })
                    : _c("div", [_vm._v(_vm._s(record.outputValue))]),
                ]
              },
            },
            {
              key: "caozuo",
              fn: function (text, record, index) {
                return _vm.formMode != "view"
                  ? [
                      !record.isremove
                        ? _c("a-icon", {
                            staticStyle: {
                              "font-size": "24px",
                              cursor: "pointer",
                            },
                            attrs: { type: "plus-circle" },
                            on: {
                              click: function ($event) {
                                return _vm.addType(index, record)
                              },
                            },
                          })
                        : _c("a-icon", {
                            staticStyle: {
                              "font-size": "24px",
                              cursor: "pointer",
                            },
                            attrs: { type: "minus-circle" },
                            on: {
                              click: function ($event) {
                                return _vm.removeItem(index, record)
                              },
                            },
                          }),
                    ]
                  : undefined
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
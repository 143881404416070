var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-config-provider", { attrs: { locale: _vm.locale } }, [
    _vm.loadState
      ? _c(
          "div",
          { staticClass: "form-designer-container-9136076486841527" },
          [
            _vm.showHead
              ? _c("k-header", { attrs: { title: _vm.title } })
              : _vm._e(),
            _vm.toolbarsTop
              ? _c(
                  "operatingArea",
                  {
                    attrs: {
                      showToolbarsText: _vm.showToolbarsText,
                      toolbars: _vm.toolbars,
                    },
                    on: {
                      handleSave: _vm.handleSave,
                      handlePreview: _vm.handlePreview,
                      handleOpenImportJsonModal: _vm.handleOpenImportJsonModal,
                      handleOpenCodeModal: _vm.handleOpenCodeModal,
                      handleOpenJsonModal: _vm.handleOpenJsonModal,
                      handleReset: _vm.handleReset,
                      handleClose: _vm.handleClose,
                      handleUndo: _vm.handleUndo,
                      handleRedo: _vm.handleRedo,
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "left-action" },
                      [_vm._t("left-action")],
                      2
                    ),
                    _c(
                      "template",
                      { slot: "right-action" },
                      [_vm._t("right-action")],
                      2
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "content",
                class: {
                  "show-head": _vm.showHead,
                  "toolbars-top": _vm.toolbarsTop,
                  "show-head-and-toolbars-top": _vm.toolbarsTop && _vm.showHead,
                },
              },
              [
                _c(
                  "aside",
                  { staticClass: "left" },
                  [
                    _c(
                      "a-collapse",
                      {
                        attrs: {
                          defaultActiveKey: _vm.collapseDefaultActiveKey,
                        },
                        on: { change: _vm.collapseChange },
                      },
                      _vm._l(_vm.schemaGroup, function (item, index) {
                        return _c(
                          "a-collapse-panel",
                          { key: index, attrs: { header: item.title } },
                          [
                            _c("collapseItem", {
                              attrs: { list: item.list },
                              on: {
                                generateKey: _vm.generateKey,
                                handleListPush: _vm.handleListPush,
                                start: _vm.handleStart,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "section",
                  [
                    !_vm.toolbarsTop
                      ? _c(
                          "operatingArea",
                          {
                            attrs: {
                              showToolbarsText: _vm.showToolbarsText,
                              toolbars: _vm.toolbars,
                            },
                            on: {
                              handleSave: _vm.handleSave,
                              handlePreview: _vm.handlePreview,
                              handleOpenImportJsonModal:
                                _vm.handleOpenImportJsonModal,
                              handleOpenCodeModal: _vm.handleOpenCodeModal,
                              handleOpenJsonModal: _vm.handleOpenJsonModal,
                              handleReset: _vm.handleReset,
                              handleClose: _vm.handleClose,
                              handleUndo: _vm.handleUndo,
                              handleRedo: _vm.handleRedo,
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "left-action" },
                              [_vm._t("left-action")],
                              2
                            ),
                            _c(
                              "template",
                              { slot: "right-action" },
                              [_vm._t("right-action")],
                              2
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c("k-form-component-panel", {
                      ref: "KFCP",
                      class: { "no-toolbars-top": !_vm.toolbarsTop },
                      attrs: {
                        data: _vm.data,
                        selectItem: _vm.selectItem,
                        noModel: _vm.noModel,
                        hideModel: _vm.hideModel,
                        startType: _vm.startType,
                      },
                      on: { handleSetSelectItem: _vm.handleSetSelectItem },
                    }),
                    _c("k-json-modal", { ref: "jsonModal" }),
                    _c("k-code-modal", { ref: "codeModal" }),
                    _c("importJsonModal", { ref: "importJsonModal" }),
                    _c("previewModal", { ref: "previewModal" }),
                  ],
                  1
                ),
                _c(
                  "aside",
                  { staticClass: "right" },
                  [
                    _c(
                      "a-tabs",
                      {
                        attrs: {
                          activeKey: _vm.activeKey,
                          tabBarStyle: { margin: 0 },
                        },
                        on: { change: _vm.changeTab },
                      },
                      [
                        _c(
                          "a-tab-pane",
                          { key: 1, attrs: { tab: "表单属性设置" } },
                          [
                            _c("formProperties", {
                              attrs: {
                                config: _vm.data.config,
                                previewOptions: _vm.previewOptions,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-tab-pane",
                          { key: 2, attrs: { tab: "控件属性设置" } },
                          [
                            _c("formItemProperties", {
                              staticClass: "form-item-properties",
                              attrs: {
                                selectItem: _vm.selectItem,
                                hideModel: _vm.hideModel,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
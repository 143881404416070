var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: {
        textAlign: _vm.record.options.textAlign,
        transform: "translateY(-2px)",
        paddingRight: "8px",
      },
    },
    [
      _c("label", {
        class: {
          "ant-form-item-required": _vm.record.options.showRequiredMark,
        },
        style: {
          fontFamily: _vm.record.options.fontFamily,
          fontSize: _vm.record.options.fontSize,
          color: _vm.record.options.color,
        },
        domProps: { textContent: _vm._s(_vm.record.label) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          overflow: "hidden",
          "margin-bottom": "0",
          "padding-bottom": "0",
        },
      },
      _vm._l(_vm.memberNews, function (news, index) {
        return _c("div", { key: index }, [
          news.title
            ? _c("h4", [_vm._v(_vm._s(_vm.$t(news.title)))])
            : _vm._e(),
          news.content
            ? _c("h4", { domProps: { innerHTML: _vm._s(news.content) } })
            : _vm._e(),
          news.content || news.title
            ? _c(
                "p",
                { staticClass: "creattime-size" },
                [
                  _vm._v(_vm._s(_vm.$t(news.createTime)) + " "),
                  _c("a-divider", {
                    staticStyle: { margin: "0", padding: "0" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      0
    ),
    _c(
      "div",
      { staticStyle: { "margin-top": "0", "padding-top": "0" } },
      [
        _c(
          "a-button",
          { attrs: { type: "link" }, on: { click: _vm.moreNews } },
          [_vm._v("查看更多")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import request from "@/utils/request";

const mailTemplateApi = {
  Add: "/system-mms-user/mailTemplate/add",
  Update: "/system-mms-user/mailTemplate/update",
  Find: "/system-mms-user/mailTemplate/mailTemplates",
  Delete: "/system-mms-user/mailTemplate/delete",
};

//添加
export function addEmailTemplate(parameter) {
  return request({
    url: mailTemplateApi.Add,
    method: "post",
    data: parameter,
  });
}
//修改
export function updateEmailTemplate(parameter) {
  return request({
    url: mailTemplateApi.Update,
    method: "post",
    data: parameter,
  });
}
//查询
export function findEmailTemplates(parameter) {
  return request({
    url: mailTemplateApi.Find,
    method: "post",
    data: parameter,
  });
}
//删除
export function deleteEmailTemplate(parameter) {
  return request({
    url: mailTemplateApi.Delete,
    method: "post",
    data: parameter,
  });
}

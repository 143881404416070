import request from "@/utils/request";

const roleApi = {
  Add: "/system-mms-user/role/add",
  Delete: "/system-mms-user/role/delete",
  Update: "/system-mms-user/role/update",
  Query: "/system-mms-user/role/roles",
  Find: "/system-mms-user/role/menus",
  Increase: "/system-mms-user/role/add/menu/permission",
  Sync: "/system-business/enterpriseInfo/synchronousMembership",
};

//增加
export function addRole(parameter) {
  return request({
    url: roleApi.Add,
    method: "post",
    data: parameter,
  });
}
//删除
export function deleteRole(parameter) {
  return request({
    url: roleApi.Delete,
    method: "post",
    data: parameter,
  });
}
//修改
export function updateRole(parameter) {
  return request({
    url: roleApi.Update,
    method: "post",
    data: parameter,
  });
}
//查看
export function queryRole(parameter) {
  return request({
    url: roleApi.Query,
    method: "post",
    data: parameter,
  });
}

//添加角色菜单资源
export function addRoleMenu(parameter) {
  return request({
    url: roleApi.Increase,
    method: "post",
    data: parameter,
  });
}

//查询角色菜单资源
export function findRoleMenu(parameter) {
  return request({
    url: roleApi.Find,
    method: "post",
    data: parameter,
  });
}

//同步会员
export function synchronousMembership(parameter) {
  return request({
    url: roleApi.Sync,
    method: "post",
    data: parameter,
  });
}


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-form-model",
    {
      ref: "dynamicValidateForm",
      staticClass: "select-input-list-box",
      attrs: { layout: "inline", model: _vm.dynamicValidateForm },
    },
    _vm._l(_vm.record.columns, function (column, i) {
      return _c(
        "div",
        { key: i, staticClass: "list-col" },
        [
          _c(
            "a-form-model-item",
            { staticClass: "w-auto" },
            [
              _vm.record.options.multiple
                ? _c(
                    "CheckboxItem",
                    {
                      attrs: {
                        checked: _vm.dynamicValidateForm.domains[i].checked,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onCheckboxChange($event, i)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(column.label) + " ")]
                  )
                : _c(
                    "RadioItem",
                    {
                      attrs: {
                        checked: _vm.dynamicValidateForm.domains[i].checked,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onRadioChange($event, i)
                        },
                      },
                    },
                    [_vm._v(_vm._s(column.label))]
                  ),
            ],
            1
          ),
          _vm._l(column.list, function (item) {
            return _c("KFormModelItem", {
              key: item.key + "1",
              attrs: {
                record: item,
                config: _vm.config,
                parentDisabled: _vm.disabled,
                domains: _vm.dynamicValidateForm.domains,
                index: i,
                dynamicData: _vm.dynamicData,
              },
              on: { input: _vm.handleInput },
              model: {
                value: _vm.dynamicValidateForm.domains[i][item.model],
                callback: function ($$v) {
                  _vm.$set(_vm.dynamicValidateForm.domains[i], item.model, $$v)
                },
                expression: "dynamicValidateForm.domains[i][item.model]",
              },
            })
          }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import request from "@/utils/request";

const Api = {
  Find: "/system-mms-user/send/findSmsRecord",
  Add: "/system-mms-user/send/sms",
};

//发送短信
export function sendSms(parameter) {
  return request({
    url: Api.Add,
    method: "post",
    data: parameter,
  });
}
//分页查询已发送短信
export function findSmses(parameter) {
  return request({
    url: Api.Find,
    method: "post",
    data: parameter,
  });
}
export default {
  'menu.welcome': 'Welcome',
  'menu.home': '',
  'menu.index': 'Home',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',

  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.logout': 'Logout',

  'menu.info': 'Information',
  'menu.info.emailPublish': 'Mail Management',
  'menu.info.infoPublish': 'Station Information',
  'menu.info.smsPublish': 'SMS Management',

  'menu.filling': 'Info Filling',
  'menu.filling.annual': 'Annual Filling',
  'menu.filling.quarterly': 'Quarterly Filling',
  'menu.filling.monthly': 'Monthly Filling',

  'menu.form': 'Form Manage',
  'menu.form.design': 'Form Design',
  'menu.form.all': 'All Form',

  'menu.statistic': 'Statistic',
  'menu.statistic.annual': 'Annual Statistic',
  'menu.statistic.quarterly': 'Quarterly Statistic',
  'menu.statistic.monthly': 'Monthly Statistic',

  'menu.system': 'Settings',
  'menu.system.menu': 'Menus',
  'menu.system.role': 'Roles',
  'menu.system.sync': 'syncs',
  'menu.personal': 'Personal Center',
  'menu.personal.personSetting': 'Personal Settings',
  'menu.personal.passwordChange': 'Change Password',
  'account.personal.passwordChange': 'Change Password',
  'account.personal.personSetting': 'Personal Settings',
};

<template>
  <page-header-wrapper>
    <a-card>
      季度统计，敬请期待！
    </a-card>
  </page-header-wrapper>
</template>

<script>
export default {
  name:"QuarterStatistic",
  data(){
    return {}
  },
  methods:{
   
  }

}
</script>

<style>

</style>
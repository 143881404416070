export default {
  "user.login.userName": "userName",
  "user.login.password": "password",
  "user.login.username.placeholder": "Account: admin",
  "user.login.password.placeholder": "password: admin or ant.design",
  "user.login.message-invalid-credentials": "Invalid username or password",
  "user.login.message-invalid-verification-code": "Invalid verification code",
  "user.login.tab-login-credentials": "Credentials",
  "user.login.tab-login-mobile": "Mobile number",
  "user.login.mobile.placeholder": "Mobile number",
  "user.login.mobile.verification-code.placeholder": "Verification code",
  "user.login.remember-me": "Remember me",
  "user.login.forgot-password": "Forgot your password?",
  "user.login.sign-in-with": "Sign in with",
  "user.login.signup": "Sign up",
  "user.login.login": "Login",
  "user.login.prompt": "Login prompt:",
  "user.login.prompt.begin": "Account name and password are the same as",
  "user.login.prompt.among": "Member System",
  "user.login.prompt.end": "are consistent",
  "user.login.prompt.CapsLock":'Caps lock is enabled',
  "user.email.required": "Please enter your email!",
  "user.email.wrong-format": "The email address is in the wrong format!",
  "user.userName.required": "Please enter account name or email address",
  "user.password.required": "Please enter your password!",
  "user.password.twice.msg": "The passwords entered twice do not match!",
  "user.password.strength.msg": "The password is not strong enough",
  "user.password.strength.strong": "Strength: strong",
  "user.password.strength.medium": "Strength: medium",
  "user.password.strength.low": "Strength: low",
  "user.password.strength.short": "Strength: too short",
  "user.confirm-password.required": "Please confirm your password!",
  "user.phone-number.required": "Please enter your phone number!",
  "user.phone-number.wrong-format": "Please enter a valid phone number",
  "user.verification-code.required": "Please enter the verification code!",
  "user.apply.title": "Application For Membership",
  "user.apply.companyInfo.title": "Company Info",
  "user.apply.companyInfo.companyNameCn": "Company Name(CN)",
  "user.apply.companyInfo.companyNameCn.placeholder":
    "Please enter the company name in Chinese",
  "user.apply.companyInfo.companyNameEn": "Company Name(EN)",
  "user.apply.companyInfo.companyNameEn.placeholder":
    "Please enter the company name in English",
  "user.apply.companyInfo.unifiedSocialCreditCode": "USCI",
  "user.apply.companyInfo.unifiedSocialCreditCode.placeholder":
    "Please enter the unified social credit identifier",
  "user.apply.companyInfo.foundDate": "Found Date",
  "user.apply.companyInfo.foundDate.required": "Please selete found date",
  "user.apply.companyInfo.logo": "Logo",
  "user.apply.companyInfo.logo.btn": "Click to Upload",
  "user.apply.companyInfo.logoUrl.required": "Please uload your company LOGO",
  "user.apply.companyInfo.address": "Address",
  "user.apply.companyInfo.address.placeholder": "Please enter the address",
  "user.apply.companyInfo.address.required": "Please enter the address",
  "user.apply.companyInfo.location": "Location",
  "user.apply.companyInfo.location.placeholder": "Please select the location",
  "user.apply.companyInfo.location.required": "Please select the location",
  "user.apply.companyInfo.tel": "Tel",
  "user.apply.companyInfo.tel.placeholder": "Please enter the tel",
  "user.apply.companyInfo.tel.required": "Please enter the tel",
  "user.apply.companyInfo.fax": "Fax",
  "user.apply.companyInfo.fax.placeholder": "Please enter the fax",
  "user.apply.companyInfo.fax.required": "Please enter the fax",
  "user.apply.companyInfo.zipCode": "Zip Code",
  "user.apply.companyInfo.zipCode.placeholder": "Please enter the zip code",
  "user.apply.companyInfo.zipCode.required": "Please enter the zip code",
  "user.apply.companyInfo.email": "Email",
  "user.apply.companyInfo.email.placeholder": "Please enter the email",
  "user.apply.companyInfo.email.required": "Please enter the email",
  "user.apply.companyInfo.registeredCapital": "Registered Capital",
  "user.apply.companyInfo.registeredCapital.placeholder":
    "Please enter the registered capital",
  "user.apply.companyInfo.registeredCapital.required":
    "Please enter the registered capital",
  "user.apply.companyInfo.registeredCapital.unit": "million",
  "user.apply.companyInfo.profile": "Profile",
  "user.apply.companyInfo.profile.placeholder": "Please enter the profile",
  "user.apply.companyInfo.profile.required": "Please enter the profile",
  "user.apply.marketInfo.title": "Product And Sale Info",
  "user.apply.marketInfo.classifications": "Product Classification",
  "user.apply.marketInfo.classifications.required":
    "Please select the product classification",
  "user.apply.marketInfo.classification.PCB": "RPCB",
  "user.apply.marketInfo.classification.FPC": "FPCB",
  "user.apply.marketInfo.classification.PCBandFPC": "R-FPCB",
  "user.apply.marketInfo.classification.Material": "Material",
  "user.apply.marketInfo.classification.CCL": "CCL",
  "user.apply.marketInfo.classification.Potion": "Chemicals",
  "user.apply.marketInfo.classification.Equipment": "Equipment/Instrument",
  "user.apply.marketInfo.classification.ResearchInstitute": "Environment",
  "user.apply.marketInfo.classification.Other": "Other",
  "user.apply.marketInfo.sales": "Sales",
  "user.apply.marketInfo.sales.placeholder": "Product Capacity and Capability",
  "user.apply.marketInfo.sales.required": "Product Capacity and Capability",
  "user.apply.marketInfo.lastYearSales": "Last Year Annual Sales",
  "user.apply.marketInfo.lastYearSales.placeholder":
    "Please enter the last year annual sales",
  "user.apply.marketInfo.lastYearSales.required":
    "Please enter the last year annual sales",
  "user.apply.marketInfo.thisYearSales": "This Year's Annual Sales(Plan)",
  "user.apply.marketInfo.thisYearSales.placeholder":
    "Please enter the this year annual sales",
  "user.apply.marketInfo.thisYearSales.required":
    "Please enter the this year annual sales",
  "user.apply.workerInfo.title": "Worker Info",
  "user.apply.workerInfo.numberOfStaff": "Num Of Staff",
  "user.apply.workerInfo.numberOfStaff.placeholder":
    "Please enter the num of staff",
  "user.apply.workerInfo.numberOfStaff.required":
    "Please enter the num of staff",
  "user.apply.workerInfo.numberOfTechnicians": "Num Of Tech",
  "user.apply.workerInfo.numberOfTechnicians.placeholder":
    "Please enter the num of tech",
  "user.apply.workerInfo.numberOfTechnicians.required":
    "Please enter the num of tech",
  "user.apply.workerInfo.numberOfManagers": "Num Of Manager",
  "user.apply.workerInfo.numberOfManagers.placeholder":
    "Please enter the num of manager",
  "user.apply.workerInfo.numberOfManagers.required":
    "Please enter the num of manager",
  "user.apply.workerInfo.numberOfWorkers": "Num Of Worker",
  "user.apply.workerInfo.numberOfWorkers.placeholder":
    "Please enter the num of worker",
  "user.apply.workerInfo.numberOfWorkers.required":
    "Please enter the num of worker",
  "user.apply.workerInfo.functional": "Functional",
  "user.apply.workerInfo.functional.legalRepresentative":
    "legal Representative",
  "user.apply.workerInfo.functional.meetingContact": "Meeting Contact",
  "user.apply.workerInfo.functional.developmentContact": "Development Contact",
  "user.apply.workerInfo.functional.marketContact": "Market Contact",
  "user.apply.workerInfo.functional.protectionContact":
    "Enviroment Protected Contact",

  "user.apply.workerInfo.name": "Name",
  "user.apply.workerInfo.position": "Position",
  "user.apply.workerInfo.phone": "Phone",
  "user.apply.workerInfo.email": "Email",
  "user.apply.workerInfo.action": "Action",
  "user.apply.workerInfo.tableCommit.error":
    "Please enter the full member information.",
  "user.apply.accountInfo.title": "Account Info",
  "user.apply.accountInfo.username": "Username",
  "user.apply.accountInfo.username.placeholder": "Please enter the username",
  "user.apply.workerInfo.phone.error": "Please enter the correct mobile number",
  "user.apply.workerInfo.email.error": "Please enter the correct mailbox",
  "user.apply.accountInfo.username.required": "Please enter the username",
  "user.apply.accountInfo.password": "Password",
  "user.apply.accountInfo.password.placeholder": "Please enter the password",
  "user.apply.accountInfo.password.required": "Please enter the password",
  "user.apply.accountInfo.type": "Member Type",
  "user.apply.accountInfo.type.placeholder": "Please select the member type",
  "user.apply.accountInfo.type.required": "Please select the member type",
  "user.apply.accountInfo.associationId": "Association",
  "user.apply.accountInfo.associationId.placeholder":
    "Please select the association",
  "user.apply.accountInfo.associationId.required":
    "Please select the association",
  "user.apply.checkInfo": "The Info of Check Result",
  "user.apply.submitBtn": "Apply",
  "user.apply.saveSuccess": "Draft saved success",
  "service.professor.addSuccess": "Successfully added",
  "service.professor.error": "Add failed",
  "service.professor.deleteSuccess": "Deletion succeeded",
  "service.professor.deleteError": "Delete failed",
  "service.professor.editSuccess": "Modified successfully",
  "service.professor.editError": "Modification failed",

  "user.apply.account": "Existing account",
  "user.apply.fetchdata": "Fetch cached data",
  "user.apply.login": "Please login",
  "user.apply.vipnumber": "Member number",
  "user.apply.password": "Member password",
  "user.apply.pleasepassword":
    "Please enter the member password to be modified",
  "user.apply.username": "Please enter the member number",
  "user.apply.formphone":
    "Please enter the correct mobile number or landline number",
  "user.apply.formemail":
    "Please enter the correct email address Multiple uses; separate",
  "user.apply.formcredit": "Please enter the correct social credit code",
  "user.apply.formdesc": "Please enter at least 200 words of company profile",
  "user.apply.w": "ten thousand",
  "user.apply.companyInfo.fj": "Company profile(pdf)",
  "user.apply.companyInfo.head.hint": "Click Save to take effect",
};

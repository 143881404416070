<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <!-- 查询条件及保存数据 -->
        <a-form layout="inline" size="small">
          <a-row :gutter="48">
            <a-col :md="10" :sm="24">
              <a-form-item>
                <a-input style="width:250px" v-model="searchForm.object.content" placeholder="请输入要搜索的内容" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-button class="btn-item" type="primary" @click="search(1)">搜索</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-card>

    <a-card style="margin-top:24px">
      <div style="margin: 20px 0;margin-top: 0;">
        <a-button class="add" type="primary" @click="allSendVisible = true" style="float: right;" v-if="isAdmin">催报</a-button>
        <div style="clear: both;margin-top: 10px;"></div>
      </div>
      <!-- 查询数据展示 -->
      <a-table class="ant-table-striped" :columns="isAdmin?adminColumns:columns" :data-source="loadData" :row-key="record => record.id" :pagination="pagination" @change="changePage" bordered size="small" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
        <template slot="fillingType" slot-scope="text,record">
          {{getFormName(text)}}
        </template>
        <template v-if="isAdmin" slot="enterpriseName" slot-scope="text,record">
          {{text}}
        </template>
        <template  slot="createTime" slot-scope="text,record">
          {{text}}
        </template>
        <template slot="type" slot-scope="text">
          <template v-if="text == 1"><span>年报</span></template>
          <template v-if="text == 2"><span>季报</span></template>
          <template v-if="text == 3"><span>月报</span></template>
        </template>
      </a-table>
    </a-card>

    <a-modal width="65%" title="催报选择" :maskClosable="false" :visible="allSendVisible">
      <a-form :model="allSendForm" :rules="rules" ref="allSendForm">
        <a-form-item label="接收单位">
          <a-select style="width:300px" :allowClear="true" mode="multiple" v-model="allSendForm.ids" placeholder="请选择单位" :filter-option="filterOption">
            <template v-if="allSendFormFetching" >
            <a-spin slot="notFoundContent" size="small" />
            </template>
            <template v-else>
              <a-select-option :value="item.value" v-for="(item) in enterpriseInfoAlls" :key="item.value">
              {{item.label}}
              </a-select-option>
            </template>
          </a-select>
        </a-form-item>
        <a-form-item label="推送表单">
          <a-select style="width:300px" :allowClear="true" v-model="allSendForm.formId" placeholder="请选择表单">
            <template v-if="formIdFetching" >
              <a-spin slot="notFoundContent" size="small" />
            </template>
            <template v-else>
              <a-select-option :value="item.value" v-for="(item) in allForm" :key="item.value">
                {{item.label}}
              </a-select-option>
            </template>
            
          </a-select>
        </a-form-item>
        <a-form-item label="推送方式">
          <a-radio-group v-model="allSendForm.promptMethod" :default-value="0">
            <a-radio :value="0">
              邮箱
            </a-radio>
            <a-radio :value="1">
              短信
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="是否可重复催">
          <a-radio-group v-model="allSendForm.repetition" :default-value="0">
            <a-radio :value="0">
              否
            </a-radio>
            <a-radio :value="1">
              是
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button key="back" @click="allSendVisible = false">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="sendOk">
          催报
        </a-button>
      </template>
    </a-modal>

  </div>
</template>

<script>
import { sendInfo,sendTempalteInfo,findSendInfos, enterpriseInfoAll, enterpriseReminder } from '@/api/info/infoPublish'
import { getCellStyleFunction,findLabelByValue } from '@/utils/util'
import { getFormData } from "@/api/form/allForm.js"
import filter from "lodash.filter"
import { findCompanys } from "@/api/common"


const columns=[
  {
    title: '表单标题',
    dataIndex: "fillingType",
    customHeaderCell: getCellStyleFunction(180,14),
    customCell: getCellStyleFunction(180,14),
    scopedSlots: { customRender: 'fillingType' },

  },
  {
    title: '催报时间',
    dataIndex: 'promptTime',
    align: 'center',
    customHeaderCell: getCellStyleFunction(180,14),
    customCell: getCellStyleFunction(180,14)
  },
]
const adminColumns=[
  {
    title: '表单标题',
    dataIndex: "fillingType",
    customHeaderCell: getCellStyleFunction(180,14),
    customCell: getCellStyleFunction(180,14),
    scopedSlots: { customRender: 'fillingType' },

  },
  {
    title: '企业名称',
    dataIndex: "enterpriseName",
    customHeaderCell: getCellStyleFunction(180,14),
    customCell: getCellStyleFunction(180,14),
    scopedSlots: { customRender: 'enterpriseName' },
  },
  {
    title: '催报时间',
    dataIndex: 'promptTime',
    customHeaderCell: getCellStyleFunction(180,14),
    customCell: getCellStyleFunction(180,14)
  },
]
export default {
  name: 'InfoPublish',
  components: {
  },
  mounted() {
    this.search(1);  
    this.enterpriseInfoAll()  
    this.getFormData()
  },
  data() {
    this.columns = columns
    this.adminColumns = adminColumns
    return {
      selectEventlist: [],
      allSendVisible: false,
      isAdmin:this.$store.getters.nickname&&this.$store.getters.nickname.indexOf('admin')>-1,
      pagination: {
        current: 1,
        total: 0,
        pageIndex: 1,
        pageSize: 10,//每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10","20","50","100"],//每页中显示的数据
        showTotal: total => this.$t('page.pagination.total.prefix')+`${total}`+this.$t('page.pagination.total.suffix'),
      },
      isTemplateInfo: false,
      mailRecord: {},
      // 查询参数
      searchForm: {
        pageIndex: 1,
        pageSize: 10,
        object: {
          title: '',
          content: '',
          membershipNumber:'',
          templateId: undefined
        }
      },
      loadData: [ ],
      statusOptions: [
        { value: 1,label: "已发送" },
        { value: 2,label: "发送失败" },
      ],
      rules: {
        formId: [{ required: true, message: '请选择表单', trigger: 'blur' }],
        sendType: [{ required: true, message: '请选择推送方式', trigger: 'blur' }],
      },
      allSendForm: {
        ids: undefined,
        formId: undefined,
        promptMethod: 0,
        repetition: 0
      },
      enterpriseInfoAlls: [],
      enterpriseInfoAllSs: [],
      allForms: [],
      allForm: [],
      formIdFetching: false,
      allSendFormFetching: false
    }
  },

  methods: {
    findLabelByValue,
    getFormName(formId){
      if(!formId) return '';
      let formFilterItem = this.allForm.filter(item=>item.value==formId);
      if(formFilterItem && formFilterItem.length>0){
        return formFilterItem[0].label;
      }else{
        return '';
      }
    },
    changeData(label, value, list) {
      let arr = []
      list.forEach(item => {
        arr.push({ value: item[value], label: item[label] })
      });
      return arr
    },
    enterpriseInfoAll() {
      enterpriseInfoAll({}).then(res => {
        this.enterpriseInfoAllSs = this.changeData('enterpriseName', 'id', res)
        this.enterpriseInfoAlls = this.changeData('enterpriseName', 'id', res)
      })
    },
    getFormData() {
      let object = {
        pageIndex: 1,
        pageSize: 1000
      }
      getFormData(object).then(res => {
        if (res.code == 200) {
          this.allForm = this.changeData('title', 'id', res.data.pageObject)
          this.allForms = this.changeData('title', 'id', res.data.pageObject)
        } else {
          this.$message.error(res.msg)
        }
        
      })
    },
    //重置
    resetting() {
      this.searchForm={
        pageIndex: 1,
        pageSize: 10,
        object: {
          title: '',
          content: '',
          templateId: undefined,
        }
      }
    },
    //根据查询菜单列表数据
    search(pageIndex) {
      this.searchForm.pageIndex = pageIndex ? pageIndex : 1;
      if(!this.isAdmin){
        this.searchForm.object.membershipNumber=localStorage.getItem('userName')
      }
      findSendInfos(this.searchForm).then(res => {
        if(res.code==200) {
          this.pagination.total=res.data.total;
          this.pagination.current=this.searchForm.pageIndex;
          this.loadData = res.data.pageObject;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //分页事件
    changePage(pagination) {
      this.searchForm.pageIndex=pagination.current;
      this.searchForm.pageSize=pagination.pageSize;
      this.searchForm.current=pagination.current;

      this.search(this.searchForm.pageIndex);
    },



    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    
    sendOk() {
      if (!this.allSendForm.formId || this.allSendForm.formId.length < 1) {
        this.$message.error('请选择表单')
        return
      }
      enterpriseReminder(this.allSendForm).then((res) => {
        console.log("res", res);
        if (res.code == 200) {
          this.$message.success('催报完成')
          this.allSendVisible = false
        } else {
          this.$message.success(res.msg)
        }
      })
    },
    
  }
}
</script>
<style lang="less" scoped>
</style>
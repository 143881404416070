var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-config-provider",
    { attrs: { locale: _vm.locale } },
    [
      typeof _vm.value.list !== "undefined" &&
      typeof _vm.value.config !== "undefined"
        ? _c(
            "a-form",
            _vm._b(
              {
                staticClass: "k-form-build-9136076486841527",
                style: _vm.value.config.customStyle,
                attrs: {
                  selfUpdate: "",
                  layout: _vm.value.config.layout,
                  hideRequiredMark: _vm.value.config.hideRequiredMark,
                  form: _vm.form,
                },
                on: { submit: _vm.handleSubmit },
              },
              "a-form",
              _vm.$attrs,
              false
            ),
            _vm._l(_vm.value.list, function (record, index) {
              return record.show
                ? _c("buildBlocks", {
                    key: index,
                    ref: "buildBlocks",
                    refInFor: true,
                    attrs: {
                      record: record,
                      dynamicData: _vm.getDynamicData,
                      config: _vm.config,
                      disabled: _vm.disabled,
                      formConfig: _vm.value.config,
                      validatorError: _vm.validatorError,
                    },
                    on: { handleReset: _vm.reset, change: _vm.handleChange },
                  })
                : _vm._e()
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
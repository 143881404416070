var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      staticStyle: { top: "20px" },
      attrs: {
        title: "预览",
        visible: _vm.visible,
        okText: "获取数据",
        cancelText: "关闭",
        destroyOnClose: true,
        centered: true,
        dialogStyle: _vm.dialogStyle,
        bodyStyle: _vm.bodyStyle,
        wrapClassName: "k-form-modal",
        width: `${_vm.previewWidth}px`,
      },
      on: { ok: _vm.handleGetData, cancel: _vm.handleCancel },
    },
    [
      _c("k-form-build", {
        ref: "KFormBuild",
        attrs: { value: _vm.jsonData },
        on: { change: _vm.handleChange, submit: _vm.handleSubmit },
      }),
      _c("jsonModel", { ref: "jsonModel" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <page-header-wrapper>
    <a-card :bordered="false" style="height: 70vh;">
      <div style="position: relative; top: 20vh; width: 50%;margin-left: 25%;">
        <div style="font-size: 16px;">年份选择（如果不选择年份，则生成季度数据）</div>
        <a-select v-model="timeInterval" placeholder="请选择年度" style="width: 100%;" clearable>
          <a-select-option :value="item.value" v-for="(item,index) in annualOptions" :key="index" >
            {{item.label}}
          </a-select-option>
        </a-select>
        <a-button @click="sync" type="primary" style="width: 30%; margin-left: 35%; margin-top: 10vh;">
          同步
        </a-button>
      </div>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { synchronousMembership } from '@/api/system/role'
import {annualOptions} from '@/utils/util.js'
export default {
  name: 'Sync',
  components: {  },
  data() {
    return {
      timeInterval:'',
      annualOptions,
    }
  },
  methods: {
    sync() {
      if (this.timeInterval) {
        synchronousMembership({timeInterval: this.timeInterval}).then(res => {
          if (res.code == 200) {
            this.$message.success('同步成功')
          }
        })
      } else {
        this.$message.error(res.msg)
      }
    },


  },
  mounted() {

  }
}
</script>

<style lang="less">
</style>

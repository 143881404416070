var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.prefixCls }, [
    _c("div", { ref: "editor", staticClass: "editor-wrapper" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
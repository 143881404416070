var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "json-box-9136076486841527" },
      [
        _c("Codemirror", {
          ref: "myEditor",
          staticStyle: { height: "100%" },
          attrs: { value: _vm.editorJson },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "copy-btn-box-9136076486841527" },
      [
        _c(
          "Button",
          {
            staticClass: "copy-btn",
            attrs: {
              type: "primary",
              "data-clipboard-action": "copy",
              "data-clipboard-text": _vm.editorJson,
            },
            on: { click: _vm.handleCopyJson },
          },
          [_vm._v(" 复制数据 ")]
        ),
        _c(
          "Button",
          { attrs: { type: "primary" }, on: { click: _vm.handleExportJson } },
          [_vm._v(" 导出代码 ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
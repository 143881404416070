var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-result", {
    attrs: {
      status: "404",
      title: "404",
      "sub-title": "Sorry, the page you visited does not exist.",
    },
    scopedSlots: _vm._u([
      {
        key: "extra",
        fn: function () {
          return [
            _c(
              "a-button",
              { attrs: { type: "primary" }, on: { click: _vm.toHome } },
              [_vm._v(" Back Home ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
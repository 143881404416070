<template>
  <div class="pcbtable">
    <a-table :columns="columns" :data-source="data" bordered :pagination="false" :rowKey="record => record.key">
      <template slot="factoryState" slot-scope="text, record">
        {{ handleText(record) }}
      </template>
      <template slot="factoryName" slot-scope="text, record">
        <a-input v-if="formMode != 'view'" v-model="record.factoryName" placeholder="请输入"></a-input>
        <div v-else>{{record.factoryName}}</div>
      </template>
      <template slot="locationMunicipalLevel" slot-scope="text, record">
        <a-input v-if="formMode != 'view'" v-model="record.locationMunicipalLevel" placeholder="请输入"></a-input>
        <div v-else>{{record.locationMunicipalLevel}}</div>
      </template>
      <!-- <template slot="totalAmountInvestment" slot-scope="text, record">
        <a-input v-if="formMode != 'view'" v-model="record.totalAmountInvestment" placeholder="请输入"></a-input>
        <div v-else>{{record.totalAmountInvestment}}</div>
      </template> -->
      <template slot="capacity" slot-scope="text, record">
        <a-input v-if="formMode != 'view'" v-model="record.capacity" placeholder="请输入"></a-input>
        <div v-else>{{record.capacity}}</div>
      </template>
      <template slot="productPositioning" slot-scope="text, record">
        <a-input v-if="formMode != 'view'" v-model="record.productPositioning" placeholder="请输入"></a-input>
        <div v-else>{{record.productPositioning}}</div>
      </template>
      <template slot="outputValue" slot-scope="text, record">
        <a-input v-if="formMode != 'view'" v-model="record.outputValue" placeholder="请输入"></a-input>
        <div v-else>{{record.outputValue}}</div>
      </template>
      <template slot="caozuo" slot-scope="text, record,index" v-if="formMode != 'view'">
        <a-icon v-if="!record.isremove" type="plus-circle" style="font-size:24px;cursor:pointer;" @click="addType(index,record)" />
        <a-icon v-else type="minus-circle" style="font-size:24px;cursor:pointer;" @click="removeItem(index,record)" />
      </template>
    </a-table>
  </div>
</template>

<script>
import uniqueid from 'lodash.uniqueid'
const initdata = [
  {
    factoryState: 1,
    factoryName: '',
    enterpriseId: '',
    id:'',
    locationMunicipalLevel: '',
    totalAmountInvestment: '',
    capacity: '',
    productPositioning: '',
    outputValue:'',
    key: uniqueid('1'),
  },
  {
    factoryState: 2,
    factoryName: '',
    enterpriseId: '',
    id:'',
    locationMunicipalLevel: '',
    totalAmountInvestment: '',
    capacity: '',
    productPositioning: '',
    outputValue:'',
    key: uniqueid('1'),
  },
  {
    factoryState: 3,
    factoryName: '',
    enterpriseId: '',
    id:'',
    locationMunicipalLevel: '',
    totalAmountInvestment: '',
    capacity: '',
    productPositioning: '',
    outputValue:'',
    key: uniqueid('1'),
  },
]
export default {
  name: 'pcbtable',
  props: {
    childForm: {
      type: Array,
    },
    formMode: {
      type: String,
      default() {
        return 'edit'
      },
    },
    enterpriseBusinessType: {
      type: [Number, String],
    },
  },
  components: {},
  watch: {
    childForm: {
      handler(arr) {
        if (arr && arr.length > 0) {
          let d = arr.map((item) => {
            return {
              ...item,
              key: uniqueid('1'),
              isremove: true,
            }
          })
          this.data = [...d, ...initdata]
        }
        // console.log('arr', arr)
      },
    },
    formMode: {
      immediate: true,
      handler(val) {
        if (val == 'view') {
          this.columns.splice(this.columns.length - 1, 1)
          // console.log(this.columns, 'waa')
        }
      },
    },
  },
  data() {
    return {
      //enterpriseLayoutInfos
      form: {},
      columns: [
        {
          title: '状态',
          dataIndex: 'factoryState',
          scopedSlots: { customRender: 'factoryState' },
          width: '120px',
        },
        {
          title: '工厂名称',
          className: 'factoryName',
          dataIndex: 'factoryName',
          scopedSlots: { customRender: 'factoryName' },
        },
        {
          title: '所在地（市级）',
          dataIndex: 'locationMunicipalLevel',
          scopedSlots: { customRender: 'locationMunicipalLevel' },
        },
        // {
        //   title: '投资总额(万元)',
        //   dataIndex: 'totalAmountInvestment',
        //   scopedSlots: { customRender: 'totalAmountInvestment' },
        // },
        {
          title: '产品定位',
          dataIndex: 'productPositioning',
          scopedSlots: { customRender: 'productPositioning' },
        },
        {
          title: '产量（万平方米/年）',
          dataIndex: 'capacity',
          scopedSlots: { customRender: 'capacity' },
        },
        // {
        //   title: '产能（万平方米/年）',
        //   dataIndex: 'capacity',
        //   scopedSlots: { customRender: 'capacity' },
        // },
        {
          title: '产值（万元）',
          dataIndex: 'outputValue',
          scopedSlots: { customRender: 'outputValue' },
        },
        {
          title: '操作',
          dataIndex: 'caozuo',
          scopedSlots: { customRender: 'caozuo' },
        },
      ],
      data: initdata,
    }
  },
  created() {},
  methods: {
    handleText(record) {
      let text = ''
      let _arr = [
        {
          type: '1',
          title: '已有',
        },
        {
          type: '2',
          title: '在建',
        },
        {
          type: '3',
          title: '技改扩产项目',
        },
      ]

      const isText = _arr.find((item) => item.type == record.factoryState)
      if (isText) {
        text = isText.title
      }

      return text
    },
    addType(index, record) {
      this.data.splice(index + 1, 0, {
        factoryState: record.factoryState,
        factoryName: '',
        locationMunicipalLevel: '',
        totalAmountInvestment: '',
        capacity: '',
        isremove: true,
        productPositioning: '',
        outputValue:'',
        key: uniqueid('1'),
      })
      // console.log( this.data,'---')
    },
    removeItem(index) {
      this.data.splice(index, 1)
    },
    resetForm() {
      this.data = [
        {
          factoryState: 1,
          factoryName: '',
          locationMunicipalLevel: '',
          totalAmountInvestment: '',
          capacity: '',
          productPositioning: '',
          outputValue:'',
          key: uniqueid('1'),
        },
        {
          factoryState: 2,
          factoryName: '',
          locationMunicipalLevel: '',
          totalAmountInvestment: '',
          capacity: '',
          productPositioning: '',
          outputValue:'',
          key: uniqueid('1'),
        },
        {
          factoryState: 3,
          factoryName: '',
          locationMunicipalLevel: '',
          totalAmountInvestment: '',
          capacity: '',
          productPositioning: '',
          outputValue:'',
          key: uniqueid('1'),
        },
      ]
    },
    submit(findstep, direction) {
      //后期优化可以添加判断是否通过验证再走调用emit方法
      let bool = 0
      this.data.forEach((item) => {
        if (item.factoryState == 1 && item.factoryName != '') {
          bool += 1
        } else if (item.factoryState == 2 && item.factoryName != '') {
          bool += 1
        } else if (item.factoryState == 3 && item.factoryName != '') {
          bool += 1
        }
      })
      if (bool >= 1) {
        // this.$emit('tonext', this.enterpriseBusinessType == 1 ? 4 : 3)
        let cur = findstep.current
        if (direction === 'next') {
          cur++
        } else {
          cur--
          if (cur < 0) {
            cur = 0
          }
        }
        this.$emit('tonext', cur)
      } else {
        this.$message.error('请填写其他状态的工厂名称')
      }
    },
  },
}
</script>

<style scoped lang="less">
.pcbtable {
}
</style>

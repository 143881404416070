var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "isFixedMask" }, [
    _c(
      "div",
      { staticClass: "loadbox" },
      [_c("a-spin", { attrs: { size: "large", tip: _vm.txt } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import antdEnUS from 'ant-design-vue/es/locale-provider/en_US'
import momentEU from 'moment/locale/eu'
import global from './en-US/global'

import menu from './en-US/menu'
import user from './en-US/user'

import dashboard from './en-US/dashboard'
import account from './en-US/account'
import reports from "./en-US/reports/reports";
import quarter from "./en-US/quarter";

const components = {
  antLocale: antdEnUS,
  momentName: 'eu',
  momentLocale: momentEU
}

export default {
  message: "-",
  "layouts.usermenu.dialog.title": "Message",
  "layouts.usermenu.dialog.content": "Are you sure you would like to logout?",
  ...components,
  ...global,
  ...menu,
  ...user,
  ...dashboard,
  ...account,
  ...reports,
  ...quarter,
};

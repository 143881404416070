var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "operating-area" }, [
    _c(
      "div",
      { staticClass: "left-btn-box" },
      [
        _c("a-tooltip", { attrs: { title: "保存" } }, [
          _vm.toolbars.includes("save")
            ? _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleSave")
                    },
                  },
                },
                [
                  _c("a-icon", { attrs: { type: "save" } }),
                  _vm.showToolbarsText
                    ? _c("span", [_vm._v("保存")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("a-tooltip", { attrs: { title: "预览" } }, [
          _vm.toolbars.includes("preview")
            ? _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handlePreview")
                    },
                  },
                },
                [
                  _c("a-icon", { attrs: { type: "chrome" } }),
                  _vm.showToolbarsText
                    ? _c("span", [_vm._v("预览")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("a-tooltip", { attrs: { title: "导入" } }, [
          _vm.toolbars.includes("importJson")
            ? _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleOpenImportJsonModal")
                    },
                  },
                },
                [
                  _c("a-icon", { attrs: { type: "upload" } }),
                  _vm.showToolbarsText
                    ? _c("span", [_vm._v("导入")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("a-tooltip", { attrs: { title: "生成JSON" } }, [
          _vm.toolbars.includes("exportJson")
            ? _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleOpenJsonModal")
                    },
                  },
                },
                [
                  _c("a-icon", { attrs: { type: "credit-card" } }),
                  _vm.showToolbarsText
                    ? _c("span", [_vm._v("生成JSON")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("a-tooltip", { attrs: { title: "生成代码" } }, [
          _vm.toolbars.includes("exportCode")
            ? _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleOpenCodeModal")
                    },
                  },
                },
                [
                  _c("a-icon", { attrs: { type: "code" } }),
                  _vm.showToolbarsText
                    ? _c("span", [_vm._v("生成代码")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("a-tooltip", { attrs: { title: "清空" } }, [
          _vm.toolbars.includes("reset")
            ? _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleReset")
                    },
                  },
                },
                [
                  _c("a-icon", { attrs: { type: "delete" } }),
                  _vm.showToolbarsText
                    ? _c("span", [_vm._v("清空")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("Divider", { attrs: { type: "vertical" } }),
        _c("a-tooltip", { attrs: { title: "撤销" } }, [
          _vm.toolbars.includes("undo")
            ? _c(
                "a",
                {
                  class: { disabled: !(_vm.recordList.length > 0) },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleUndo")
                    },
                  },
                },
                [
                  _c("a-icon", { attrs: { type: "undo" } }),
                  _vm.showToolbarsText
                    ? _c("span", [_vm._v("撤销")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("a-tooltip", { attrs: { title: "重做" } }, [
          _vm.toolbars.includes("redo")
            ? _c(
                "a",
                {
                  class: { disabled: !(_vm.redoList.length > 0) },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleRedo")
                    },
                  },
                },
                [
                  _c("a-icon", { attrs: { type: "redo" } }),
                  _vm.showToolbarsText
                    ? _c("span", [_vm._v("重做")])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._t("left-action"),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "right-btn-box" },
      [
        _vm._t("right-action"),
        _c("a-tooltip", { attrs: { title: "关闭" } }, [
          _vm.toolbars.includes("close")
            ? _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleClose")
                    },
                  },
                },
                [_c("a-icon", { attrs: { type: "close" } })],
                1
              )
            : _vm._e(),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
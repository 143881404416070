var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "basicCompany" },
    [
      _c(
        "a-form-model",
        {
          ref: "enterprise",
          attrs: { model: _vm.form, rules: _vm.rules, colon: false },
        },
        [
          _c("div", { staticClass: "small_title" }, [_vm._v("经济指标")]),
          _c(
            "a-form-model-item",
            { attrs: { label: "营业收入", prop: "operatingIncome" } },
            [
              _c("a-input", {
                attrs: {
                  "addon-after": "万元",
                  type: "number",
                  placeholder: "请输入营业收入",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.operatingIncome,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "operatingIncome", $$v)
                  },
                  expression: "form.operatingIncome",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "净利润", prop: "netProfit" } },
            [
              _c("a-input", {
                attrs: {
                  "addon-after": "万元",
                  type: "number",
                  placeholder: "请输入净利润",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.netProfit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "netProfit", $$v)
                  },
                  expression: "form.netProfit",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "营业总成本", prop: "totalOperatingCost" } },
            [
              _c("a-input", {
                attrs: {
                  "addon-after": "万元",
                  type: "number",
                  placeholder: "请输入营业总成本",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.totalOperatingCost,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "totalOperatingCost", $$v)
                  },
                  expression: "form.totalOperatingCost",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "纳税总额", prop: "totalTaxPaid" } },
            [
              _c("a-input", {
                attrs: {
                  "addon-after": "万元",
                  type: "number",
                  placeholder: "请输入纳税总额",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.totalTaxPaid,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "totalTaxPaid", $$v)
                  },
                  expression: "form.totalTaxPaid",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "small_title" }, [_vm._v("总成本比例")]),
          _c(
            "a-form-model-item",
            { attrs: { label: "人工费用", prop: "artificial" } },
            [
              _c("a-input", {
                attrs: {
                  "addon-after": "%",
                  type: "number",
                  placeholder: "请输入",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.artificial,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "artificial", $$v)
                  },
                  expression: "form.artificial",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "原材料", prop: "rawMaterials" } },
            [
              _c("a-input", {
                attrs: {
                  "addon-after": "%",
                  type: "number",
                  placeholder: "请输入",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.rawMaterials,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rawMaterials", $$v)
                  },
                  expression: "form.rawMaterials",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "制造费用", prop: "manufacturingExpense" } },
            [
              _c("a-input", {
                attrs: {
                  type: "number",
                  "addon-after": "%",
                  placeholder: "请输入",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.manufacturingExpense,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "manufacturingExpense", $$v)
                  },
                  expression: "form.manufacturingExpense",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "其它", prop: "other" } },
            [
              _c("a-input", {
                attrs: {
                  "addon-after": "%",
                  type: "number",
                  placeholder: "请输入",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.other,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "other", $$v)
                  },
                  expression: "form.other",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "small_title" }, [_vm._v("出口")]),
          _c(
            "a-form-model-item",
            { attrs: { label: "出口额", prop: "exports" } },
            [
              _c("a-input", {
                attrs: {
                  "addon-after": "万元",
                  type: "number",
                  placeholder: "请输入出口额",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.exports,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "exports", $$v)
                  },
                  expression: "form.exports",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "主要出口国家", prop: "exportingCountry" } },
            [
              _c("a-input", {
                attrs: {
                  "addon-after": "",
                  placeholder: "请输入主要出口国家",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.exportingCountry,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "exportingCountry", $$v)
                  },
                  expression: "form.exportingCountry",
                },
              }),
            ],
            1
          ),
          _vm.formType == "1"
            ? [
                _c("div", { staticClass: "small_title" }, [
                  _vm._v("企业库存指标"),
                ]),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "2022年末", prop: "beginYearInventory" } },
                  [
                    _c("a-input", {
                      attrs: {
                        "addon-after": "万元",
                        type: "number",
                        placeholder: "请输入",
                        disabled: _vm.formMode == "view" ? true : false,
                      },
                      model: {
                        value: _vm.form.beginYearInventory,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "beginYearInventory", $$v)
                        },
                        expression: "form.beginYearInventory",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "2022年初", prop: "endYearInventory" } },
                  [
                    _c("a-input", {
                      attrs: {
                        "addon-after": "万元",
                        type: "number",
                        placeholder: "请输入",
                        disabled: _vm.formMode == "view" ? true : false,
                      },
                      model: {
                        value: _vm.form.endYearInventory,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "endYearInventory", $$v)
                        },
                        expression: "form.endYearInventory",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "small_title" }, [
                  _vm._v("应收账款余额"),
                ]),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "2022年末", prop: "beginYearAccount" } },
                  [
                    _c("a-input", {
                      attrs: {
                        "addon-after": "万元",
                        type: "number",
                        placeholder: "请输入",
                        disabled: _vm.formMode == "view" ? true : false,
                      },
                      model: {
                        value: _vm.form.beginYearAccount,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "beginYearAccount", $$v)
                        },
                        expression: "form.beginYearAccount",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "2022年初", prop: "endYearAccount" } },
                  [
                    _c("a-input", {
                      attrs: {
                        "addon-after": "万元",
                        type: "number",
                        placeholder: "请输入",
                        disabled: _vm.formMode == "view" ? true : false,
                      },
                      model: {
                        value: _vm.form.endYearAccount,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "endYearAccount", $$v)
                        },
                        expression: "form.endYearAccount",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c("div", { staticClass: "small_title" }, [_vm._v("企业研发能力")]),
          _c(
            "a-form-model-item",
            { attrs: { label: "研发投入", prop: "developmentInvestment" } },
            [
              _c("a-input", {
                attrs: {
                  "addon-after": "万元",
                  type: "number",
                  placeholder: "请输入研发投入",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.developmentInvestment,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "developmentInvestment", $$v)
                  },
                  expression: "form.developmentInvestment",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "工程技术人员", prop: "technicalPersonnelTotal" },
            },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入",
                  type: "number",
                  "addon-after": "人",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.technicalPersonnelTotal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "technicalPersonnelTotal", $$v)
                  },
                  expression: "form.technicalPersonnelTotal",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "small_title" }, [
            _vm._v("获取（发明专利）数"),
          ]),
          _c(
            "a-form-model-item",
            { attrs: { label: "2022年", prop: "patentInvention" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入",
                  type: "number",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.patentInvention,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "patentInvention", $$v)
                  },
                  expression: "form.patentInvention",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "2022年末累计", prop: "cumulativeInvention" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入",
                  type: "number",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.cumulativeInvention,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cumulativeInvention", $$v)
                  },
                  expression: "form.cumulativeInvention",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "small_title" }, [
            _vm._v("获取（其他专利）数"),
          ]),
          _c(
            "a-form-model-item",
            { attrs: { label: "2022年", prop: "otherPatentInvention" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入",
                  type: "number",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.otherPatentInvention,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "otherPatentInvention", $$v)
                  },
                  expression: "form.otherPatentInvention",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "2022年末累计",
                prop: "otherCumulativeInvention",
              },
            },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入",
                  type: "number",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.otherCumulativeInvention,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "otherCumulativeInvention", $$v)
                  },
                  expression: "form.otherCumulativeInvention",
                },
              }),
            ],
            1
          ),
          _vm.enterpriseBusinessType != 2
            ? _c("div", { staticClass: "small_title" }, [
                _vm._v("企业专精特新产品(只填一种)"),
              ])
            : _vm._e(),
          _vm.enterpriseBusinessType != 2
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "细分产品名称",
                    prop: "segmentedProductName",
                  },
                },
                [
                  _c("a-input", {
                    attrs: {
                      "addon-after": "",
                      placeholder: "请输入",
                      disabled: _vm.formMode == "view" ? true : false,
                    },
                    model: {
                      value: _vm.form.segmentedProductName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "segmentedProductName", $$v)
                      },
                      expression: "form.segmentedProductName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "产值", prop: "outputValue" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入",
                  type: "number",
                  "addon-after": "万元",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.outputValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "outputValue", $$v)
                  },
                  expression: "form.outputValue",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "产量", prop: "speciesCapacity" } },
            [
              _c("a-input", {
                attrs: {
                  "addon-after": "万平方米",
                  placeholder: "请输入",
                  type: "number",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.speciesCapacity,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "speciesCapacity", $$v)
                  },
                  expression: "form.speciesCapacity",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "主要应用领域", prop: "applicationField" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入",
                  "addon-after": "",
                  disabled: _vm.formMode == "view" ? true : false,
                },
                model: {
                  value: _vm.form.applicationField,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "applicationField", $$v)
                  },
                  expression: "form.applicationField",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
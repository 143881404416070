import {
  addPcb,
  addCcl,
  getCclDetail,
  PcbDetail,
  pcbUpdateForm,
  cclUpdateForm,
  addData,
  UpdDateDataForm,
  getDataDetail,
  pcbdel,
  ccldel,
  datadel,
} from "@/api/mas/pcb.js";
/**
 *
 * @param {*} Vue 当前调用的Vue组件实例化对象
 * @param {*} data 表单详情数据
 * @param {*} formType 表单提交的是 pcb | ccl | 其他表单数据
 * @param {*} status 新增或者修改表单 add | edit | del
 */

export const APIStatus = [
  {
    matchNumber: 1,
    type: "1",
    add: (Vue, data, success, err) => addPcbAPI(Vue, data, success, err),
    edit: (Vue, data, success, error) => updPcbAPI(Vue, data, success, error),
    detail: (Vue, id, success, error) => PcbDetailAPI(Vue, id, success, error),
    del: (Vue, id, success, error) => pcbdelAPI(Vue, id, success, error),
  },
  {
    matchNumber: 2,
    type: "2",
    add: (Vue, data, success, err) => addCClAPI(Vue, data, success, err),
    edit: (Vue, data, success, error) => updCClAPI(Vue, data, success, error),
    detail: (Vue, id, success, error) =>
      getCclDetailAPI(Vue, id, success, error),
    del: (Vue, id, success, error) => ccldelAPI(Vue, id, success, error),
  },
  {
    matchNumber: 3,
    type: "3",
    add: (Vue, data, success, err) => addDataAPI(Vue, data, success, err),
    edit: (Vue, data, success, error) => updDataAPI(Vue, data, success, error),
    detail: (Vue, id, success, error) =>
      getDataDetailAPI(Vue, id, success, error),
    del: (Vue, id, success, error) => datadelAPI(Vue, id, success, error),
  },
  {
    matchNumber: 4,
    type: "4",
    add: (Vue, data, success, err) => addDataAPI(Vue, data, success, err),
    edit: (Vue, data, success, error) => updDataAPI(Vue, data, success, error),
    detail: (Vue, id, success, error) =>
      getDataDetailAPI(Vue, id, success, error),
    del: (Vue, id, success, error) => datadelAPI(Vue, id, success, error),
  },
  {
    matchNumber: 5,
    type: "5",
    add: (Vue, data, success, err) => addDataAPI(Vue, data, success, err),
    edit: (Vue, data, success, error) => updDataAPI(Vue, data, success, error),
    detail: (Vue, id, success, error) =>
      getDataDetailAPI(Vue, id, success, error),
    del: (Vue, id, success, error) => datadelAPI(Vue, id, success, error),
  },
  {
    matchNumber: 6,
    type: "6",
    add: (Vue, data, success, err) => addDataAPI(Vue, data, success, err),
    edit: (Vue, data, success, error) => updDataAPI(Vue, data, success, error),
    detail: (Vue, id, success, error) =>
      getDataDetailAPI(Vue, id, success, error),
    del: (Vue, id, success, error) => datadelAPI(Vue, id, success, error),
  },
];

export function AssignmentForm(
  Vue,
  data,
  status,
  formType = "1",
  success,
  err
) {
  switch (status) {
    case "add":
      let Prerequest = APIStatus.find((item) => item.type === formType);
      if (Prerequest) Prerequest.add(Vue, data, success, err);
      break;
    case "edit":
      let Prerequest_ = APIStatus.find((item) => item.type === formType);
      if (Prerequest_) Prerequest_.edit(Vue, data, success, err);
      break;
    case "detail":
      let Predetail = APIStatus.find((item) => item.type === formType);
      if (Predetail) Predetail.detail(Vue, data, success, err);
      break;
    case "del":
      let Predel = APIStatus.find((item) => item.type === formType);
      if (Predel) Predel.del(Vue, data, success, err);
      break;

    default:
      console.log("进入default");
  }
}

//添加pcb表单数据操作
const addPcbAPI = (Vue, data, success, error) => {
  addPcb(data)
    .then((res) => {
      if (res.code === 200) {
        success && success(res);
        Vue.$message.success("添加成功");
      } else {
        Vue.$message.error(res.msg);
      }
    })
    .catch((err) => {
      if (err) {
        error();
      }
    });
};

//更新pcb表单数据操作
const updPcbAPI = (Vue, data, success, error) => {
  pcbUpdateForm(data)
    .then((res) => {
      if (res.code === 200) {
        success && success(res);
        Vue.$message.success("修改成功");
      } else {
        Vue.$message.error(res.msg);
      }
    })
    .catch((err) => {
      if (err) {
        error();
      }
    });
};

//获取pcb申报表单详情页数据
const PcbDetailAPI = (Vue, id, success, error) => {
  PcbDetail(id)
    .then((res) => {
      if (res.code === 200) {
        success && success(res);
      }
    })
    .catch((err) => {
      if (err) {
        error();
      }
    });
};

//新增ccl申报表单
const addCClAPI = (Vue, data, success, error) => {
  addCcl(data)
    .then((res) => {
      if (res.code === 200) {
        success && success(res);
        Vue.$message.success("添加成功");
      } else {
        Vue.$message.error(res.msg);
      }
    })
    .catch((err) => {
      if (err) {
        error();
      }
    });
};

//更新ccl表单数据
const updCClAPI = (Vue, data, success, error) => {
  cclUpdateForm(data)
    .then((res) => {
      if (res.code === 200) {
        success && success(res);
        Vue.$message.success("修改成功");
      } else {
        Vue.$message.error(res.msg);
      }
    })
    .catch((err) => {
      if (err) {
        error();
      }
    });
};

//获取ccl详情数据
const getCclDetailAPI = (Vue, id, success, error) => {
  getCclDetail(id)
    .then((res) => {
      if (res.code === 200) {
        success && success(res);
      }
    })
    .catch((err) => {
      if (err) {
        error();
      }
    });
};

//获取data表详情字段
const getDataDetailAPI = (Vue, id, success, error) => {
  getDataDetail(id)
    .then((res) => {
      if (res.code === 200) {
        success && success(res);
      }
    })
    .catch((err) => {
      if (err) {
        error();
      }
    });
};

//新增data表
const addDataAPI = (Vue, data, success, err) => {
  addData(data)
    .then((res) => {
      if (res.code === 200) {
        success && success(res);
        Vue.$message.success("添加成功");
      } else {
        Vue.$message.error(res.msg);
      }
    })
    .catch((err) => {
      if (err) {
        error();
      }
    });
};

//更新修改data表数据
const updDataAPI = (Vue, data, success, error) => {
  UpdDateDataForm(data)
    .then((res) => {
      if (res.code === 200) {
        success && success(res);
        Vue.$message.success("修改成功");
      } else {
        Vue.$message.error(res.msg);
      }
    })
    .catch((err) => {
      if (err) {
        error();
      }
    });
};

//pcb删除接口
const pcbdelAPI = (Vue, id, success, error) => {
  pcbdel(id)
    .then((res) => {
      if (res.code === 200) {
        success && success(res);
      }
    })
    .catch((err) => {
      if (err) {
        error();
      }
    });
};

//ccl删除接口
const ccldelAPI = (Vue, id, success, error) => {
  ccldel(id)
    .then((res) => {
      if (res.code === 200) {
        success && success(res);
      }
    })
    .catch((err) => {
      if (err) {
        error();
      }
    });
};

//data删除接口
const datadelAPI = (Vue, id, success, error) => {
  datadel(id)
    .then((res) => {
      if (res.code === 200) {
        success && success(res);
      }
    })
    .catch((err) => {
      if (err) {
        error();
      }
    });
};

//填报步骤
export const steps = [
  // {
  //   name: "企业基本信息",
  //   type: ["1", "2", "3", "4", "5", "6"],
  // },
  {
    name: "企业经营状况",
    type: ["1", "2", "3", "4", "5", "6"],
  },
  {
    name: "PCB产品",
    type: ["1"],
  },
  {
    name: "集团布局",
    type: ["1", "2", "3", "4", "5", "6"],
  },
  {
    name: "生产指标",
    type: ["1", "2"],
  },
];

//提报表的类型
export  const typeBussine = [
  { label: "印制线路板", value: "1", style: { backgroundColor: "red" } },
  { label: "覆铜板", value: "2", style: { backgroundColor: "orange" } },
  { label: "专用材料", value: "3", style: { backgroundColor: "#677A82" } },
  { label: "专用化学品", value: "4", style: { backgroundColor: "green" } },
  { label: "专用设备与仪器", value: "5", style: { backgroundColor: "blue" } },
  { label: "环保洁净", value: "6", style: { backgroundColor: "purple" } },
];

export const handleStep = (type) => {
  const typekey = APIStatus.find((item) => item.matchNumber == type);
  if (typekey) {
   return steps.filter((item) => {
      return item.type.indexOf(typekey.type) > -1
    });
  }
};

<template>

  <div class="app-container change-password">
    <a-card>
      <p class="change-password_title">修改密码</p>
      <a-form-model ref="passwordForm" :model="passwordForm" :rules="rules" label-width="100px">
        <a-form-model-item has-feedback label="原密码" prop="oldPassword">
          <a-input-password
            v-model="passwordForm.oldPassword"
            placeholder="请输入原登录密码"
            show-password
          />
        </a-form-model-item>
        <a-form-model-item has-feedback label="新密码" prop="password">
          <a-input-password 
            v-model="passwordForm.password"
            placeholder="请输入新的登录密码"
            show-password
          />
        </a-form-model-item>
        <a-form-model-item has-feedback label="确认密码" prop="confirmPassword">
          <a-input-password 
            v-model="passwordForm.confirmPassword"
            placeholder="请再次输入登录密码"
            show-password
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary"  @click="submit()">保存</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-card>
    
  </div>
</template>

<script>
import { updatePersonPwd } from "@/api/account/passwordChange";
export default {
  name: "PasswordChange",
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.passwordForm.password !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      passwordForm: {
        oldPassword: '',
        password: '',
        confirmPassword: '',
      },
      // 表单校验
      calibration: false,
      rules: {
        oldPassword: [
          { required: true, message: "旧密码不能为空", trigger: 'change' },
        ],
        password: [
          {required: true, message: "新密码不能为空", trigger: 'change' },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          { required: true, message: "确认密码不能为空", trigger: 'change' },
          { required: true, validator: equalToPassword, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submit() { 
      let update = {
        id: this.$store.getters.userInfo.id,
        oldPassword: this.passwordForm.oldPassword,
        password: this.passwordForm.password
      }
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          updatePersonPwd(update).then(res => {
            if (res.status == 0 && res.code == 200) {
              this.$message.success('修改成功');
            } else {
              this.$message.error(res.msg);
            }
            this.resetForm("form");
          });
        }
      });     
    }
  }
};
</script>

<style lang="less" scoped>
.change-password{
  width: 70%;
  margin: 0 auto;
  margin-top: 30px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.047) 0px 0px 5px;
  &_title {
    color: #666666;
    font-weight: 700;
    font-size: 18px;
    margin-left: 20px;
    padding-left: 10px;
    border-left: 6px solid #409eff;
    margin-bottom: 60px;
  }
}
</style>

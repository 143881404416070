<template>
  <div class="isFixedMask">
    <div class="loadbox">
       <a-spin  size="large" :tip="txt" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FixedMask',
  pros:{
    ltxt:{
      type:String,
      default: () => ''
    }
  },
  data() {
    return {
      txt:'发送中...'
    };
  },
  watch:{
    ltxt:{
      deep:true,
      handler(v) {
        // this.txt = v
      }
    }
  },
  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>
.isFixedMask{
  text-align: center;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111123;
  .loadbox{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
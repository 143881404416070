var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    _vm._b(
      {
        attrs: { tag: "ul", value: _vm.list },
        on: {
          start: function ($event) {
            return _vm.handleStart($event, _vm.list)
          },
        },
      },
      "draggable",
      {
        group: { name: "form-draggable", pull: "clone", put: false },
        sort: false,
        animation: 180,
        ghostClass: "moving",
      },
      false
    ),
    _vm._l(_vm.list, function (val, index) {
      return _c(
        "li",
        {
          key: index,
          on: {
            dragstart: function ($event) {
              return _vm.$emit("generateKey", _vm.list, index)
            },
            click: function ($event) {
              return _vm.$emit("handleListPush", val)
            },
          },
        },
        [
          val.icon
            ? _c(
                "svg",
                { staticClass: "icon", attrs: { "aria-hidden": "true" } },
                [_c("use", { attrs: { "xlink:href": `#${val.icon}` } })]
              )
            : _vm._e(),
          _vm._v(" " + _vm._s(val.label) + " "),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
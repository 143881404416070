export default {
  "user.login.userName": "用户名",
  "user.login.password": "密码",
  "user.login.username.placeholder": "请输入账户名",
  "user.login.password.placeholder": "请输入密码",
  "user.login.message-invalid-credentials": "账户或密码错误",
  "user.login.message-invalid-verification-code": "验证码错误",
  "user.login.tab-login-credentials": "账户密码登录",
  "user.login.tab-login-mobile": "手机号登录",
  "user.login.mobile.placeholder": "手机号",
  "user.login.mobile.verification-code.placeholder": "验证码",
  "user.login.remember-me": "自动登录",
  "user.login.forgot-password": "忘记密码",
  "user.login.sign-in-with": "其他登录方式",
  "user.login.signup": "注册账户",
  "user.login.login": "登录",
  "user.login.prompt": "登录提示：",
  "user.login.prompt.begin": "账号名及密码同",
  "user.login.prompt.among": "会员管理系统",
  "user.login.prompt.end": "一致",
  "user.login.prompt.CapsLock": "大写锁定已打开",
  "user.email.required": "请输入邮箱地址！",
  "user.email.wrong-format": "邮箱地址格式错误！",
  "user.userName.required": "请输入帐户名或邮箱地址",
  "user.password.required": "请输入密码！",
  "user.password.twice.msg": "两次输入的密码不匹配!",
  "user.password.strength.msg": "密码强度不够 ",
  "user.password.strength.strong": "强度：强",
  "user.password.strength.medium": "强度：中",
  "user.password.strength.low": "强度：低",
  "user.password.strength.short": "强度：太短",
  "user.confirm-password.required": "请确认密码！",
  "user.phone-number.required": "请输入正确的手机号",
  "user.phone-number.wrong-format": "手机号格式错误！",
  "user.verification-code.required": "请输入验证码！",
  "user.apply.title": "入会申请",
  "user.apply.companyInfo.title": "公司信息",
  "user.apply.companyInfo.companyNameCn": "单位名称（中文）",
  "user.apply.companyInfo.companyNameCn.placeholder": "请输入单位名称（中文）",
  "user.apply.companyInfo.companyNameCn.required": "请输入单位名称（中文）",
  "user.apply.companyInfo.companyNameEn": "单位名称（英文）",
  "user.apply.companyInfo.companyNameEn.placeholder": "请输入单位名称（英文）",
  "user.apply.companyInfo.companyNameEn.required": "请输入单位名称（英文）",
  "user.apply.companyInfo.unifiedSocialCreditCode": "统一社会信用代码",
  "user.apply.companyInfo.unifiedSocialCreditCode.placeholder":
    "请输入统一社会信用代码",
  "user.apply.companyInfo.unifiedSocialCreditCode.required":
    "请输入统一社会信用代码",
  "user.apply.companyInfo.foundDate": "建立日期",
  "user.apply.companyInfo.foundDate.required": "请选择建立日期",
  "user.apply.companyInfo.logo": "公司LOGO",
  "user.apply.companyInfo.logo.btn": "点击上传",
  "user.apply.companyInfo.logoUrl.required": "请上传LOGO",
  "user.apply.companyInfo.address": "公司详细地址",
  "user.apply.companyInfo.address.placeholder": "请输入公司详细地址",
  "user.apply.companyInfo.address.required": "请输入公司详细地址",
  "user.apply.companyInfo.location": "公司所在地",
  "user.apply.companyInfo.location.placeholder": "请选择公司所在地",
  "user.apply.companyInfo.location.required": "请选择公司所在地",
  "user.apply.companyInfo.tel": "电话",
  "user.apply.companyInfo.tel.placeholder": "请输入电话",
  "user.apply.companyInfo.tel.required": "请输入电话",
  "user.apply.companyInfo.fax": "传真",
  "user.apply.companyInfo.fax.placeholder": "请输入传真",
  "user.apply.companyInfo.fax.required": "请输入传真",
  "user.apply.companyInfo.zipCode": "邮政编码",
  "user.apply.companyInfo.zipCode.placeholder": "请输入邮政编码",
  "user.apply.companyInfo.zipCode.required": "请输入邮政编码",
  "user.apply.companyInfo.email": "电子邮箱",
  "user.apply.companyInfo.email.placeholder": "请输入电子邮箱",
  "user.apply.companyInfo.email.required": "请输入电子邮箱",
  "user.apply.companyInfo.registeredCapital": "注册资本",
  "user.apply.companyInfo.registeredCapital.placeholder": "请输入注册资本",
  "user.apply.companyInfo.registeredCapital.required": "请输入注册资本",
  "user.apply.companyInfo.registeredCapital.unit": "万元",
  "user.apply.companyInfo.profile": "公司简介",
  "user.apply.companyInfo.profile.placeholder": "请输入公司简介,不少于200字",
  "user.apply.companyInfo.profile.required": "请输入公司简介",
  "user.apply.marketInfo.title": "产品及销售信息",
  "user.apply.marketInfo.classifications": "企业产品大类",
  "user.apply.marketInfo.classifications.required": "请选择企业产品大类",
  "user.apply.marketInfo.classification.PCB": "刚性板",
  "user.apply.marketInfo.classification.FPC": "挠性板",
  "user.apply.marketInfo.classification.PCBandFPC": "刚挠板",
  "user.apply.marketInfo.classification.Material": "专用材料",
  "user.apply.marketInfo.classification.CCL": "覆铜板",
  "user.apply.marketInfo.classification.Potion": "专用化学品",
  "user.apply.marketInfo.classification.Equipment": "专用设备/仪器",
  "user.apply.marketInfo.classification.ResearchInstitute": "环保",
  "user.apply.marketInfo.classification.Other": "其他",
  "user.apply.marketInfo.sales": "主要产品产量及能力",
  "user.apply.marketInfo.sales.placeholder": "请输入主要产品产量及能力",
  "user.apply.marketInfo.sales.required": "请输入主要产品产量及能力",
  "user.apply.marketInfo.lastYearSales": "去年销售额",
  "user.apply.marketInfo.lastYearSales.placeholder": "请输入去年销售额",
  "user.apply.marketInfo.lastYearSales.required": "请输入去年销售额",
  "user.apply.marketInfo.thisYearSales": "今年销售额（计划）",
  "user.apply.marketInfo.thisYearSales.placeholder": "请输入今年销售额（计划）",
  "user.apply.marketInfo.thisYearSales.required": "请输入今年销售额（计划）",
  "user.apply.workerInfo.title": "成员信息",
  "user.apply.workerInfo.numberOfStaff": "职工人数",
  "user.apply.workerInfo.numberOfStaff.placeholder": "请输入职工人数",
  "user.apply.workerInfo.numberOfStaff.required": "请输入职工人数",
  "user.apply.workerInfo.numberOfTechnicians": "技术人员数",
  "user.apply.workerInfo.numberOfTechnicians.placeholder": "请输入技术人员数",
  "user.apply.workerInfo.numberOfTechnicians.required": "请输入技术人员数",
  "user.apply.workerInfo.numberOfManagers": "管理人员数",
  "user.apply.workerInfo.numberOfManagers.placeholder": "请输入管理人员数",
  "user.apply.workerInfo.numberOfManagers.required": "请输入管理人员数",
  "user.apply.workerInfo.numberOfWorkers": "工人数",
  "user.apply.workerInfo.numberOfWorkers.placeholder": "请输入工人数",
  "user.apply.workerInfo.numberOfWorkers.required": "请输入工人数",
  "user.apply.workerInfo.functional": "职能",
  "user.apply.workerInfo.functional.legalRepresentative": "法定代表人",
  "user.apply.workerInfo.functional.meetingContact": "会务对接",
  "user.apply.workerInfo.functional.developmentContact": "工程/研发",
  "user.apply.workerInfo.functional.marketContact": "市场营销",
  "user.apply.workerInfo.functional.protectionContact": "环保",
  "user.apply.workerInfo.name": "姓名",
  "user.apply.workerInfo.position": "职位",
  "user.apply.workerInfo.phone": "手机号",
  "user.apply.workerInfo.email": "邮箱",
  "user.apply.workerInfo.action": "操作",
  "user.apply.workerInfo.tableCommit.error": "请填写完整成员信息。",
  "user.apply.workerInfo.phone.error": "请输入正确的手机号码",
  "user.apply.workerInfo.email.error": "请输入正确的邮箱",
  "user.apply.accountInfo.title": "账号信息",
  "user.apply.accountInfo.username": "用户名",
  "user.apply.accountInfo.username.placeholder": "请输入用户名",
  "user.apply.accountInfo.username.required": "请输入用户名",
  "user.apply.accountInfo.password": "密码",
  "user.apply.accountInfo.password.placeholder": "请输入密码",
  "user.apply.accountInfo.password.required": "请输入密码",
  "user.apply.accountInfo.type": "会员类型",
  "user.apply.accountInfo.type.placeholder": "请选择会员类型",
  "user.apply.accountInfo.type.required": "请选择会员类型",
  "user.apply.accountInfo.associationId": "协会",
  "user.apply.accountInfo.associationId.placeholder": "请选择协会",
  "user.apply.accountInfo.associationId.required": "请选择协会",
  "user.apply.checkInfo": "申请表单校验信息",
  "user.apply.submitBtn": "申请",
  "user.apply.saveSuccess": "草稿保存成功",
  "service.professor.addSuccess": "添加成功",
  "service.professor.error": "添加失败",
  "service.professor.deleteSuccess": "删除成功",
  "service.professor.deleteError": "删除失败",
  "service.professor.editSuccess": "修改成功",
  "service.professor.editError": "修改失败",

  "user.apply.account": "已有账号",
  "user.apply.fetchdata": "取出缓存数据",
  "user.apply.login": "请登录",
  "user.apply.vipnumber": "会员编号",
  "user.apply.password": "会员密码",
  "user.apply.pleasepassword": "请输入要修改的会员密码",
  "user.apply.username": "请输入会员编号",
  "user.apply.formphone": "请输入正确的手机号码或座机号码",
  "user.apply.formemail": "请输入正确的邮箱地址多个邮箱请使用;隔开",
  "user.apply.formcredit": "请输入正确的社会信用代码",
  "user.apply.formdesc": "请输入公司简介不少于200字数",
  "user.apply.w": "万",
  "user.apply.companyInfo.fj": "公司介绍（pdf）",
  "user.apply.companyInfo.head.hint": "点击保存后生效",
};

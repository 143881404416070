export default {
  'menu.welcome': '欢迎',
  'menu.home': '',
  'menu.index': '首页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',

  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.logout': '退出登录',

  'menu.info': '信息管理',
  'menu.info.emailPublish': '邮件管理',
  'menu.info.infoPublish': '催报列表',
  'menu.info.smsPublish': '短信管理',

  'menu.filling': '信息填报',
  'menu.filling.annual': '年度填报',
  'menu.filling.quarterly': '季度填报',
  'menu.filling.monthly': '月度填报',

  'menu.form': '表单管理',
  'menu.form.design': '表单设计',
  'menu.form.all': '全部表单',

  'menu.statistic': '统计报表',
  'menu.statistic.annual': '年度统计',
  'menu.statistic.quarterly': '季度统计',
  'menu.statistic.monthly': '月度统计',

  'menu.system': '系统管理',
  'menu.system.menu': '菜单管理',
  'menu.system.role': '角色管理',
  'menu.system.sync': '同步会员',
  'menu.personal': '个人中心',
  'menu.personal.personSetting': '个人设置',
  'menu.personal.passwordChange': '密码修改',
  'account.personal.passwordChange': '密码修改',
  'account.personal.personSetting': '个人设置',
};
